import { createMuiTheme } from '@material-ui/core/styles';

const colors = {
  powderYellow: '#FEFFEA',
  powderGreen: '#dcedc8',
  powderBlue: '#B8E4FF',
  powderOrange: '#FEE6B3',
  powderPink: '#FFB8B8',
  powderGrey: '#9C9EA1',
  lightGreen: '#9ccc65',
  white: '#FFF',
  red: '#f44336',
  yellow: '#eeff41',
  green: '#54b337',
  darkGreen: '#004D40',
  darkBackground: '#333',
  greyBackground: '#424242',
  grey: 'grey',
};

const getTheme = (theme) => {
  const darkMode = theme === 'dark';

  return createMuiTheme({
    maxWidth: 1300,
    palette: {
      type: darkMode ? 'dark' : 'light',
      primary: {
        main: darkMode ? colors.lightGreen : colors.darkGreen,
      },
      secondary: {
        main: darkMode ? colors.darkGreen : colors.lightGreen,
      },
      background: {
        default: darkMode ? colors.darkBackground : colors.powderGreen,
      },
      primaryBackground: darkMode ? colors.greyBackground : colors.white,
      danger: colors.red,
    },
    navDrawer: {
      openWidth: 240,
      closeWidth: 56,
      expandButtonColor: darkMode ? colors.powderGreen : colors.yellow,
      backgroundColor: darkMode ? colors.greyBackground : colors.lightGreen,
    },
    taxCast: {
      yearContainer: darkMode ? colors.powderGrey : colors.powderYellow,
      canvasExpando: darkMode ? colors.powderGrey : colors.powderYellow,
      primaryTextColor: colors.darkGreen,
      summaryText: colors.darkGreen,
      tiles: {
        incomeColor: colors.powderBlue,
        capitalGainsColor: colors.powderOrange,
        deductionsColor: colors.powderPink,
        moreIcon: colors.grey,
      },
    },
  });
};

export default getTheme;

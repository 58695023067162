import React from 'react';
import { Typography } from '@material-ui/core';

import MrBean from 'assets/img/Logo192x192.png';

const DrawerHeader = () => (
  <div style={{ display: 'flex' }}>
    <img src={MrBean} height='50' alt='placeholder' />
    <Typography variant='h4'>TaxCast</Typography>
  </div>
);

export default DrawerHeader;

/* eslint-disable import/prefer-default-export */
import { filter, forEach, map, pick, sortBy, split } from 'lodash';
import { categoryTypes } from 'constants/categories';

export const normalizedClientProfileProperties = () => {
  return [
    'prefix',
    'givenName',
    'middleName',
    'surname',
    'postfix',
    'filingStatus',
    'birthDate',
    'preferredEmailAddress',
    'preferredPhoneNumber',
    'advisorId',
  ];
};

export const normalizeClientProfile = (clientApiData) => {
  const client = pick(clientApiData, normalizedClientProfileProperties());

  client.fullName = `${client.givenName} ${client.surname}`;

  return { ...client, id: clientApiData.id.toString() };
};

export const normalizeForecast = (forecastApiData) => {
  const norm = pick(forecastApiData, ['name', 'notes', 'advisorId']);
  const yearList = split(forecastApiData.yearList, ',');

  // Support for filing status by tax year
  const filingStatusByYearList = split(
    forecastApiData.filingStatusByYearList,
    ','
  );

  return {
    ...norm,
    yearList,
    id: forecastApiData.id.toString(),
    filingStatusByYearList,
  };
};

export const normalizeTransaction = (transaction) => {
  const norm = pick(transaction, [
    'id',
    'clientProfileId',
    'forecastId',
    'category',
    'subcategory',
    'name',
    'value',
    'taxYear',
    'notes',
    'displaySequence',
    'charitableContributionTypeValue',
    'salePrice',
    'basisAmount',
    'isQualifiedBusinessIncome',
    'isSpecifiedServiceOrTradeBusiness',
    'w2WagesPaidByBusiness',
    'qualifiedPropertyAmountOwnedByBusiness',
    'isSection199AReitDividend',
    'associatedWith',
  ]);
  // return { ...norm };
  return {
    ...norm,
    taxYear: transaction.taxYear.toString(),
    value: transaction.value.toString(),
  };
};

const filterTransactions = (transactions, year, categoryType) => {
  const filtered = filter(
    transactions,
    ({ taxYear, category }) =>
      taxYear.toString() === year && category === categoryType
  );

  const normalizedTransactions = map(filtered, (data) =>
    normalizeTransaction(data)
  );

  return sortBy(normalizedTransactions, 'displaySequence');
};

export const normalizeTransactions = (transactions, yearList) => {
  const taxYears = {};

  forEach(yearList, (year) => {
    taxYears[year] = {
      ordinaryIncome: filterTransactions(
        transactions,
        year,
        categoryTypes.ordinaryIncome
      ),
      capitalGains: filterTransactions(
        transactions,
        year,
        categoryTypes.capitalGains
      ),
      deductions: filterTransactions(
        transactions,
        year,
        categoryTypes.deductions
      ),
    };
  });

  return taxYears;
};

export const normalizeTransactionSpread = (transactions) => {
  return transactions.map((tx) => normalizeTransaction(tx));
};

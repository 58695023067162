import React from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  makeStyles,
} from '@material-ui/core';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  PlaylistAdd as PlaylistAddIcon,
} from '@material-ui/icons';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  moreItemIcon: {
    minWidth: theme.spacing(4),
  },
  moreText: {
    color: theme.palette.primary.main,
  },
}));

const TransactionPopover = ({
  id,
  editTransaction,
  deleteTransaction,
  duplicateTransaction,
  anchorEl,
  togglePopover,
  taxYear,
}) => {
  const classes = useStyles();
  return (
    <Popover
      id={`${id}_popover`}
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={togglePopover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <List dense>
        {taxYear >= new Date().getFullYear() && (
          <ListItem button component='li' onClick={editTransaction}>
            <ListItemIcon className={classes.moreItemIcon}>
              <EditIcon color='primary' />
            </ListItemIcon>
            <ListItemText className={classes.moreText} primary='Edit' />
          </ListItem>
        )}

        <ListItem button component='li' onClick={duplicateTransaction}>
          <ListItemIcon className={classes.moreItemIcon}>
            <PlaylistAddIcon color='primary' />
          </ListItemIcon>
          <ListItemText className={classes.moreText} primary='Duplicate' />
        </ListItem>

        {taxYear >= new Date().getFullYear() && (
          <ListItem button component='li' onClick={deleteTransaction}>
            <ListItemIcon className={classes.moreItemIcon}>
              <DeleteIcon color='primary' />
            </ListItemIcon>
            <ListItemText className={classes.moreText} primary='Delete' />
          </ListItem>
        )}
      </List>
    </Popover>
  );
};

TransactionPopover.propTypes = {
  id: PropTypes.string.isRequired,
  editTransaction: PropTypes.func.isRequired,
  deleteTransaction: PropTypes.func.isRequired,
  anchorEl: PropTypes.objectOf(PropTypes.object),
  togglePopover: PropTypes.func.isRequired,
  duplicateTransaction: PropTypes.func.isRequired,
  taxYear: PropTypes.string.isRequired,
};

TransactionPopover.defaultProps = {
  anchorEl: null,
};

export default TransactionPopover;

/* eslint-disable import/prefer-default-export */

const isUnhandledExceptionResponse = (error) => {
  // For exceptions from the server which are unhandled, the response is:
  // {
  //    data: ""
  //    status: 500
  //    statusText: "Internal Server Error"
  // }
  let result = false;
  if (
    error &&
    error.response &&
    error.response.data === '' &&
    error.response.status === 500 &&
    error.response.statusText === 'Internal Server Error'
  ) {
    result = true;
  }
  return result;
};

export const shapeErrorsForToast = (error) => {
  if (isUnhandledExceptionResponse(error)) {
    return [
      'A problem occurred performing the operation. The technology team has been notified.',
    ];
  }

  if (error && error.response && error.response.data) {
    if (Array.isArray(error.response.data)) {
      return error.response.data;
    }
    return [error.response.data];
  }

  if (error && error.status) {
    return [`${error.status} - ${error.statusText}`];
  }

  // Special Case: May still occur until exception handling is fully implemented.
  if (error && !error.status) {
    return [error.toString()];
  }

  return [error];
};

/* eslint-disable import/prefer-default-export */

// Catchup: The forecast is behind meaning it is not
//          representing current year plus nine (9) years.
export const needToCatchUp = (forecast) => {
  const currentYear = new Date().getFullYear();
  const years = forecast.yearList.split(',');
  const latestYear = years[years.length - 1];
  const expectedLatestYear = currentYear + 9;
  return latestYear < expectedLatestYear;
};

export const numberOfYearsToCatchUp = (forecast) => {
  const currentYear = new Date().getFullYear();
  const years = forecast.yearList; // The yearList property is already normalized to an array.
  const latestYear = years[years.length - 1];
  const expectedLatestYear = currentYear + 9;
  return expectedLatestYear - latestYear;
};

// Rollover: The forecast is NOT behind BUT a new year may be added due to
//           availability of new tax table values. The association with
//           new tax table values is incidental but is generally close to
//           the end of the calendar year.
export const canDoRollover = (forecast, taxTablesYear) => {
  const currentYear = new Date().getFullYear();
  const years = forecast.yearList.split(',');
  const latestYear = years[years.length - 1];
  const expectedLatestYear = currentYear + 9;
  return +latestYear === +expectedLatestYear && taxTablesYear > currentYear;
};

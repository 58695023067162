import React from 'react';
import { Field } from 'react-final-form';
import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import noop from 'lodash/noop';

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.primary.main,
  },
  error: {
    color: theme.palette.danger,
  },
}));

const CheckboxField = ({ name, id, label, required, validate }) => {
  const classes = useStyles();
  return (
    <Field
      id={id}
      name={name}
      type='checkbox'
      validate={validate}
      render={({ input, meta }) => (
        <FormControlLabel
          className={meta.error && meta.touched ? classes.error : classes.text}
          control={
            <Checkbox
              name={name}
              required={required}
              color='primary'
              className={clsx({ [classes.error]: meta.error && meta.touched })}
              {...input}
            />
          }
          label={`${label}${required ? ' *' : ''}`}
        />
      )}
    />
  );
};

CheckboxField.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  validate: PropTypes.func,
};

CheckboxField.defaultProps = {
  required: false,
  validate: noop,
};

export default CheckboxField;

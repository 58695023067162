/* eslint max-lines: [2, {"max": 225, "skipComments": true, "skipBlankLines": true}] */
import React, { useState } from 'react';
import {
  Avatar,
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  PersonOutline as PersonOutlineIcon,
} from '@material-ui/icons';
import PropTypes from 'prop-types';

import { remove } from 'lodash';

import * as clientApi from 'api/client';
import { relationshipDisplayMap } from 'constants/relationships';
import { getAge } from 'lib/utils/dateTimeUtils';

import NewDialog from './NewDialog';
import EditDialog from './EditDialog';
import DeleteDialog from '../DeleteDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1000,
    width: '100%',
    flexGrow: 1,
  },
  list: {
    padding: theme.spacing(2),
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  content: {
    padding: theme.spacing(1),
  },
}));

const OtherDependents = ({ dependentsList, clientProfileId }) => {
  const classes = useStyles();
  const [showDialogDelete, setShowDialogDelete] = useState(false);
  const [selectedRelation, setSelectedRelation] = useState();
  const [selectedRelationId, setSelectedRelationId] = useState(-1);
  const [dependents, setDependents] = useState(dependentsList);
  const [showDialogNew, setShowDialogNew] = useState(false);
  const [showDialogEdit, setShowDialogEdit] = useState(false);

  const onClickEdit = (_, selectedRelationParam) => {
    setSelectedRelation(selectedRelationParam);
    setShowDialogEdit(true);
  };

  const onClickDelete = (event, relationId) => {
    setSelectedRelationId(relationId);
    setShowDialogDelete(true);
  };

  const onCloseDeleteDialog = (event) => {
    if (event === 'ok') {
      clientApi
        .deleteClientRelation(clientProfileId, selectedRelationId)
        .then(() => {
          const deletedIndex = dependents.findIndex((item) => {
            return item.id === selectedRelationId;
          });
          if (deletedIndex >= 0) {
            remove(dependents, { id: selectedRelationId });
            setDependents(dependents);
          }
          setSelectedRelationId(-1);
        })
        .catch(() => {
          setSelectedRelationId(-1);
        });
    } else {
      setSelectedRelationId(-1);
    }
    setShowDialogDelete(false);
  };

  const onClickAdd = () => {
    setShowDialogNew(true);
  };

  const onCloseEditDialog = (relation) => {
    if (relation) {
      const updatedIndex = dependents.findIndex((item) => {
        return item.id === relation.id;
      });
      if (updatedIndex >= 0) {
        const dependentsCopy = [...dependents];
        const updatedItem = { ...dependents[updatedIndex], ...relation };
        dependentsCopy[updatedIndex] = updatedItem;
        setDependents(dependentsCopy);
      }
    }
    setSelectedRelation(null);
    setShowDialogEdit(false);
  };

  const onCloseNewDialog = (relation) => {
    if (relation) {
      dependents.push(relation);
      setDependents(dependents);
    }
    setShowDialogNew(false);
  };

  return (
    <>
      <NewDialog
        show={showDialogNew}
        clientProfileId={clientProfileId}
        onClose={onCloseNewDialog}
      />
      <EditDialog
        dependent={selectedRelation}
        show={showDialogEdit}
        onClose={onCloseEditDialog}
      />
      <DeleteDialog
        title='Remove the dependent relationship?'
        content='Are you certain you want to remove thid relationship from the client profile?'
        show={showDialogDelete}
        onClose={onCloseDeleteDialog}
      />
      <Grid container item xs={12} className={classes.content}>
        <Grid item xs={9}>
          <Typography component='h6' variant='h6'>
            Other Dependents ({dependents.length})
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Button variant='contained' color='primary' onClick={onClickAdd}>
            Add
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} className={classes.content}>
          <Typography component='span'>
            <List className={classes.root}>
              {dependents.map((relation, index) => (
                <Grid item xs={12} key={relation.id}>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar className={classes.avatar}>
                        <PersonOutlineIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={`${relation.givenName} ${
                        relation.surname
                      } (${getAge(relation.birthDate)})`}
                      secondary={relationshipDisplayMap[relation.relationship]}
                    />
                    <ListItemSecondaryAction>
                      <Tooltip title='Edit'>
                        <IconButton
                          edge='end'
                          aria-label='edit'
                          onClick={(event) => onClickEdit(event, relation)}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title='Delete'>
                        <IconButton
                          edge='end'
                          aria-label='delete'
                          onClick={(event) => onClickDelete(event, relation.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  </ListItem>
                  {index < dependents.length - 1 && (
                    <Divider variant='inset' component='li' />
                  )}
                </Grid>
              ))}
            </List>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

OtherDependents.propTypes = {
  dependentsList: PropTypes.arrayOf(
    PropTypes.shape({
      givenName: PropTypes.string.isRequired,
      surname: PropTypes.string.isRequired,
      birthDate: PropTypes.string.isRequired,
      relationship: PropTypes.string.isRequired,
    })
  ),
  clientProfileId: PropTypes.string.isRequired,
};

OtherDependents.defaultProps = {
  dependentsList: null,
};

export default OtherDependents;

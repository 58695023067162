/* eslint max-lines: [2, {"max": 625, "skipComments": true, "skipBlankLines": true}] */
import React, { useEffect, useState } from 'react';
import {
  Backdrop,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';

import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { forEach, map } from 'lodash';
import { toast } from 'react-toastify';

import {
  categoryDisplayMap,
  subcategoryDisplayMap,
} from 'constants/categories';
import { shapeErrorsForToast } from 'lib/utils/errorUtil';
import { ErrorToast } from 'lib/components/ErrorToast';
import loading from 'assets/img/BouncingBarsBlack.png';
import { numberOfYearsToCatchUp } from 'lib/utils/forecastUtils';
import { currencyFormatter } from 'lib/utils/format';
import * as taxCastApi from 'api/taxCast';
import { NEW_TRANSACTION } from 'store/asyncActions/taxCast';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.maxWidth,
    [theme.breakpoints.down('lg')]: {
      margin: theme.spacing(1, 3),
    },
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(1, 'auto'),
    },
  },
  table: {
    padding: theme.spacing(2),
  },
}));

const ExtendForecast = () => {
  const history = useHistory();
  const classes = useStyles();
  const params = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const queryString = new URLSearchParams(useLocation().search);
  const selectedOption = useState(queryString.get('option'))[0];
  const [forecast, setForecast] = useState();
  const [transactions, setTransactions] = useState();
  const [
    copyLatestYearTransactionsToNewYears,
    setCopyLatestYearTransactionsToNewYears,
  ] = useState(true);
  const [
    extendingCompletedSuccessfully,
    setExtendingCompletedSuccessfully,
  ] = useState(false);
  const [
    cloningCompletedSuccessfully,
    setCloningCompletedSuccessfully,
  ] = useState(false);
  const [errorCount, setErrorCount] = useState(0);

  useEffect(() => {
    document.title = 'TaxCast - Extend Forecast';
  });

  useEffect(() => {
    const clientProfileId = params.id;
    taxCastApi
      .getClientData(clientProfileId)
      .then((response) => {
        setForecast(response.forecast);
        const latestYear =
          response.forecast.yearList[response.forecast.yearList.length - 1];
        setTransactions([
          ...response.forecast.years[latestYear].ordinaryIncome,
          ...response.forecast.years[latestYear].capitalGains,
          ...response.forecast.years[latestYear].deductions,
        ]);
      })
      .catch((error) => {
        toast.error(<ErrorToast errorMessages={shapeErrorsForToast(error)} />, {
          autoClose: false,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [params.id]);

  const handleInputChangeCopyTransactions = () => {
    setCopyLatestYearTransactionsToNewYears(
      !copyLatestYearTransactionsToNewYears
    );
  };

  const parseOptionForDisplay = (option) => {
    if (option === 'catchup') {
      return 'Catch-up';
    }
    if (option === 'rollover') {
      return 'Rollover';
    }
    return 'Extend';
  };

  const getExtendedToYear = (option) => {
    const currentYear = new Date().getFullYear();
    if (option === 'catchup') {
      return currentYear + 9;
    }
    return currentYear + 1;
  };

  const handleClickOk = () => {
    setIsLoading(true);
    let numberOfYearsToAdd = 0;
    const latestYear = +forecast.yearList[forecast.yearList.length - 1];

    if (selectedOption === 'catchup') {
      numberOfYearsToAdd = numberOfYearsToCatchUp(forecast);
    }

    if (selectedOption === 'rollover') {
      // This operation always adds a single year only.
      numberOfYearsToAdd = 1;
    }

    // Update the forecast to include new years and filing statuses.
    const latestFilingStatus =
      forecast.filingStatusByYearList[
        forecast.filingStatusByYearList.length - 1
      ];
    for (let i = 1; i <= numberOfYearsToAdd; i += 1) {
      forecast.yearList.push(latestYear + i);
      forecast.filingStatusByYearList.push(latestFilingStatus);
    }
    // Update the entire forecast.
    // The forecast provided by the API utility is normalized.
    // Normalizing removes certain elements and reformats other elements.
    // Therefore, in order to make PUT work properly, the forecast must be
    // fetched without normalizing.
    taxCastApi
      .getForecast(params.id, forecast.id)
      .then((response) => {
        const newForecast = { ...response.data };
        newForecast.yearList = forecast.yearList.join(',');
        newForecast.filingStatusByYearList = forecast.filingStatusByYearList.join(
          ','
        );
        // BUG: There is a defect in the template subsystem.
        newForecast.clientProfileId = params.id;
        taxCastApi
          .putForecast(newForecast)
          .then(() => {
            toast.success(
              `Canvas updated with new year(s) and filing status(es).`
            );
            setExtendingCompletedSuccessfully(true);
          })
          .catch((error) => {
            setErrorCount(errorCount + 1);
            toast.error(
              <ErrorToast errorMessages={shapeErrorsForToast(error)} />,
              {
                autoClose: false,
              }
            );
          });
      })
      .catch((error) => {
        setErrorCount(errorCount + 1);
        toast.error(<ErrorToast errorMessages={shapeErrorsForToast(error)} />, {
          autoClose: false,
        });
      });

    // Clone transactions.
    // NOTE: This operation can be done independently of
    //       updating the forecast. The two tables are
    //       loosely linked. Therefore, even if the forecast
    //       update fails, the new transactions may be
    //       created successfully.
    if (copyLatestYearTransactionsToNewYears) {
      for (let i = 1; i <= numberOfYearsToAdd; i += 1) {
        forEach(transactions, (tx) => {
          const newTx = { ...tx };
          newTx.taxYear = latestYear + i;
          dispatch(NEW_TRANSACTION(newTx));
        });
      }
      setCloningCompletedSuccessfully(true); // This is NOT actually accurate.
    } else {
      setIsLoading(false);
      setCloningCompletedSuccessfully(true);
    }
  };

  const handleClickCancel = () => {
    history.push(`/clients/${params.id}`);
  };

  if (!forecast || !transactions) {
    return null;
  }

  return (
    <>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <img
          src={loading}
          alt='Loading...'
          className='loading-img'
          height='256px'
          width='256px'
        />
      </Backdrop>
      <Grid container item xs={12} className={classes.root}>
        <Grid container item xs={12}>
          <Grid container>
            <Paper elevation={3} style={{ padding: 16 }}>
              <Grid container className={classes.form}>
                <Grid container item xs={12}>
                  <Grid item xs={12}>
                    <Typography component='h4' variant='h4' color='primary'>
                      {parseOptionForDisplay(selectedOption)} Canvas
                    </Typography>
                    <Typography variant='h5' component='h5' color='primary'>
                      The latest tax year for the selected canvas
                      is {forecast.yearList[forecast.yearList.length - 1]}. This
                      canvas will be extended to {getExtendedToYear(selectedOption)}.
                    </Typography>
                  </Grid>
                </Grid>
                {transactions.length > 0 && (
                  <>
                    <Grid container className={classes.table}>
                      <Grid item xs={12}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Category</TableCell>
                              <TableCell>Sub-Category</TableCell>
                              <TableCell>Name</TableCell>
                              <TableCell>Value</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {map(transactions, (item) => (
                              <TableRow key={item.id}>
                                <TableCell>
                                  {categoryDisplayMap[item.category]}
                                </TableCell>
                                <TableCell>
                                  {subcategoryDisplayMap[item.subcategory]}
                                </TableCell>
                                <TableCell>{item.name}</TableCell>
                                <TableCell>
                                  {currencyFormatter.format(item.value)}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Grid>
                    </Grid>
                    <Grid container justify='flex-end'>
                      <Grid item>
                        <Typography variant='h5' component='h5' color='primary'>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name='copyLatestYearTransactionsToNewYears'
                                id='copyLatestYearTransactionsToNewYears'
                                checked={copyLatestYearTransactionsToNewYears}
                                onChange={handleInputChangeCopyTransactions}
                                color='primary'
                              />
                            }
                            label={`Copy ${
                              forecast.yearList[forecast.yearList.length - 1]
                            } transactions to new tax year(s).`}
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}
                <Grid container justify='flex-end'>
                  <Grid item>
                    <Button
                      variant='outlined'
                      color='primary'
                      onClick={handleClickCancel}
                      style={{ marginRight: 5 }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={handleClickOk}
                    >
                      Ok
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={extendingCompletedSuccessfully && cloningCompletedSuccessfully}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Success</DialogTitle>
        <DialogContent>
          {errorCount === 0 && (
            <DialogContentText id='alert-dialog-description'>
              {copyLatestYearTransactionsToNewYears
                ? `The canvas was extended and transactions were cloned successfully.`
                : `The canvas was extended successfully.`}
            </DialogContentText>
          )}
          {errorCount !== 0 && (
            <DialogContentText id='alert-dialog-description'>
              At least some of the operations did not complete successfully.
              Please note the error toasts and contact support by email
              (support@taxcastplan.com) or phone (888.482.9227 (888-4-TAXCAST))
              to report the issues.
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClickCancel}
            color='primary'
            value='ok'
            variant='contained'
          >
            Return to Canvas
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ExtendForecast;

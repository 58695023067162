/* eslint max-lines: [2, {"max": 400, "skipComments": true, "skipBlankLines": true}] */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import { forEach, pickBy } from 'lodash';
import { spreadTransaction } from 'lib/utils/transactionSpreader';
import { validAmount } from 'lib/utils/form';
import { isNullEmptyOrBlank, isValidAmount } from 'lib/utils/validation';

const useStyles = makeStyles((theme) => ({
  button: {
    [theme.breakpoints.up('sm')]: {
      minWidth: theme.spacing(1),
    },
  },
  spreadField: {},
}));

const SpreadTransactionForm = ({
  mode,
  value,
  taxYear,
  forecastYearList,
  onIsSpreadUpdate,
  onSpreadDataUpdate,
  onValidate,
}) => {
  const classes = useStyles();
  const [showSpread, setShowSpread] = useState(mode === 'Duplicate');
  const [selectAllNone, setSelectAllNone] = useState(
    mode === 'Duplicate' ? 'All' : 'None'
  );
  const [spreadByAmount, setSpreadByAmount] = useState(1);
  const [isErrorSpreadByAmount, setIsErrorSpreadByAmount] = useState(false);
  const [spreadByUnits, setSpreadByUnits] = useState('times');
  const [isApplySpreadEnabled, setIsApplySpreadEnabled] = useState(true);
  /* spreadData structure:
   {
    <year>: { selected: <bool>, value: <num>, displaySequence: <num>, error: <bool> }, 
    2024: { selected: true, value: '', displaySequence: -1, error: false },
    2025: { selected: true, value: 1100, displaySequence: -1, error: true }
   }
  */
  const [spreadData, setSpreadData] = useState();

  useEffect(() => {
    const updatingSpreadData = {};
    forEach(forecastYearList, (year) => {
      if (year >= new Date().getFullYear()) {
        updatingSpreadData[year] = {
          selected: year >= taxYear && showSpread,
          value: '',
          displaySequence: -1,
          error: false,
        };
      }
    });
    setSpreadData({ ...updatingSpreadData });
  }, [taxYear, forecastYearList, showSpread]);

  const validateFormSpread = (newSpreadData) => {
    let isValid = true;
    if (newSpreadData) {
      forEach(Object.keys(newSpreadData), (year) => {
        if (newSpreadData[year].selected) {
          isValid =
            isValid &&
            !isNullEmptyOrBlank(newSpreadData[year].value) &&
            isValidAmount(newSpreadData[year].value);
        }
      });
    }
    onValidate(isValid);
  };

  const updateOverallSelectionState = (spreadDataState) => {
    let newSelectionState = 'Some';
    // Separate the selected items.
    const selectedItems = pickBy(spreadDataState, (item) => item.selected);
    if (Object.keys(selectedItems).length === 0) newSelectionState = 'None';
    if (
      Object.keys(selectedItems).length === Object.keys(spreadDataState).length
    )
      newSelectionState = 'All';
    setSelectAllNone(newSelectionState);
  };

  const handleSpreadSwitchChange = () => {
    const willShowSpread = !showSpread;
    const updatingSpreadData = {};
    forEach(forecastYearList, (year) => {
      if (year >= new Date().getFullYear()) {
        updatingSpreadData[year] = {
          selected: year >= taxYear && willShowSpread,
          value: '',
          displaySequence: -1,
          error: false,
        };
      }
    });
    setSpreadData({ ...updatingSpreadData });
    updateOverallSelectionState(updatingSpreadData);
    setShowSpread(!showSpread);
    onIsSpreadUpdate(!showSpread);
    onSpreadDataUpdate(updatingSpreadData);
    validateFormSpread(updatingSpreadData);
  };

  const handleSpreadByAmountInputChange = (event) => {
    setSpreadByAmount(event.target.value);
    setIsErrorSpreadByAmount(
      !Number(event.target.value) && spreadByUnits !== 'split'
    );
    setIsApplySpreadEnabled(
      Number(event.target.value) || spreadByUnits === 'split'
    );
    validateFormSpread(spreadData);
  };

  const handleSpreadByUnitsChange = (event) => {
    setSpreadByUnits(event.target.value);
    setSpreadByAmount(event.target.value === 'split' ? '' : spreadByAmount);
    setIsErrorSpreadByAmount(
      !Number(spreadByAmount) && spreadByUnits !== 'split'
    );
    const updatingSpreadData = { ...spreadData };
    forEach(forecastYearList, (year) => {
      if (year >= new Date().getFullYear()) {
        updatingSpreadData[year].value = '';
      }
    });
    setSpreadData({ ...updatingSpreadData });
    onSpreadDataUpdate(updatingSpreadData);
    validateFormSpread(spreadData);
  };

  const toggleSelectAllNone = () => {
    const nextState = selectAllNone === 'All' ? 'None' : 'All';
    const updatingSpreadData = { ...spreadData };
    forEach(forecastYearList, (year) => {
      if (year >= new Date().getFullYear()) {
        updatingSpreadData[year].selected = nextState === 'All';
      }
    });
    setSpreadData({ ...updatingSpreadData });
    setSelectAllNone(nextState);
    onSpreadDataUpdate(updatingSpreadData);
    validateFormSpread(updatingSpreadData);
  };

  const handleResetSpread = () => {
    const updatingSpreadData = { ...spreadData };
    forEach(forecastYearList, (year) => {
      if (year >= new Date().getFullYear()) {
        updatingSpreadData[year] = {
          ...spreadData[year],
          selected: year >= taxYear,
          value: '',
          displaySequence: -1,
        };
      }
    });
    setSpreadData(updatingSpreadData);
    updateOverallSelectionState(updatingSpreadData);
    setSpreadByAmount(1);
    setSpreadByUnits('times');
    setIsApplySpreadEnabled(true);
    onSpreadDataUpdate(updatingSpreadData);
    validateFormSpread(updatingSpreadData);
  };

  const handleApplySpread = () => {
    if (spreadByUnits === 'split' || Number(spreadByAmount)) {
      const result = spreadTransaction(
        value,
        taxYear,
        spreadData,
        spreadByAmount,
        spreadByUnits
      );
      const updatingSpreadData = { ...spreadData };
      forEach(forecastYearList, (year) => {
        if (year >= new Date().getFullYear()) {
          updatingSpreadData[year].value = result[year].value;
        }
      });
      onSpreadDataUpdate(updatingSpreadData);
      validateFormSpread(updatingSpreadData);
    }
  };

  const toggleSingleYear = (event) => {
    const year = event.target.id.replace('spreadCheckbox', '');
    const updatingSpreadData = { ...spreadData };
    updatingSpreadData[year].selected = !spreadData[year].selected;
    updatingSpreadData[year].value = spreadData[year].selected
      ? spreadData[year].value
      : '';
    updatingSpreadData[year].error =
      updatingSpreadData[year].selected &&
      !isValidAmount(updatingSpreadData[year].value);
    setSpreadData({ ...updatingSpreadData });
    updateOverallSelectionState(updatingSpreadData);
    onSpreadDataUpdate(updatingSpreadData);
    validateFormSpread(updatingSpreadData);
  };

  const handleYearlyAmountInputChange = (event) => {
    const year = event.target.id.replace('amount', '');
    const updatingSpreadData = { ...spreadData };
    updatingSpreadData[year].value = event.target.value;
    setSpreadData({ ...updatingSpreadData });
    onSpreadDataUpdate(updatingSpreadData);
    validateFormSpread(updatingSpreadData);
    updatingSpreadData[year].error =
      spreadData[year].selected && !Number(spreadData[year].value);
  };

  const handleYearlyAmountInputBlur = (event) => {
    const year = event.target.id.replace('amount', '');
    const updatingSpreadData = { ...spreadData };
    // If the user departs an amount field and the value is either:
    // !provided || 0 -> deselect the associated year. The inverse case is
    // handled as the amount field is disabled year's checkbox is unchecked.
    if (
      isNullEmptyOrBlank(event.target.value) ||
      Number(event.target.value) === 0
    ) {
      updatingSpreadData[year].selected = false;
    } else {
      updatingSpreadData[year].value = event.target.value;
    }
    updatingSpreadData[year].error =
      spreadData[year].selected &&
      !isValidAmount(
        spreadData[year].value || isNullEmptyOrBlank(spreadData[year].value)
      );
    updateOverallSelectionState(updatingSpreadData);
    setSpreadData({ ...updatingSpreadData });
    onSpreadDataUpdate(updatingSpreadData);
    validateFormSpread(updatingSpreadData);
  };

  if (!spreadData) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <Grid container item xs spacing={1}>
        {mode === 'New' && (
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={showSpread}
                  onChange={handleSpreadSwitchChange}
                  name='showSpreadSwitch'
                  color='primary'
                />
              }
              label='Duplicate'
            />
          </Grid>
        )}
        <Grid item xs={6} className={classes.spreadField}>
          <TextField
            name='spreadByAmount'
            id='spreadByAmount'
            label='Change by'
            fullWidth
            variant='standard'
            disabled={spreadByUnits === 'split' || !showSpread}
            value={spreadByAmount}
            onChange={handleSpreadByAmountInputChange}
            error={isErrorSpreadByAmount}
            required={spreadByUnits !== 'split'}
          />
        </Grid>
        <Grid item xs={6} className={classes.spreadField}>
          <FormControl fullWidth>
            <InputLabel id='spreadByUnitsLabel'>Units(s)</InputLabel>
            <Select
              id='spreadByUnits'
              name='spreadByUnits'
              value={spreadByUnits}
              onChange={handleSpreadByUnitsChange}
              disabled={!showSpread}
            >
              <MenuItem value='currency' key='currency'>
                Dollars ($)
              </MenuItem>
              <MenuItem value='percent' key='percent'>
                Percent (%)
              </MenuItem>
              <MenuItem value='times' key='times'>
                Times (*)
              </MenuItem>
              <MenuItem value='split' key='split'>
                Split Evenly
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} className={classes.spreadField}>
          <FormControlLabel
            control={
              <Checkbox
                name='selectAllNone'
                id='selectAllNone'
                onChange={toggleSelectAllNone}
                indeterminate={selectAllNone === 'Some'}
                checked={selectAllNone === 'All'}
                disabled={!showSpread}
                color='primary'
              />
            }
            label={selectAllNone}
          />
        </Grid>
        <Grid item xs={8} className={classes.spreadField}>
          <Button
            className={classes.button}
            variant='outlined'
            onClick={handleResetSpread}
            disabled={!showSpread}
          >
            Reset
          </Button>
          &nbsp;
          <Button
            className={classes.button}
            variant='outlined'
            onClick={handleApplySpread}
            disabled={!isApplySpreadEnabled || !showSpread}
          >
            Apply
          </Button>
        </Grid>
        {/* <Grid item xs={4} className={classes.spreadField}>
            <Button
              className={classes.button}
              variant='outlined'
              onClick={handleApplySpread}
              disabled={!isApplySpreadEnabled || !showSpread}
            >
              Apply
            </Button>
          </Grid> */}
        {forecastYearList.map(
          (year) =>
            year >= new Date().getFullYear() && (
              <Grid container item xs={12} spacing={0} key={year}>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={`spreadCheckbox${year}`}
                        id={`spreadCheckbox${year}`}
                        key={`spreadCheckbox${year}`}
                        checked={spreadData[year]?.selected}
                        onChange={toggleSingleYear}
                        disabled={!showSpread}
                        color='primary'
                      />
                    }
                    label={year}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name={`amount${year}`}
                    id={`amount${year}`}
                    key={`amount${year}`}
                    fullWidth
                    variant='standard'
                    disabled={!spreadData[year]?.selected || !showSpread}
                    error={spreadData[year]?.error}
                    helperText={validAmount(spreadData[year]?.value)}
                    value={spreadData[year]?.value}
                    onChange={handleYearlyAmountInputChange}
                    onBlur={handleYearlyAmountInputBlur}
                    required={spreadData[year]?.selected}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            )
        )}
      </Grid>
    </Grid>
  );
};

SpreadTransactionForm.propTypes = {
  mode: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  taxYear: PropTypes.number.isRequired,
  forecastYearList: PropTypes.arrayOf(PropTypes.string).isRequired,
  onIsSpreadUpdate: PropTypes.func.isRequired,
  onSpreadDataUpdate: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
};

export default SpreadTransactionForm;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  taxTableYear: new Date().getFullYear(),
};

/* eslint no-param-reassign: ["error", { "ignorePropertyModificationsFor": ["state"] }] */
const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    LOAD_SETTINGS(state, action) {
      state.taxTableYear = +action.payload;
    },
  },
});

export const settingsActions = settingsSlice.actions;
export default settingsSlice.reducer;

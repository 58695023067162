/* eslint-disable import/prefer-default-export */
export const charitableContributionTypesDisplayMap = {
  cash: 'Cash',
  conservationEasementFarmerRancher: 'Conservation Easement: Farmer/Rancher',
  conservationEasement: 'Conservation Easement',
  propertyDepreciatedFairMarketValue: 'Property - Depreciated (FMV)',
  propertyShortTermAppreciationBasis:
    'Property with Short-term Appreciation (Basis)',
  propertyShortTermAppreciationFairMarketValue:
    'Property with Long-term Appreciation (FMV)',
  propertyLongTermAppreciationBasis:
    'Property with Long-term Appreciation (Basis)',
};

export const charitableContributionTypes = {
  cash: 'cash',
  conservationEasementFarmerRancher: 'conservationEasementFarmerRancher',
  conservationEasement: 'conservationEasement',
  propertyDepreciatedFairMarketValue: 'propertyDepreciatedFairMarketValue',
  propertyShortTermAppreciationBasis: 'propertyShortTermAppreciationBasis',
  propertyShortTermAppreciationFairMarketValue:
    'propertyShortTermAppreciationFairMarketValue',
  propertyLongTermAppreciationBasis: 'propertyLongTermAppreciationBasis',
};

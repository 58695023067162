import React from 'react';
import { Button, Grid, Paper, makeStyles } from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';
import { RUN_CALCULATIONS } from 'store/asyncActions/taxCast';
import ClientShortProfile from './ClientShortProfile';
import TaxTrend from './TaxTrend';

const useStyles = makeStyles((theme) => ({
  root: { height: '100%', padding: theme.spacing(2) },
}));

const TrendRibbon = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const clientProfileId = useSelector(({ taxCast }) => taxCast.client.id);
  const forecastId = useSelector(({ taxCast }) => taxCast.forecast.id);
  const isRunningCalculations = useSelector(
    ({ taxCast }) => taxCast.isRunningCalculations
  );
  const advisorId = useSelector(({ auth }) => auth.account.id);

  const handleCalculateClick = () => {
    dispatch(RUN_CALCULATIONS({ clientProfileId, forecastId, advisorId }));
  };

  return (
    <Paper className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid item xs={3} sm={2} md={1} className={classes.addButton}>
            <Button
              disabled={isRunningCalculations}
              variant='contained'
              color='primary'
              onClick={handleCalculateClick}
            >
              {isRunningCalculations ? 'Running' : 'Calculate'}
            </Button>
          </Grid>
          {/* <FormControl variant='outlined' color='primary' fullWidth>
            <InputLabel id='scenario_input_label'>Scenario</InputLabel>
            <Select
              labelId='scenario_input_label'
              id='scenario_select'
              value='basic'
              label='Scenario' // must match the input label component above due to outline
            >
              <MenuItem value='basic'>Basic Scenario</MenuItem>
            </Select>
          </FormControl> */}
        </Grid>

        <Grid item xs={12} sm={6} lg={12}>
          <ClientShortProfile />
        </Grid>

        <Grid item xs={12} sm={6} lg={12}>
          <TaxTrend />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default TrendRibbon;

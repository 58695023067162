import React from 'react';
import { Field } from 'react-final-form';
import { InputAdornment, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

const FormField = ({
  name,
  id,
  label,
  validate,
  autoFocus,
  type,
  maxLength,
  rows,
  rowsMax,
  multiline,
  required,
  shrink,
  submitOnEnter,
  startAdornment,
  variant,
  format,
  className,
  readOnly,
}) => (
  <Field
    name={name}
    id={id}
    type={type}
    validate={validate}
    format={format}
    formatOnBlur
    render={({ input, meta }) => {
      const showError = !!meta.error && !!meta.touched;

      return (
        <TextField
          className={className}
          label={label}
          autoFocus={autoFocus}
          fullWidth
          variant={variant}
          rowsMax={rowsMax}
          rows={rows}
          multiline={multiline}
          required={required}
          error={showError}
          helperText={!!meta.touched && meta.error}
          InputLabelProps={{
            shrink,
          }}
          disabled={readOnly}
          InputProps={{
            inputProps: {
              maxLength,
              autoComplete: 'off',
              'aria-required': required,
              onKeyPress: (e) => {
                if (e.key === 'Enter' && !submitOnEnter && !multiline)
                  e.preventDefault();
              },
            },
            startAdornment: startAdornment && (
              <InputAdornment position='start'>{startAdornment}</InputAdornment>
            ),
          }}
          {...input}
          value={input.value || ''} // https://github.com/final-form/react-final-form/issues/529
        />
      );
    }}
  />
);

FormField.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  validate: PropTypes.func,
  autoFocus: PropTypes.bool,
  type: PropTypes.string,
  maxLength: PropTypes.number,
  required: PropTypes.bool,
  rows: PropTypes.number,
  rowsMax: PropTypes.number,
  multiline: PropTypes.bool,
  submitOnEnter: PropTypes.bool,
  shrink: PropTypes.bool,
  variant: PropTypes.string,
  format: PropTypes.func,
  startAdornment: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
};

FormField.defaultProps = {
  validate: noop,
  autoFocus: false,
  type: 'text',
  maxLength: 50,
  required: false,
  rows: 1,
  rowsMax: 1,
  multiline: false,
  submitOnEnter: false,
  shrink: undefined,
  startAdornment: undefined,
  variant: 'standard',
  format: undefined,
  className: undefined,
  readOnly: false,
  disabled: false,
};

export default FormField;

/* eslint-disable import/prefer-default-export */
import axios from 'axios';

import { forEach } from 'lodash';

import { baseUrl } from 'constants/api';
import { canDoRollover, needToCatchUp } from 'lib/utils/forecastUtils';
import {
  normalizeClientProfile,
  normalizeForecast,
  normalizeTransaction,
  normalizeTransactionSpread,
  normalizeTransactions,
} from './utils';

export const getClientData = (id, availableTaxTableYear) =>
  axios.get(`${baseUrl}/api/canvases/${id}`).then(({ data }) => {
    const client = normalizeClientProfile(data.clientProfile);

    // BCL: Updated to support extending outdated forecasts
    //      and/or rolling forecasts foreward when new tax
    //      tables are available.
    //      This must be done before normalizing the forecast.
    const needsToCatchUp = needToCatchUp(data.forecast);
    const canRollover = canDoRollover(data.forecast, availableTaxTableYear);

    const forecast = normalizeForecast(data.forecast);
    forecast.years = normalizeTransactions(
      data.transactions,
      forecast.yearList
    );

    return { client, forecast, needsToCatchUp, canRollover };
  });

export const createTransaction = (transactionDetail) =>
  axios
    .post(`${baseUrl}/api/transactions`, transactionDetail)
    .then(({ data }) => normalizeTransactionSpread(data));

export const moveTransaction = (updateDetails) =>
  axios.patch(`${baseUrl}/api/transactions/${updateDetails.id}`, updateDetails);

export const editTransaction = (transaction) =>
  axios
    .put(`${baseUrl}/api/transactions/${transaction.id}`, transaction)
    .then(() => normalizeTransaction(transaction));

export const deleteTransaction = (id, forecastId) =>
  axios.delete(`${baseUrl}/api/forecasts/${forecastId}/transactions/${id}`);

export const runCalculations = (clientProfileId, forecastId, advisorId) =>
  axios
    .post(`${baseUrl}/api/calculations`, {
      clientProfileId,
      forecastId,
      advisorId,
    })
    .then(({ data }) => data);

export const patchForecast = (
  clientProfileId,
  forecastId,
  taxYear,
  filingStatus
) =>
  axios
    .patch(`${baseUrl}/api/forecasts/${forecastId}`, {
      clientProfileId,
      taxYear,
      filingStatus,
    })
    .then(() => {});

export const createTransactionSpread = (transactions) => {
  forEach(transactions, (tx) => {
    createTransaction(tx);
  });
};

export const getForecast = (clientProfileId, forecastId) =>
  axios.get(
    `${baseUrl}/api/client-profiles/${clientProfileId}/forecasts/${forecastId}`
  );

export const putForecast = (forecast) =>
  axios.put(`${baseUrl}/api/forecasts/${forecast.id}`, forecast);

/* eslint react/prop-types: 0 */
import React, { useEffect, useState } from 'react';
import { Backdrop, Grid, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
// import axios from 'axios';

import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';

import loading from 'assets/img/BouncingBarsBlack.png';
import { baseReportingUrl } from 'constants/api';
import UnusedOrdinaryBracketCapacity from './UnusedOrdinaryBracketCapacity';


// import PDFObject from 'pdfobject';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
  },
}));

const ReportViewer = () => {
  const classes = useStyles();
  const [isLocalStorageLoaded, setIsLocalStorageLoaded] = useState(false);
  const reportingBaseUrl = `${baseReportingUrl}/api`;
  // const reportingBaseUrl =
  // 'http://localhost:7071/api';
  const [reportState, setReportState] = useState({});
  const [isBusy, setIsBusy] = useState(true);
  // const [numPages, setNumPages] = useState(null);
  const functionsKey =
    'twvFT/INZxwdwV7bYp7Bik0io5GcJJdzt1u/FHPWj2u3g75HDnMC1g==';
  const taxCastUserId = useSelector(({ auth }) => auth.account.id);
  const accessToken = window.sessionStorage.getItem('taxcast.auth.accessToken');
  // const [fileUrl, setFileUrl] = useState(`${reportingBaseUrl}/reports/${reportState.reportName}/advisors/${reportState.advisorId}/client-profiles/${reportState.clientProfileId}/forecasts/${reportState.forecastId}`);
  // const [rawPdf, setRawPdf] = useState();

  // const base64ToArrayBuffer = (data) => {
  //   console.log(data);
  //   var input = window.btoa(unescape(encodeURIComponent(data))); //.substring(data.indexOf(',') + 1);
  //   // var input = data.substring(data.indexOf(',') + 1);
  //   console.log(input);
  //   return input;
  //   var binaryString = decodeURIComponent(escape(window.atob(input))); // window.atob(encodeURIComponent(input)); // unescape(decodeURIComponent(input)));
  //   console.log(binaryString);
  //   return binaryString;
  //   var binaryLen = data.length;
  //   var bytes = new Uint8Array(binaryLen);
  //   for (var i = 0; i < binaryLen; i++) {
  //     var ascii = binaryString.charCodeAt(i);
  //     bytes[i] = ascii;
  //   }
  //   console.log(bytes);
  //   return bytes;
  // };

  const onDocumentLoadSuccess = () => {
    // // console.log(documentProxy);
    // setNumPages(7);
    // // setFileUrl(base64ToArrayBuffer(documentProxy));
    setIsBusy(false);
  };

  const deserializeStateFromLocalStorage = () => {
    const state = localStorage.getItem('report-state');
    const deserializedState = JSON.parse(state);
    setReportState(deserializedState);
    setIsLocalStorageLoaded(true);
    // setFileUrl(`${reportingBaseUrl}/repodrts/${deserializedState.reportName}/advisors/${deserializedState.advisorId}/client-profiles/${deserializedState.clientProfileId}/forecasts/${deserializedState.forecastId}`);
  };

  useEffect(() => {
    if (!isLocalStorageLoaded) {
      deserializeStateFromLocalStorage();
    }
    // else {
    //   axios.get(`${reportingBaseUrl}/reports/${reportState.reportName}/advisors/${reportState.advisorId}/client-profiles/${reportState.clientProfileId}/forecasts/${reportState.forecastId}`)
    //   .then((response) => {
    //     if (response.status === 200) {
    //       // console.log(response);
    //       // const embedValue = `data:application/pdf;base64,${response.data}`;
    //       // console.log(embedValue);
    //       // setFileUrl(base64ToArrayBuffer(response.data));
    //       // PDFObject.embed(`${embedValue}`, "#pdfViewer");
    //     } else {
    //       console.log(response.status);
    //     }
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });

    // }
  }, [isLocalStorageLoaded]);

  const toolbarPluginInstance = toolbarPlugin({});
  const { Toolbar } = toolbarPluginInstance;

  return (
    <Worker workerUrl='https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js'>
      <Grid container>
        <Backdrop className={classes.backdrop} open={isBusy}>
          <img
            src={loading}
            alt='Loading...'
            className='loading-img'
            height='256px'
            width='256px'
          />
        </Backdrop>
        {reportState &&
          reportState.reportType === 'application/pdf' &&
          isLocalStorageLoaded && (
            <Grid container item spacing={2} className={classes.root}>
              {/* <div id="pdfViewer"></div> */}
              <Toolbar>
                {(props) => {
                  const {
                    EnterFullScreen,
                    Print,
                    Zoom,
                    ZoomIn,
                    ZoomOut,
                  } = props;
                  return (
                    <>
                      <div style={{ padding: '0px 2px' }}>
                        <ZoomOut />
                      </div>
                      <div style={{ padding: '0px 2px' }}>
                        <Zoom />
                      </div>
                      <div style={{ padding: '0px 2px' }}>
                        <ZoomIn />
                      </div>
                      <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                        <EnterFullScreen />
                      </div>
                      <div style={{ padding: '0px 2px' }}>
                        <Print />
                      </div>
                    </>
                  );
                }}
              </Toolbar>
              <Viewer
                plugins={[toolbarPluginInstance]}
                fileUrl={`${reportingBaseUrl}/reports/${reportState.reportName}/affiliates/${reportState.affiliateId}/advisors/${reportState.advisorId}/client-profiles/${reportState.clientProfileId}/forecasts/${reportState.forecastId}`}
                httpHeaders={{
                  'x-functions-key': functionsKey,
                  'x-taxcast-user-id': taxCastUserId,
                  Authorization: `Bearer ${accessToken}`,
                }}
                onDocumentLoad={onDocumentLoadSuccess}
                // plugins={[toolbarPluginInstance]}
              />
            </Grid>
          )}
        {reportState && reportState.reportType === 'visualization' && (
          <Grid container item spacing={2} className={classes.root}>
            <UnusedOrdinaryBracketCapacity
              advisorId={reportState.advisorId}
              clientProfileId={reportState.clientProfileId}
              forecastId={reportState.forecastId}
              forecast={reportState.forecast}
              calculations={reportState.calculations}
              advisorGivenName={reportState.advisorGivenName}
              advisorSurname={reportState.advisorSurname}
              disclosureA={reportState.disclosureA}
              disclosureB={reportState.disclosureB}
              disclosureC={reportState.disclosureC}
              clientGivenName={reportState.clientGivenName}
              clientSurname={reportState.clientSurname}
            />
          </Grid>
        )}
      </Grid>
    </Worker>
  );
};

export default ReportViewer;

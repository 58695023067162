// import { LogLevel } from "@azure/msal-browser";
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
const msie11 = ua.indexOf('Trident/');
const msedge = ua.indexOf('Edge/');
const firefox = ua.indexOf('Firefox');
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

const tenant = 'canvilyticsb2c.onmicrosoft.com';
const signInPolicy = 'B2C_1_SignUpSignInTaxCast';
const applicationId = process.env.REACT_APP_MSAL_APPLICATION_ID;

// To support custom subdomains, build the domain string.
// Ignore port numbers for the common ports. NOTE: HTTPS is required so port 80 should fail.
const protocolAndHostName = `${window.location.protocol}//${window.location.hostname}`;
const port =
  window.location.protocol === 'http:' ? `:${window.location.port}` : '';
const reactRedirectUri = protocolAndHostName + port; // process.env.REACT_APP_MSAL_REDIRECT_URI;

const tenantSubdomain = tenant.split('.')[0];
const instance = `https://${tenantSubdomain}.b2clogin.com`;
const signInAuthority = `${instance}/${tenant}/${signInPolicy}`;

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: applicationId,
    authority: signInAuthority,
    redirectUri: `${reactRedirectUri}/login`,
    postLogoutRedirectUri: `${reactRedirectUri}/logout`,
    knownAuthorities: ['canvilyticsb2c.b2clogin.com'],
  },
  cache: {
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
  // system: {
  //   loggerOptions: {
  //       loggerCallback: (level, message, containsPii) => {
  //           if (containsPii) {
  //               return;
  //           }
  //           switch (level) {
  //               case LogLevel.Error:
  //                   console.error(message);
  //                   break;
  //               case LogLevel.Info:
  //                   console.info(message);
  //                   break;
  //               case LogLevel.Verbose:
  //                   console.debug(message);
  //                   break;
  //               case LogLevel.Warning:
  //                   console.warn(message);
  //                   break;
  //               default:
  //                 break;
  //               }
  //       }
  //   }
  // },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: [
    'openid',
    'profile',
    'https://canvilyticsb2c.onmicrosoft.com/ab122c9b-3da9-42f6-aa8b-a09b4a1f996b/TaxCast.Advisor',
  ],
};

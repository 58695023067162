/* eslint max-lines: [2, {"max": 475, "skipComments": true, "skipBlankLines": true}] */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Divider,
  Grid,
  MenuItem,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Form } from 'react-final-form';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import omit from 'lodash/omit';
import { useHistory } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {
  composeValidators,
  isEmail,
  required,
  trimWhiteSpace,
} from 'lib/utils/form';

import CheckboxField from 'lib/components/CheckboxField';
import FormField from 'lib/components/FormField';
import SelectField from 'lib/components/SelectField';
import DatePickerField from 'lib/components/DatePickerField';
import { useSelector } from 'react-redux';
import {
  filingStatusDisplayMap,
  filingStatusTypes,
} from 'constants/filingStatuses';
import * as clientApi from 'api/client';
import { toast } from 'react-toastify';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { shapeErrorsForToast } from 'lib/utils/errorUtil';
import { ErrorToast } from 'lib/components/ErrorToast';

import { isValidPhoneNumberUnadorned } from 'lib/utils/validation';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '95vh',
    maxWidth: 1000,
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(1, 'auto'),
    },
    [theme.breakpoints.down('lg')]: {
      margin: theme.spacing(1, 3),
    },
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(1, 'auto'),
    },
  },
  title: {
    fontWeight: 'bold',
    textAlign: 'center',
    padding: theme.spacing(3),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    padding: theme.spacing(2),
  },
  field: {
    [theme.breakpoints.up('sm')]: {
      minHeight: theme.spacing(12),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const NewClientForm = () => {
  const classes = useStyles();
  const advisorId = useSelector(({ auth }) => auth.account.id);
  const [clientId, setClientId] = useState('');
  const history = useHistory();
  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {
    document.title = 'TaxCast - New Client';
  }, []);

  const createNewClient = (form) => {
    setIsBusy(true);
    const clientProfile = omit(form, ['sameBillingAddress']);

    if (form.sameBillingAddress) {
      clientProfile.billingAddressLineOne = form.preferredAddressLineOne;
      clientProfile.billingAddressLineTwo = form.preferredAddressLineTwo;
      clientProfile.billingAddressCity = form.preferredAddressCity;
      clientProfile.billingAddressStateOrProvince =
        form.preferredAddressStateOrProvince;
      clientProfile.billingAddressPostalCode = form.preferredAddressPostalCode;
    }

    // BCL - Add actual post to server.
    clientProfile.advisorId = advisorId;

    // Sanitize phone number to ensure no adornments.
    if (!isValidPhoneNumberUnadorned(clientProfile.preferredPhoneNumber)) {
      const error =
        'Phone number must be ten digits with no adornments. e.g. 9065551212';
      toast.error(<ErrorToast errorMessages={shapeErrorsForToast(error)} />, {
        autoClose: false,
      });
      setIsBusy(false);
    } else {
      // BCL - No need to use redux.
      clientApi
        .createClientProfile(clientProfile)
        .then((response) => {
          toast.success(`Successfully created profile.`);
          setIsBusy(false);
          setClientId(response.data.id);
        })
        .catch((error) => {
          setIsBusy(false);
          toast.error(
            <ErrorToast errorMessages={shapeErrorsForToast(error)} />,
            {
              autoClose: false,
            }
          );
        });
    }
  };

  const navigateNext = (action) => {
    if (action === 'profile') {
      history.push(`/clients/${clientId}?tab=0&subtab=1`);
    } else if (action === 'canvas') {
      history.push(`/clients/${clientId}`);
    }
  };

  return (
    <>
      <UnauthenticatedTemplate>Nah dawg</UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <Backdrop className={classes.backdrop} open={isBusy}>
          <CircularProgress color='inherit' />
        </Backdrop>
        <Grid container className={classes.root}>
          <Paper spacing={2} elevation={3}>
            <Typography
              variant='h4'
              component='h1'
              color='primary'
              className={classes.title}
            >
              New Client
            </Typography>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Form
                onSubmit={createNewClient}
                initialValues={{ sameBillingAddress: true }}
                render={({ handleSubmit, values, submitting, invalid }) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <Grid container justify='center' className={classes.form}>
                      <Grid container item xs={12} md={10} lg={9} spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            variant='h6'
                            component='h2'
                            color='primary'
                          >
                            Client Details
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} className={classes.field}>
                          <FormField
                            name='givenName'
                            id='givenName'
                            label='First Name'
                            validate={required}
                            format={trimWhiteSpace}
                            required
                            autoFocus
                            variant='outlined'
                          />
                        </Grid>

                        <Grid item xs={12} sm={4} className={classes.field}>
                          <FormField
                            name='surname'
                            id='surname'
                            label='Last Name'
                            validate={required}
                            format={trimWhiteSpace}
                            required
                            variant='outlined'
                          />
                        </Grid>

                        <Grid item xs={12} sm={4} className={classes.field}>
                          <DatePickerField
                            name='birthDate'
                            id='birthDate'
                            label='Birth Date'
                            validate={required}
                            required
                            variant='outlined'
                            disableFuture
                          />
                        </Grid>

                        <Grid item xs={12} sm={4} className={classes.field}>
                          <FormField
                            name='preferredPhoneNumber'
                            id='preferredPhoneNumber'
                            label='Phone'
                            validate={required}
                            format={trimWhiteSpace}
                            required
                            variant='outlined'
                          />
                        </Grid>

                        <Grid item xs={12} sm={8} className={classes.field}>
                          <FormField
                            name='preferredEmailAddress'
                            id='preferredEmailAddress'
                            label='Email'
                            validate={composeValidators(required, isEmail)}
                            format={trimWhiteSpace}
                            required
                            variant='outlined'
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Divider />
                        </Grid>

                        <Grid item xs={12}>
                          <Typography
                            variant='h6'
                            component='h2'
                            color='primary'
                          >
                            Address
                          </Typography>
                        </Grid>

                        <Grid item xs={12} className={classes.field}>
                          <FormField
                            name='preferredAddressLineOne'
                            id='preferredAddressLineOne'
                            label='Address Line 1'
                            validate={required}
                            required
                            format={trimWhiteSpace}
                            variant='outlined'
                          />
                        </Grid>

                        <Grid item xs={12} className={classes.field}>
                          <FormField
                            name='preferredAddressLineTwo'
                            id='preferredAddressLineTwo'
                            label='Address Line 2'
                            format={trimWhiteSpace}
                            variant='outlined'
                          />
                        </Grid>

                        <Grid item xs={12} sm={4} className={classes.field}>
                          <FormField
                            name='preferredAddressCity'
                            id='preferredAddressCity'
                            label='City'
                            format={trimWhiteSpace}
                            variant='outlined'
                            validate={required}
                            required
                          />
                        </Grid>

                        <Grid item xs={12} sm={4} className={classes.field}>
                          <FormField
                            name='preferredAddressStateOrProvince'
                            id='preferredAddressStateOrProvince'
                            label='State'
                            format={trimWhiteSpace}
                            variant='outlined'
                            validate={required}
                            required
                          />
                        </Grid>

                        <Grid item xs={12} sm={4} className={classes.field}>
                          <FormField
                            name='preferredAddressPostalCode'
                            id='preferredAddressPostalCode'
                            label='Postal Code'
                            format={trimWhiteSpace}
                            variant='outlined'
                            validate={required}
                            required
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <CheckboxField
                            name='sameBillingAddress'
                            id='sameBillingAddress'
                            label='Use this for billing address'
                          />
                        </Grid>

                        {!values.sameBillingAddress && (
                          <>
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                variant='h6'
                                component='h2'
                                color='primary'
                              >
                                Billing Address
                              </Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.field}>
                              <FormField
                                name='billingAddressLineOne'
                                id='billingAddressLineOne'
                                label='Billing Address Line 1'
                                validate={required}
                                required
                                format={trimWhiteSpace}
                                variant='outlined'
                              />
                            </Grid>

                            <Grid item xs={12} className={classes.field}>
                              <FormField
                                name='billingAddressLineTwo'
                                id='billingAddressLinTwo'
                                label='Billing Address Line 2'
                                format={trimWhiteSpace}
                                variant='outlined'
                              />
                            </Grid>

                            <Grid item xs={12} sm={4} className={classes.field}>
                              <FormField
                                name='billingAddressCity'
                                id='billingAddressCity'
                                label='Billing City'
                                format={trimWhiteSpace}
                                variant='outlined'
                                validate={required}
                                required
                              />
                            </Grid>

                            <Grid item xs={12} sm={4} className={classes.field}>
                              <FormField
                                name='billingAddressStateOrProvince'
                                id='billingAddressStateOrProvince'
                                label='Billing State'
                                format={trimWhiteSpace}
                                variant='outlined'
                                validate={required}
                                required
                              />
                            </Grid>

                            <Grid item xs={12} sm={4} className={classes.field}>
                              <FormField
                                name='billingAddressPostalCode'
                                id='billingAddressPostalCode'
                                label='Billing Postal Code'
                                format={trimWhiteSpace}
                                variant='outlined'
                                validate={required}
                                required
                              />
                            </Grid>
                          </>
                        )}

                        <Grid item xs={12}>
                          <Divider />
                        </Grid>

                        <Grid item xs={12}>
                          <Typography
                            variant='h6'
                            component='h2'
                            color='primary'
                          >
                            Tax information
                          </Typography>
                        </Grid>

                        <Grid item xs={12} className={classes.field}>
                          <SelectField
                            id='filingStatus'
                            name='filingStatus'
                            label='Filing Status'
                            variant='outlined'
                            validate={required}
                            required
                          >
                            {Object.keys(filingStatusTypes).map((item) => (
                              <MenuItem value={item} key={item}>
                                {filingStatusDisplayMap[item]}
                              </MenuItem>
                            ))}
                          </SelectField>
                        </Grid>

                        <Grid item xs={12}>
                          <FormField
                            name='notes'
                            id='notes'
                            label='Notes'
                            variant='outlined'
                            rows={5}
                            rowsMax={5}
                            multiline
                            maxLength={1000}
                          />
                        </Grid>

                        <Grid
                          container
                          item
                          xs={12}
                          justify='flex-end'
                          spacing={2}
                        >
                          <Button
                            disabled={submitting}
                            color='primary'
                            variant='outlined'
                            onClick={() => history.push('/clients/search')}
                            style={{ marginRight: 5 }}
                          >
                            Cancel
                          </Button>
                          <Button
                            disabled={submitting || invalid}
                            type='submit'
                            color='primary'
                            variant='contained'
                          >
                            Submit
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                )}
              />
            </MuiPickersUtilsProvider>
          </Paper>
        </Grid>
        <Dialog
          open={clientId !== ''}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>Success!</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              The profile was created successfully. What do you want to do next?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => navigateNext('profile')}
              color='primary'
              value='cancel'
            >
              More Details
            </Button>
            <Button
              onClick={() => navigateNext('canvas')}
              color='primary'
              value='ok'
              autoFocus
            >
              Start Planning
            </Button>
          </DialogActions>
        </Dialog>
      </AuthenticatedTemplate>
    </>
  );
};

export default NewClientForm;

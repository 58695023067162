/* eslint max-lines: [2, {"max": 275, "skipComments": true, "skipBlankLines": true}] */
import React, { useEffect } from 'react';
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import HelpAbout from 'assets/img/Help-About.jpg';
import HelpAccountMaintenance from 'assets/img/Help-AccountMaintenance.jpg';
import HelpContactUsSupport from 'assets/img/Help-ContactUsSupport.jpg';
import HelpCurrentProvisions from 'assets/img/Help-CurrentProvisions.jpg';
import HelpGlossary from 'assets/img/Help-Glossary.jpg';
import HelpRoadmap from 'assets/img/Help-Roadmap.jpg';
import HelpTaxPlanningWithTaxCast from 'assets/img/Help-TaxPlanningWithTaxCast.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    marginTop: theme.spacing(3),
  },
  card: {
    maxWidth: 345,
  },
}));

const HelpAndSupport = () => {
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    document.title = 'TaxCast - Help';
  }, []);

  const handleClickContactUsSupport = () => history.push('/help/contact-us');
  const handleClickTaxPlanningWithTaxCast = () =>
    history.push('/help/tax-planning');
  const handleClickGlossary = () => history.push('/help/glossary');
  const handleClickCurrentProvisions = () =>
    history.push('/help/current-provisions');
  const handleClickFutureProvisions = () =>
    history.push('/help/future-provisions');
  const handleClickAccountMaintenance = () =>
    history.push('/help/account-maintenance');
  const handleClickAbout = () => history.push('/help/about');

  return (
    <Grid container className={classes.root} padding={2} spacing={1}>
      <Grid item xs={12} sm={6} md={3}>
        <Card className={classes.card}>
          <CardActionArea onClick={handleClickContactUsSupport}>
            <CardMedia
              component='img'
              alt='Contact Us and Support'
              height='140'
              image={HelpContactUsSupport}
              title='Contact Us / Support'
            />
            <CardContent>
              <Typography gutterBottom variant='h5' component='h2'>
                Get Help
              </Typography>
              <Typography variant='body2' color='textSecondary' component='p'>
                Need help with your account? Have a question about TaxCast? Want
                to learn more about tax planning? Reach out to us!
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button
              size='small'
              color='primary'
              onClick={handleClickContactUsSupport}
            >
              Contact Us / Support
            </Button>
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Card className={classes.card}>
          <CardActionArea onClick={handleClickTaxPlanningWithTaxCast}>
            <CardMedia
              component='img'
              alt='Tax Planning with TaxCast'
              height='140'
              image={HelpTaxPlanningWithTaxCast}
              title='Tax Planning with TaxCast'
            />
            <CardContent>
              <Typography gutterBottom variant='h5' component='h2'>
                Watch and Learn
              </Typography>
              <Typography variant='body2' color='textSecondary' component='p'>
                These videos will give you the basics of using TaxCast to help
                your clients optimize their tax liability.
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button
              size='small'
              color='primary'
              onClick={handleClickTaxPlanningWithTaxCast}
            >
              Tax Planning with TaxCast&trade;
            </Button>
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Card className={classes.card}>
          <CardActionArea onClick={handleClickGlossary}>
            <CardMedia
              component='img'
              alt='Glossary'
              height='140'
              image={HelpGlossary}
              title='Glossary'
            />
            <CardContent>
              <Typography gutterBottom variant='h5' component='h2'>
                Tax Code Terms
              </Typography>
              <Typography variant='body2' color='textSecondary' component='p'>
                Form 4952? Series of Substantially Equal Periodic Payments?
                Learn what these terms and more mean and how they apply to your
                clients.
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button size='small' color='primary' onClick={handleClickGlossary}>
              Glossary
            </Button>
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Card className={classes.card}>
          <CardActionArea onClick={handleClickCurrentProvisions}>
            <CardMedia
              component='img'
              alt='Current Provisions'
              height='140'
              image={HelpCurrentProvisions}
              title='Current Provisions'
            />
            <CardContent>
              <Typography gutterBottom variant='h5' component='h2'>
                Included in TaxCast
              </Typography>
              <Typography variant='body2' color='textSecondary' component='p'>
                Find out what tax provisions are currently covered by TaxCast.
                Take a look at the Roadmap to see what is coming next.
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button
              size='small'
              color='primary'
              onClick={handleClickCurrentProvisions}
            >
              Current Provisions
            </Button>
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Card className={classes.card}>
          <CardActionArea onClick={handleClickFutureProvisions}>
            <CardMedia
              component='img'
              alt='Future Provisions'
              height='140'
              image={HelpRoadmap}
              title='Future Provisions'
            />
            <CardContent>
              <Typography gutterBottom variant='h5' component='h2'>
                In Development
              </Typography>
              <Typography variant='body2' color='textSecondary' component='p'>
                Learn what portions of the tax are being implemented. Current
                Provisions shows you what is already implemented.
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button
              size='small'
              color='primary'
              onClick={handleClickFutureProvisions}
            >
              Future Provisions
            </Button>
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Card className={classes.card}>
          <CardActionArea onClick={handleClickAccountMaintenance}>
            <CardMedia
              component='img'
              alt='Account Maintenance'
              height='140'
              image={HelpAccountMaintenance}
              title='Account Maintenance'
            />
            <CardContent>
              <Typography gutterBottom variant='h5' component='h2'>
                Account Questions?
              </Typography>
              <Typography variant='body2' color='textSecondary' component='p'>
                Coming soon
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button
              size='small'
              color='primary'
              onClick={handleClickAccountMaintenance}
            >
              Account Maintenance
            </Button>
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Card className={classes.card}>
          <CardActionArea onClick={handleClickAbout}>
            <CardMedia
              component='img'
              alt='About'
              height='140'
              image={HelpAbout}
              title='About'
            />
            <CardContent>
              <Typography gutterBottom variant='h5' component='h2'>
                Version Information
              </Typography>
              <Typography variant='body2' color='textSecondary' component='p'>
                Verify the version of TaxCast you are using. This can be helpful
                in troubleshooting issues with the support team.
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button size='small' color='primary' onClick={handleClickAbout}>
              About
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};

export default HelpAndSupport;

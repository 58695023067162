/* eslint max-lines: [2, {"max": 230, "skipComments": true, "skipBlankLines": true}] */
import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import { currencyFormatter } from 'lib/utils/format';
import {
  categoryDisplayMap,
  subcategoryDisplayMap,
} from 'constants/categories';
import { charitableContributionTypesDisplayMap } from 'constants/charitableContributionTypes';

const useStyles = makeStyles((theme) => ({
  leftPanel: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    position: 'relative',
  },
  category: {
    paddingTop: theme.spacing(2),
  },
  subcategory: {
    paddingLeft: theme.spacing(1),
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
    },
  },
}));

const TransactionInfoPanel = ({ mode, transaction }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.leftPanel}>
      <Grid container>
        <Grid item xs={12}>
          <Typography color='primary' component='h2' variant='h6'>
            {mode} Transaction
          </Typography>
        </Grid>

        {mode === 'Duplicate' && (
          <>
            <Grid item xs={4} sm={12} className={classes.category}>
              <Typography color='primary'>Name</Typography>
            </Grid>
            <Grid item xs={8} sm={12} className={classes.subcategory}>
              <Typography>{transaction.name}</Typography>
            </Grid>
          </>
        )}

        {mode === 'Duplicate' && (
          <>
            <Grid item xs={4} sm={12} className={classes.category}>
              <Typography color='primary'>Value</Typography>
            </Grid>
            <Grid item xs={8} sm={12} className={classes.subcategory}>
              <Typography>
                {currencyFormatter.format(transaction.value)}
              </Typography>
            </Grid>
          </>
        )}

        <Grid item xs={4} sm={12} className={classes.category}>
          <Typography color='primary'>TaxYear</Typography>
        </Grid>
        <Grid item xs={8} sm={12} className={classes.subcategory}>
          <Typography>{transaction.taxYear}</Typography>
        </Grid>

        <Grid item xs={4} sm={12} className={classes.category}>
          <Typography color='primary'>Category</Typography>
        </Grid>
        <Grid item xs={8} sm={12} className={classes.subcategory}>
          <Typography>{categoryDisplayMap[transaction.category]}</Typography>
        </Grid>

        <Grid item xs={4} sm={12} className={classes.category}>
          <Typography color='primary'>Sub-Category</Typography>
        </Grid>
        <Grid item xs={8} sm={12} className={classes.subcategory}>
          <Typography>
            {subcategoryDisplayMap[transaction.subcategory]}
          </Typography>
        </Grid>

        {mode === 'Duplicate' &&
          transaction.subcategory === 'charitableContribution' && (
            <>
              <Grid item xs={4} sm={12} className={classes.category}>
                <Typography color='primary'>
                  Charitable Contribution Type
                </Typography>
              </Grid>
              <Grid item xs={8} sm={12} className={classes.subcategory}>
                <Typography>
                  {
                    charitableContributionTypesDisplayMap[
                      transaction.charitableContributionTypeValue
                    ]
                  }
                </Typography>
              </Grid>
            </>
          )}

        {mode === 'Duplicate' &&
          transaction.subcategory === 'longTermCapitalGains' && (
            <>
              <Grid item xs={4} sm={12} className={classes.category}>
                <Typography color='primary'>Sale Price</Typography>
              </Grid>
              <Grid item xs={8} sm={12} className={classes.subcategory}>
                <Typography>
                  {currencyFormatter.format(transaction.salePrice)}
                </Typography>
              </Grid>
              <Grid item xs={4} sm={12} className={classes.category}>
                <Typography color='primary'>Sale Price</Typography>
              </Grid>
              <Grid item xs={8} sm={12} className={classes.subcategory}>
                <Typography>
                  {currencyFormatter.format(transaction.basisAmount)}
                </Typography>
              </Grid>
              <Grid item xs={4} sm={12} className={classes.category}>
                <Typography color='primary'>Sale Price</Typography>
              </Grid>
              <Grid item xs={8} sm={12} className={classes.subcategory}>
                <Typography>
                  {currencyFormatter.format(
                    transaction.salePrice - transaction.basisAmount
                  )}
                </Typography>
              </Grid>
            </>
          )}

        {mode === 'Duplicate' &&
          (transaction.subcategory === 'soleProprietorshipIncome' ||
            transaction.subcategory === 'sCorpDistributions') && (
            <>
              <Grid item xs={4} sm={12} className={classes.category}>
                <Typography color='primary'>
                  Qualified Business Income
                </Typography>
              </Grid>
              <Grid item xs={8} sm={12} className={classes.subcategory}>
                <Typography>
                  {transaction.isQualifiedBusinessIncome ? 'Yes' : 'No'}
                </Typography>
              </Grid>
              <Grid item xs={4} sm={12} className={classes.category}>
                <Typography color='primary'>
                  Specified Service Trade or Business
                </Typography>
              </Grid>
              <Grid item xs={8} sm={12} className={classes.subcategory}>
                <Typography>
                  {transaction.isSpecifiedServiceOrTradeBusiness ? 'Yes' : 'No'}
                </Typography>
              </Grid>
              <Grid item xs={4} sm={12} className={classes.category}>
                <Typography color='primary'>
                  W2 Wages Paid By Business
                </Typography>
              </Grid>
              <Grid item xs={8} sm={12} className={classes.subcategory}>
                <Typography>
                  {currencyFormatter.format(transaction.w2WagesPaidByBusiness)}
                </Typography>
              </Grid>
              <Grid item xs={4} sm={12} className={classes.category}>
                <Typography color='primary'>
                  Qualified Property Owned by Business
                </Typography>
              </Grid>
              <Grid item xs={8} sm={12} className={classes.subcategory}>
                <Typography>
                  {currencyFormatter.format(
                    transaction.qualifiedPropertyAmountOwnedByBusiness
                  )}
                </Typography>
              </Grid>
            </>
          )}

        {mode === 'Duplicate' &&
          transaction.subcategory === 'nonQualifiedDividends' && (
            <>
              <Grid item xs={4} sm={12} className={classes.category}>
                <Typography color='primary'>
                  Section 199A REIT Dividend
                </Typography>
              </Grid>
              <Grid item xs={8} sm={12} className={classes.subcategory}>
                <Typography>
                  {transaction.isSection199AReitDividend ? 'Yes' : 'No'}
                </Typography>
              </Grid>
            </>
          )}

        {mode === 'Duplicate' && (
          <>
            <Grid item xs={4} sm={12} className={classes.category}>
              <Typography color='primary'>Notes</Typography>
            </Grid>
            <Grid item xs={8} sm={12} className={classes.subcategory}>
              <Typography>{transaction.notes}</Typography>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

TransactionInfoPanel.propTypes = {
  mode: PropTypes.string.isRequired,
  transaction: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    taxYear: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    subcategory: PropTypes.string.isRequired,
    notes: PropTypes.string,
    charitableContributionTypeValue: PropTypes.string,
    salePrice: PropTypes.number,
    basisAmount: PropTypes.number,
    isQualifiedBusinessIncome: PropTypes.bool,
    isSpecifiedServiceOrTradeBusiness: PropTypes.bool,
    w2WagesPaidByBusiness: PropTypes.number,
    qualifiedPropertyAmountOwnedByBusiness: PropTypes.number,
    isSection199AReitDividend: PropTypes.bool,
  }),
};

TransactionInfoPanel.defaultProps = {
  transaction: {},
};

export default TransactionInfoPanel;

/* eslint-disable import/prefer-default-export */
export const filingStatusTypes = {
  single: 'single',
  marriedFilingJointly: 'marriedFilingJointly',
  // qualifyingWidow: "qualifyingWidow",
  marriedFilingSeparatelyNoCohabitation:
    'marriedFilingSeparatelyNoCohabitation',
  marriedFilingSeparatelySomeCohabitation:
    'marriedFilingSeparatelySomeCohabitation',
  headOfHousehold: 'headOfHousehold',
};

export const filingStatusDisplayMap = {
  single: 'Single',
  marriedFilingJointly: 'Married Filing Jointly',
  // qualifyingWidow: "Qualifying Widow(er)",
  marriedFilingSeparatelyNoCohabitation:
    'Married Filing Separately-No Cohabitation',
  marriedFilingSeparatelySomeCohabitation:
    'Married Filing Separately-Some Cohabitation',
  headOfHousehold: 'Head of Household',
};

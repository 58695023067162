import React, { useEffect, useRef, useState } from 'react';
import { Field } from 'react-final-form';
import { FormControl, FormHelperText, InputLabel, Select } from '@material-ui/core';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

const SelectField = ({
  name,
  id,
  label,
  required,
  children,
  variant,
  validate,
  fullWidth,
  margin,
}) => {
  // inputLabel and labelWidth is used for outlined dropdowns
  // without the code below, select not recognize the label, and will render a line through it
  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);
  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  return (
    <Field
      id={id}
      name={name}
      validate={validate}
      render={({ input, meta }) => (
        <FormControl
          error={meta.error && meta.touched}
          variant={variant}
          fullWidth={fullWidth}
          margin={margin}
        >
          <InputLabel ref={inputLabel} htmlFor={id} required={required} variant={variant}>
            {label}
          </InputLabel>
          <Select
            labelWidth={labelWidth}
            inputProps={{
              name,
              id,
            }}
            fullWidth={fullWidth}
            {...input}
          >
            {children}
          </Select>
          {meta.error && meta.touched && <FormHelperText>{meta.error}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};

SelectField.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  required: PropTypes.bool,
  variant: PropTypes.string,
  margin: PropTypes.string,
  fullWidth: PropTypes.bool,
  validate: PropTypes.func,
};

SelectField.defaultProps = {
  required: false,
  variant: 'standard',
  margin: 'none',
  fullWidth: true,
  validate: noop,
};

export default SelectField;

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';

import axios from 'axios';

import { PublicClientApplication } from '@azure/msal-browser';
import { loginRequest, msalConfig } from './lib/utils/authConfig';

import configureStore from './store';

import App from './App';
import ThemeProvider from './theme';

import * as serviceWorker from './serviceWorker';
import 'react-toastify/dist/ReactToastify.css';
import 'index.css';

const msalInstance = new PublicClientApplication(msalConfig);

const { store, persistor } = configureStore();

axios.defaults.headers.common['x-functions-key'] =
  'twvFT/INZxwdwV7bYp7Bik0io5GcJJdzt1u/FHPWj2u3g75HDnMC1g==';

axios.interceptors.request.use(
  (request) => {
    request.headers.Authorization = `Bearer ${window.sessionStorage.getItem(
      'taxcast.auth.accessToken'
    )}`;
    request.headers['x-taxcast-user-id'] = store.getState().auth.account.id;
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// https://thedutchlab.com/blog/using-axios-interceptors-for-refreshing-your-api-token
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      const requestConfig = error.config;
      return msalInstance
        .acquireTokenSilent({ ...loginRequest })
        .then((msalResponse) => {
          window.sessionStorage.setItem(
            'taxcast.auth.accessToken',
            msalResponse.accessToken
          );
          // ['Authorization'];
          delete requestConfig.headers.Authorization;
          delete requestConfig.headers['x-taxcast-user-id'];
          return axios.request(requestConfig);
        })
        .catch(() => {
          // acquireTokenSilent can fail for a number of reasons, fallback to interaction
          msalInstance
            .acquireTokenPopup({ ...loginRequest })
            .then((msalResponse) => {
              msalInstance.setActiveAccount(msalResponse.account);
              window.sessionStorage.setItem(
                'taxcast.auth.accessToken',
                msalResponse.accessToken
              );
              delete requestConfig.headers.Authorization;
              delete requestConfig.headers['x-taxcast-user-id'];
              return axios.request(requestConfig);
            });
        });
    }
    return Promise.reject(error);
  }
);

ReactDOM.render(
  <>
    <ToastContainer autoClose={3000} hideProgressBar />
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider>
          <App store={store} pca={msalInstance} />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </>,
  document.getElementById('root')
);

serviceWorker.register();

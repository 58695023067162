import React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from '@material-ui/icons';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  expandIcon: {
    background: theme.navDrawer.expandButtonColor,
    position: 'fixed',
    top: theme.spacing(1),
    height: theme.spacing(4),
    borderRadius: theme.spacing(3),
    left: 40,
    zIndex: theme.zIndex.drawer + 1,
    '&:hover': {
      background: theme.navDrawer.expandButtonColor,
    },
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  expanded: {
    left: 224,
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

const ExpandButton = ({ open, toggleDrawer }) => {
  const classes = useStyles();
  return (
    <IconButton
      onClick={toggleDrawer}
      size='small'
      color='secondary'
      className={clsx(classes.expandIcon, { [classes.expanded]: open })}
      aria-label='Expand Navigation Bar'
    >
      {open ? <ChevronLeftIcon color='primary' /> : <ChevronRightIcon color='primary' />}
    </IconButton>
  );
};

ExpandButton.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
};

export default ExpandButton;

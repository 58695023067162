import thunk from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit';
// , getDefaultMiddleware
// import { configureStore } from '@reduxjs/toolkit';
import {
  // FLUSH,
  // PAUSE,
  // PERSIST,
  // PURGE,
  // REGISTER,
  // REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';
import storage from 'redux-persist/lib/storage';
// import axios from 'axios';

import authReducer from './slices/auth';
import prefReducer from './slices/pref';
import taxCastReducer from './slices/taxCast';
import clientProfileReducer from './slices/clientProfile';
import advisorReducer from './slices/advisor';
import settingsReducer from './slices/settings';

const logger =
  process.env.NODE_ENV === 'development'
    ? require('redux-logger').createLogger({
        diff: true,
        duration: true,
        collapsed: true,
      })
    : null;

const middlewares = [thunk];

if (logger) {
  middlewares.push(logger);
}

// // Authorization Header
// const authHeader = (store) => (next) => (action) => {
//   const { accessToken } = store.getState().auth;
//   axios.defaults.headers.common.Authorization = accessToken
//     ? `Bearer ${accessToken}`
//     : null;
//   return next(action);
// };

// middlewares.push(authHeader);

// Custom Headers
// const customHeaders = (store) => (next) => (action) => {
//   axios.defaults.headers.common[
//     'x-taxcast-user-id'
//   ] = store.getState().auth.account.id;
//   return next(action);
// };

// middlewares.push(customHeaders);

const authPersistConfig = {
  key: 'auth',
  storage: sessionStorage,
};

const prefPersistConfig = {
  key: 'pref',
  storage,
};

export default () => {
  const store = configureStore({
    reducer: {
      auth: persistReducer(authPersistConfig, authReducer),
      preference: persistReducer(prefPersistConfig, prefReducer),
      taxCast: taxCastReducer,
      clientProfile: clientProfileReducer,
      advisor: advisorReducer,
      settings: settingsReducer,
    },
    middleware: [
      // ...getDefaultMiddleware({
      //   serializableCheck: {
      //     ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      //   },
      // }),
      ...middlewares,
    ],
  });

  const persistor = persistStore(store);

  return { store, persistor };
};

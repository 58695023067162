/* eslint-disable import/prefer-default-export */
import { currencyRegex, emailRegex } from 'constants/regex';
import trim from 'lodash/trim';

export const trimWhiteSpace = (value) => trim(value);

export const composeValidators = (...validators) => (value) =>
  validators.reduce((error, validator) => error || validator(value), undefined);

export const required = (value) => (value ? undefined : 'Required');

export const validAmount = (value) =>
  !value || currencyRegex.test(value) ? undefined : 'Invalid Value';

export const isEmail = (value) => (!value || emailRegex.test(value) ? undefined : 'Invalid Email');

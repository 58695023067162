import React from 'react';
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: 8,
  },
  avatar: {
    color: theme.palette.primary.main,
    backgroundColor: theme.navDrawer.backgroundColor,
  },
  text: {
    color: theme.palette.primary.main,
  },
}));

const WorkingItems = () => {
  const history = useHistory();
  const classes = useStyles();
  const loadedClient = useSelector(({ taxCast }) => taxCast.client);

  const navCanvas = () => history.push(`/clients/${loadedClient.id}`);

  return (
    <div style={{ height: '100%' }}>
      <List>
        {loadedClient && loadedClient.id && (
          <ListItem button onClick={navCanvas} className={classes.root}>
            <ListItemAvatar>
              <Avatar
                variant='rounded'
                alt={`${loadedClient.givenName
                  .substring(0, 1)
                  .toUpperCase()}${loadedClient.surname
                  .substring(0, 1)
                  .toUpperCase()}`}
                className={classes.avatar}
              >
                {loadedClient.givenName.substring(0, 1).toUpperCase()}
                {loadedClient.surname.substring(0, 1).toUpperCase()}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              className={classes.text}
              primary={`${loadedClient.givenName} ${loadedClient.surname}`}
            />
          </ListItem>
        )}
      </List>
    </div>
  );
};

export default WorkingItems;

/* eslint max-lines: [2, {"max": 375, "skipComments": true, "skipBlankLines": true}] */
import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useMsal } from '@azure/msal-react';

import { shapeErrorsForToast } from 'lib/utils/errorUtil';
import { ErrorToast } from 'lib/components/ErrorToast';
import * as authActions from 'store/asyncActions/auth';

import { useDispatch, useSelector } from 'react-redux';
import * as advisorApi from 'api/advisor';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '95vh',
    maxWidth: 1000,
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(1, 'auto'),
    },
    [theme.breakpoints.down('lg')]: {
      margin: theme.spacing(1, 3),
    },
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(1, 'auto'),
    },
  },
  title: {
    fontWeight: 'bold',
    textAlign: 'center',
    padding: theme.spacing(3),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    padding: theme.spacing(2),
  },
  field: {
    [theme.breakpoints.up('sm')]: {
      minHeight: theme.spacing(6),
    },
  },
}));

const AcceptUsagePolicies = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const affiliateId = useSelector(({ auth }) => auth.account.affiliateId);
  const advisorId = useSelector(({ auth }) => auth.account.id);
  const history = useHistory();
  const { instance } = useMsal();
  const [confirmEula, setConfirmEula] = useState(false);
  const [confirmPrivacyPolicy, setConfirmPrivacyPolicy] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);

  const handleConfirmEulaClick = () => {
    setConfirmEula(!confirmEula);
  };

  const handleConfirmPrivacyPolicyClick = () => {
    setConfirmPrivacyPolicy(!confirmPrivacyPolicy);
  };

  const handleClickSave = () => {
    // Update account status and
    // write an event to the account history.
    setIsSaving(true);
    advisorApi
      .updateAccountStatusWithEventEntry(
        affiliateId,
        advisorId,
        'active',
        'Accepted usage policies',
        'Accept Usage Policies',
        `Eula:${confirmEula}|PrivacyPolicy:${confirmPrivacyPolicy}`
      )
      .then(() => {
        dispatch(authActions.ACCOUNT_STATUS_CHANGED('active'));
        toast.success(
          `Congratulations! You successfully joined as an early adopter.`
        );
        history.push('/clients/search');
      })
      .catch((error) => {
        toast.error(<ErrorToast errorMessages={shapeErrorsForToast(error)} />, {
          autoClose: false,
        });
      })
      .finally(() => {
        setIsSaving(false);
      });

    // advisorApi.setAccountStatus(affiliateId, advisorId, 'active')
    //   .then(() => {
    //     // Write account history entry
    //     advisorApi.createAccountEventEntry(advisorId, {
    //       "status": "active",
    //       "reason": "User agrees to usage policies"
    //     })
    //       .then(() => {
    //         setIsSaving(true);
    //         // Redirect to home page.
    //         history.push('/clients/search');
    //       })
    //       .catch((error) => {
    //         toast.error(<ErrorToast errorMessages={shapeErrorsForToast(error)} />, {
    //           autoClose: false,
    //         });
    //       })
    //       .finally(() => {
    //         setIsSaving(false);
    //       });
    //   })
    //   .catch((error) => {
    //     toast.error(<ErrorToast errorMessages={shapeErrorsForToast(error)} />, {
    //       autoClose: false,
    //     });
    //   });
  };

  const handleClickDoNotAgree = () => {
    setIsSaving(true);
    // Update account status
    advisorApi
      .updateAccountStatusWithEventEntry(
        affiliateId,
        advisorId,
        'incomplete',
        'Did not accept usage policies',
        'Accept Usage Policies',
        `Eula:${confirmEula}|PrivacyPolicy:${confirmPrivacyPolicy}`
      )
      .then(() => {
        dispatch(authActions.ACCOUNT_STATUS_CHANGED('incomplete'));
        toast.success(`TaxCast successfully recorded your decision.`);
        instance.logoutPopup();
        dispatch(authActions.LOGOUT());
        history.push('/login');
      })
      .catch((error) => {
        toast.error(<ErrorToast errorMessages={shapeErrorsForToast(error)} />, {
          autoClose: false,
        });
      })
      .finally(() => {
        setIsSaving(false);
      });

    // advisorApi.setAccountStatus(affiliateId, advisorId, 'locked')
    // .then(() => {
    //   // Write account history entry
    //   advisorApi.createAccountEventEntry(advisorId, {
    //     "status": "locked",
    //     "reason": "User does not agree to usage policies"
    //   })
    //     .then(() => {
    //       setIsSaving(true);
    //       // Logout
    //       instance.logoutPopup();
    //       dispatch(authActions.LOGOUT());
    //       // Redirect to login
    //       history.push('/login');
    //     })
    //     .catch((error) => {
    //       toast.error(<ErrorToast errorMessages={shapeErrorsForToast(error)} />, {
    //         autoClose: false,
    //       });
    //     })
    //     .finally(() => {
    //       setIsSaving(false);
    //     });
    // })
    // .catch((error) => {
    //   toast.error(<ErrorToast errorMessages={shapeErrorsForToast(error)} />, {
    //     autoClose: false,
    //   });
    // });
  };

  return (
    <Grid container className={classes.root}>
      <Paper spacing={2} elevation={3}>
        <Grid container justify='center' className={classes.form}>
          <Grid container item xs={12} spacing={2} padding={2}>
            <Grid item xs={12}>
              <Typography
                variant='h2'
                component='h1'
                color='primary'
                className={classes.title}
              >
                EULA &amp; Privacy Policy
              </Typography>
              <Typography variant='h4' component='h1' color='primary'>
                To remain current, we occasionally update our EULA and Privacy
                Policies. Please re-read and reaffirm your agreement before
                continuing. By using TaxCast&trade; you agree to the following:
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justify='center' className={classes.form}>
          <Grid container item xs={12} spacing={2} padding={2}>
            <Grid item xs={12}>
              <Grid item xs={12} className={classes.field}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name='confirmEulaCheckbox'
                      id='confirmEulaCheckbox'
                      checked={confirmEula}
                      onChange={handleConfirmEulaClick}
                      color='primary'
                    />
                  }
                  label='I have read, understand, and agree to the TaxCast&trade; End User License Agreement (EULA).'
                />
                <Button
                  onClick={() =>
                    window.open(
                      '/Terms_and_Conditions_HTML_20201128.html',
                      '_blank'
                    )
                  }
                >
                  View
                </Button>
              </Grid>
              <Grid item xs={12} className={classes.field}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name='confirmPrivacyPolicyCheckbox'
                      id='confirmPrivacyPolicyCheckbox'
                      checked={confirmPrivacyPolicy}
                      onChange={handleConfirmPrivacyPolicyClick}
                      color='primary'
                    />
                  }
                  label='I have read, understand, and agree to the TaxCast&trade; Privacy Policy.'
                />
                <Button
                  onClick={() =>
                    window.open('/Privacy_Policy_HTML_20201128.html', '_blank')
                  }
                >
                  View
                </Button>
              </Grid>
            </Grid>

            <Grid container item xs={12} justify='flex-end'>
              <Button
                disabled={isSaving}
                type='button'
                color='primary'
                variant='outlined'
                style={{ marginRight: 5 }}
                onClick={() => setIsCancelled(true)}
              >
                Cancel - I do not agree to the terms
              </Button>
              <Button
                disabled={!confirmEula || !confirmPrivacyPolicy}
                type='button'
                color='primary'
                variant='contained'
                onClick={handleClickSave}
              >
                Continue planning!
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Dialog
        open={isCancelled}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Cancel Your Subscription?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            This action will lock your account. Are you certain?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsCancelled(false)}
            color='primary'
            value='cancel'
          >
            Oops! I clicked the wrong button!
          </Button>
          <Button
            onClick={handleClickDoNotAgree}
            color='primary'
            value='ok'
            autoFocus
          >
            Yes, I really do want to stop using TaxCast&trade;.
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default AcceptUsagePolicies;

import React, { useEffect } from 'react';
import { Button, Grid, Paper, Typography, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from '@azure/msal-react';
import TaxCastLogo from 'assets/img/Logo512x512.png';
import * as authActions from 'store/asyncActions/auth';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.maxWidth,
    [theme.breakpoints.down('lg')]: {
      margin: theme.spacing(1, 3),
    },
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(1, 'auto'),
    },
  },
  header: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(1),
  },
  divider: {
    borderRight: `1px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(1),
  },
  rightCell: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
  },
  leftCell: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
    borderRight: `1px solid ${theme.palette.primary.main}`,
  },
}));

const Logout = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { instance } = useMsal();

  useEffect(() => {
    document.title = 'TaxCast - Logout';
  }, []);

  const handleLogoutClick = () => {
    instance.logoutPopup();
    dispatch(authActions.LOGOUT());
  };

  const handleGoBackClick = () => {
    history.goBack();
  };

  const handleGoToLoginPageClick = () => {
    history.push('/login');
  };

  return (
    <Grid container item xs={12} className={classes.root} spacing={2}>
      <Paper spacing={2} elevation={3}>
        <Grid container>
          <Grid container item xs={12}>
            <Grid item xs={12} md={6} className={classes.leftCell}>
              <AuthenticatedTemplate>
                <Typography component='h4' variant='h4' color='primary'>
                  Logout?
                </Typography>
                <br />
                <Typography component='h5' variant='h5' color='primary'>
                  Confirm you want to logout by clicking &quot;Logout&quot;
                  below.
                </Typography>
                <br />
                <Typography component='h4' variant='h4'>
                  <Button
                    onClick={handleLogoutClick}
                    variant='contained'
                    color='secondary'
                  >
                    Logout
                  </Button>
                  &nbsp;
                  <Button
                    onClick={handleGoBackClick}
                    variant='contained'
                    color='primary'
                  >
                    Go Back
                  </Button>
                </Typography>
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <Grid item xs={12} md={5} className={classes.rightCell}>
                  <Typography component='h4' variant='h4' color='primary'>
                    Logged Out
                  </Typography>
                  <br />
                  <Typography component='h5' variant='h5' color='primary'>
                    You are logged out of TaxCast. Go to the login page to
                    login.
                  </Typography>
                  <br />
                  <Typography component='h4' variant='h4'>
                    <Button
                      onClick={handleGoToLoginPageClick}
                      variant='contained'
                      color='primary'
                    >
                      Go To Login Page
                    </Button>
                  </Typography>
                </Grid>
              </UnauthenticatedTemplate>
            </Grid>
            <Grid item xs={12} md={5} className={classes.rightCell}>
              <img src={TaxCastLogo} alt='TaxCast Logo' />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default Logout;

/* eslint max-lines: [2, {"max": 200, "skipComments": true, "skipBlankLines": true}] */
import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import { validAmount } from 'lib/utils/form';
import { isNullEmptyOrBlank, isValidAmount } from 'lib/utils/validation';

const useStyles = makeStyles((theme) => ({
  field: {
    minHeight: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const QbidForm = ({
  isQbi,
  isSstb,
  w2WagesPaid,
  qualifiedProperty,
  onDataUpdate,
  onValidate,
}) => {
  const classes = useStyles();
  const [isErrorW2Wages, setIsErrorW2Wages] = useState(false);
  const [isErrorQualifiedProperty, setIsErrorQualifiedProperty] = useState(
    false
  );
  const [data, setData] = useState({
    isQualifiedBusinessIncome: isQbi,
    isSpecifiedServiceOrTradeBusiness: isSstb,
    w2WagesPaidByBusiness: w2WagesPaid,
    qualifiedPropertyAmountOwnedByBusiness: qualifiedProperty,
  });

  useEffect(() => {
    onDataUpdate(data);
  }, [data, onDataUpdate]);

  const isValidForm = (dataToValidate) => {
    let result = true;
    if (dataToValidate.isQualifiedBusinessIncome) {
      result =
        result &&
        isValidAmount(dataToValidate.w2WagesPaidByBusiness) &&
        !isNullEmptyOrBlank(dataToValidate.w2WagesPaidByBusiness);
      result =
        result &&
        isValidAmount(dataToValidate.qualifiedPropertyAmountOwnedByBusiness) &&
        !isNullEmptyOrBlank(
          dataToValidate.qualifiedPropertyAmountOwnedByBusiness
        );
    }
    return result;
  };

  const handleInputChangeQbid = (event) => {
    const newData = { ...data };
    switch (event.target.name) {
      case 'isQbi':
        newData.isQualifiedBusinessIncome = event.target.checked;
        newData.isSpecifiedServiceOrTradeBusiness = event.target.checked
          ? data.isSpecifiedServiceOrTradeBusiness
          : false;
        if (!event.target.checked) {
          setIsErrorW2Wages(false);
          setIsErrorQualifiedProperty(false);
        }
        setData({
          ...data,
          isQualifiedBusinessIncome: event.target.checked,
          isSpecifiedServiceOrTradeBusiness: event.target.checked
            ? data.isSpecifiedServiceOrTradeBusiness
            : false,
        });
        break;
      case 'isSstb':
        newData.isSpecifiedServiceOrTradeBusiness = event.target.checked;
        break;
      case 'w2WagesPaidByBusiness':
        newData.w2WagesPaidByBusiness = event.target.value;
        setIsErrorW2Wages(
          !isValidAmount(event.target.value) ||
            isNullEmptyOrBlank(event.target.value)
        );
        break;
      case 'qualifiedPropertyOwnedByBusiness':
        newData.qualifiedPropertyAmountOwnedByBusiness = event.target.value;
        setIsErrorQualifiedProperty(
          !isValidAmount(event.target.value) ||
            isNullEmptyOrBlank(event.target.value)
        );
        break;
      default:
        break;
    }
    setData(newData);
    onDataUpdate(newData);
    onValidate(isValidForm(newData));
  };

  return (
    <Grid item xs={12} className={classes.field}>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              name='isQbi'
              id='isQbi'
              checked={data.isQualifiedBusinessIncome}
              onChange={handleInputChangeQbid}
              color='primary'
            />
          }
          label='Qualified Business Income'
        />
      </Grid>
      <Grid item xs={12} className={classes.field}>
        <FormControlLabel
          control={
            <Checkbox
              name='isSstb'
              id='isSstb'
              checked={data.isSpecifiedServiceOrTradeBusiness}
              onChange={handleInputChangeQbid}
              disabled={!data.isQualifiedBusinessIncome}
              color='primary'
            />
          }
          label='Specified Service Trade or Business'
        />
      </Grid>
      <Grid item xs={12} className={classes.field}>
        <TextField
          name='w2WagesPaidByBusiness'
          id='w2WagesPaidByBusiness'
          fullWidth
          label='W2 Wages Paid By Business'
          required
          value={data.w2WagesPaidByBusiness}
          onChange={handleInputChangeQbid}
          error={isErrorW2Wages}
          helperText={validAmount(data.w2WagesPaidByBusiness)}
          disabled={!data.isQualifiedBusinessIncome}
          InputProps={{
            startAdornment: <InputAdornment position='start'>$</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12} className={classes.field}>
        <TextField
          name='qualifiedPropertyOwnedByBusiness'
          id='qualifiedPropertyOwnedByBusiness'
          fullWidth
          label='Qualified Property Owned by Business'
          required={data.isQualifiedBusinessIncome}
          value={data.qualifiedPropertyAmountOwnedByBusiness}
          onChange={handleInputChangeQbid}
          error={isErrorQualifiedProperty}
          helperText={validAmount(data.qualifiedPropertyAmountOwnedByBusiness)}
          disabled={!data.isQualifiedBusinessIncome}
          InputProps={{
            startAdornment: <InputAdornment position='start'>$</InputAdornment>,
          }}
        />
      </Grid>
    </Grid>
  );
};

QbidForm.propTypes = {
  isQbi: PropTypes.bool,
  isSstb: PropTypes.bool,
  w2WagesPaid: PropTypes.number,
  qualifiedProperty: PropTypes.number,
  onDataUpdate: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
};

QbidForm.defaultProps = {
  isQbi: false,
  isSstb: PropTypes.bool,
  w2WagesPaid: 0,
  qualifiedProperty: 0,
};

export default QbidForm;

/* eslint max-lines: [2, {"max": 175, "skipComments": true, "skipBlankLines": true}] */
import React, { useState } from 'react';
import {
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import { required, validAmount } from 'lib/utils/form';
import { isNullEmptyOrBlank, isValidAmount } from 'lib/utils/validation';

const useStyles = makeStyles((theme) => ({
  field: {
    minHeight: theme.spacing(11),
  },
  textArea: {
    marginBottom: theme.spacing(3),
  },
  capitalGainsIcons: {
    paddingTop: theme.spacing(3),
  },
  capitalGainsIconsSumButton: {
    paddingTop: theme.spacing(1),
  },
}));

const TransactionForm = ({ transaction, onFieldUpdate, onValidate }) => {
  const classes = useStyles();

  const [isErrorName, setIsErrorName] = useState(false);
  const [isErrorValue, setIsErrorValue] = useState(false);

  const isValidForm = (name, value) => {
    let result = true;
    result = result && !isNullEmptyOrBlank(name);
    result = result && !isNullEmptyOrBlank(value) && isValidAmount(value);
    return result;
  };

  const handleInputChangeBase = (event) => {
    let isValid = true;
    if (event.target.id === 'name') {
      setIsErrorName(isNullEmptyOrBlank(event.target.value));
      isValid = isValidForm(event.target.value, transaction.value);
    }
    if (event.target.id === 'value') {
      setIsErrorValue(
        isNullEmptyOrBlank(event.target.value) ||
          !isValidAmount(event.target.value)
      );
      isValid = isValidForm(transaction.name, event.target.value);
    }
    onFieldUpdate(event.target.id, event.target.value);
    onValidate(isValid);
  };

  const handleFilerSpouseRadioGroupChange = (event) => {
    onFieldUpdate('associatedWith', event.target.value);
  };

  return (
    <>
      <Grid item xs={12} className={classes.field}>
        <RadioGroup
          aria-label='associatedWith'
          name='associatedWith'
          value={transaction.associatedWith}
          onChange={handleFilerSpouseRadioGroupChange}
          row
          color='primary'
        >
          <FormControlLabel
            value='Filer'
            key='Filer'
            control={<Radio color='primary' />}
            label='Taxpayer'
            labelPlacement='start'
            color='primary'
          />
          <FormControlLabel
            value='Spouse'
            key='Spouse'
            control={<Radio color='primary' />}
            label='Spouse'
            labelPlacement='start'
          />
        </RadioGroup>
      </Grid>

      <Grid item xs={12} className={classes.field}>
        <TextField
          name='name'
          id='name'
          label='Name'
          autoFocus
          fullWidth
          value={transaction.name}
          variant='standard'
          required
          onChange={handleInputChangeBase}
          error={isErrorName}
          helperText={required(transaction.name)}
        />
      </Grid>

      <Grid item xs={12} className={classes.field}>
        <TextField
          name='value'
          id='value'
          fullWidth
          label='Value'
          required
          value={transaction.value}
          onChange={handleInputChangeBase}
          error={isErrorValue}
          helperText={validAmount(transaction.value)}
          InputProps={{
            startAdornment: <InputAdornment position='start'>$</InputAdornment>,
          }}
        />
      </Grid>
    </>
  );
};

TransactionForm.propTypes = {
  transaction: PropTypes.shape({
    associatedWith: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    notes: PropTypes.string,
    isSpread: PropTypes.bool,
  }).isRequired,
  onFieldUpdate: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
};

TransactionForm.defaultProps = {};

export default TransactionForm;

/* eslint max-lines: [2, {"max": 450, "skipComments": true, "skipBlankLines": true}] */
import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Form } from 'react-final-form';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {
  composeValidators,
  isEmail,
  required,
  trimWhiteSpace,
} from 'lib/utils/form';
import CheckboxField from 'lib/components/CheckboxField';
import FormField from 'lib/components/FormField';
import SelectField from 'lib/components/SelectField';
import DatePickerField from 'lib/components/DatePickerField';
import { toast } from 'react-toastify';
import { omit, pick } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  filingStatusDisplayMap,
  filingStatusTypes,
} from 'constants/filingStatuses';
import * as clientApi from 'api/client';
import * as apiUtils from 'api/utils';
import { UPDATE_CLIENT_PROFILE } from 'store/asyncActions/taxCast';
import PropTypes from 'prop-types';
import { shapeErrorsForToast } from 'lib/utils/errorUtil';
import { ErrorToast } from 'lib/components/ErrorToast';

import { isValidPhoneNumberUnadorned } from 'lib/utils/validation';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '95vh',
    maxWidth: 1000,
  },
  form: {
    padding: theme.spacing(2),
  },
  field: {
    [theme.breakpoints.up('sm')]: {
      minHeight: theme.spacing(12),
    },
  },
}));

const EditDialog = ({ clientProfile, show, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // TODO: Replace when authentication is implemented.
  const advisorId = useSelector(({ auth }) => auth.account.id);

  const closeDialog = (entity) => {
    onClose(entity);
  };

  const handleSave = (form) => {
    const modifiedProfile = omit(form, ['sameBillingAddress']);
    if (form.sameBillingAddress) {
      modifiedProfile.billingAddressLineOne = form.preferredAddressLineOne;
      modifiedProfile.billingAddressLineTwo = form.preferredAddressLineTwo;
      modifiedProfile.billingAddressCity = form.preferredAddressCity;
      modifiedProfile.billingAddressStateOrProvince =
        form.preferredAddressStateOrProvince;
      modifiedProfile.billingAddressPostalCode =
        form.preferredAddressPostalCode;
    }
    modifiedProfile.advisorId = advisorId;

    // Sanitize phone number to ensure no adornments.
    if (!isValidPhoneNumberUnadorned(modifiedProfile.preferredPhoneNumber)) {
      const error =
        'Phone number must be ten digits with no adornments. e.g. 9065551212';
      toast.error(<ErrorToast errorMessages={shapeErrorsForToast(error)} />, {
        autoClose: false,
      });
    } else {
      clientApi
        .updateClientProfile(modifiedProfile)
        .then(() => {
          // If any of the following properties change, the loaded
          // Canvas needs updating. Intentionally chose not to diff.
          const client = pick(
            modifiedProfile,
            apiUtils.normalizedClientProfileProperties()
          );
          dispatch(UPDATE_CLIENT_PROFILE(client));
          toast.success(`Updates successful.`);
          onClose(modifiedProfile);
        })
        .catch((error) => {
          toast.error(
            <ErrorToast errorMessages={shapeErrorsForToast(error)} />,
            {
              autoClose: false,
            }
          );
        });
    }
  };

  return (
    <Dialog
      open={!!show}
      disableBackdropClick
      aria-labelledby='Edit Client Profile Dialog'
    >
      <Grid container className={classes.root}>
        <DialogTitle id='form-dialog-title'>Edit Client Profile</DialogTitle>
        <Paper elevation={3}>
          <DialogContent>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Form
                onSubmit={handleSave}
                initialValues={{ ...clientProfile, sameBillingAddress: true }}
                render={({ handleSubmit, values, submitting, invalid }) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <Grid container justify='center' className={classes.form}>
                      <Grid container item xs={12} md={10} lg={9} spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            variant='h6'
                            component='h2'
                            color='primary'
                          >
                            Client Details
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} className={classes.field}>
                          <FormField
                            name='givenName'
                            id='givenName'
                            label='First Name'
                            validate={required}
                            format={trimWhiteSpace}
                            required
                            autoFocus
                            variant='outlined'
                          />
                        </Grid>

                        <Grid item xs={12} sm={4} className={classes.field}>
                          <FormField
                            name='surname'
                            id='surname'
                            label='Last Name'
                            validate={required}
                            format={trimWhiteSpace}
                            required
                            variant='outlined'
                          />
                        </Grid>

                        <Grid item xs={12} sm={4} className={classes.field}>
                          <DatePickerField
                            name='birthDate'
                            id='birthDate'
                            label='Birth Date'
                            validate={required}
                            required
                            variant='outlined'
                            disableFuture
                          />
                        </Grid>

                        <Grid item xs={12} sm={4} className={classes.field}>
                          <FormField
                            name='preferredPhoneNumber'
                            id='preferredPhoneNumber'
                            label='Phone'
                            validate={required}
                            format={trimWhiteSpace}
                            required
                            variant='outlined'
                          />
                        </Grid>

                        <Grid item xs={12} sm={8} className={classes.field}>
                          <FormField
                            name='preferredEmailAddress'
                            id='preferredEmailAddress'
                            label='Email'
                            validate={composeValidators(required, isEmail)}
                            format={trimWhiteSpace}
                            required
                            variant='outlined'
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Divider />
                        </Grid>

                        <Grid item xs={12}>
                          <Typography
                            variant='h6'
                            component='h2'
                            color='primary'
                          >
                            Address
                          </Typography>
                        </Grid>

                        <Grid item xs={12} className={classes.field}>
                          <FormField
                            name='preferredAddressLineOne'
                            id='preferredAddressLineOne'
                            label='Address Line 1'
                            validate={required}
                            required
                            format={trimWhiteSpace}
                            variant='outlined'
                          />
                        </Grid>

                        <Grid item xs={12} className={classes.field}>
                          <FormField
                            name='preferredAddressLineTwo'
                            id='preferredAddressLineTwo'
                            label='Address Line 2'
                            format={trimWhiteSpace}
                            variant='outlined'
                          />
                        </Grid>

                        <Grid item xs={12} sm={4} className={classes.field}>
                          <FormField
                            name='preferredAddressCity'
                            id='preferredAddressCity'
                            label='City'
                            format={trimWhiteSpace}
                            variant='outlined'
                            validate={required}
                            required
                          />
                        </Grid>

                        <Grid item xs={12} sm={4} className={classes.field}>
                          <FormField
                            name='preferredAddressStateOrProvince'
                            id='preferredAddressStateOrProvince'
                            label='State'
                            format={trimWhiteSpace}
                            variant='outlined'
                            validate={required}
                            required
                          />
                        </Grid>

                        <Grid item xs={12} sm={4} className={classes.field}>
                          <FormField
                            name='preferredAddressPostalCode'
                            id='preferredAddressPostalCode'
                            label='Postal Code'
                            format={trimWhiteSpace}
                            variant='outlined'
                            validate={required}
                            required
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <CheckboxField
                            name='sameBillingAddress'
                            id='sameBillingAddress'
                            label='Use this for billing address'
                          />
                        </Grid>

                        {!values.sameBillingAddress && (
                          <>
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                variant='h6'
                                component='h2'
                                color='primary'
                              >
                                Billing Address
                              </Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.field}>
                              <FormField
                                name='billingAddressLineOne'
                                id='billingAddressLineOne'
                                label='Billing Address Line 1'
                                validate={required}
                                required
                                format={trimWhiteSpace}
                                variant='outlined'
                              />
                            </Grid>

                            <Grid item xs={12} className={classes.field}>
                              <FormField
                                name='billingAddressLineTwo'
                                id='billingAddressLinTwo'
                                label='Billing Address Line 2'
                                format={trimWhiteSpace}
                                variant='outlined'
                              />
                            </Grid>

                            <Grid item xs={12} sm={4} className={classes.field}>
                              <FormField
                                name='billingAddressCity'
                                id='billingAddressCity'
                                label='Billing City'
                                format={trimWhiteSpace}
                                variant='outlined'
                                validate={required}
                                required
                              />
                            </Grid>

                            <Grid item xs={12} sm={4} className={classes.field}>
                              <FormField
                                name='billingAddressStateOrProvince'
                                id='billingAddressStateOrProvince'
                                label='Billing State'
                                format={trimWhiteSpace}
                                variant='outlined'
                                validate={required}
                                required
                              />
                            </Grid>

                            <Grid item xs={12} sm={4} className={classes.field}>
                              <FormField
                                name='billingAddressPostalCode'
                                id='billingAddressPostalCode'
                                label='Billing Postal Code'
                                format={trimWhiteSpace}
                                variant='outlined'
                                validate={required}
                                required
                              />
                            </Grid>
                          </>
                        )}

                        <Grid item xs={12}>
                          <Divider />
                        </Grid>

                        <Grid item xs={12}>
                          <Typography
                            variant='h6'
                            component='h2'
                            color='primary'
                          >
                            Tax information
                          </Typography>
                        </Grid>

                        <Grid item xs={12} className={classes.field}>
                          <SelectField
                            id='filingStatus'
                            name='filingStatus'
                            label='Filing Status'
                            variant='outlined'
                            validate={required}
                            required
                          >
                            {Object.keys(filingStatusTypes).map((item) => (
                              <MenuItem value={item} key={item}>
                                {filingStatusDisplayMap[item]}
                              </MenuItem>
                            ))}
                          </SelectField>
                        </Grid>

                        {/* <Grid item xs={12}>
                          <FormField
                            name='notes'
                            id='notes'
                            label='Notes'
                            variant='outlined'
                            rows={5}
                            rowsMax={5}
                            multiline
                          />
                        </Grid> */}

                        <Grid container item justify='flex-end' xs={12}>
                          <DialogActions>
                            <Grid item>
                              <Button
                                disabled={submitting}
                                color='primary'
                                variant='outlined'
                                onClick={() => closeDialog(null)}
                              >
                                Cancel
                              </Button>
                            </Grid>
                            <Grid item>
                              <Button
                                disabled={submitting || invalid}
                                type='submit'
                                color='primary'
                                variant='contained'
                              >
                                Save
                              </Button>
                            </Grid>
                          </DialogActions>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                )}
              />
            </MuiPickersUtilsProvider>
          </DialogContent>
        </Paper>
      </Grid>
    </Dialog>
  );
};

EditDialog.propTypes = {
  spouse: PropTypes.shape({
    id: PropTypes.string,
  }),
  clientProfile: PropTypes.shape({
    givenName: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    birthDate: PropTypes.string.isRequired,
    filingStatus: PropTypes.string.isRequired,
    preferredEmailAddress: PropTypes.string.isRequired,
    preferredPhoneNumber: PropTypes.string.isRequired,
  }).isRequired,
  show: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

EditDialog.defaultProps = {
  spouse: null,
  show: false,
};

export default EditDialog;

/* eslint-disable import/prefer-default-export */
import { authActions } from '../slices/auth';

export const AADB2C_LOGIN_SUCCESS = (idToken) => (dispatch) => {
  dispatch(authActions.AADB2C_LOGIN_SUCCESS({ ...idToken, isNew: false }));
};

export const ACCOUNT_STATUS_CHANGED = (newStatus, newStatusReason) => (
  dispatch
) => {
  dispatch(authActions.UPDATE_ACCOUNT_STATUS({ newStatus, newStatusReason }));
};

export const LOAD_EXISTING_ACCOUNT = (advisor) => (dispatch) => {
  dispatch(authActions.LOAD_ACCOUNT({ ...advisor, isNew: false }));
};

export const LOAD_NEW_ACCOUNT = (advisor) => (dispatch) => {
  dispatch(authActions.LOAD_ACCOUNT({ ...advisor, isNew: true }));
};

export const LOGOUT = () => (dispatch) => {
  dispatch(authActions.AADB2C_LOGOUT_SUCCESS());
};

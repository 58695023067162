/* eslint max-lines: [2, {"max": 600, "skipComments": true, "skipBlankLines": true}] */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';

import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import { toast } from 'react-toastify';

import axios from 'axios';

import { shapeErrorsForToast } from 'lib/utils/errorUtil';
import { baseUrl } from 'constants/api';
import { TaxCastWebVersion } from 'constants/settings';
import * as advisorApi from 'api/advisor';
import * as authActions from 'store/asyncActions/auth';
import * as settingsActions from 'store/asyncActions/settings';
import TaxCastLogo from 'assets/img/Logo512x512.png';
import { ErrorToast } from 'lib/components/ErrorToast';
import {
  countTrialDaysRemaining,
  extractTrialDuration,
  isTrialExpired,
} from 'lib/utils/account';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.maxWidth,
    [theme.breakpoints.down('lg')]: {
      margin: theme.spacing(1, 3),
    },
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(1, 'auto'),
    },
  },
  paper: {
    minWidth: '100%',
  },
  header: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(1),
  },
  rightCell: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
  },
  leftCell: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
    borderRight: `1px solid ${theme.palette.primary.main}`,
  },
}));

const Auth = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const user = useSelector(({ auth }) => auth.user);
  const [isFound, setIsFound] = useState(true); // Expectation is the info will be found.
  const account = useSelector(({ auth }) => auth.account);
  const accessToken = window.sessionStorage.getItem('taxcast.auth.accessToken');
  const [checkingUpdateAvailable, setCheckingUpdateAvailable] = useState(true);
  const [suggestUpdate, setSuggestUpdate] = useState(false);
  const [availableVersion, setAvailableVersion] = useState(null);
  const [accountStatus, setAccountStatus] = useState('');
  const [trialExpired, setTrialExpired] = useState(false);
  const [trialDaysRemaining, setTrialDaysRemaining] = useState(14);
  const [isCancelled, setIsCancelled] = useState(false);
  const [isLoadingAccount, setIsLoadingAccount] = useState(false);
  const marketingWebSiteLinkChoice = useSelector(
    ({ preference }) => preference.choice
  );

  useEffect(() => {
    document.title = `TaxCast`;
  }, []);

  useEffect(() => {
    let unmounted = false;
    const tokenSource = axios.CancelToken.source();

    if (isAuthenticated && accessToken !== '') {
      // Add support for forced refresh updates.
      axios
        .get(`${baseUrl}/api/settings/TaxCastSetting`)
        .then((response) => {
          if (!unmounted) {
            // The response is now an array.
            const versionSettingIndex = response.data.findIndex(
              (s) => s.rowKey === 'TaxCastWebVersion'
            );
            let versionSetting = '';
            if (versionSettingIndex !== -1) {
              versionSetting = response.data[versionSettingIndex].value;
            }

            setCheckingUpdateAvailable(false);
            setSuggestUpdate(TaxCastWebVersion < versionSetting);
            setAvailableVersion(versionSetting);

            // Load the available tax table year for other pages to use.
            const taxTableYearSettingIndex = response.data.findIndex(
              (s) => s.rowKey === 'TaxTableYear'
            );
            if (taxTableYearSettingIndex !== -1) {
              const taxTableYearSetting = +response.data[
                taxTableYearSettingIndex
              ].value;
              dispatch(
                settingsActions.LOAD_TAXTABLEYEAR_SETTING(taxTableYearSetting)
              );
            }
          }
        })
        .catch((error) => {
          const shapedErrors = shapeErrorsForToast(error);
          const index = shapedErrors.find((e) => e.includes('Network Error'));
          if (index !== -1) {
            history.push('/itsdeadjim');
          } else {
            toast.error(<ErrorToast errorMessages={shapedErrors} />, {
              autoClose: false,
            });
          }
        });

      // *** NEW ADVISOR ***
      // If the account is NOT new, attempt to load the advisor info.
      // isFound is initialized to TRUE so that flag is overloaded here.
      if (
        !checkingUpdateAvailable &&
        !isLoadingAccount &&
        !('id' in account) &&
        isFound
      ) {
        setIsLoadingAccount(true);
        advisorApi
          .searchAdvisor(user.preferredEmailAddress, user.oid)
          .then((response) => {
            if (response.status === 201) {
              // The account information is found in TaxCast database.
              // Set this Axios header immediately.
              axios.defaults.headers.common['x-taxcast-user-id'] =
                response.data.id;
              // This page's behavior is a function of account status.
              // unspecified | cancelled | closed | locked => logout and redirect to "Contact Us" page with status reason
              // incomplete => redirect to "Initial Payment" page (which allows trial)
              // suspended => redirect to "Payment" page
              // needsUpdating => redirect to "T&C" page
              // active => redirect to "Home" page
              // Ensure, though, a value is provided
              if (!response.data.accountStatus) {
                // If no account status is provided, default to 'active'.
                response.data.accountStatus = 'active';
              }
              setAccountStatus(response.data.accountStatus);
              if (response.data.accountStatus === 'trial') {
                const trialDuration = extractTrialDuration(response.data);
                setTrialExpired(
                  isTrialExpired(
                    response.data.accountCreateStamp,
                    trialDuration
                  )
                );
                setTrialDaysRemaining(
                  countTrialDaysRemaining(
                    response.data.accountCreateStamp,
                    trialDuration
                  )
                );
              }
              // Now that account status is set, finish loading the account.
              dispatch(authActions.LOAD_EXISTING_ACCOUNT(response.data));
            } else {
              // If the account information cannot be found, setup is incomplete.
              // Flag the state so the layout below renders the "Finish Setup" button.
              setIsFound(false);
            }
          })
          .catch((error) => {
            const shapedErrors = shapeErrorsForToast(error);
            const index = shapedErrors.find((e) => e.includes('Network Error'));
            if (index !== -1) {
              history.push('/itsdeadjim');
            } else {
              toast.error(<ErrorToast errorMessages={shapedErrors} />, {
                autoClose: false,
              });
            }
          })
          .finally(() => {
            setIsLoadingAccount(false);
          });
      }

      // *** EXISTING ADVISOR ***
      // Handle changes to account status.
      if (
        !checkingUpdateAvailable &&
        !isLoadingAccount &&
        'id' in account &&
        accountStatus === ''
      ) {
        setIsLoadingAccount(true);
        advisorApi
          .reloadAdvisor(account.affiliateId, account.id)
          .then((response) => {
            if (response.data.accountStatus === 'trial') {
              const trialDuration = extractTrialDuration(response.data);
              setTrialExpired(
                isTrialExpired(response.data.accountCreateStamp, trialDuration)
              );
              setTrialDaysRemaining(
                countTrialDaysRemaining(
                  response.data.accountCreateStamp,
                  trialDuration
                )
              );
            }
            if (response.data.accountStatus !== accountStatus) {
              setAccountStatus(response.data.accountStatus);
              dispatch(
                authActions.ACCOUNT_STATUS_CHANGED(
                  response.data.accountStatus,
                  response.data.accountStatusReason
                )
              );
            }
          })
          .catch((error) => {
            const shapedErrors = shapeErrorsForToast(error);
            const index = shapedErrors.find((e) => e.includes('Network Error'));
            if (index !== -1) {
              history.push('/itsdeadjim');
            } else {
              toast.error(<ErrorToast errorMessages={shapedErrors} />, {
                autoClose: false,
              });
            }
          })
          .finally(() => {
            setIsLoadingAccount(false);
          });
      }
    }

    return () => {
      unmounted = true;
      tokenSource.cancel('unmounted');
    };
  }, [
    dispatch,
    user,
    account,
    isAuthenticated,
    accessToken,
    history,
    trialExpired,
    trialDaysRemaining,
    accountStatus,
    checkingUpdateAvailable,
    suggestUpdate,
    availableVersion,
    isLoadingAccount,
    isFound,
    marketingWebSiteLinkChoice,
  ]);

  const accountStatusIsUnspecifiedCancelledClosedLocked = (status) => {
    return (
      status === 'unspecified' ||
      status === 'cancelled' ||
      status === 'closed' ||
      status === 'locked'
    );
  };
  const accountStatusIsActiveOrUnexpiredTrial = (status) => {
    return status === 'active' || (status === 'trial' && !trialExpired);
  };

  const handleFinishSetupClick = () => {
    history.push('/advisors/new');
  };

  const handleForceRefresh = () => {
    window.location.reload();
  };

  const handleNewClientClick = () => {
    history.push('/clients/new');
  };

  const handleSearchClick = () => {
    history.push('/clients/search');
  };

  const handleClickLeaveTaxCast = () => {
    advisorApi
      .updateAccountStatusWithEventEntry(
        account.affiliateId,
        account.id,
        'suspended',
        'Did not convert',
        'Trial Expired',
        ''
      )
      .then(() => {
        setIsLoadingAccount(true);
        dispatch(authActions.ACCOUNT_STATUS_CHANGED('suspended'));
        toast.success(`TaxCast successfully recorded your decision.`);
        instance.logoutPopup();
        dispatch(authActions.LOGOUT());
      })
      .catch((error) => {
        toast.error(<ErrorToast errorMessages={shapeErrorsForToast(error)} />, {
          autoClose: false,
        });
      });
  };

  if (!isAuthenticated) {
    return <Redirect to='/login' />;
  }

  if (
    isFound &&
    accountStatusIsUnspecifiedCancelledClosedLocked(accountStatus)
  ) {
    return <Redirect to='/contact-us-about-status' />;
  }

  return (
    <Grid container item xs={12} className={classes.root} spacing={2}>
      <Paper spacing={2} elevation={3} className={classes.paper}>
        <Grid container>
          <Grid container item xs={12}>
            <Grid item xs={12} md={6} className={classes.leftCell}>
              <img src={TaxCastLogo} alt='TaxCast Logo' />
            </Grid>
            <Grid container item xs={12} md={5} className={classes.rightCell}>
              {checkingUpdateAvailable && (
                <Typography component='h5' variant='h5' color='primary'>
                  Checking for an available update...
                </Typography>
              )}
              {suggestUpdate && !checkingUpdateAvailable && (
                <Grid item xs={12}>
                  <Typography component='h5' variant='h5' color='primary'>
                    An update from version {TaxCastWebVersion} to version{' '}
                    {availableVersion} is available.
                  </Typography>
                  <br />
                  <Button
                    onClick={handleForceRefresh}
                    variant='contained'
                    color='primary'
                  >
                    Update
                  </Button>
                </Grid>
              )}
              {!isFound && !suggestUpdate && !checkingUpdateAvailable && (
                <Grid item xs={12}>
                  <Typography component='h4' variant='h4' color='primary'>
                    Welcome to TaxCast!
                  </Typography>
                  <br />
                  <Typography component='h5' variant='h5' color='primary'>
                    We need a few more details to finish creating your account
                    and then you can get started.
                  </Typography>
                  <br />
                  <Typography component='h4' variant='h4'>
                    <Button
                      onClick={handleFinishSetupClick}
                      variant='contained'
                      color='primary'
                    >
                      Finish Account Setup
                    </Button>
                  </Typography>
                </Grid>
              )}
              {isFound &&
                accountStatus === 'incomplete' &&
                !suggestUpdate &&
                !checkingUpdateAvailable && (
                  <Grid item xs={12}>
                    <Typography component='h4' variant='h4' color='primary'>
                      Account Setup Incomplete
                    </Typography>
                    <br />
                    <Typography component='h5' variant='h5' color='primary'>
                      Your account setup is incomplete. You must either
                      subscribe or start a risk-free 14-day trial. Please choose
                      an option to start using TaxCast.
                    </Typography>
                    <br />
                    <Typography component='h4' variant='h4'>
                      <Button
                        onClick={() => history.push('/payments/initial')}
                        variant='contained'
                        color='primary'
                      >
                        Choose Payment Option
                      </Button>
                    </Typography>
                  </Grid>
                )}
              {isFound &&
                accountStatus === 'suspended' &&
                !suggestUpdate &&
                !checkingUpdateAvailable && (
                  <Grid item xs={12}>
                    <Typography component='h4' variant='h4' color='primary'>
                      Payment Due
                    </Typography>
                    <br />
                    <Typography component='h5' variant='h5' color='primary'>
                      Payment is required for you to continue using TaxCast. If
                      you believe this is incorrect, please contact us.
                    </Typography>
                    <br />
                    <Typography component='h5' variant='h5'>
                      888.482.9227 (888-4-TAXCAST)
                    </Typography>
                    <br />
                    <Typography component='h5' variant='h5'>
                      <a href='mailto: support@taxcastplan.com'>
                        support@taxcastplan.com
                      </a>
                    </Typography>
                    <br />
                    <Typography component='h4' variant='h4'>
                      <Button
                        onClick={() => history.push('/payments/initial')}
                        variant='contained'
                        color='primary'
                      >
                        Payment Page
                      </Button>
                    </Typography>
                  </Grid>
                )}
              {isFound &&
                accountStatus === 'trial' &&
                trialExpired &&
                !suggestUpdate &&
                !checkingUpdateAvailable && (
                  <Grid item xs={12}>
                    <Typography component='h4' variant='h4' color='primary'>
                      Your TaxCast trial has expired.
                    </Typography>
                    <br />
                    <Typography component='h5' variant='h5' color='primary'>
                      We hope you got a good feel for the value TaxCast can
                      bring to your clients.
                    </Typography>
                    <br />
                    <Typography component='h4' variant='h4'>
                      <Button
                        onClick={() => setIsCancelled(true)}
                        variant='contained'
                        color='primary'
                        style={{ marginRight: '5px' }}
                      >
                        Leave :-(
                      </Button>
                      <Button
                        onClick={() => history.push('/payments/initial')}
                        variant='contained'
                        color='primary'
                      >
                        Subscribe to TaxCast&trade;
                      </Button>
                    </Typography>
                    <br />
                    <Typography component='h5' variant='h5'>
                      Want more time? Need help getting the most out of TaxCast?
                      Let us know. We are happy to discuss with you!
                    </Typography>
                    <br />
                    <Typography component='h5' variant='h5'>
                      888.482.9227 (888-4-TAXCAST)
                    </Typography>
                    <br />
                    <Typography component='h5' variant='h5'>
                      <a href='mailto: support@taxcastplan.com'>
                        support@taxcastplan.com
                      </a>
                    </Typography>
                  </Grid>
                )}
              {isFound &&
                accountStatus === 'needsUpdating' &&
                !suggestUpdate &&
                !checkingUpdateAvailable && (
                  <Grid item xs={12}>
                    <Typography component='h4' variant='h4' color='primary'>
                      We updated our EULA and Privacy Policies
                    </Typography>
                    <br />
                    <Typography component='h5' variant='h5' color='primary'>
                      To remain current, we occasionally update our EULA and
                      Privacy Policies. Please re-read and reaffirm your
                      agreement before continuing.
                    </Typography>
                    <br />
                    <Typography component='h4' variant='h4'>
                      <Button
                        onClick={() => history.push('/agreements')}
                        variant='contained'
                        color='primary'
                      >
                        Review Usage Policies
                      </Button>
                    </Typography>
                  </Grid>
                )}
              {isFound &&
                accountStatusIsActiveOrUnexpiredTrial(accountStatus) &&
                !suggestUpdate &&
                !checkingUpdateAvailable && (
                  <Grid item xs={12}>
                    <Typography component='h4' variant='h4' color='primary'>
                      Welcome to TaxCast {account.givenName} {account.surname}
                    </Typography>
                    {accountStatus === 'trial' && (
                      <>
                        <Typography component='h5' variant='h5' color='primary'>
                          There are {trialDaysRemaining} days remaining in your
                          trial.
                        </Typography>
                        <br />
                      </>
                    )}
                    <Typography component='h5' variant='h5' color='primary'>
                      Choose how to get started:
                    </Typography>
                    <br />
                    <Button
                      onClick={handleNewClientClick}
                      variant='contained'
                      color='primary'
                    >
                      New Client
                    </Button>
                    &nbsp;
                    <Button
                      onClick={handleSearchClick}
                      variant='contained'
                      color='primary'
                    >
                      Search
                    </Button>
                    &nbsp;
                    <Button
                      onClick={handleSearchClick}
                      variant='contained'
                      color='secondary'
                    >
                      Template Profiles
                    </Button>
                  </Grid>
                )}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Dialog
        open={isCancelled}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Leave TaxCast?</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you certain you do not want to simplify tax planning for your
            clients?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsCancelled(false)}
            color='primary'
            value='cancel'
          >
            Oops! I clicked the wrong button!
          </Button>
          <Button
            onClick={handleClickLeaveTaxCast}
            color='primary'
            value='ok'
            autoFocus
          >
            Yes, I really do want to leave TaxCast.
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default Auth;

/* eslint max-lines: [2, {"max": 100, "skipComments": true, "skipBlankLines": true}] */
import React from 'react';
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';
import { provisions } from './provisionsData';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.maxWidth,
    [theme.breakpoints.down('lg')]: {
      margin: theme.spacing(1, 1),
    },
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(1, 'auto'),
    },
  },
}));

const CurrentProvisions = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleClickBack = () => history.goBack();

  return (
    <Grid container justify='center' className={classes.root}>
      <Grid item xs={12}>
        <Button onClick={handleClickBack}>
          <ArrowBackIcon />
          &nbsp;Back
        </Button>
        <Typography
          variant='h4'
          component='h1'
          color='primary'
          className={classes.title}
        >
          Current Tax Code Provisions
        </Typography>
      </Grid>
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table aria-label='Current Provisions in TaxCast Table'>
              <TableHead>
                <TableRow>
                  <TableCell>PROVISION</TableCell>
                  <TableCell>SECTION</TableCell>
                  <TableCell>TAX RETURN REFERENCE</TableCell>
                  <TableCell>NOTES</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {provisions.currentProvisions.map((item) => (
                  <TableRow key={item.provision} className={classes.root}>
                    <TableCell>{item.provision}</TableCell>
                    <TableCell>{item.section}</TableCell>
                    <TableCell>{item.taxReturnReference}</TableCell>
                    <TableCell>{item.notes}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CurrentProvisions;

/* eslint max-lines: [2, {"max": 500, "skipComments": true, "skipBlankLines": true}] */
import React, { useEffect, useState } from 'react';
import {
  Backdrop,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Redirect, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';

import { toast } from 'react-toastify';
import { shapeErrorsForToast } from 'lib/utils/errorUtil';
import { ErrorToast } from 'lib/components/ErrorToast';
import { currencyFormatter } from 'lib/utils/format';
import loading from 'assets/img/BouncingBarsBlack.png';
import * as authActions from 'store/asyncActions/auth';

import * as paymentApi from 'api/payment';
import * as advisorApi from 'api/advisor';
import { basePaymentVendorUrl } from 'constants/api';

const useStyles = makeStyles((theme) => ({
  root: {
    // minHeight: '95vh',
    maxWidth: 1000,
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(1, 'auto'),
    },
    [theme.breakpoints.down('lg')]: {
      margin: theme.spacing(1, 3),
    },
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(1, 'auto'),
    },
  },
  title: {
    fontWeight: 'bold',
    textAlign: 'center',
    padding: theme.spacing(3),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    padding: theme.spacing(2),
  },
  field: {
    [theme.breakpoints.up('sm')]: {
      minHeight: theme.spacing(6),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const Payment = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const params =
    'useexpiry=true&useexpiryfield=true&usecvv=true&formatinput=true&enhancedresponse=true&cardnumbernumericonly=true';
  const css = encodeURI(
    'css=.error{color:red;border-color:red;}label{font-family:"Roboto", "Helvetica", "Arial", sans-serif;font-size:1.25rem;}input{height:1.5rem;font-size:1rem;}body{margin:0px;height:100%}select{height:18px;}'
  );
  const account = useSelector(({ auth }) => auth.account);
  const [paymentData, setPaymentData] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [isCancelled, setIsCancelled] = useState(false);
  const [newDiscountCodeId, setNewDiscountCodeId] = useState('');
  const [discountCodeAmount, setDiscountCodeAmount] = useState('');
  const retailPrice = 149;
  const [hasConsented, setHasConsented] = useState(false);
  const [amountDue, setAmountDue] = useState('');
  const [appliedDiscountCodeId, setAppliedDiscountCodeId] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [expiry, setExpiry] = useState();
  const [paymentSucceeded, setPaymentSucceeded] = useState(false);

  useEffect(() => {
    if (amountDue === '' && !paymentSucceeded) {
      paymentApi
        .getAmountDue(account.affiliateId, account.id)
        .then((response) => {
          setAmountDue(response.data);
          paymentApi
            .getPublicDiscountCodeInfo(account.affiliateId, account.id)
            .then((responseDiscountCodes) => {
              if (responseDiscountCodes.data.length === 1) {
                const discountCodeInfo = responseDiscountCodes.data[0];
                setAppliedDiscountCodeId(discountCodeInfo.id);
                if (discountCodeInfo) {
                  if (discountCodeInfo.amountUnits === 'dollars') {
                    setDiscountCodeAmount(
                      currencyFormatter.format(discountCodeInfo.amount)
                    );
                  } else {
                    setDiscountCodeAmount(`${discountCodeInfo.amount}%`);
                  }
                }
              }
            })
            .catch((error) => {
              const shapedErrors = shapeErrorsForToast(error);
              toast.error(<ErrorToast errorMessages={shapedErrors} />, {
                autoClose: false,
              });
            });
        })
        .catch((error) => {
          const shapedErrors = shapeErrorsForToast(error);
          toast.error(<ErrorToast errorMessages={shapedErrors} />, {
            autoClose: false,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [amountDue, account.affiliateId, account.id, paymentSucceeded]);

  const handleTokenEvent = () => {
    window.addEventListener(
      'message',
      function (event) {
        if (!event.data.payload) {
          const token = JSON.parse(event.data);
          const cardPointeToken = document.getElementById('cardPointeToken');
          cardPointeToken.value = token.message;
          setExpiry(token.expiry);
          setPaymentData(event.data);
          event.preventDefault();
        }
      },
      false
    );
  };

  const handleClickConsent = () => {
    setHasConsented(!hasConsented);
  };

  const handleSubmitPaymentClick = () => {
    setIsSubmitting(true);
    setIsLoading(true);
    const paymentJson = {
      amount: amountDue,
      expiry,
      token: document.getElementById('cardPointeToken').value,
      cvv: '',
    };
    paymentApi
      .createPayment(
        account.affiliateId,
        account.id,
        { ...paymentJson },
        newDiscountCodeId
      )
      .then(() => {
        dispatch(
          authActions.ACCOUNT_STATUS_CHANGED(
            'active',
            'Initial Payment successful'
          )
        );
        setPaymentSucceeded(true);
      })
      .catch((error) => {
        const shapedErrors = shapeErrorsForToast(error);
        toast.error(<ErrorToast errorMessages={shapedErrors} />, {
          autoClose: false,
        });
      })
      .finally(() => {
        setIsLoading(false);
        setIsSubmitting(false);
      });
  };

  const handleInputChangeDiscountCode = (event) => {
    setNewDiscountCodeId(event.target.value);
  };

  // const handleCancelPaymentClick = () => {
  //   // TODO: implement this
  // };

  const handleClickStartTrial = () => {
    setIsSubmitting(true);
    setIsLoading(true);
    advisorApi
      .setAccountStatus(account.affiliateId, account.id, 'trial')
      .then(() => {
        // Write account history entry
        advisorApi
          .createAccountEventEntry(
            account.affiliateId,
            account.id,
            'trial',
            'User started trial'
          )
          .then(() => {
            dispatch(
              authActions.ACCOUNT_STATUS_CHANGED('trial', 'User started trial')
            );
            history.push('/auth');
          })
          .catch((error) => {
            toast.error(
              <ErrorToast errorMessages={shapeErrorsForToast(error)} />,
              {
                autoClose: false,
              }
            );
          });
      })
      .catch((error) => {
        toast.error(<ErrorToast errorMessages={shapeErrorsForToast(error)} />, {
          autoClose: false,
        });
      })
      .finally(() => {
        setIsLoading(false);
        setIsSubmitting(false);
      });
  };

  const handleApplyDiscountCodeClick = () => {
    setIsLoading(true);
    paymentApi
      .getDiscountCodeInfo(newDiscountCodeId)
      .then((response) => {
        setAppliedDiscountCodeId(response.data.id);
        if (response.data.amountUnits === 'dollars') {
          setDiscountCodeAmount(currencyFormatter.format(response.data.amount));
        } else {
          setDiscountCodeAmount(`${response.data.amount}%`);
        }
        // Apply the discount code to the user's account.
        paymentApi
          .applyDiscountCode(account.affiliateId, account.id, response.data.id)
          .then(() => {
            // 204 === success
            // Get the updated amount due.
            paymentApi
              .getAmountDue(account.affiliateId, account.id)
              .then((amountDueResponse) => {
                setAmountDue(amountDueResponse.data);
              })
              .catch((applyError) => {
                const shapedErrors = shapeErrorsForToast(applyError);
                toast.error(<ErrorToast errorMessages={shapedErrors} />, {
                  autoClose: false,
                });
              });
          })
          .catch((getAmountDueError) => {
            setAmountDue('');
            setAppliedDiscountCodeId('');
            setDiscountCodeAmount('');
            const shapedErrors = shapeErrorsForToast(getAmountDueError);
            toast.error(<ErrorToast errorMessages={shapedErrors} />, {
              autoClose: false,
            });
          });
      })
      .catch((getDiscountCodeError) => {
        const shapedErrors = shapeErrorsForToast(getDiscountCodeError);
        toast.error(<ErrorToast errorMessages={shapedErrors} />, {
          autoClose: false,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleClickPaymentSuccessful = () => {
    history.push('/auth');
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <img
          src={loading}
          alt='Loading...'
          className='loading-img'
          height='256px'
          width='256px'
        />
      </Backdrop>
      <AuthenticatedTemplate>
        <Grid container className={classes.root}>
          <Paper spacing={2} elevation={3}>
            <Grid container justify='center' className={classes.form}>
              <Grid container item xs={12} spacing={2} padding={2}>
                <Grid item xs={12}>
                  <Typography
                    variant='h4'
                    component='h1'
                    color='primary'
                    className={classes.title}
                  >
                    Payment Information
                  </Typography>
                  <Typography variant='h5' component='h5' color='primary'>
                    TaxCast uses Card Pointe to process payments. We never see
                    your credit card number and do not store your credit card
                    information.
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justify='center' className={classes.form}>
                <Grid
                  container
                  item
                  xs={12}
                  direction='column'
                  spacing={3}
                  padding={2}
                >
                  <Grid item xs={12} sm={6}>
                    <form name='tokenForm' id='tokenForm'>
                      <iframe
                        id='tokenFrame'
                        title='tokenFrame'
                        name='tokenFrame'
                        src={`${basePaymentVendorUrl}/itoke/ajax-tokenizer.html?${params}&${css}`}
                        frameBorder='0'
                        scrolling='no'
                        width='100%'
                        height='200vh'
                        onLoad={handleTokenEvent}
                      />
                      <input
                        type='hidden'
                        name='cardPointeToken'
                        id='cardPointeToken'
                      />
                    </form>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name='confirmPrivacyPolicyCheckbox'
                          id='confirmPrivacyPolicyCheckbox'
                          checked={hasConsented}
                          onChange={handleClickConsent}
                          color='primary'
                        />
                      }
                      label='I consent to allowing Card Pointe store and reuse payment information, and TaxCast to bill me monthly.'
                    />
                  </Grid>
                  <Grid container item xs={12} sm={6}>
                    <Grid item xs={12}>
                      <Typography variant='h5' component='h5' color='primary'>
                        {`Monthly Subscription: ${currencyFormatter.format(
                          retailPrice
                        )}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.field}>
                      <TextField
                        fullWidth
                        label='Discount Code'
                        onChange={handleInputChangeDiscountCode}
                      />
                    </Grid>
                    <Grid container item xs={12}>
                      &nbsp;
                    </Grid>
                    <Grid container item xs={12} justify='flex-end'>
                      <Button
                        type='button'
                        color='primary'
                        variant='contained'
                        onClick={handleApplyDiscountCodeClick}
                      >
                        Apply
                      </Button>
                    </Grid>
                    <Grid container item xs={12}>
                      &nbsp;
                    </Grid>
                    {amountDue !== retailPrice && (
                      <Grid item xs={12}>
                        <Typography variant='h5' component='h5' color='primary'>
                          {`Discount Amount: ${discountCodeAmount}`}
                        </Typography>
                        <Typography variant='h5' component='h5' color='primary'>
                          {`Code: ${appliedDiscountCodeId}`}
                        </Typography>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Typography variant='h5' component='h5' color='primary'>
                        You {retailPrice > amountDue ? `Only` : ``} Pay:{' '}
                        {currencyFormatter.format(amountDue)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                &nbsp;
              </Grid>
              <Grid container item xs={12} justify='flex-end'>
                {/* <Button
                  disabled={isSubmitting}
                  type='button'
                  color='primary'
                  variant='outlined'
                  style={{ marginRight: 5 }}
                  onClick={() => setIsCancelled(true)}
                >
                  Cancel
                </Button> */}
                {/* Only show the trial option if the account status is not 'suspended'. */}
                {account.accountStatus !== 'suspended' && (
                  <Button
                    disabled={isSubmitting}
                    type='button'
                    color='primary'
                    variant='outlined'
                    style={{ marginRight: 5 }}
                    onClick={handleClickStartTrial}
                  >
                    Start Trial
                  </Button>
                )}
                <Button
                  disabled={!paymentData || !hasConsented}
                  type='button'
                  color='primary'
                  variant='contained'
                  onClick={handleSubmitPaymentClick}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {/* <Dialog
          open={isCancelled}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>Cancel Payment?</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              {isEligibleForTrialPeriod
                ? `Cancelling payment now will enroll you in a free 14-day trial.`
                : `Your account is not eligible for a trial subscription. Cancelling payment will lock your account. Are you certain?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCancelPaymentClick}
              color='primary'
              value='ok'
              autoFocus
            >
              {isEligibleForTrialPeriod ? `Take The Trial` : `Leave TaxCast`}
            </Button>
            <Button
              onClick={() => setIsCancelled(false)}
              color='primary'
              value='cancel'
            >
              Provide Payment Now
            </Button>
          </DialogActions>
        </Dialog> */}
        <Dialog
          open={paymentSucceeded}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>Payment Successful</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              Thank you for joining TaxCast!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClickPaymentSuccessful}
              color='primary'
              value='ok'
              autoFocus
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Redirect to='/login' />
      </UnauthenticatedTemplate>
    </>
  );
};

export default Payment;

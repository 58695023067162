import React from 'react';
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { shapeErrorsForToast } from 'lib/utils/errorUtil';
import { ErrorToast } from 'lib/components/ErrorToast';

const initialState = {
  isSubmitting: false,
  error: '',
  client: {},
};

/* eslint no-param-reassign: ["error", { "ignorePropertyModificationsFor": ["state"] }] */
const clientProfileSlice = createSlice({
  name: 'clientProfile',
  initialState,
  reducers: {
    ERROR(state, action) {
      Object.assign(state, initialState, { error: action });
    },
    SILENT_ERROR(state, action) {
      toast.error(
        <ErrorToast errorMessages={shapeErrorsForToast(action.payload)} />,
        { autoClose: false }
      );
      state.isSubmitting = false;
    },
    SUBMITTING_FORM(state) {
      state.isSubmitting = true;
    },
    ADD_CLIENT_PROFILE(state) {
      // const client = action.payload;
      // state.client = client;
      state.isSubmitting = false;
    },
  },
});

export const clientProfileActions = clientProfileSlice.actions;
export default clientProfileSlice.reducer;

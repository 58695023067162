/* eslint max-lines: [2, {"max": 300, "skipComments": true, "skipBlankLines": true}] */
import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { forEach, pickBy } from 'lodash';

import {
  NEW_TRANSACTION,
  NEW_TRANSACTION_DIALOG,
} from 'store/asyncActions/taxCast';

import { isValidAmount } from 'lib/utils/validation';

import TransactionInfoPanel from './components/TransactionInfoPanel';
import TransactionForm from './components/TransactionForm';
import SpreadTransactionForm from './components/SpreadTransactionForm';
import AlimonyStatement from './components/AlimonyStatement';
import CharitableContributionTypeForm from './components/CharitableContributionTypeForm';
import LongTermCapitalGainsForm from './components/LongTermCapitalGainsForm';
import NonQualifiedDividendForm from './components/NonQualifiedDividendForm';
import QbidForm from './components/QbidForm';

const useStyles = makeStyles((theme) => ({
  leftPanel: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    position: 'relative',
  },
  category: {
    paddingTop: theme.spacing(2),
  },
  subcategory: {
    paddingLeft: theme.spacing(1),
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
    },
  },
  button: {
    [theme.breakpoints.up('sm')]: {
      minWidth: theme.spacing(13),
    },
  },
}));

const NewTransactionDialog = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const transaction = useSelector(
    ({ taxCast }) => taxCast.newTransactionDialog
  );
  const isSubmitting = useSelector(({ taxCast }) => taxCast.isSubmitting);
  const forecast = useSelector(({ taxCast }) => taxCast.forecast);

  const [isValidBase, setIsValidBase] = useState(true);
  const [isValidExtended, setIsValidExtended] = useState(true);
  const [isValidSpread, setIsValidSpread] = useState(true);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);

  // All new transactions are 'spreadable', thus the nomenclature.
  const [spreadableTransaction, setSpreadableTransaction] = useState({
    associatedWith: 'Filer',
    name: '',
    value: '',
    notes: '',
    isSpread: true,
  });
  // Extended form data will vary based on subcategory.
  const [extendedData, setExtendedData] = useState({});
  /* spreadData structure:
   {
    <year>: { selected: <bool>, value: <num>, displaySequence: <num>, error: <bool> }, 
    2024: { selected: true, value: '', displaySequence: -1, error: false },
    2025: { selected: true, value: 1100, displaySequence: -1, error: true }
   }
  */
  const [spreadData, setSpreadData] = useState({});

  const handleInputChangeNotes = (event) => {
    setSpreadableTransaction({
      ...spreadableTransaction,
      notes: event.target.value,
    });
  };

  const handleCancel = () => {
    setIsValidBase(true);
    setIsValidExtended(true);
    setIsValidSpread(true);
    setIsSaveEnabled(false);
    setSpreadableTransaction({
      associatedWith: 'Filer',
      name: '',
      value: '',
      notes: '',
      isSpread: false,
    });
    setExtendedData({});
    setSpreadData({});
    dispatch(NEW_TRANSACTION_DIALOG(null));
  };

  const handleSubmit = () => {
    if (isValidBase && isValidExtended && isValidSpread) {
      // Structure the data for submission.
      // 'transaction' contains toolbox info such as category and tax year.
      // 'spreadableTransaction' contains user input from the form.
      // 'spreadData' contains the spread information.
      // 'extendedData' contains extended form input.
      const selectedItems = pickBy(
        spreadData,
        (item) => item.selected && isValidAmount(item.value)
      );
      const spreadIsUsed = Object.keys(selectedItems).length > 0;

      const newTransaction = {
        ...transaction,
        ...spreadableTransaction,
        ...extendedData,
        isSpread: spreadableTransaction.isSpread && spreadIsUsed,
      };
      // Add all new transactions to the end of the row.
      if (spreadableTransaction.isSpread && spreadIsUsed) {
        newTransaction.spreadData = { ...selectedItems };
        const nowYear = new Date().getFullYear();
        forEach(forecast.yearList, (year) => {
          if (year >= nowYear && newTransaction.spreadData[year]) {
            newTransaction.spreadData[year].displaySequence =
              forecast.years[year][transaction.category].length;
          }
        });
      }
      dispatch(NEW_TRANSACTION(newTransaction));
      handleCancel();
    } else {
      // TODO: Need to present to the user.
    }
  };

  // *****************
  // *** BASE FORM ***
  // *****************
  const handleOnValidateBase = (isValid) => {
    setIsValidBase(isValid);
    setIsSaveEnabled(isValidSpread && isValidExtended && isValid);
  };

  const handleOnFieldUpdate = (fieldName, fieldValue) => {
    const updatingSpreadableTransaction = { ...spreadableTransaction };
    updatingSpreadableTransaction[fieldName] = fieldValue;
    setSpreadableTransaction(updatingSpreadableTransaction);
  };

  // ************************
  // *** EXTENDED SUBFORM ***
  // ************************
  const handleOnValidateExtended = (isValid) => {
    setIsValidExtended(isValid);
    setIsSaveEnabled(isValidBase && isValidSpread && isValid);
  };

  // This method uses the moniker 'data' to reflect the
  // updating of the entire extended data object on each
  // change as opposed to individual field updates.
  const handleOnExtendedDataUpdate = (extendedFormData) => {
    setExtendedData(extendedFormData);
  };

  // **********************
  // *** SPREAD SUBFORM ***
  // **********************
  const handleOnValidateSpread = (isValid) => {
    setIsValidSpread(isValid);
    setIsSaveEnabled(isValidBase && isValidExtended && isValid);
  };

  const handleOnIsSpreadUpdate = (isTransactionSpread) => {
    setSpreadableTransaction({
      ...spreadableTransaction,
      isSpread: isTransactionSpread,
    });
  };

  const handleOnSpreadDataUpdate = (updatingSpreadData) => {
    setSpreadData(updatingSpreadData);
  };

  if (!transaction) {
    return null;
  }

  return (
    <Dialog
      open={!!transaction}
      disableBackdropClick
      aria-labelledby='New Transaction Dialog'
      maxWidth='md'
    >
      <Grid container>
        <Grid item xs={12} sm={3} className={classes.leftPanel}>
          <TransactionInfoPanel mode='New' transaction={transaction} />
        </Grid>
        <Grid item xs={12} sm={9}>
          <DialogContent>
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={6}>
                {/* Base Form */}
                <TransactionForm
                  transaction={spreadableTransaction}
                  onFieldUpdate={handleOnFieldUpdate}
                  onValidate={handleOnValidateBase}
                />

                {/* Extended Form */}
                {transaction.subcategory === 'alimony' && <AlimonyStatement />}

                {transaction.subcategory === 'charitableContribution' && (
                  <CharitableContributionTypeForm
                    contributionType='cash'
                    onDataUpdate={handleOnExtendedDataUpdate}
                  />
                )}

                {transaction.subcategory === 'longTermCapitalGains' && (
                  <LongTermCapitalGainsForm
                    salePrice={0}
                    basisAmount={0}
                    onDataUpdate={handleOnExtendedDataUpdate}
                    onValidate={handleOnValidateExtended}
                  />
                )}

                {(transaction.subcategory === 'soleProprietorshipIncome' ||
                  transaction.subcategory === 'sCorpDistributions') && (
                  <QbidForm
                    isQbi={false}
                    isSstb={false}
                    w2WagesPaid={0}
                    qualifiedProperty={0}
                    onDataUpdate={handleOnExtendedDataUpdate}
                    onValidate={handleOnValidateExtended}
                  />
                )}

                {transaction.subcategory === 'nonQualifiedDividends' && (
                  <NonQualifiedDividendForm
                    isSection199a={false}
                    onDataUpdate={handleOnExtendedDataUpdate}
                  />
                )}

                <Grid item xs={12} className={classes.textArea}>
                  &nbsp;
                </Grid>

                {/* Notes */}
                <Grid item xs={12} className={classes.textArea}>
                  <TextField
                    name='notes'
                    id='notes'
                    fullWidth
                    value={transaction.notes}
                    variant='outlined'
                    label='Notes'
                    rowsMax={5}
                    rows={5}
                    multiline
                    maxLength={200}
                    onChange={handleInputChangeNotes}
                  />
                </Grid>
              </Grid>

              {/* Spread Form */}
              <Grid item xs={6}>
                <SpreadTransactionForm
                  mode='New'
                  value={+spreadableTransaction.value}
                  taxYear={+transaction.taxYear}
                  forecastYearList={forecast.yearList}
                  onIsSpreadUpdate={handleOnIsSpreadUpdate}
                  onSpreadDataUpdate={handleOnSpreadDataUpdate}
                  onValidate={handleOnValidateSpread}
                />
              </Grid>
            </Grid>

            <DialogActions>
              <Button
                disabled={isSubmitting}
                className={classes.button}
                variant='outlined'
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                disabled={isSubmitting || !isSaveEnabled}
                className={classes.button}
                color='primary'
                variant='outlined'
                type='submit'
                onClick={handleSubmit}
              >
                {isSubmitting ? 'Saving...' : 'Save'}
              </Button>
            </DialogActions>
          </DialogContent>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default NewTransactionDialog;

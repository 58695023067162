/* eslint-disable import/prefer-default-export */
export const relationshipTypes = {
  unspecified: "unspecified",
  spouse: "spouse",
  child: "child",
  sibling: "sibling",
  parent: "parent",
  nibling: "nibling",
  parentSibling: "parentSibling",
  grandparent: "grandparent",
  other: "other"
};

export const relationshipDisplayMap = {
  unspecified: "unspecified",
  spouse: "Spouse/Partner",
  child: "Child",
  sibling: "Sibling",
  parent: "Parent",
  nibling: "Cousin",
  parentSibling: "Aunt/Uncle",
  grandparent: "Grandparent",
  other: "other"
};

/* eslint max-lines: [2, {"max": 250, "skipComments": true, "skipBlankLines": true}] */
import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Backdrop,
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Restore as RestoreIcon } from '@material-ui/icons';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import loading from 'assets/img/BouncingBarsBlack.png';
import * as advisorApi from 'api/advisor';
import { getAge } from 'lib/utils/dateTimeUtils';
import { shapeErrorsForToast } from 'lib/utils/errorUtil';
import { ErrorToast } from 'lib/components/ErrorToast';
import { CLEAR_TAXCAST_DATA } from 'store/asyncActions/taxCast';

import RestoreDialog from './RestoreDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1000,
    width: '100%',
    flexGrow: 1,
  },
  list: {
    padding: theme.spacing(2),
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  content: {
    padding: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#bebebe',
  },
}));

const TemplatesList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const advisorId = useSelector(({ auth }) => auth.account.id);
  const [selectedTemplateId, setSelectedTemplateId] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [showDialogRestore, setShowDialogRestore] = useState(false);
  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {
    if (!isLoaded && !isBusy) {
      setIsBusy(true);
      advisorApi
        .getClientProfileTemplates(advisorId)
        .then((response) => {
          setTemplates(response.data);
          setIsLoaded(true);
        })
        .catch((error) => {
          toast.error(
            <ErrorToast errorMessages={shapeErrorsForToast(error)} />,
            {
              autoClose: false,
            }
          );
        })
        .finally(() => {
          setIsBusy(false);
        });
    }
  }, [isLoaded, templates, advisorId, isBusy]);

  const onClickRestore = (_, selectedTemplateParam) => {
    setSelectedTemplateId(selectedTemplateParam);
    setShowDialogRestore(true);
  };

  const onCloseRestoreDialog = (event) => {
    if (event === 'ok') {
      dispatch(CLEAR_TAXCAST_DATA());
      setTemplates([]);
      setIsLoaded(false);
      setIsBusy(true);
      advisorApi
        .getRestoreClientProfileTemplates(advisorId, selectedTemplateId)
        .then(() => {
          setSelectedTemplateId('');
        })
        .catch(() => {
          setSelectedTemplateId('');
        })
        .finally(() => {
          setIsBusy(false);
        });
    } else {
      setSelectedTemplateId('');
    }
    setShowDialogRestore(false);
  };

  const onClickAdd = () => {
    setIsBusy(true);
    advisorApi
      .getRestoreClientProfileTemplates(advisorId, 'all')
      .then(() => {
        setSelectedTemplateId('');
      })
      .catch(() => {
        setSelectedTemplateId('');
      })
      .finally(() => {
        setIsBusy(false);
        setIsLoaded(false); // Force a reload.
      });
  };

  return (
    <>
      <RestoreDialog
        title='Restore the Template?'
        content={
          selectedTemplateId === 'all'
            ? 'Are you certain you want to restore all template profiles to their baselines? This will erase any changes you made.'
            : 'Are you certain you want to restore this template profile to the baseline? This will erase any changes you made.'
        }
        show={showDialogRestore}
        onClose={onCloseRestoreDialog}
      />
      <Backdrop className={classes.backdrop} open={isBusy}>
        <img
          src={loading}
          alt='Loading...'
          className='loading-img'
          height='256px'
          width='256px'
        />
      </Backdrop>
      <Grid container item xs={12} className={classes.content}>
        <Grid item xs={12}>
          <Typography component='h6' variant='h6'>
            Template Client Profiles
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {isLoaded && templates.length === 0 && (
            <>
              <Typography component='span'>
                There are no template client profiles associated with your
                account.
              </Typography>
              <br />
              <Button
                variant='contained'
                color='primary'
                onClick={onClickAdd}
                disabled={isBusy}
              >
                Add Templates
              </Button>
            </>
          )}
          {isLoaded && templates.length > 0 && (
            <Button
              variant='contained'
              color='primary'
              disabled={isBusy}
              onClick={(event) => onClickRestore(event, 'all')}
            >
              Restore All
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} className={classes.content}>
          <Typography component='span'>
            {!isBusy && (
              <List className={classes.root}>
                {templates.map((template, index) => (
                  <Grid item xs={12} key={template.id}>
                    <ListItem
                      key={template.id}
                      button
                      onClick={() => history.push(`/clients/${template.id}`)}
                      component='li'
                    >
                      <ListItemAvatar>
                        <Avatar
                          className={classes.avatar}
                          alt={`${template.givenName
                            .substring(0, 1)
                            .toUpperCase()}${template.surname
                            .substring(0, 1)
                            .toUpperCase()}`}
                        >
                          {template.givenName.substring(0, 1).toUpperCase()}
                          {template.surname.substring(0, 1).toUpperCase()}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={`${template.givenName} ${
                          template.surname
                        } (${getAge(template.birthDate)})`}
                        secondary={template.notes}
                      />
                      <ListItemSecondaryAction>
                        <Tooltip title='Restore'>
                          <IconButton
                            edge='end'
                            aria-label='restore'
                            onClick={(event) =>
                              onClickRestore(event, template.id)
                            }
                          >
                            <RestoreIcon />
                          </IconButton>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    </ListItem>
                    {index < templates.length - 1 && (
                      <Divider variant='inset' component='li' />
                    )}
                  </Grid>
                ))}
              </List>
            )}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default TemplatesList;

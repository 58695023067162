/* eslint max-lines: [2, {"max": 300, "skipComments": true, "skipBlankLines": true}] */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { pick } from 'lodash';

import { EDIT_TRANSACTION } from 'store/asyncActions/taxCast';
import { taxCastActions } from 'store/slices/taxCast';

import TransactionInfoPanel from './components/TransactionInfoPanel';
import TransactionForm from './components/TransactionForm';
import AlimonyStatement from './components/AlimonyStatement';
import CharitableContributionTypeForm from './components/CharitableContributionTypeForm';
import LongTermCapitalGainsForm from './components/LongTermCapitalGainsForm';
import NonQualifiedDividendForm from './components/NonQualifiedDividendForm';
import QbidForm from './components/QbidForm';

const useStyles = makeStyles((theme) => ({
  leftPanel: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    position: 'relative',
  },
  category: {
    paddingTop: theme.spacing(2),
  },
  subcategory: {
    paddingLeft: theme.spacing(1),
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
    },
  },
  button: {
    [theme.breakpoints.up('sm')]: {
      minWidth: theme.spacing(13),
    },
  },
}));

const EditTransactionDialog = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const transaction = useSelector(
    ({ taxCast }) => taxCast.editTransactionDialog
  );
  const isSubmitting = useSelector(({ taxCast }) => taxCast.isSubmitting);

  const [isValidBase, setIsValidBase] = useState(true);
  const [isValidExtended, setIsValidExtended] = useState(true);
  const [isSaveEnabled, setIsSaveEnabled] = useState(true);

  const [editableTransaction, setEditableTransaction] = useState();

  // Extended form data will vary based on subcategory.
  const [extendedData, setExtendedData] = useState();

  useEffect(() => {
    if (transaction && !editableTransaction) {
      setEditableTransaction({ ...transaction });
    }
  }, [transaction, editableTransaction]);

  useEffect(() => {
    if (transaction && !extendedData) {
      switch (transaction.subcategory) {
        case 'charitableContribution':
          setExtendedData(
            pick(transaction, ['charitableContributionTypeValue'])
          );
          break;
        case 'longTermCapitalGains':
          setExtendedData(pick(transaction, ['salePrice', 'basisAmount']));
          break;
        case 'soleProprietorshipIncome':
        case 'sCorpDistributions':
          setExtendedData(
            pick(transaction, [
              'isQualifiedBusinessIncome',
              'isSpecifiedServiceOrTradeBusiness',
              'w2WagesPaidByBusiness',
              'qualifiedPropertyAmountOwnedByBusiness',
            ])
          );
          break;
        case 'nonQualifiedDividends':
          setExtendedData(pick(transaction, ['isSection199AReitDividend']));
          break;
        default:
          break;
      }
    }
  }, [transaction, extendedData]);

  const handleInputChangeNotes = (event) => {
    setEditableTransaction({
      ...editableTransaction,
      notes: event.target.value,
    });
  };

  const handleCancel = () => {
    setIsValidBase(true);
    setIsValidExtended(true);
    setIsSaveEnabled(true);
    setEditableTransaction();
    setExtendedData();
    dispatch(taxCastActions.TOGGLE_EDIT_TRANSACTION_DIALOG(null));
  };

  const handleSubmit = () => {
    dispatch(EDIT_TRANSACTION({ ...editableTransaction, ...extendedData }));
    handleCancel();
  };

  // *****************
  // *** BASE FORM ***
  // *****************
  const handleOnValidateBase = (isValid) => {
    setIsValidBase(isValid);
    setIsSaveEnabled(isValidExtended && isValid);
  };

  const handleOnFieldUpdate = (fieldName, fieldValue) => {
    const updatingeditableTransaction = { ...editableTransaction };
    updatingeditableTransaction[fieldName] = fieldValue;
    setEditableTransaction(updatingeditableTransaction);
  };

  // ************************
  // *** EXTENDED SUBFORM ***
  // ************************
  const handleOnValidateExtended = (isValid) => {
    setIsValidExtended(isValid);
    setIsSaveEnabled(isValidBase && isValid);
  };

  // This method uses the moniker 'data' to reflect the
  // updating of the entire extended data object on each
  // change as opposed to individual field updates.
  const handleOnExtendedDataUpdate = (extendedFormData) => {
    setExtendedData(extendedFormData);
  };

  if (!transaction || !editableTransaction) {
    return null;
  }

  return (
    <Dialog
      open={!!transaction}
      disableBackdropClick
      aria-labelledby='Edit Transaction Dialog'
    >
      <Grid container>
        <Grid item xs={12} sm={4} className={classes.leftPanel}>
          <TransactionInfoPanel mode='Edit' transaction={transaction} />
        </Grid>

        <Grid item xs={12} sm={8}>
          <DialogContent>
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={12}>
                {/* Base Form */}
                <TransactionForm
                  transaction={editableTransaction}
                  onFieldUpdate={handleOnFieldUpdate}
                  onValidate={handleOnValidateBase}
                />

                {/* Extended Form */}
                {transaction.subcategory === 'alimony' && <AlimonyStatement />}

                {transaction.subcategory === 'charitableContribution' && (
                  <CharitableContributionTypeForm
                    contributionType={
                      editableTransaction.charitableContributionTypeValue
                    }
                    onDataUpdate={handleOnExtendedDataUpdate}
                  />
                )}

                {transaction.subcategory === 'longTermCapitalGains' && (
                  <LongTermCapitalGainsForm
                    salePrice={editableTransaction.salePrice}
                    basisAmount={editableTransaction.basisAmount}
                    onDataUpdate={handleOnExtendedDataUpdate}
                    onValidate={handleOnValidateExtended}
                  />
                )}

                {(transaction.subcategory === 'soleProprietorshipIncome' ||
                  transaction.subcategory === 'sCorpDistributions') && (
                  <QbidForm
                    isQbi={editableTransaction.isQualifiedBusinessIncome}
                    isSstb={
                      editableTransaction.isSpecifiedServiceOrTradeBusiness
                    }
                    w2WagesPaid={editableTransaction.w2WagesPaidByBusiness}
                    qualifiedProperty={
                      editableTransaction.qualifiedPropertyAmountOwnedByBusiness
                    }
                    onDataUpdate={handleOnExtendedDataUpdate}
                    onValidate={handleOnValidateExtended}
                  />
                )}

                {transaction.subcategory === 'nonQualifiedDividends' && (
                  <NonQualifiedDividendForm
                    isSection199a={
                      editableTransaction.isSection199AReitDividend
                    }
                    onDataUpdate={handleOnExtendedDataUpdate}
                  />
                )}

                <Grid item xs={12} className={classes.textArea}>
                  &nbsp;
                </Grid>

                {/* Notes */}
                <Grid item xs={12} className={classes.textArea}>
                  <TextField
                    name='notes'
                    id='notes'
                    fullWidth
                    value={editableTransaction.notes}
                    variant='outlined'
                    label='Notes'
                    rowsMax={5}
                    rows={5}
                    multiline
                    maxLength={200}
                    onChange={handleInputChangeNotes}
                  />
                </Grid>
              </Grid>
            </Grid>

            <DialogActions>
              <Button
                disabled={isSubmitting}
                className={classes.button}
                variant='outlined'
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                disabled={isSubmitting || !isSaveEnabled}
                className={classes.button}
                color='primary'
                variant='outlined'
                type='submit'
                onClick={handleSubmit}
              >
                {isSubmitting ? 'Saving...' : 'Save'}
              </Button>
            </DialogActions>
          </DialogContent>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default EditTransactionDialog;

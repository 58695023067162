import axios from 'axios';

import { baseUrl } from 'constants/api';

export const createAdvisor = (advisor) =>
  axios.post(`${baseUrl}/api/advisors`, advisor);

export const getClientProfileList = (advisorId) =>
  axios.get(`${baseUrl}/api/advisors/${advisorId}/client-profiles`);

export const searchAdvisor = (emailAddress, azureActiveDirectoryObjectId) =>
  axios.post(`${baseUrl}/api/advisors/search`, {
    emailAddress,
    azureActiveDirectoryObjectId,
  });

export const reloadAdvisor = (affiliateId, advisorId) =>
  axios.get(`${baseUrl}/api/affiliates/${affiliateId}/advisors/${advisorId}`);

export const updateAdvisorDisclosures = (affiliateId, advisorId, disclosures) =>
  axios.patch(
    `${baseUrl}/api/affiliates/${affiliateId}/advisors/${advisorId}`,
    disclosures
  );

export const updateAdvisorDesignationsList = (
  affiliateId,
  advisorId,
  designationsList
) =>
  axios.patch(
    `${baseUrl}/api/affiliates/${affiliateId}/advisors/${advisorId}`,
    {
      designationsList: designationsList.join(),
    }
  );

export const createAccountChangeRequest = (advisorId, changeRequest) =>
  axios.post(
    `${baseUrl}/api/advisors/${advisorId}/account-change-requests/`,
    changeRequest
  );

export const getAccountChangeRequestList = (advisorId) =>
  axios.get(`${baseUrl}/api/advisors/${advisorId}/account-change-requests/`);

// This invokes an experience API which fetches the advisor record and the change requests in single call.
export const getAdvisorAccount = (affiliateId, advisorId) =>
  axios.get(
    `${baseUrl}/api/affiliates/${affiliateId}/advisors/${advisorId}/accounts/`
  );

export const getClientProfileTemplates = (advisorId) =>
  axios.get(`${baseUrl}/api/advisors/${advisorId}/client-profile-templates`);

export const getRestoreClientProfileTemplates = (advisorId, templateId) =>
  axios.get(
    `${baseUrl}/api/advisors/${advisorId}/client-profile-templates/${templateId}/actions/restore`
  );

export const setAccountStatus = (affiliateId, advisorId, newStatus, reason) =>
  axios.patch(
    `${baseUrl}/api/affiliates/${affiliateId}/advisors/${advisorId}/account-statuses/${newStatus}?reason=${reason}`
  );

export const createAccountEventEntry = (
  affiliateId,
  advisorId,
  event,
  details
) =>
  axios.post(
    `${baseUrl}/api/affiliates/${affiliateId}/advisors/${advisorId}/account-events`,
    {
      stamp: new Date(),
      event,
      details,
    }
  );

export const updateAccountStatusWithEventEntry = (
  affiliateId,
  advisorId,
  newStatus,
  reason,
  event,
  details
) =>
  axios
    .patch(
      `${baseUrl}/api/affiliates/${affiliateId}/advisors/${advisorId}/account-statuses/${newStatus}?reason=${reason}`
    )
    .then(() => {
      axios.post(
        `${baseUrl}/api/affiliates/${affiliateId}/advisors/${advisorId}/account-events`,
        {
          event,
          details,
        }
      );
    });

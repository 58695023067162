import React, { useState } from 'react';
import { Divider, Drawer, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import DrawerHeader from './components/DrawerHeader';
import NavList from './components/NavList';
import ExpandButton from './components/ExpandButton';
import UserUtils from './components/UserUtils';
import WorkingItems from './components/WorkingItems';

const useStyles = makeStyles((theme) => ({
  drawer: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  paper: {
    backgroundColor: theme.navDrawer.backgroundColor,
    display: 'flex',
    flexDirection: 'column',
  },
  drawerOpen: {
    overflowX: 'hidden',
    width: theme.navDrawer.openWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    width: theme.navDrawer.closeWidth,
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

const NavDrawer = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const toggleDrawer = () => {
    setOpen((openState) => !openState);
  };

  return (
    <>
      <Drawer
        variant='permanent'
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx(classes.paper, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <DrawerHeader />
        <Divider />
        <NavList />
        <Divider />
        {/* this empty div is intended to fill up space, so the footer list will be at the bottom */}
        <WorkingItems />
        {/* <div style={{ height: '100%' }} /> */}
        <Divider />
        <UserUtils />
      </Drawer>
      <ExpandButton open={open} toggleDrawer={toggleDrawer} />
    </>
  );
};

export default NavDrawer;

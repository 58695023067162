import React from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Home as HomeIcon, Search as SearchIcon } from '@material-ui/icons';
import { useIsAuthenticated } from '@azure/msal-react';

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.primary.main,
  },
}));

const NavList = () => {
  const history = useHistory();
  const classes = useStyles();
  const isAuthenticated = useIsAuthenticated();

  const navHome = () => history.push('/');
  const navClientProfile = () => history.push('/clients/search');

  return (
    <>
      {isAuthenticated && (
        <List>
          <ListItem button onClick={navHome} component='li'>
            <ListItemIcon>
              <HomeIcon color='primary' />
            </ListItemIcon>
            <ListItemText className={classes.text} primary='Home' />
          </ListItem>

          <ListItem button onClick={navClientProfile} component='li'>
            <ListItemIcon>
              <SearchIcon color='primary' />
            </ListItemIcon>
            <ListItemText className={classes.text} primary='Client Search' />
          </ListItem>
        </List>
      )}
    </>
  );
};

export default NavList;

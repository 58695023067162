export const isAboveTheLineDeduction = (subcategory) => {
  let subcat = subcategory;
  if (subcat.startsWith('toolbox-')) {
    subcat = subcat.substring('toolbox-'.length);
  }
  if (
    subcat === 'retirementPlanContribution' ||
    subcat === 'healthSavingsAccountContribution' ||
    subcat === 'studentLoanInterest' ||
    subcat === 'alimony' ||
    subcat === 'otherDeductionAboveTheLine' ||
    subcat === 'higherEducationExpense'
  ) {
    return true;
  }
  return false;
};

export const getAboveTheLineDeductions = (allDeductions) => {
  return allDeductions.filter((deduction) =>
    isAboveTheLineDeduction(deduction.id)
  );
};

export const getBelowTheLineDeductions = (allDeductions) => {
  return allDeductions.filter(
    (deduction) => !isAboveTheLineDeduction(deduction.id)
  );
};

import React, { useEffect } from 'react';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { Redirect, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { prefActions } from 'store/slices/pref';

const LandingPage = () => {
  const dispatch = useDispatch();
  const queryString = new URLSearchParams(useLocation().search);

  useEffect(() => {
    // options: try | buy
    dispatch(
      prefActions.SET_WEB_SITE_CHOICE(
        queryString.has('choice') ? queryString.get('choice') : 'app'
      )
    );
  });

  return (
    <>
      <AuthenticatedTemplate>
        <Redirect to='/clients/search' />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Redirect to='/login' />
      </UnauthenticatedTemplate>
    </>
  );
};

export default LandingPage;

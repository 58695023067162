import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { currencyFormatter } from 'lib/utils/format';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2),
    },
  },
  header: {
    borderBottom: `1px solid ${theme.taxCast.summaryText}`,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  propertyText: {
    color: theme.taxCast.summaryText,
  },
  textSize: {
    fontSize: '0.9em',
  },
  calculation: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1, 0),
    borderTop: `1px solid ${theme.taxCast.summaryText}`,
  },
}));

// eslint-disable-next-line
const TaxYearSummary = ({ year }) => {
  const classes = useStyles();
  const summary = useSelector(({ taxCast }) => taxCast.calculations[year]);

  if (!summary) return null;

  return (
    <Grid container className={classes.root}>
      <Grid container>
        <Grid item xs={12} className={classes.header}>
          <Typography className={classes.propertyText}>Summary</Typography>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={5} sm={12} lg={8}>
            <Typography
              className={clsx(classes.propertyText, classes.textSize)}
              noWrap
            >
              Ordinary Income
            </Typography>
          </Grid>
          <Grid item xs={7} sm={12} lg={4}>
            <Typography className={classes.textSize}>
              {currencyFormatter.format(summary.ordinaryTaxableIncome)}
            </Typography>
          </Grid>

          <Grid item xs={5} sm={12} lg={8}>
            <Typography
              className={clsx(classes.propertyText, classes.textSize)}
              noWrap
            >
              Capital Gains
            </Typography>
          </Grid>
          <Grid item xs={7} sm={12} lg={4}>
            <Typography className={classes.textSize}>
              {currencyFormatter.format(summary.capitalGainsTaxableIncome)}
            </Typography>
          </Grid>

          <Grid item xs={5} sm={12} lg={8}>
            <Typography
              className={clsx(classes.propertyText, classes.textSize)}
              noWrap
            >
              Social Security
            </Typography>
          </Grid>
          <Grid item xs={7} sm={12} lg={4}>
            <Typography className={classes.textSize}>
              {currencyFormatter.format(summary.taxableSocialSecurity)}
            </Typography>
          </Grid>

          <Grid item xs={5} sm={12} lg={8}>
            <Typography
              className={clsx(classes.propertyText, classes.textSize)}
              noWrap
            >
              Deductions
            </Typography>
          </Grid>
          <Grid item xs={7} sm={12} lg={4}>
            <Typography className={classes.textSize}>
              {currencyFormatter.format(summary.deductions)}
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} className={classes.header}>
          <Typography className={classes.propertyText}>Taxes</Typography>
        </Grid>

        {/* <Grid container>
          <Grid item xs={12} className={classes.header}>
            <Typography className={classes.propertyText}>Summary</Typography>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={5} sm={12} lg={8}> */}

        <Grid container item xs={12}>
          <Grid item xs={5} sm={12} lg={8}>
            <Typography
              className={clsx(classes.propertyText, classes.textSize)}
            >
              Income
            </Typography>
          </Grid>
          <Grid item xs={7} sm={12} lg={4}>
            <Typography className={classes.textSize}>
              {currencyFormatter.format(summary.ordinaryTaxableIncomeTax)}
            </Typography>
          </Grid>

          <Grid item xs={5} sm={12} lg={8}>
            <Typography
              className={clsx(classes.propertyText, classes.textSize)}
            >
              Capital Gains
            </Typography>
          </Grid>
          <Grid item xs={7} sm={12} lg={4}>
            <Typography className={classes.textSize}>
              {currencyFormatter.format(summary.capitalGainsTaxableIncomeTax)}
            </Typography>
          </Grid>

          {/* TODO: Uncomment this upon implementation of Surtax calculation. */}
          {/* <Grid item xs={5} sm={12} lg={8}>
            <Typography className={clsx(classes.propertyText, classes.textSize)} noWrap>
              Surtax
            </Typography>
          </Grid>
          <Grid item xs={7} sm={12} lg={4}>
            <Typography className={classes.textSize}>{currencyFormatter.format(summary.surtax)}</Typography>
          </Grid> */}

          <Grid item xs={5} sm={12} lg={8}>
            <Typography
              className={clsx(classes.propertyText, classes.textSize)}
            >
              Total
            </Typography>
          </Grid>
          <Grid item xs={7} sm={12} lg={4}>
            <Typography className={classes.textSize}>
              {currencyFormatter.format(summary.totalTax)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

TaxYearSummary.propTypes = {
  year: PropTypes.string.isRequired,
};

export default TaxYearSummary;

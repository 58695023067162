/* eslint max-lines: [2, {"max": 350, "skipComments": true, "skipBlankLines": true}] */
import React from 'react';
import { Button, Grid, makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1000,
  },
  form: {
    padding: theme.spacing(2),
  },
  field: {
    [theme.breakpoints.up('sm')]: {
      minHeight: theme.spacing(12),
    },
  },
}));

const RestoreDialog = ({ title, content, show, onClose }) => {
  const classes = useStyles();

  const handleConfirmRemoveClose = (event) => {
    onClose(event);
  };

  return (
    <Grid container className={classes.root}>
      <Dialog
        open={show}
        onClose={handleConfirmRemoveClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleConfirmRemoveClose('cancel')}
            color='primary'
            value='cancel'
            // disabled={isDeleting}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleConfirmRemoveClose('ok')}
            color='primary'
            value='ok'
            autoFocus
            // disabled={isDeleting}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

RestoreDialog.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  show: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

RestoreDialog.defaultProps = {
  title: 'Confirm Restore',
  content: 'Are you certain you want to restore the selected item?',
  show: false,
};

export default RestoreDialog;

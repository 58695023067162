import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import { phoneNumberFormatter } from 'lib/utils/format';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.maxWidth,
    padding: theme.spacing(3, 3),
  },
  name: {
    fontSize: '2em',
    paddingLeft: theme.spacing(2),
    fontWeight: 'bold',
    paddingBottom: theme.spacing(3),
  },
  otherThanNameContainer: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
  otherThanNameText: {
    paddingLeft: theme.spacing(2),
    fontSize: '2em',
  },
}));

const Summary = ({ advisor }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Grid container item xs={12}>
        <Typography className={classes.name}>
          {advisor.givenName} {advisor.surname} {advisor.designationsList}
        </Typography>
      </Grid>
      <Grid container className={classes.otherThanName}>
        <Grid item xs={12}>
          <Typography className={classes.otherThanNameText}>
            {advisor.preferredAddressLineOne}
          </Typography>
        </Grid>
        {advisor.preferredAddressLineTwo && (
          <Grid item xs={12}>
            <Typography className={classes.otherThanNameText}>
              {advisor.preferredAddressLineTwo}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography className={classes.otherThanNameText}>
            {advisor.preferredAddressCity}{' '}
            {advisor.preferredAddressStateOrProvince},{' '}
            {advisor.preferredAddressPostalCode}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} className={classes.otherThanName}>
        <Typography className={classes.otherThanNameText}>
          {advisor.preferredEmailAddress}
        </Typography>
      </Grid>
      <Grid container item xs={12} className={classes.otherThanName}>
        <Typography className={classes.otherThanNameText}>
          {phoneNumberFormatter(advisor.preferredPhoneNumber)}
        </Typography>
      </Grid>
    </Grid>
  );
};

Summary.propTypes = {
  advisor: PropTypes.shape({
    givenName: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    preferredAddressLineOne: PropTypes.string.isRequired,
    preferredAddressLineTwo: PropTypes.string,
    preferredAddressCity: PropTypes.string.isRequired,
    preferredAddressStateOrProvince: PropTypes.string.isRequired,
    preferredAddressPostalCode: PropTypes.string.isRequired,
    preferredEmailAddress: PropTypes.string.isRequired,
    preferredPhoneNumber: PropTypes.string.isRequired,
    designationsList: PropTypes.string,
  }).isRequired,
};

Summary.defaultProps = {};

export default Summary;

/* eslint max-lines: [2, {"max": 350, "skipComments": true, "skipBlankLines": true}] */
import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Form } from 'react-final-form';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {
  composeValidators,
  isEmail,
  required,
  trimWhiteSpace,
} from 'lib/utils/form';
import CheckboxField from 'lib/components/CheckboxField';
import FormField from 'lib/components/FormField';
import DatePickerField from 'lib/components/DatePickerField';
import { toast } from 'react-toastify';
import { omit } from 'lodash';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import * as clientApi from 'api/client';
import { shapeErrorsForToast } from 'lib/utils/errorUtil';
import { ErrorToast } from 'lib/components/ErrorToast';
import { relationshipTypes } from 'constants/relationships';

import { isValidPhoneNumberUnadorned } from 'lib/utils/validation';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '65vh',
    maxWidth: 1000,
  },
  form: {
    padding: theme.spacing(2),
  },
  field: {
    [theme.breakpoints.up('sm')]: {
      minHeight: theme.spacing(12),
    },
  },
}));

const NewDialog = ({
  show,
  clientProfileId,
  clientProfileAddress,
  onClose,
}) => {
  const classes = useStyles();

  // TODO: Replace when authentication is implemented.
  const advisorId = useSelector(({ auth }) => auth.account.id);

  const closeDialog = (relation) => {
    onClose(relation);
  };

  const handleSave = (form) => {
    const clientSpouse = omit(form, ['sameAddressAsClient']);
    clientSpouse.advisorId = advisorId;
    clientSpouse.relationship = relationshipTypes.spouse;

    if (form.sameAddressAsClient) {
      clientSpouse.addressLineOne =
        clientProfileAddress.preferredAddressLineOne;
      clientSpouse.addressLineTwo =
        clientProfileAddress.preferredAddressLineTwo;
      clientSpouse.addressCity = clientProfileAddress.preferredAddressCity;
      clientSpouse.addressStateOrProvince =
        clientProfileAddress.preferredAddressStateOrProvince;
      clientSpouse.addressPostalCode =
        clientProfileAddress.preferredAddressPostalCode;
    }

    clientSpouse.clientProfileId = clientProfileId;

    // Sanitize phone number to ensure no adornments.
    if (!isValidPhoneNumberUnadorned(clientSpouse.phoneNumber)) {
      const error =
        'Phone number must be ten digits with no adornments. e.g. 9065551212';
      toast.error(<ErrorToast errorMessages={shapeErrorsForToast(error)} />, {
        autoClose: false,
      });
    } else {
      clientApi
        .createClientRelation(clientSpouse)
        .then((response) => {
          closeDialog(response.data);
          toast.success(`Spouse/partner added.`);
        })
        .catch((error) => {
          toast.error(
            <ErrorToast errorMessages={shapeErrorsForToast(error)} />,
            {
              autoClose: false,
            }
          );
        });
    }
  };

  return (
    <Dialog
      open={!!show}
      disableBackdropClick
      aria-labelledby='Add Spouse/Partner Dialog'
    >
      <Grid container className={classes.root}>
        <DialogTitle id='form-dialog-title'>New Dependent</DialogTitle>
        <Grid item xs={12}>
          <DialogContent>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Form
                onSubmit={handleSave}
                initialValues={{ sameAddressAsClient: true }}
                render={({ handleSubmit, values, submitting, invalid }) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <Grid container justify='center' className={classes.form}>
                      <Grid container item xs={12} md={10} lg={9} spacing={1}>
                        <Grid item xs={12}>
                          <Typography
                            variant='h6'
                            component='h2'
                            color='primary'
                          >
                            Spouse/Partner
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} className={classes.field}>
                          <FormField
                            name='givenName'
                            id='givenName'
                            label='First Name'
                            validate={required}
                            format={trimWhiteSpace}
                            required
                            autoFocus
                            variant='outlined'
                          />
                        </Grid>

                        <Grid item xs={12} sm={4} className={classes.field}>
                          <FormField
                            name='surname'
                            id='surname'
                            label='Last Name'
                            validate={required}
                            format={trimWhiteSpace}
                            required
                            variant='outlined'
                          />
                        </Grid>

                        <Grid item xs={12} sm={4} className={classes.field}>
                          <DatePickerField
                            name='birthDate'
                            id='birthDate'
                            label='Birth Date'
                            validate={required}
                            required
                            variant='outlined'
                            disableFuture
                          />
                        </Grid>

                        <Grid item xs={12} sm={4} className={classes.field}>
                          <FormField
                            name='phoneNumber'
                            id='phoneNumber'
                            label='Phone'
                            validate={required}
                            format={trimWhiteSpace}
                            required
                            variant='outlined'
                          />
                        </Grid>

                        <Grid item xs={12} sm={8} className={classes.field}>
                          <FormField
                            name='emailAddress'
                            id='emailAddress'
                            label='Email'
                            validate={composeValidators(required, isEmail)}
                            format={trimWhiteSpace}
                            required
                            variant='outlined'
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <FormField
                            name='notes'
                            id='notes'
                            label='Notes'
                            variant='outlined'
                            rows={5}
                            rowsMax={5}
                            multiline
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <CheckboxField
                            name='sameAddressAsClient'
                            id='sameAddressAsClient'
                            label='Spouse/Partner has same address as client'
                          />
                        </Grid>

                        {!values.sameAddressAsClient && (
                          <>
                            <Grid item xs={12} className={classes.field}>
                              <FormField
                                name='addressLineOne'
                                id='addressLineOne'
                                label='Address Line 1'
                                validate={required}
                                required
                                format={trimWhiteSpace}
                                variant='outlined'
                              />
                            </Grid>

                            <Grid item xs={12} className={classes.field}>
                              <FormField
                                name='addressLineTwo'
                                id='addressLineTwo'
                                label='Address Line 2'
                                format={trimWhiteSpace}
                                variant='outlined'
                              />
                            </Grid>

                            <Grid item xs={12} sm={4} className={classes.field}>
                              <FormField
                                name='addressCity'
                                id='addressCity'
                                label='City'
                                format={trimWhiteSpace}
                                variant='outlined'
                                validate={required}
                                required
                              />
                            </Grid>

                            <Grid item xs={12} sm={4} className={classes.field}>
                              <FormField
                                name='addressStateOrProvince'
                                id='addressStateOrProvince'
                                label='State'
                                format={trimWhiteSpace}
                                variant='outlined'
                                validate={required}
                                required
                              />
                            </Grid>

                            <Grid item xs={12} sm={4} className={classes.field}>
                              <FormField
                                name='addressPostalCode'
                                id='addressPostalCode'
                                label='Postal Code'
                                format={trimWhiteSpace}
                                variant='outlined'
                                validate={required}
                                required
                              />
                            </Grid>
                          </>
                        )}

                        <Grid container justify='flex-end' spacing={2}>
                          <DialogActions>
                            <Grid item>
                              <Button
                                disabled={submitting}
                                color='primary'
                                variant='outlined'
                                onClick={() => closeDialog(null)}
                              >
                                Cancel
                              </Button>
                            </Grid>
                            <Grid item>
                              <Button
                                disabled={submitting || invalid}
                                type='submit'
                                color='primary'
                                variant='contained'
                              >
                                Save
                              </Button>
                            </Grid>
                          </DialogActions>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                )}
              />
            </MuiPickersUtilsProvider>
          </DialogContent>
        </Grid>
      </Grid>
    </Dialog>
  );
};

NewDialog.propTypes = {
  show: PropTypes.bool,
  clientProfileId: PropTypes.string.isRequired,
  clientProfileAddress: PropTypes.shape({
    preferredAddressLineOne: PropTypes.string.isRequired,
    preferredAddressLineTwo: PropTypes.string.isRequired,
    preferredAddressCity: PropTypes.string.isRequired,
    preferredAddressStateOrProvince: PropTypes.string.isRequired,
    preferredAddressPostalCode: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

NewDialog.defaultProps = {
  show: false,
};

export default NewDialog;

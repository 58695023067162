import React, { useState } from 'react';
import { Paper, makeStyles } from '@material-ui/core';

import { categoryTypes } from 'constants/categories';
import { toolBoxData } from 'constants/toolBoxData';
import { getAboveTheLineDeductions, getBelowTheLineDeductions } from 'lib/utils/transactionCategoriesUtil';
import ExpandableToolBox from './ExpandableToolBox';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
}));

const TaxCastToolBar = () => {
  const classes = useStyles();
  const [expand, setExpand] = useState('');

  return (
    <Paper className={classes.root}>
      <ExpandableToolBox
        id='toolbox1'
        title='Ordinary Income'
        type={categoryTypes.ordinaryIncome}
        expanded={expand}
        onChange={setExpand}
        items={toolBoxData.ordinaryIncome}
      />

      <ExpandableToolBox
        id='toolbox2'
        title='Capital Gains'
        type={categoryTypes.capitalGains}
        expanded={expand}
        onChange={setExpand}
        items={toolBoxData.capitalGains}
      />

      <ExpandableToolBox
        id='toolbox3'
        title='ATL Deductions'
        type={categoryTypes.deductions}
        expanded={expand}
        onChange={setExpand}
        items={getAboveTheLineDeductions(toolBoxData.deductions)}
      />

      <ExpandableToolBox
        id='toolbox4'
        title='Itemized Deductions'
        type={categoryTypes.deductions}
        expanded={expand}
        onChange={setExpand}
        items={getBelowTheLineDeductions(toolBoxData.deductions)}
      />
    </Paper>
  );
};

export default TaxCastToolBar;

/* eslint max-lines: [2, {"max": 200, "skipComments": true, "skipBlankLines": true}] */
import React, { useEffect } from 'react';
import { Button, Grid, Paper, Typography, makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { EventType } from '@azure/msal-browser';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from '@azure/msal-react';
import { loginRequest } from 'lib/utils/authConfig';

import * as authActions from 'store/asyncActions/auth';
import TaxCastLogo from 'assets/img/Logo512x512.png';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.maxWidth,
    [theme.breakpoints.down('lg')]: {
      margin: theme.spacing(1, 3),
    },
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(1, 'auto'),
    },
  },
  paper: {
    minWidth: '100%',
  },
  header: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(1),
  },
  divider: {
    borderRight: `1px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(1),
  },
  rightCell: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
  },
  leftCell: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
    borderRight: `1px solid ${theme.palette.primary.main}`,
  },
}));

const Login = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { instance } = useMsal();

  useEffect(() => {
    document.title = 'TaxCast - Login';
  }, []);

  useEffect(() => {
    const callbackId = instance.addEventCallback((event) => {
      // switch (event.eventType) {
      //   case EventType.LOGIN_SUCCESS:
      //     console.log("login-success", event);
      //     break;
      //   case EventType.LOGIN_FAILURE:
      //     console.log("login-failure", event);
      //     break;
      //   case EventType.LOGOUT_SUCCESS:
      //     console.log("logout-success", event);
      //     break;
      //   case EventType.LOGOUT_FAILURE:
      //     console.log("logout-failure", event);
      //     break;
      //   case EventType.HANDLE_REDIRECT_START:
      //     console.log("redirect-start", event);
      //     break;
      //   case EventType.HANDLE_REDIRECT_END:
      //     console.log("redirect-end", event);
      //     break;
      //   default:
      //     break;
      // }
      // if (event.EventType === EventType.ACQUIRE_TOKEN_SUCCESS) {

      // }
      if (event.eventType === EventType.LOGIN_SUCCESS) {
        instance.setActiveAccount(event.payload.account);
        instance
          .acquireTokenSilent({ ...loginRequest })
          .then((response) => {
            window.sessionStorage.setItem(
              'taxcast.auth.accessToken',
              response.accessToken
            );
            dispatch(
              authActions.AADB2C_LOGIN_SUCCESS(event.payload.idTokenClaims)
            );
            history.push('/auth');
          })
          .catch(() => {
            // acquireTokenSilent can fail for a number of reasons, fallback to interaction
            instance.acquireTokenPopup({ ...loginRequest }).then((response) => {
              window.sessionStorage.setItem(
                'taxcast.auth.accessToken',
                response.accessToken
              );
              dispatch(
                authActions.AADB2C_LOGIN_SUCCESS(event.payload.idTokenClaims)
              );
              history.push('/auth');
            });
          });
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [dispatch, history, instance]);

  const handleLoginClick = () => {
    instance.loginPopup(loginRequest);
  };

  const handleGoToTaxCastClick = () => {
    history.push('/auth');
  };

  return (
    <Grid container item xs={12} className={classes.root} spacing={2}>
      <Paper spacing={2} elevation={3} className={classes.paper}>
        <Grid container>
          <Grid container item xs={12}>
            <Grid item xs={12} md={6} className={classes.leftCell}>
              <img src={TaxCastLogo} alt='TaxCast Logo' />
            </Grid>
            <AuthenticatedTemplate>
              <Grid item xs={12} md={5} className={classes.rightCell}>
                <Grid item xs={12}>
                  <Typography component='h4' variant='h4' color='primary'>
                    Welcome to TaxCast!
                  </Typography>
                  <br />
                  <Typography component='h5' variant='h5' color='primary'>
                    You are authenticated.
                  </Typography>
                  <br />
                  <Typography component='h4' variant='h4'>
                    <Button
                      onClick={handleGoToTaxCastClick}
                      variant='contained'
                      color='primary'
                    >
                      Go To TaxCast
                    </Button>
                  </Typography>
                </Grid>
              </Grid>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <Grid item xs={12} md={5} className={classes.rightCell}>
                <Typography component='h4' variant='h4' color='primary'>
                  Login to TaxCast
                </Typography>
                <br />
                <Typography component='h5' variant='h5' color='primary'>
                  If you do not have a TaxCast account, click &quot;Login&quot;
                  below and then choose &quot;Sign up now&quot;.
                </Typography>
                <br />
                <Typography component='h5' variant='h5' color='primary'>
                  Forgot your password? Click &quot;Login&quot; below and then
                  choose &quot;Forgot your password?&quot;.
                </Typography>
                <br />
                <Typography component='h4' variant='h4'>
                  <Button
                    onClick={handleLoginClick}
                    variant='contained'
                    color='primary'
                  >
                    Login
                  </Button>
                </Typography>
              </Grid>
            </UnauthenticatedTemplate>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default Login;

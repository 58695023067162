/* eslint-disable import/prefer-default-export */
/* eslint max-lines: [2, {"max": 250, "skipComments": true, "skipBlankLines": true}] */
import React from 'react';
import { find, split } from 'lodash';
import { toast } from 'react-toastify';

import * as taxCastApi from 'api/taxCast';
import { toolBoxData } from 'constants/toolBoxData';
import { shapeErrorsForToast } from 'lib/utils/errorUtil';
import { ErrorToast } from 'lib/components/ErrorToast';
import { taxCastActions } from '../slices/taxCast';

export const GET_CLIENT_DATA = (clientId, taxTableYear) => (dispatch) => {
  taxCastApi
    .getClientData(clientId, taxTableYear)
    .then((data) => {
      dispatch(taxCastActions.LOAD_CANVAS(data));
    })
    .catch((error) => {
      dispatch(taxCastActions.ERROR(error.message));
    });
};

export const NEW_TRANSACTION_DIALOG = (
  transaction,
  forecastId,
  clientProfileId
) => (dispatch) => {
  if (!transaction) {
    dispatch(taxCastActions.TOGGLE_NEW_TRANSACTION_DIALOG(null));
    return;
  }

  const subcategory = find(
    toolBoxData[transaction.type],
    (sub) => sub.id === transaction.draggableId
  )?.id?.replace('toolbox-', '');

  const [taxYear, category] = split(transaction.destination.droppableId, '-');
  const displaySequence = transaction.destination.index;

  if (taxYear && category && subcategory) {
    dispatch(
      taxCastActions.TOGGLE_NEW_TRANSACTION_DIALOG({
        taxYear,
        category,
        subcategory,
        displaySequence,
        forecastId,
        clientProfileId,
      })
    );
  }
};

export const NEW_TRANSACTION = (transaction) => (dispatch) => {
  dispatch(taxCastActions.SUBMITTING_FORM());
  let charitableContributionType;

  taxCastApi
    .createTransaction({ ...transaction, charitableContributionType })
    .then((data) => {
      // Support for transaction spreading realizes a change in which
      // this operation returns an array, even if the transaction is not spread.
      dispatch(taxCastActions.ADD_TRANSACTION_SPREAD(data));
    })
    .catch((error) => {
      dispatch(taxCastActions.SILENT_ERROR(error));
    });
};

export const REFRESH_CALCULATIONS = ({
  clientProfileId,
  forecastId,
  advisorId,
}) => (dispatch) => {
  dispatch(taxCastActions.RUNNING_CALCULATIONS());
  taxCastApi
    .runCalculations(clientProfileId, forecastId, advisorId)
    .then((response) => {
      dispatch(taxCastActions.CALCULATIONS_RESULTS(response));
    })
    .catch((error) => {
      dispatch(taxCastActions.SILENT_ERROR(error.message));
    });
};

export const MOVE_TRANSACTION = ({
  destination,
  source,
  category,
  id,
  forecastId,
  clientProfileId,
  advisorId,
}) => (dispatch) => {
  dispatch(
    taxCastActions.REORDER_TRANSACTIONS({ destination, source, category, id })
  );
  taxCastApi
    .moveTransaction({ destination, source, id, forecastId })
    .then(() => {
      dispatch(
        REFRESH_CALCULATIONS({ clientProfileId, forecastId, advisorId })
      );
    })
    .catch((error) => {
      dispatch(taxCastActions.SILENT_ERROR(error.message));
    });
};

export const EDIT_TRANSACTION = (transaction) => (dispatch) => {
  dispatch(taxCastActions.SUBMITTING_FORM());

  taxCastApi
    .editTransaction(transaction)
    .then((data) => {
      dispatch(taxCastActions.EDIT_TRANSACTION(data));
    })
    .catch((error) => {
      dispatch(taxCastActions.SILENT_ERROR(error.message));
    });
};

export const SPLIT_DISTRIBUTE_TRANSACTION_DIALOG = (transaction, operation) => (
  dispatch
) => {
  dispatch(
    taxCastActions.TOGGLE_SPLIT_DISTRIBUTE_TRANSACTION_DIALOG(
      transaction,
      operation
    )
  );
};

export const DELETE_TRANSACTION = (transaction) => (dispatch) => {
  dispatch(taxCastActions.SUBMITTING_FORM());
  const { id, taxYear, category, forecastId } = transaction;

  taxCastApi
    .deleteTransaction(id, forecastId)
    .then(() => {
      dispatch(taxCastActions.DELETE_TRANSACTION({ id, taxYear, category }));
    })
    .catch((error) => {
      dispatch(taxCastActions.SILENT_ERROR(error.message));
    });
};

export const RUN_CALCULATIONS = ({
  clientProfileId,
  forecastId,
  advisorId,
}) => (dispatch) => {
  // dispatch(taxCastActions.SET_BUSY());
  if (clientProfileId && forecastId && advisorId) {
    dispatch(taxCastActions.RUNNING_CALCULATIONS());

    taxCastApi
      .runCalculations(clientProfileId, forecastId, advisorId)
      .then((response) => {
        dispatch(taxCastActions.CALCULATIONS_RESULTS(response));
        // dispatch(taxCastActions.CLEAR_BUSY());
      })
      .catch((error) => {
        dispatch(taxCastActions.SILENT_ERROR());
        toast.error(<ErrorToast errorMessages={shapeErrorsForToast(error)} />, {
          autoClose: false,
        });
        // dispatch(taxCastActions.CLEAR_BUSY());
      });
  }
};

export const UPDATE_CLIENT_PROFILE = (normalizedClientProfile) => (
  dispatch
) => {
  dispatch(taxCastActions.UPDATED_CLIENT_PROFILE(normalizedClientProfile));
};

export const UPDATE_FILING_STATUS_FOR_TAX_YEAR = (
  clientProfileId,
  forecastId,
  taxYear,
  filingStatus
) => (dispatch) => {
  taxCastApi
    .patchForecast(clientProfileId, forecastId, +taxYear, filingStatus)
    .then(() => {
      dispatch(
        taxCastActions.UPDATED_FILING_STATUS_FOR_TAX_YEAR({
          taxYear,
          filingStatus,
        })
      );
    })
    .catch((error) => {
      dispatch(taxCastActions.ERROR(error.message));
    });
};

export const SPREADING_TRANSACTION = (spreadData) => (dispatch) => {
  dispatch(taxCastActions.SPREAD_TRANSACTIONS(spreadData));
};

export const CLEAR_TAXCAST_DATA = () => (dispatch) => {
  dispatch(taxCastActions.RESET());
};

export const COMPLETE_PREFLIGHT = () => (dispatch) => {
  dispatch(taxCastActions.UPDATE_PREFLIGHT_STATUS(true));
};

export const UNLOAD_FORECAST = () => (dispatch) => {
  dispatch(taxCastActions.UNLOAD_FORECAST());
};

import React from 'react';
import { Paper, Tabs } from '@material-ui/core';
import PropTypes from 'prop-types';

export const FSTabs = ({ currentTab, onChange, children, orientation }) => {
    return (
    <Paper>
      <Tabs orientation={orientation} value={currentTab} onChange={onChange} indicatorColor='primary' textColor='primary'>
        {children}
      </Tabs>
    </Paper>
  );
};

FSTabs.propTypes = {
  currentTab: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  orientation: PropTypes.string,
};

FSTabs.defaultProps = {
  orientation: "horizontal",
};

export const FSTabPanel = ({ children, value, index, id, labelBy }) => (
  <div role='tabpanel' hidden={value !== index} id={id} aria-labelledby={labelBy}>
    {value === index && children}
  </div>
);

FSTabPanel.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  labelBy: PropTypes.string.isRequired,
};

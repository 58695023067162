import axios from 'axios';

import { baseUrl } from 'constants/api';

// Client Profile
export const createClientProfile = (clientProfile) =>
  axios.post(`${baseUrl}/api/client-profiles`, clientProfile);

export const getClientProfile = (clientProfileId) =>
  axios.get(`${baseUrl}/api/client-profiles/${clientProfileId}`);
// `${baseUrl}/api/advisors/${advisorId}/client-profiles/${clientProfileId}`

export const getClientProfileWithDependents = (clientProfileId) =>
  axios.get(`${baseUrl}/api/client-profiles/${clientProfileId}/families`);

export const updateClientProfile = (clientProfile) =>
  axios.put(
    `${baseUrl}/api/client-profiles/${clientProfile.id}`,
    clientProfile
  );

// Relationships
export const getClientRelationsList = (clientProfileId) =>
  axios.get(
    `${baseUrl}/api/client-profiles/${clientProfileId}/client-relations`
  );

export const getClientSpouse = (clientProfileId) =>
  axios.get(
    `${baseUrl}/api/client-profiles/${clientProfileId}/client-relations?spouseFilter=only`
  );

export const getClientOtherDependents = (clientProfileId) =>
  axios.get(
    `${baseUrl}/api/client-profiles/${clientProfileId}/client-relations?spouseFilter=omit`
  );

export const createClientRelation = (clientRelation) =>
  axios.post(
    `${baseUrl}/api/client-profiles/${clientRelation.clientProfileId}/client-relations`,
    clientRelation
  );

export const updateClientRelation = (clientRelation) =>
  axios.put(
    `${baseUrl}/api/client-profiles/${clientRelation.clientProfileId}/client-relations/${clientRelation.id}`,
    clientRelation
  );

export const deleteClientRelation = (clientProfileId, clientRelationId) =>
  axios.delete(
    `${baseUrl}/api/client-profiles/${clientProfileId}/client-relations/${clientRelationId}`
  );

import React from 'react';
import {
  Divider,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { ExpandMore as ExpandIcon } from '@material-ui/icons';
import { Droppable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';

import ToolBoxItem from './ToolBoxItem';

const useStyles = makeStyles((theme) => ({
  categoryText: {
    fontWeight: 540,
    color: theme.palette.primary.main,

    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9em',
    },
  },
  panelDetails: {
    padding: 0,
  },
  detailGrid: {
    maxHeight: '65vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    [theme.breakpoints.down('lg')]: {
      maxHeight: '52vh',
    },
  },
}));

const ExpandableToolBox = ({ id, type, title, items, expanded, onChange }) => {
  const classes = useStyles();
  const handleChange = () => {
    onChange(expanded !== title ? title : '');
  };

  return (
    <ExpansionPanel expanded={expanded === title} onChange={handleChange}>
      <ExpansionPanelSummary expandIcon={<ExpandIcon color='primary' />}>
        <Typography className={classes.categoryText}>{title}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.panelDetails}>
        <Droppable droppableId={id} isDropDisabled type={type}>
          {(provided) => (
            <Grid
              container
              ref={provided.innerRef}
              className={classes.detailGrid}
            >
              {items.map((item, index) => (
                <Grid item xs={12} className={classes.item} key={item.id}>
                  <Divider />
                  <ToolBoxItem item={item} index={index} />
                </Grid>
              ))}
              {provided.placeholder}
            </Grid>
          )}
        </Droppable>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

ExpandableToolBox.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({ type: PropTypes.string, name: PropTypes.string })
  ).isRequired,
  expanded: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ExpandableToolBox;

import React from 'react';
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import {
  BarChart as BarChartIcon,
  // CloudDownload as CloudDownloadIcon,
  Description as DescriptionIcon,
  OpenInNew as OpenInNewIcon,
  // PlayArrow as PlayArrowIcon,
} from '@material-ui/icons';

import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(0.5),
  },
  card: {
    maxWidth: 235,
  },
}));

const Analytics = () => {
  const classes = useStyles();
  const affiliateId = useSelector(({ auth }) => auth.account.affiliateId);
  const advisorId = useSelector(({ auth }) => auth.account.id);
  const advisorGivenName = useSelector(({ auth }) => auth.account.givenName);
  const advisorSurname = useSelector(({ auth }) => auth.account.surname);
  const disclosureA = useSelector(({ auth }) => auth.account.disclosureA);
  const disclosureB = useSelector(({ auth }) => auth.account.disclosureB);
  const disclosureC = useSelector(({ auth }) => auth.account.disclosureC);
  const clientGivenName = useSelector(
    ({ taxCast }) => taxCast.client.givenName
  );
  const clientSurname = useSelector(({ taxCast }) => taxCast.client.surname);
  const clientProfileId = useSelector(({ taxCast }) => taxCast.client.id);
  const forecastId = useSelector(({ taxCast }) => taxCast.forecast.id);
  const forecast = useSelector(({ taxCast }) => taxCast.forecast);
  const calculations = useSelector(({ taxCast }) => taxCast.calculations);

  const serializeStateToLocalStorage = (state) => {
    try {
      localStorage.setItem('report-state', JSON.stringify(state));
    } catch {
      // TODO: Handle errors
    }
  };

  const handleClickOpenInNew = (reportType, reportName) => {
    serializeStateToLocalStorage({
      affiliateId,
      advisorId,
      clientProfileId,
      forecastId,
      reportType,
      reportName,
      forecast,
      calculations,
      advisorGivenName,
      advisorSurname,
      disclosureA,
      disclosureB,
      disclosureC,
      clientGivenName,
      clientSurname,
    });
    window.open(`/reports`, '_blank');
  };

  return (
    <Grid container>
      <Grid container item spacing={2} className={classes.root}>
        <Grid item>
          <Card className={classes.card}>
            <CardHeader
              avatar={
                <Avatar aria-label='recipe'>
                  <DescriptionIcon />
                </Avatar>
              }
              title='Canvas Report'
              subheader='PDF'
            />
            <CardContent>
              This report provides a printable view of the entire canvas. It
              typically requires four (4) to nine (9) pages.
            </CardContent>
            <CardActions disableSpacing>
              <IconButton
                aria-label='generate'
                onClick={() =>
                  handleClickOpenInNew('application/pdf', 'canvas')
                }
              >
                <OpenInNewIcon />
              </IconButton>
              {/* <IconButton aria-label='generate' onClick={handleClickDownload}>
                <CloudDownloadIcon />
              </IconButton> */}
            </CardActions>
          </Card>
        </Grid>
        <Grid item>
          <Card className={classes.card}>
            <CardHeader
              avatar={
                <Avatar aria-label='recipe'>
                  <BarChartIcon />
                </Avatar>
              }
              title='COMING SOON! Unused Bracket Capacity'
              subheader='Bar Chart'
            />
            <CardContent>
              This bar char helps identify the unused bracket capacity for the
              current TaxCast distribution.
            </CardContent>
            <CardActions disableSpacing>
              <IconButton
                aria-label='view'
                onClick={() =>
                  handleClickOpenInNew(
                    'visualization',
                    'unusedOrdinaryBracketCapacity'
                  )
                }
              >
                <OpenInNewIcon />
              </IconButton>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Analytics;

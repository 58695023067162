/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';

export const ProtectedRoute = ({ children, path, exact }) => {
  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();

  return (
    <Route
      exact={exact}
      path={path}
      render={({ location }) => {
        return isAuthenticated || inProgress !== InteractionStatus.None ? (
          children
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: location } }} />
        );
      }}
    />
  );
};

ProtectedRoute.propTypes = {
  children: PropTypes.element.isRequired,
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool,
};

ProtectedRoute.defaultProps = {
  exact: false,
};

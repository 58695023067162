import React, { useState } from 'react';
import { Grid, IconButton, Typography, makeStyles } from '@material-ui/core';
import {
  ToggleOff as ToggleOffIcon,
  ToggleOn as ToggleOnIcon,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { currencyFormatter } from 'lib/utils/format';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1),
    },
  },
  header: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    fontSize: '1.2em',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9em',
      width: '90%',
    },
  },
  text: {
    fontSize: '1em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9em',
    },
  },
}));

const YearTaxPair = ({ year, tax, classes, effectiveTaxRate, showTax }) => (
  <>
    <Grid item xs={3} sm={4}>
      <Typography className={classes.text} color='primary'>
        {year}
      </Typography>
    </Grid>

    <Grid item xs={9} sm={8}>
      <Typography className={classes.text} noWrap>
        {showTax && currencyFormatter.format(tax)}
        {!showTax && `${Math.round(effectiveTaxRate)}%`}
      </Typography>
    </Grid>
  </>
);

YearTaxPair.propTypes = {
  year: PropTypes.number.isRequired,
  tax: PropTypes.number.isRequired,
  classes: PropTypes.shape({ text: PropTypes.string }).isRequired,
  effectiveTaxRate: PropTypes.number.isRequired,
  showTax: PropTypes.bool.isRequired,
};

const TaxTrend = () => {
  const classes = useStyles();
  const calculations = useSelector(({ taxCast }) => taxCast.calculations);
  const [toggleShowTaxAmount, setToggleShowTaxAmount] = useState(true);

  const handleToggleShowTaxAmountClick = () => {
    setToggleShowTaxAmount(!toggleShowTaxAmount);
  };

  if (!calculations) return null;

  return (
    <Grid container>
      <Grid container item xs={12} className={classes.header}>
        <Grid item xs={10}>
          <Typography color='primary'>Tax Trend</Typography>
        </Grid>
        <Grid item xs={2}>
          <IconButton
            aria-label='show tax amount'
            onClick={handleToggleShowTaxAmountClick}
            size='small'
            style={{ padding: 0 }}
          >
            {toggleShowTaxAmount && <ToggleOffIcon />}
            {!toggleShowTaxAmount && <ToggleOnIcon />}
          </IconButton>
        </Grid>
      </Grid>

      <Grid container item xs={6} lg={12}>
        {Object.keys(calculations).map((key) => (
          <YearTaxPair
            key={key}
            year={calculations[key].taxYear}
            tax={calculations[key].totalTax}
            classes={classes}
            effectiveTaxRate={calculations[key].effectiveTaxRate}
            showTax={toggleShowTaxAmount}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default TaxTrend;

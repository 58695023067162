import React from 'react';
import { Grid, Typography, makeStyles, useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Droppable } from 'react-beautiful-dnd';

import TaxTile from './TaxTile';

const useStyles = makeStyles((theme) => ({
  groupContainer: {
    minHeight: theme.spacing(6.5),
    overflow: 'hidden', // this is hidden due to DnD not being able to handle nested scroll
    paddingBottom: 5, // this is space reserved for scrollbar gutter
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
    '&:hover': {
      overflowX: 'scroll',
      paddingBottom: 0, // this is space reserved removed for scrollbar gutter
    },
  },
  text: {
    color: theme.taxCast.primaryTextColor,
  },
}));

const TaxGroup = ({ type, label, year }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const transactions = useSelector(({ taxCast }) => taxCast.forecast.years[year][type]);

  if (!transactions) return null;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography className={classes.text} variant='caption'>
          {label} {`(${transactions.length})`}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Droppable
          droppableId={`${year}-${type}`}
          type={type}
          direction={isMobile ? 'vertical' : 'horizontal'}
        >
          {(provided) => (
            <Grid
              ref={provided.innerRef}
              container
              className={classes.groupContainer}
              wrap='nowrap'
            >
              {transactions.map((transaction, index) => (
                <TaxTile transaction={transaction} type={type} key={transaction.id} index={index} />
              ))}
              {provided.placeholder}
            </Grid>
          )}
        </Droppable>
      </Grid>
    </Grid>
  );
};

TaxGroup.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
};

export default TaxGroup;

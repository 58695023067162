/* eslint-disable import/prefer-default-export */
import { categoryTypes } from './categories';

export const toolBoxData = {
  ordinaryIncome: [
    {
      id: 'toolbox-farmIncome',
      type: categoryTypes.ordinaryIncome,
      name: 'Farm Income',
    },
    {
      id: 'toolbox-interest',
      type: categoryTypes.ordinaryIncome,
      name: 'Interest',
    },
    {
      // id: 'toolbox-iraDistributionRetirementPlanPensions',
      id: 'toolbox-iraDistribution',
      type: categoryTypes.ordinaryIncome,
      name: 'IRA Distribution / Retirement Plan, Pensions',
    },
    {
      id: 'toolbox-nonQualifiedDividends',
      type: categoryTypes.ordinaryIncome,
      name: 'Non-Qualified Dividends',
    },
    {
      id: 'toolbox-sCorpDistributions',
      type: categoryTypes.ordinaryIncome,
      name: 'S Corp Distributions',
    },
    {
      id: 'toolbox-shortTermCapitalGains',
      type: categoryTypes.ordinaryIncome,
      name: 'Short Term Capital Gains',
    },
    {
      id: 'toolbox-soleProprietorshipIncome',
      type: categoryTypes.ordinaryIncome,
      name: 'Sole Proprietorship Income',
    },
    {
      id: 'toolbox-taxableSocialSecurity',
      type: categoryTypes.ordinaryIncome,
      name: 'Social Security',
    },
    {
      id: 'toolbox-wages',
      type: categoryTypes.ordinaryIncome,
      name: 'Wages',
    },
  ],
  capitalGains: [
    {
      id: 'toolbox-longTermCapitalGains',
      type: categoryTypes.capitalGains,
      name: 'Long-Term Capital Gains',
    },
    {
      id: 'toolbox-qualifiedDividends',
      type: categoryTypes.capitalGains,
      name: 'Qualified Dividends',
    },
  ],
  deductions: [
    {
      id: 'toolbox-charitableContribution',
      type: categoryTypes.deductions,
      name: 'Charitable Contribution',
    },
    {
      id: 'toolbox-healthSavingsAccountContribution',
      type: categoryTypes.deductions,
      name: 'Health Savings Account Contribution',
    },
    {
      id: 'toolbox-medicalExpense',
      type: categoryTypes.deductions,
      name: 'Medical Expense',
    },
    {
      id: 'toolbox-mortgageInterest',
      type: categoryTypes.deductions,
      name: 'Mortgage Interest',
    },
    {
      id: 'toolbox-personalPropertyTaxes',
      type: categoryTypes.deductions,
      name: 'Personal Property Taxes',
    },
    {
      id: 'toolbox-realEstateTaxes',
      type: categoryTypes.deductions,
      name: 'Real Estate Taxes',
    },
    {
      id: 'toolbox-retirementPlanContribution',
      type: categoryTypes.deductions,
      name: 'Retirement Plan Contribution',
    },
    {
      id: 'toolbox-stateIncomeTaxes',
      type: categoryTypes.deductions,
      name: 'State Income Taxes',
    },
    {
      id: 'toolbox-studentLoanInterest',
      type: categoryTypes.deductions,
      name: 'Student Loan Interest',
    },
    {
      id: 'toolbox-alimony',
      type: categoryTypes.deductions,
      name: 'Alimony',
    },
    {
      id: 'toolbox-otherDeductionAboveTheLine',
      type: categoryTypes.deductions,
      name: 'Other',
    },
    {
      id: 'toolbox-otherDeductionBelowTheLine',
      type: categoryTypes.deductions,
      name: 'Other',
    },
  ],
};

import React from 'react';
import { Grid, Paper, Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '95vh',
    maxWidth: 1000,
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(1, 'auto'),
    },
    [theme.breakpoints.down('lg')]: {
      margin: theme.spacing(1, 3),
    },
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(1, 'auto'),
    },
  },
  title: {
    fontWeight: 'bold',
    textAlign: 'center',
    padding: theme.spacing(3),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    padding: theme.spacing(2),
  },
  field: {
    [theme.breakpoints.up('sm')]: {
      minHeight: theme.spacing(6),
    },
  },
}));

const ContactUsAboutStatus = ({ status, reason }) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      <Paper spacing={2} elevation={3}>
        <Grid container justify='center' className={classes.form}>
          <Grid container item xs={12} spacing={2} padding={2}>
            <Grid item xs={12}>
              <Typography
                variant='h2'
                component='h1'
                color='primary'
                className={classes.title}
              >
                Account Locked
              </Typography>
              <Typography variant='h4' component='h1' color='primary'>
                Your account was found in the system but is locked. The reason
                is listed below. We apologize for any inconveniences. If this is
                in error, please contact us to resolve the situation at your
                earliest convenience. Alternately, send an email to us with
                suggested dates and times we can contact you and we will give
                you a call. Thank you for using TaxCast.
              </Typography>
            </Grid>
          </Grid>
          <Grid container justify='center' className={classes.form}>
            <Grid container item xs={12} spacing={2} padding={2}>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  &nbsp;
                </Grid>
                <Grid item xs={12} className={classes.field}>
                  <Typography component='h4' variant='h4'>
                    888.482.9227 (888-4-TAXCAST)
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  &nbsp;
                </Grid>
                <Grid item xs={12} className={classes.field}>
                  <Typography component='h4' variant='h4'>
                    <a href='mailto: support@taxcastplan.com'>
                      support@taxcastplan.com
                    </a>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  &nbsp;
                </Grid>
                {status !== 'unspecified' && (
                  <Grid item xs={12} className={classes.field}>
                    <Typography component='h5' variant='h5'>
                      Status: {status}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12}>
                  &nbsp;
                </Grid>
                {reason !== 'none provided' && (
                  <Grid item xs={12} className={classes.field}>
                    <Typography component='h5' variant='h5'>
                      Reason: {reason}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

ContactUsAboutStatus.propTypes = {
  status: PropTypes.string,
  reason: PropTypes.string,
};

ContactUsAboutStatus.defaultProps = {
  status: 'unspecified',
  reason: 'none provided',
};

export default ContactUsAboutStatus;

import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import InactiveTaxTile from './InactiveTaxTile';

const useStyles = makeStyles((theme) => ({
  groupContainer: {
    minHeight: theme.spacing(6.5),
    overflow: 'hidden', // this is hidden due to DnD not being able to handle nested scroll
    paddingBottom: 5, // this is space reserved for scrollbar gutter
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
    '&:hover': {
      overflowX: 'scroll',
      paddingBottom: 0, // this is space reserved removed for scrollbar gutter
    },
  },
  text: {
    color: theme.taxCast.primaryTextColor,
  },
}));

const InactiveTaxGroup = ({ type, label, year }) => {
  const classes = useStyles();
  const transactions = useSelector(
    ({ taxCast }) => taxCast.forecast.years[year][type]
  );

  if (!transactions) return null;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography className={classes.text} variant='caption'>
          {label} {`(${transactions.length})`}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Grid container className={classes.groupContainer} wrap='nowrap'>
          {transactions.map((transaction) => (
            <InactiveTaxTile
              transaction={transaction}
              type={type}
              key={transaction.id}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

InactiveTaxGroup.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
};

export default InactiveTaxGroup;

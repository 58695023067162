/* eslint max-lines: [2, {"max": 5000, "skipComments": true, "skipBlankLines": true}] */
import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography, makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { EventType } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from 'lib/utils/authConfig';
import axios from 'axios';

import { baseUrl } from 'constants/api';

import YouTubeEmbed from 'lib/components/YouTubeEmbed';

import * as authActions from 'store/asyncActions/auth';

import TaxCastLogo from 'assets/img/Logo512x512.png';
import PreviewGif from 'assets/img/VSL_TaxCast_Screen_Images_20220328_3mb_pancrop.gif';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '100%',
  },
  rootOg: {
    maxWidth: theme.maxWidth,
    [theme.breakpoints.down('lg')]: {
      margin: theme.spacing(1, 'auto'),
    },
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(1, 'auto'),
    },
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(1, 3),
    },
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(1, 1),
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing('auto', 'auto'),
    },
  },
  paper: {
    minWidth: '95%',
  },
  headerPanel: {
    borderBottom: `5px solid ${theme.palette.primary.main}`,
  },
  header: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(1),
  },
  divider: {
    borderRight: `1px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(1),
  },
  rightCell: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
  },
  leftCell: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
    // borderRight: `1px solid ${theme.palette.primary.main}`,
  },
  leftPanel: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
  },
  logo: {
    height: '25%',
    width: '25%',
  },
  field: {
    [theme.breakpoints.up('sm')]: {
      minHeight: theme.spacing(1),
    },
  },
  dialog: {
    minHeight: '60vh',
  },
}));

const Campaign = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { instance } = useMsal();
  const [bookingsUrl, setBookingsUrl] = useState('');

  useEffect(() => {
    document.title = 'TaxCast - SignUp';
    axios
      .get(
        `${baseUrl}/api/settings/TaxCastSetting/setting/TaxCastDemoBookingsLink`
      )
      .then((response) => {
        setBookingsUrl(response.data);
      })
      .catch(() => {
        setBookingsUrl(
          'https://outlook.office365.com/owa/calendar/TaxCast@taxcastplan.com/bookings/s/MM7SEJpRQEu2P_9Wagi_SA2'
        );
      });
  }, [bookingsUrl, setBookingsUrl]);

  useEffect(() => {
    const callbackId = instance.addEventCallback((event) => {
      // switch (event.eventType) {
      //   case EventType.LOGIN_SUCCESS:
      //     console.log("login-success", event);
      //     break;
      //   case EventType.LOGIN_FAILURE:
      //     console.log("login-failure", event);
      //     break;
      //   case EventType.LOGOUT_SUCCESS:
      //     console.log("logout-success", event);
      //     break;
      //   case EventType.LOGOUT_FAILURE:
      //     console.log("logout-failure", event);
      //     break;
      //   case EventType.HANDLE_REDIRECT_START:
      //     console.log("redirect-start", event);
      //     break;
      //   case EventType.HANDLE_REDIRECT_END:
      //     console.log("redirect-end", event);
      //     break;
      //   default:
      //     break;
      // }
      if (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
        window.sessionStorage.setItem(
          'taxcast.auth.accessToken',
          event.payload.accessToken
        );
        dispatch(authActions.AADB2C_LOGIN_SUCCESS(event.payload.idTokenClaims));
        history.push('/auth');
      }

      if (event.eventType === EventType.LOGIN_SUCCESS) {
        instance.setActiveAccount(event.payload.account);
        instance
          .acquireTokenSilent({ ...loginRequest })
          .then((response) => {
            window.sessionStorage.setItem(
              'taxcast.auth.accessToken',
              response.accessToken
            );
            dispatch(
              authActions.AADB2C_LOGIN_SUCCESS(event.payload.idTokenClaims)
            );
            history.push('/auth');
          })
          .catch(() => {
            // acquireTokenSilent can fail for a number of reasons, fallback to interaction
            instance.acquireTokenPopup({ ...loginRequest }).then((response) => {
              window.sessionStorage.setItem(
                'taxcast.auth.accessToken',
                response.accessToken
              );
              dispatch(
                authActions.AADB2C_LOGIN_SUCCESS(event.payload.idTokenClaims)
              );
              history.push('/auth');
            });
          });
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [dispatch, history, instance]);

  const handleRequestDemoClick = () => {
    window.location.href = bookingsUrl;
  };

  const handleLoginClick = () => {
    instance.loginPopup(loginRequest);
  };

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          className={classes.root}
          style={{ backgroundColor: '#FEFFEA' }}
        >
          <Grid item xs={12}>
            <br />
          </Grid>
          <Grid container item xs={12} justify='center'>
            <Typography component='h3' variant='h3' color='primary'>
              The world&apos;s easiest visual tax-planning software
            </Typography>
          </Grid>
          <Grid container item xs={12} justify='center'>
            <Grid item xs={7}>
              <YouTubeEmbed embedId='Rud2W8T6JDk' />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          xs={12}
          className={classes.root}
          style={{ backgroundColor: '#FEFFEA' }}
        >
          <Grid item xs={12}>
            &nbsp;
          </Grid>
          <Grid item xs={12}>
            &nbsp;
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.root}
          style={{ backgroundColor: '#9ccc65' }}
        >
          <Grid item xs={12}>
            <br />
          </Grid>
          <Grid container item xs={12} justify='center'>
            <Grid item xs={12} sm={3}>
              &nbsp;
            </Grid>
            <Grid item xs={12} sm={3}>
              <Grid item xs={12}>
                <img
                  src={TaxCastLogo}
                  className={classes.logo}
                  alt='TaxCast Logo'
                />
              </Grid>
              <Grid item xs={12}>
                <br />
              </Grid>
              <Grid item xs={12}>
                <Typography component='h4' variant='h4' color='primary'>
                  14 Day Free Trial
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <br />
              </Grid>
              <Grid item xs={12}>
                <Typography component='h5' variant='h5' color='primary'>
                  No credit card needed
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <br />
              </Grid>
              <Grid item xs={12} padding={3}>
                <Button
                  onClick={handleLoginClick}
                  variant='contained'
                  color='primary'
                  size='large'
                >
                  Sign-up Today!
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3}>
              <img src={PreviewGif} alt='TaxCast Demo' />
            </Grid>
            <Grid item xs={12} sm={3}>
              &nbsp;
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <br />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.root}
          style={{ backgroundColor: '#004D40' }}
        >
          <Grid item xs={12}>
            <br />
          </Grid>
          <Grid container item xs={12} justify='center'>
            <Grid item xs={12} sm={3}>
              &nbsp;
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid item xs={12}>
                <Typography
                  component='h4'
                  variant='h4'
                  color='primary'
                  style={{ color: '#dcedc8' }}
                >
                  Tax Planning Made Simple&trade;
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <br />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  component='p'
                  variant='body1'
                  color='primary'
                  style={{ color: '#dcedc8' }}
                >
                  TaxCast&trade; is the world&apos;s easiest visual tax-planning
                  software. Our process allows every advisor to construct
                  multi-year visual tax plans with no advanced tax expertise.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <br />
              </Grid>
              <Grid item xs={12}>
                <Button
                  onClick={handleLoginClick}
                  variant='outlined'
                  color='secondary'
                  size='large'
                >
                  Get your 14 day free trial
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3}>
              &nbsp;
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <br />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.root}
          style={{ backgroundColor: '#FEFFEA' }}
        >
          <Grid item xs={12}>
            <br />
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={3}>
              &nbsp;
            </Grid>
            <Grid item xs={9}>
              <Typography component='h4' variant='h4' color='primary'>
                Why TaxCast&trade;
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={3}>
              &nbsp;
            </Grid>
            <Grid item xs={7}>
              <Grid container item xs={12}>
                <Grid
                  item
                  xs={5}
                  style={{ backgroundColor: '#F5F5F5' }}
                  className={classes.leftCell}
                >
                  <Typography component='h5' variant='h5' color='primary'>
                    Easy Entry &amp; Editing
                  </Typography>
                  <br />
                  <Typography component='p' variant='body1' color='primary'>
                    With our <b>DragDropDeduct&trade;</b> user interface,
                    transaction &quot;tiles&quot; are moved from the toolbar
                    onto the work surface or &quot;canvas&quot; where they can
                    be easily modified, duplicated, or moved between years.
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={5}
                  style={{ backgroundColor: '#F5F5F5' }}
                  className={classes.rightCell}
                >
                  <Typography component='h5' variant='h5' color='primary'>
                    Multi-Year
                  </Typography>
                  <br />
                  <Typography component='p' variant='body1' color='primary'>
                    Since a goal of tax planning should be to minimize the
                    client&apos;s tax burdens across multiple years,
                    TaxCast&trade; displays and calculates taxable transactions
                    across a 10-year window.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              &nbsp;
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={3}>
              &nbsp;
            </Grid>
            <Grid item xs={7}>
              <Grid container item xs={12}>
                <Grid
                  item
                  xs={5}
                  style={{ backgroundColor: '#F5F5F5' }}
                  className={classes.leftCell}
                >
                  <Typography component='h5' variant='h5' color='primary'>
                    Client-Facing &amp; Interactive
                  </Typography>
                  <br />
                  <Typography component='p' variant='body1' color='primary'>
                    TaxCast&trade; provides a graphic, easy-to-understand
                    interface that allows tax plans to be created during
                    face-to-face discussions with clients.
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={5}
                  style={{ backgroundColor: '#F5F5F5' }}
                  className={classes.rightCell}
                >
                  <Typography component='h5' variant='h5' color='primary'>
                    More Control
                  </Typography>
                  <br />
                  <Typography component='p' variant='body1' color='primary'>
                    TaxCast&trade;&apos;s robust calculation engine performs
                    validation checks to prevent clients from planning
                    disallowed transactions.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <br />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          className={classes.root}
          style={{ backgroundColor: '#FEFFEA' }}
        >
          <Grid item xs={12}>
            <br />
          </Grid>
          <Grid container item xs={12} justify='center'>
            <Grid item xs={4}>
              &nbsp;
            </Grid>
            <Grid
              container
              item
              xs={4}
              justify='center'
              className={classes.root}
            >
              <hr
                style={{
                  height: '10px',
                  width: '33%',
                  backgroundColor: '#004D40',
                }}
              />
            </Grid>
            <Grid item xs={4}>
              &nbsp;
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <br />
            </Grid>
            <Grid container item xs={12} justify='center'>
              <Typography component='h4' variant='h4' color='primary'>
                Want to learn more?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <br />
            </Grid>
            <Grid container item xs={12} justify='center'>
              <Button
                onClick={handleRequestDemoClick}
                variant='contained'
                color='primary'
                size='large'
              >
                Schedule your free demo
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <br />
          </Grid>
        </Grid>
        <Grid container item xs={12} className={classes.root}>
          <Grid item xs={12}>
            <br />
          </Grid>
          <Grid item xs={3}>
            &nbsp;
          </Grid>
          <Grid item xs={9}>
            &copy; 2022 Canvilytics, LLC
          </Grid>
          <Grid item xs={12}>
            <br />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Campaign;

/* eslint-disable import/prefer-default-export */
import trim from 'lodash/trim';
import { currencyRegex, phoneNumberRegex } from 'constants/regex';

export const isNullEmptyOrBlank = (value) => !trim(value);

export const isValidAmount = (value) => {
  return !value || currencyRegex.test(value);
};

export const isValidPhoneNumberUnadorned = (value) => {
  return !value || phoneNumberRegex.test(value);
};

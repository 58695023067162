import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  field: {
    minHeight: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const AlimonyStatement = () => {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.field}>
      <Typography>
        Alimony can only be deducted on divorces before 2019.
      </Typography>
    </Grid>
  );
};

export default AlimonyStatement;

/* eslint max-lines: [2, {"max": 350, "skipComments": true, "skipBlankLines": true}] */
import React, { useEffect, useState } from 'react';
import {
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { ExpandMore as ExpandIcon } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import map from 'lodash/map';
import loading from 'assets/img/BouncingBarsBlack.png';
import {
  COMPLETE_PREFLIGHT,
  RUN_CALCULATIONS,
} from 'store/asyncActions/taxCast';

import TaxYear from './TaxYear';
import InactiveTaxYear from './InactiveTaxYear';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 1),
    maxHeight: '90vh',
    overflowX: 'auto',
    minHeight: '375px',
    [theme.breakpoints.down('md')]: {
      maxHeight: '65vh',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#bebebe',
  },
  expandoPanel: {
    width: '100%',
  },
  panelDetails: {
    padding: 0,
    overflowY: 'auto',
  },
  expando: {
    backgroundColor: theme.taxCast.canvasExpando,
  },
  expandoText: {
    color: theme.palette.secondary.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9em',
    },
  },
  expandoGrid: {
    maxHeight: '65vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    [theme.breakpoints.down('lg')]: {
      maxHeight: '52vh',
    },
  },
}));

const TaxCastCanvas = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const forecast = useSelector(({ taxCast }) => taxCast.forecast);
  const isBusy = useSelector(({ taxCast }) => taxCast.isLoadingCanvas);
  const clientProfileId = useSelector(({ taxCast }) => taxCast.client.id);
  const forecastId = useSelector(({ taxCast }) => taxCast.forecast.id);
  const advisorId = useSelector(({ auth }) => auth.account.id);
  const isBusyCalculating = useSelector(
    ({ taxCast }) => taxCast.isRunningCalculations
  );
  const isPreFlightComplete = useSelector(
    ({ taxCast }) => taxCast.isPreFlightComplete
  );

  // Check for catch-up.
  const needsToCatchUp = useSelector(({ taxCast }) => taxCast.needsToCatchUp);
  const [didPromptToCatchUp, setDidPromptToCatchUp] = useState(false);
  const [showPromptToCatchUp, setShowPromptToCatchUp] = useState(false);
  const [wantsToCatchUp, setWantsToCatchUp] = useState(false);

  // Check for rollover.
  const canRollover = useSelector(({ taxCast }) => taxCast.canRollover);
  const [didPromptToRollover, setDidPromptToRollover] = useState(false);
  const [showPromptToRollover, setShowPromptToRollover] = useState(false);
  const [wantsToRollover, setWantsToRollover] = useState(false);

  // Expandos for previous/inactive tax years.
  const [expandedYears, setExpandedYears] = useState({});

  const handleChange = (year) => {
    const currentValue = expandedYears[year];
    setExpandedYears({ ...expandedYears, [year]: !currentValue });
  };

  const getFilingStatusForTaxYear = (taxYear) => {
    const taxYearIndex = forecast.yearList.indexOf(taxYear);
    const filingStatus = forecast.filingStatusByYearList[taxYearIndex];
    return filingStatus;
  };

  useEffect(() => {
    // Determine if the forecast needs to catch-up on years.
    if (!didPromptToCatchUp && needsToCatchUp) {
      setShowPromptToCatchUp(true);
    }
    const canProcessRolloverLogic = !needsToCatchUp || !wantsToCatchUp;

    // Determine if the forecast can rollover to new years.
    // Only prompt to rollover if the forecast is caught up.
    if (!didPromptToRollover && canProcessRolloverLogic && canRollover) {
      setShowPromptToRollover(true);
    }

    // RUN CALCS IF:
    // !(needsToCatchup && wantsToCatchup)
    // AND
    // !(canRollover && wantsToRollover)
    const catchUpPassed =
      !needsToCatchUp ||
      (needsToCatchUp && didPromptToCatchUp && !wantsToCatchUp);
    const rolloverPassed =
      !canRollover || (canRollover && didPromptToRollover && !wantsToRollover);
    if (catchUpPassed && rolloverPassed) {
      dispatch(COMPLETE_PREFLIGHT());
    }
  }, [
    advisorId,
    clientProfileId,
    forecastId,
    dispatch,
    didPromptToCatchUp,
    didPromptToRollover,
    showPromptToCatchUp,
    showPromptToRollover,
    needsToCatchUp,
    canRollover,
    wantsToCatchUp,
    wantsToRollover,
  ]);

  useEffect(() => {
    if (forecast && forecast.yearList) {
      const currentYear = new Date().getFullYear();
      const matchingYears = forecast.yearList.filter((y) => +y < currentYear);
      const expandedYearsObject = {};
      matchingYears.forEach((item) => {
        expandedYearsObject[item] = false;
      });
      setExpandedYears(expandedYearsObject);
    }

    if (forecastId && isPreFlightComplete) {
      dispatch(RUN_CALCULATIONS({ clientProfileId, forecastId, advisorId }));
    }
  }, [
    advisorId,
    clientProfileId,
    forecastId,
    dispatch,
    isPreFlightComplete,
    forecast,
  ]);

  const handleCatchUpDialogActionClick = (executeCatchUpSelection) => {
    setDidPromptToCatchUp(true);
    setShowPromptToCatchUp(false);
    setWantsToCatchUp(executeCatchUpSelection);
    if (executeCatchUpSelection) {
      history.push(
        `/clients/${clientProfileId}/extend-forecast?option=catchup`
      );
    }
  };

  const handleRolloverDialogActionClick = (executeRolloverSelection) => {
    setDidPromptToRollover(true);
    setShowPromptToRollover(false);
    setWantsToRollover(executeRolloverSelection);
    if (executeRolloverSelection) {
      history.push(
        `/clients/${clientProfileId}/extend-forecast?option=rollover`
      );
      // clients/:id/extend-forecast?option=rollover
    }
  };

  return (
    <Paper className={classes.root}>
      <Backdrop className={classes.backdrop} open={isBusy || isBusyCalculating}>
        <img
          src={loading}
          alt='Loading...'
          className='loading-img'
          height='256px'
          width='256px'
        />
      </Backdrop>
      <Grid container>
        {forecast &&
          forecast.yearList &&
          map(
            forecast.yearList,
            (year) =>
              year < new Date().getFullYear() && (
                <ExpansionPanel
                  key={year}
                  expanded={expandedYears[year] || false}
                  onChange={() => handleChange(year)}
                  className={classes.expandoPanel}
                >
                  <ExpansionPanelSummary
                    expandIcon={<ExpandIcon color='secondary' />}
                    className={classes.expando}
                  >
                    <Typography className={classes.expandoText}>
                      {year}
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails className={classes.panelDetails}>
                    <Grid container item xs={12} key={year}>
                      <InactiveTaxYear
                        key={`inactive-${year}`}
                        year={`${year}`}
                        filingStatus={getFilingStatusForTaxYear(year)}
                      />
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              )
          )}
        {forecast &&
          forecast.yearList &&
          map(
            forecast.yearList,
            (activeYear) =>
              activeYear >= new Date().getFullYear() && (
                <Grid
                  item
                  xs={12}
                  key={activeYear}
                  className={classes.yearContainer}
                >
                  <TaxYear
                    year={activeYear}
                    filingStatus={getFilingStatusForTaxYear(activeYear)}
                  />
                </Grid>
              )
          )}
        {needsToCatchUp && didPromptToCatchUp && !wantsToCatchUp && (
          <Grid container item xs={12} justify='flex-end'>
            <Button
              onClick={() => handleCatchUpDialogActionClick(true)}
              color='primary'
              value='ok'
              variant='contained'
            >
              Catch-Up
            </Button>
          </Grid>
        )}
        {(!needsToCatchUp || !wantsToCatchUp) &&
          canRollover &&
          didPromptToRollover &&
          !wantsToRollover && (
            <Grid container item xs={12} justify='flex-end'>
              <Button
                onClick={() => handleRolloverDialogActionClick(true)}
                color='primary'
                value='ok'
                variant='contained'
              >
                Rollover
              </Button>
            </Grid>
          )}

        <Dialog
          open={showPromptToCatchUp}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>Canvas Catch-Up</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              This canvas is eligible for more tax years. Extend it now? (You
              can always do it later.)
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => handleCatchUpDialogActionClick(false)}
              color='primary'
              value='cancel'
              variant='outlined'
            >
              Leave as is
            </Button>
            <Button
              onClick={() => handleCatchUpDialogActionClick(true)}
              color='primary'
              value='ok'
              variant='contained'
            >
              Catch-Up
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={showPromptToRollover}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>Canvas Rollover</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              New tax table values are available and this canvas is eligible for
              more tax years. Extend it now? (You can always do it later.)
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => handleRolloverDialogActionClick(false)}
              color='primary'
              value='cancel'
              variant='outlined'
            >
              Leave as is
            </Button>
            <Button
              onClick={() => handleRolloverDialogActionClick(true)}
              color='primary'
              value='ok'
              variant='contained'
            >
              Rollover
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Paper>
  );
};

export default TaxCastCanvas;

import React from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';

const ItsDeadJim = () => {
  return (
    <Grid container item xs={12} spacing={2}>
      <br />
      <Paper spacing={2} elevation={3}>
        <Grid container padding={3}>
          <Grid container item xs={12}>
            <Grid item xs={12} sm={3}>
              &nbsp;
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid item xs={12}>
                <Typography component='h4' variant='h4' color='primary'>
                  It&apos;s dead, Jim.
                </Typography>
                <br />
                <Typography component='h5' variant='h5' color='primary'>
                  TaxCast ran into a major problem. This is likely due to an
                  issue with the network connection keeping TaxCast from
                  reaching the server. We would appreciate it if you would
                  please use one of the options below to contact customer
                  support and let us know.
                </Typography>
                <br />
                <Typography component='h4' variant='h4'>
                  888.482.9227 (888-4-TAXCAST)
                </Typography>
                <br />
                <Typography component='h4' variant='h4'>
                  <a href='mailto: support@taxcastplan.com'>
                    support@taxcastplan.com
                  </a>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default ItsDeadJim;

/* eslint semi: ["error", "always", { "omitLastInOneLineBlock": true}] */
/* eslint max-lines: [2, {"max": 400, "skipComments": true, "skipBlankLines": true}] */
import React, { useEffect, useState } from 'react';
import {
  Backdrop,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Redirect } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FSTabPanel } from 'lib/components/Tabs';
import { filter } from 'lodash';

import * as advisorApi from 'api/advisor';
import { shapeErrorsForToast } from 'lib/utils/errorUtil';
import { ErrorToast } from 'lib/components/ErrorToast';
import loading from 'assets/img/BouncingBarsBlack.png';
import { accountStatusIsActiveOrUnexpiredTrial } from 'lib/utils/account';

import Summary from './Summary';
// import auth from 'store/slices/auth';
import Disclosures from './Disclosures';
import Designations from './Designations';
import Account from './Account';
import Address from './Address';
import EmailAddresses from './EmailAddresses';
import PhoneNumber from './PhoneNumber';
import ChangeRequests from './ChangeRequests';
// import Billing from './ClientSpouse';
// import Security from './Security';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.maxWidth,
    padding: theme.spacing(1, 3),
  },
  paper: {
    marginTop: theme.spacing(1),
  },
  header: {
    fontWeight: 'bold',
    padding: theme.spacing(3),
  },
  tab: {
    padding: theme.spacing(3, 1), // Shift the tabs to align vertically with the content
  },
  wrapper: {
    alignItems: 'flex-end',
    textAlign: 'right',
    display: 'inline-flex',
    justifyContent: 'right',
    margin: 0,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const Advisor = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const affiliateId = useSelector(({ auth }) => auth.account.affiliateId);
  const advisorId = useSelector(({ auth }) => auth.account.id);
  const [advisor, setAdvisor] = useState();
  const [currentTab, setCurrentTab] = useState(0);
  const [changeRequests, setChangeRequests] = useState([]);
  const [currentTabTitle, setCurrentTabTitle] = useState('Summary');
  const account = useSelector(({ auth }) => auth.account);

  useEffect(() => {
    document.title = 'TaxCast - Account';
  }, []);

  const handleChangeTab = (_, newTab) => {
    setCurrentTab(newTab);
    switch (newTab) {
      case 0:
        setCurrentTabTitle('Summary');
        break;
      case 1:
        setCurrentTabTitle('Disclosures');
        break;
      case 2:
        setCurrentTabTitle('Designations');
        break;
      case 3:
        setCurrentTabTitle('Account');
        break;
      case 4:
        setCurrentTabTitle('Address');
        break;
      case 5:
        setCurrentTabTitle('Email');
        break;
      case 6:
        setCurrentTabTitle('Phone');
        break;
      case 7:
        setCurrentTabTitle('Change Requests');
        break;
      case 8:
        setCurrentTabTitle('Billing');
        break;
      case 9:
        setCurrentTabTitle('Security');
        break;
      default:
        setCurrentTabTitle('');
        break;
    }
  };

  useEffect(() => {
    setIsLoading(true);
    if (
      affiliateId &&
      advisorId &&
      account &&
      account.accountStatus === 'active'
    ) {
      advisorApi
        .getAdvisorAccount(affiliateId, advisorId)
        .then((response) => {
          setAdvisor(response.data.advisor);
          setChangeRequests(response.data.changeRequests ?? []);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(
            <ErrorToast errorMessages={shapeErrorsForToast(error)} />,
            {
              autoClose: false,
            }
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [affiliateId, advisorId, account]);

  const onSaveDisclosures = (updatedDisclosures) => {
    setAdvisor({ ...advisor, ...updatedDisclosures });
  };

  const onSaveDesignations = (updatedDesignationsList) => {
    setAdvisor({
      ...advisor,
      designationsList: updatedDesignationsList.join(),
    });
  };

  const onCreateChangeRequest = (changeRequest) => {
    const updatedChangeRequests = [...changeRequests];
    updatedChangeRequests.push(changeRequest);
    setChangeRequests(updatedChangeRequests);
  };

  if (!accountStatusIsActiveOrUnexpiredTrial(account)) {
    return <Redirect to='/auth' />;
  }

  if (!advisor) {
    return null;
  }

  return (
    <>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <img
          src={loading}
          alt='Loading...'
          className='loading-img'
          height='256px'
          width='256px'
        />
      </Backdrop>
      <Paper elevation={3} spacing={2} className={classes.paper}>
        <Grid container className={classes.root}>
          <Grid item xs={12}>
            <Typography
              variant='h4'
              component='h1'
              color='primary'
              className={classes.header}
            >
              Account Maintenance - {currentTabTitle}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={2} className={classes.tab}>
            <Tabs
              orientation='vertical'
              value={currentTab}
              onChange={handleChangeTab}
            >
              <Tab
                label='Summary'
                id='advisor-tab-0'
                aria-controls='advisor-tabpanel-0'
                classes={{ wrapper: classes.wrapper }}
              />
              <Tab
                label='Disclosures'
                id='advisor-tab-1'
                aria-controls='advisor-tabpanel-1'
                classes={{ wrapper: classes.wrapper }}
              />
              <Tab
                label='Designations'
                id='advisor-tab-2'
                aria-controls='advisor-tabpanel-2'
                classes={{ wrapper: classes.wrapper }}
              />
              <Tab
                label='Account'
                id='advisor-tab-3'
                aria-controls='advisor-tabpanel-3'
                classes={{ wrapper: classes.wrapper }}
              />
              <Tab
                label='Address'
                id='advisor-tab-4'
                aria-controls='advisor-tabpanel-4'
                classes={{ wrapper: classes.wrapper }}
              />
              <Tab
                label='Email'
                id='advisor-tab-5'
                aria-controls='advisor-tabpanel-5'
                classes={{ wrapper: classes.wrapper }}
              />
              <Tab
                label='Phone'
                id='advisor-tab-6'
                aria-controls='advisor-tabpanel-6'
                classes={{ wrapper: classes.wrapper }}
              />
              <Tab
                label='Change Requests'
                id='advisor-tab-7'
                aria-controls='advisor-tabpanel-7'
                classes={{ wrapper: classes.wrapper }}
              />
              {/* <Tab
              label='Security'
              id='advisor-tab-8'
              aria-controls='advisor-tabpanel-8'
              classes={{ wrapper: classes.wrapper }}
            /> */}
              {/* <Tab
              label='Billing'
              id='advisor-tab-9'
              aria-controls='advisor-tabpanel-9'
              classes={{ wrapper: classes.wrapper }}
            /> */}
            </Tabs>
          </Grid>

          <Grid item xs={12} sm={10}>
            <FSTabPanel
              value={currentTab}
              index={0}
              id='advisor-tabpanel-0'
              labelBy='advisor-tab-0'
            >
              <Summary advisor={advisor} />
            </FSTabPanel>

            <FSTabPanel
              value={currentTab}
              index={1}
              id='advisor-tabpanel-1'
              labelBy='advisor-tab-1'
            >
              <Disclosures
                affiliateId={advisor.affiliateId}
                advisorId={advisor.id}
                advisorDisclosures={{
                  disclosureA: advisor.disclosureA ?? '',
                  disclosureB: advisor.disclosureB ?? '',
                  disclosureC: advisor.disclosureC ?? '',
                }}
                onSave={onSaveDisclosures}
              />
            </FSTabPanel>

            <FSTabPanel
              value={currentTab}
              index={2}
              id='advisor-tabpanel-2'
              labelBy='advisor-tab-2'
            >
              <Designations
                affiliateId={advisor.affiliateId}
                advisorId={advisor.id}
                advisorDesignationsList={
                  // This should never be needed but surfaced as designations were added ex post facto.
                  advisor.designationsList
                    ? advisor.designationsList.split(',')
                    : []
                }
                onSave={onSaveDesignations}
              />
            </FSTabPanel>

            <FSTabPanel
              value={currentTab}
              index={3}
              id='advisor-tabpanel-3'
              labelBy='advisor-tab-3'
            >
              <Account
                advisorId={advisor.id}
                givenName={advisor.givenName}
                middleName={advisor.middleName ?? ''}
                surname={advisor.surname}
                changeRequests={filter(changeRequests, (o) => {
                  return o.propertyGroup === 'account';
                })}
                onCreateChangeRequest={onCreateChangeRequest}
              />
            </FSTabPanel>

            <FSTabPanel
              value={currentTab}
              index={4}
              id='advisor-tabpanel-4'
              labelBy='advisor-tab-4'
            >
              <Address
                advisorId={advisor.id}
                addressLineOne={advisor.preferredAddressLineOne}
                addressLineTwo={advisor.preferredAddressLineTwo}
                addressCity={advisor.preferredAddressCity}
                addressStateOrProvince={advisor.preferredAddressStateOrProvince}
                addressPostalCode={advisor.preferredAddressPostalCode}
                changeRequests={filter(changeRequests, (o) => {
                  return o.propertyGroup === 'address';
                })}
                onCreateChangeRequest={onCreateChangeRequest}
              />
            </FSTabPanel>

            <FSTabPanel
              value={currentTab}
              index={5}
              id='advisor-tabpanel-5'
              labelBy='advisor-tab-5'
            >
              <EmailAddresses
                advisorId={advisor.id}
                emailAddress={advisor.preferredEmailAddress}
                changeRequests={filter(changeRequests, (o) => {
                  return o.propertyGroup === 'emailaddress';
                })}
                onCreateChangeRequest={onCreateChangeRequest}
              />
            </FSTabPanel>

            <FSTabPanel
              value={currentTab}
              index={6}
              id='advisor-tabpanel-6'
              labelBy='advisor-tab-6'
            >
              <PhoneNumber
                advisorId={advisor.id}
                phoneNumber={advisor.preferredPhoneNumber}
                changeRequests={filter(changeRequests, (o) => {
                  return o.propertyGroup === 'phonenumber';
                })}
                onCreateChangeRequest={onCreateChangeRequest}
              />
            </FSTabPanel>

            <FSTabPanel
              value={currentTab}
              index={7}
              id='advisor-tabpanel-7'
              labelBy='advisor-tab-7'
            >
              <ChangeRequests changeRequests={changeRequests} />
            </FSTabPanel>

            {/* <FSTabPanel
            value={currentTab}
            index={8}
            id='advisor-tabpanel-8'
            labelBy='advisor-tab-8'
          >
            <Security />
          </FSTabPanel> */}

            {/* <FSTabPanel
            value={currentTab}
            index={9}
            id='advisor-tabpanel-9'
            labelBy='advisor-tab-9'
          >
            <Billing />
          </FSTabPanel> */}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default Advisor;

/* eslint max-lines: [2, {"max": 225, "skipComments": true, "skipBlankLines": true}] */
import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  makeStyles,
} from '@material-ui/core';
import SelectField from 'lib/components/SelectField';
import { toast } from 'react-toastify';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { required, trimWhiteSpace } from 'lib/utils/form';
import FormField from 'lib/components/FormField';
import {
  relationshipDisplayMap,
  relationshipTypes,
} from 'constants/relationships';
import * as clientApi from 'api/client';
import DatePickerField from 'lib/components/DatePickerField';
import MomentUtils from '@date-io/moment';
import { shapeErrorsForToast } from 'lib/utils/errorUtil';
import { ErrorToast } from 'lib/components/ErrorToast';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '45vh',
    maxWidth: 1000,
  },
  form: {
    padding: theme.spacing(2),
  },
  field: {
    [theme.breakpoints.up('sm')]: {
      minHeight: theme.spacing(12),
    },
  },
}));

const EditDialog = ({ dependent, show, onClose }) => {
  const classes = useStyles();

  const closeDialog = (relation) => {
    onClose(relation);
  };

  const submitForm = (form) => {
    const clientRelation = {
      ...form,
      id: dependent.id,
      clientProfileId: dependent.clientProfileId,
    };
    clientApi
      .updateClientRelation(clientRelation)
      .then(() => {
        toast.success(`Updates successful.`);
        closeDialog(clientRelation);
      })
      .catch((error) => {
        toast.error(<ErrorToast errorMessages={shapeErrorsForToast(error)} />, {
          autoClose: false,
        });
      });
  };

  if (!dependent) {
    return null;
  }

  return (
    <Dialog
      open={!!show}
      disableBackdropClick
      aria-labelledby='Edit Dependent Dialog'
    >
      <Grid container className={classes.root}>
        <DialogTitle id='form-dialog-title'>Modify Dependent Info</DialogTitle>
        <Grid item xs={12}>
          <DialogContent>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Form
                onSubmit={submitForm}
                initialValues={{
                  givenName: dependent.givenName || '',
                  surname: dependent.surname || '',
                  birthDate: dependent.birthDate || '',
                  relationship: dependent.relationship || '',
                  notes: dependent.notes,
                }}
                render={({ handleSubmit, submitting, invalid }) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <Grid container justify='center' className={classes.form}>
                      <Grid container item xs={12} md={10} lg={9} spacing={2}>
                        <Grid item xs={12} md={6} className={classes.field}>
                          <FormField
                            name='givenName'
                            id='givenName'
                            label='First Name'
                            validate={required}
                            format={trimWhiteSpace}
                            autoFocus
                            required
                            shrink
                          />
                        </Grid>

                        <Grid item xs={12} md={6} className={classes.field}>
                          <FormField
                            name='surname'
                            id='surname'
                            label='Last Name'
                            validate={required}
                            format={trimWhiteSpace}
                            required
                            shrink
                          />
                        </Grid>

                        <Grid item xs={12} md={6} className={classes.field}>
                          <DatePickerField
                            name='birthDate'
                            id='birthDate'
                            label='Birth Date'
                            validate={required}
                            required
                            variant='outlined'
                            disableFuture
                          />
                        </Grid>

                        <Grid item xs={12} md={6} className={classes.textArea}>
                          <SelectField
                            id='relationship'
                            name='relationship'
                            label='Relationship'
                            variant='outlined'
                            validate={required}
                            required
                          >
                            {Object.keys(relationshipTypes)
                              .filter((item) => item !== 'spouse')
                              .map((item) => (
                                <MenuItem value={item} key={item}>
                                  {relationshipDisplayMap[item]}
                                </MenuItem>
                              ))}
                          </SelectField>
                        </Grid>

                        <Grid item xs={12}>
                          <FormField
                            name='notes'
                            id='notes'
                            label='Notes'
                            variant='outlined'
                            rows={5}
                            rowsMax={5}
                            multiline
                          />
                        </Grid>

                        <Grid container justify='flex-end' spacing={2}>
                          <DialogActions>
                            <Grid item>
                              <Button
                                color='primary'
                                variant='outlined'
                                onClick={() => closeDialog(null)}
                              >
                                Cancel
                              </Button>
                            </Grid>
                            <Grid item>
                              <Button
                                disabled={submitting || invalid}
                                type='submit'
                                color='primary'
                                variant='contained'
                              >
                                Save
                              </Button>
                            </Grid>
                          </DialogActions>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                )}
              />
            </MuiPickersUtilsProvider>
          </DialogContent>
        </Grid>
      </Grid>
    </Dialog>
  );
};

EditDialog.propTypes = {
  dependent: PropTypes.shape({
    id: PropTypes.string.isRequired,
    givenName: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    birthDate: PropTypes.string.isRequired,
    relationship: PropTypes.string.isRequired,
    clientProfileId: PropTypes.string.isRequired,
    notes: PropTypes.string,
  }),
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
};

EditDialog.defaultProps = {
  dependent: null,
  show: false,
};

export default EditDialog;

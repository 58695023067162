/* eslint max-lines: [2, {"max": 500, "skipComments": true, "skipBlankLines": true}] */
import React, { useState } from 'react';
import {
  Button,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Form } from 'react-final-form';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import omit from 'lodash/omit';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  composeValidators,
  isEmail,
  required,
  trimWhiteSpace,
} from 'lib/utils/form';

import CheckboxField from 'lib/components/CheckboxField';
import FormField from 'lib/components/FormField';
import SelectField from 'lib/components/SelectField';
import { shapeErrorsForToast } from 'lib/utils/errorUtil';
import { ErrorToast } from 'lib/components/ErrorToast';

import { useDispatch, useSelector } from 'react-redux';
import * as advisorApi from 'api/advisor';
import * as authActions from 'store/asyncActions/auth';

import { isValidPhoneNumberUnadorned } from 'lib/utils/validation';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '95vh',
    maxWidth: 1000,
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(1, 'auto'),
    },
    [theme.breakpoints.down('lg')]: {
      margin: theme.spacing(1, 3),
    },
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(1, 'auto'),
    },
  },
  title: {
    fontWeight: 'bold',
    textAlign: 'center',
    padding: theme.spacing(3),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    padding: theme.spacing(2),
  },
  field: {
    [theme.breakpoints.up('sm')]: {
      minHeight: theme.spacing(12),
    },
  },
}));

const NewAdvisorForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const newAdvisor = useSelector(({ auth }) => auth.user);
  const [isFinished, setIsFinished] = useState(false);
  const marketingWebSiteLinkChoice = useSelector(
    ({ preference }) => preference.choice
  );

  const createNewAdvisor = (form) => {
    const advisor = omit(form, ['sameBillingAddress']);

    if (form.sameBillingAddress) {
      advisor.billingAddressLineOne = form.preferredAddressLineOne;
      advisor.billingAddressLineTwo = form.preferredAddressLineTwo;
      advisor.billingAddressCity = form.preferredAddressCity;
      advisor.billingAddressStateOrProvince =
        form.preferredAddressStateOrProvince;
      advisor.billingAddressPostalCode = form.preferredAddressPostalCode;
    }

    advisor.azureActiveDirectoryObjectId = newAdvisor.oid;

    // Sanitize phone number to ensure no adornments.
    if (!isValidPhoneNumberUnadorned(advisor.preferredPhoneNumber)) {
      const error =
        'Phone number must be ten digits with no adornments. e.g. 9065551212';
      toast.error(<ErrorToast errorMessages={shapeErrorsForToast(error)} />, {
        autoClose: false,
      });
    } else {
      advisorApi
        .createAdvisor({
          ...advisor,
          accountStatus:
            marketingWebSiteLinkChoice === 'try' ? 'trial' : 'incomplete',
        })
        .then((response) => {
          dispatch(authActions.LOAD_NEW_ACCOUNT(response.data));
          setIsFinished(true);
          // Code below handles redirecting if the user chose 'trial'
          // from the Web site. Record the option here.
          advisorApi
            .createAccountEventEntry(
              response.data.affiliateId,
              response.data.id,
              'lz',
              marketingWebSiteLinkChoice
            )
            .then(() => {
              // Do NO OP
            })
            .catch(() => {
              // Do NO OP
            });
        }) // TODO: handle cancel token? Since this isn't being done in a hook, how to handle? Consider adding a hook?
        .catch((error) => {
          toast(error);
        });
    }
  };

  if (newAdvisor.id || isFinished) {
    if (marketingWebSiteLinkChoice === 'try') {
      return <Redirect to='/auth' />;
    }
    return <Redirect to='/payments/initial' />;
  }

  return (
    <Grid container className={classes.root}>
      <Paper spacing={2} elevation={3}>
        <Typography
          variant='h4'
          component='h1'
          color='primary'
          className={classes.title}
        >
          Please Complete Your Profile
        </Typography>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Form
            onSubmit={createNewAdvisor}
            initialValues={{
              givenName: newAdvisor.given_name,
              surname: newAdvisor.family_name,
              preferredEmailAddress: newAdvisor.preferredEmailAddress,
              sameBillingAddress: true,
            }}
            render={({ handleSubmit, values, submitting, invalid }) => (
              <form onSubmit={handleSubmit} noValidate>
                <Grid container justify='center' className={classes.form}>
                  <Grid container item xs={12} md={10} lg={9} spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant='h6' component='h2' color='primary'>
                        Advisor Details
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} className={classes.field}>
                      <FormField
                        name='givenName'
                        id='givenName'
                        label='First Name'
                        validate={required}
                        format={trimWhiteSpace}
                        required
                        autoFocus
                        variant='outlined'
                      />
                    </Grid>

                    <Grid item xs={12} sm={4} className={classes.field}>
                      <FormField
                        name='surname'
                        id='surname'
                        label='Last Name'
                        validate={required}
                        format={trimWhiteSpace}
                        required
                        variant='outlined'
                      />
                    </Grid>

                    <Grid item xs={12} sm={4} className={classes.field}>
                      <FormField
                        name='designations'
                        id='designations'
                        label='Professional Designations'
                        format={trimWhiteSpace}
                        variant='outlined'
                        helperText='CFP, MBA, CPA'
                      />
                    </Grid>

                    <Grid item xs={12} sm={4} className={classes.field}>
                      <FormField
                        name='preferredPhoneNumber'
                        id='preferredPhoneNumber'
                        label='Phone'
                        validate={required}
                        format={trimWhiteSpace}
                        required
                        variant='outlined'
                      />
                    </Grid>

                    <Grid item xs={12} sm={8} className={classes.field}>
                      <FormField
                        name='preferredEmailAddress'
                        id='preferredEmailAddress'
                        label='Email'
                        validate={composeValidators(required, isEmail)}
                        format={trimWhiteSpace}
                        required
                        variant='outlined'
                        readOnly
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant='h6' component='h2' color='primary'>
                        Address
                      </Typography>
                    </Grid>

                    <Grid item xs={12} className={classes.field}>
                      <FormField
                        name='preferredAddressLineOne'
                        id='preferredAddressLineOne'
                        label='Address Line 1'
                        validate={required}
                        required
                        format={trimWhiteSpace}
                        variant='outlined'
                      />
                    </Grid>

                    <Grid item xs={12} className={classes.field}>
                      <FormField
                        name='preferredAddressLineTwo'
                        id='preferredAddressLineTwo'
                        label='Address Line 2'
                        format={trimWhiteSpace}
                        variant='outlined'
                      />
                    </Grid>

                    <Grid item xs={12} sm={4} className={classes.field}>
                      <FormField
                        name='preferredAddressCity'
                        id='preferredAddressCity'
                        label='City'
                        format={trimWhiteSpace}
                        variant='outlined'
                        validate={required}
                        required
                      />
                    </Grid>

                    <Grid item xs={12} sm={4} className={classes.field}>
                      <FormField
                        name='preferredAddressStateOrProvince'
                        id='preferredAddressStateOrProvince'
                        label='State'
                        format={trimWhiteSpace}
                        variant='outlined'
                        validate={required}
                        required
                      />
                    </Grid>

                    <Grid item xs={12} sm={4} className={classes.field}>
                      <FormField
                        name='preferredAddressPostalCode'
                        id='preferredAddressPostalCode'
                        label='Postal Code'
                        format={trimWhiteSpace}
                        variant='outlined'
                        validate={required}
                        required
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <CheckboxField
                        name='sameBillingAddress'
                        id='sameBillingAddress'
                        label='Use this for billing address'
                      />
                    </Grid>

                    {!values.sameBillingAddress && (
                      <>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            variant='h6'
                            component='h2'
                            color='primary'
                          >
                            Billing Address
                          </Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.field}>
                          <FormField
                            name='billingAddressLineOne'
                            id='billingAddressLineOne'
                            label='Billing Address Line 1'
                            validate={required}
                            required
                            format={trimWhiteSpace}
                            variant='outlined'
                          />
                        </Grid>

                        <Grid item xs={12} className={classes.field}>
                          <FormField
                            name='billingAddressLineTwo'
                            id='billingAddressLinTwo'
                            label='Billing Address Line 2'
                            format={trimWhiteSpace}
                            variant='outlined'
                          />
                        </Grid>

                        <Grid item xs={12} sm={4} className={classes.field}>
                          <FormField
                            name='billingAddressCity'
                            id='billingAddressCity'
                            label='Billing City'
                            format={trimWhiteSpace}
                            variant='outlined'
                            validate={required}
                            required
                          />
                        </Grid>

                        <Grid item xs={12} sm={4} className={classes.field}>
                          <FormField
                            name='billingAddressStateOrProvince'
                            id='billingAddressStateOrProvince'
                            label='Billing State'
                            format={trimWhiteSpace}
                            variant='outlined'
                            validate={required}
                            required
                          />
                        </Grid>

                        <Grid item xs={12} sm={4} className={classes.field}>
                          <FormField
                            name='billingAddressPostalCode'
                            id='billingAddressPostalCode'
                            label='Billing Postal Code'
                            format={trimWhiteSpace}
                            variant='outlined'
                            validate={required}
                            required
                          />
                        </Grid>
                      </>
                    )}

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    <Grid item xs={12} className={classes.field}>
                      <CheckboxField
                        name='confirmEulaCheckbox'
                        id='confirmEulaCheckbox'
                        label='I have read, understand, and agree to the TaxCast End User License Agreement (EULA).'
                      />
                      <Button
                        onClick={() =>
                          window.open(
                            '/Terms_and_Conditions_HTML_20201128.html',
                            '_blank'
                          )
                        }
                      >
                        View
                      </Button>
                    </Grid>

                    <Grid item xs={12} className={classes.field}>
                      <CheckboxField
                        name='confirmPrivacyPolicyCheckbox'
                        id='confirmPrivacyPolicyCheckbox'
                        label='I have read, understand, and agree to the TaxCast Privacy Policy.'
                      />
                      <Button
                        onClick={() =>
                          window.open(
                            '/Privacy_Policy_HTML_20201128.html',
                            '_blank'
                          )
                        }
                      >
                        View
                      </Button>
                    </Grid>

                    <Grid item xs={12} className={classes.field}>
                      <FormControl fullWidth>
                        {/* <InputLabel id='howDidYouLearnAboutTaxCastLabel'>
                          How did you learn about TaxCast?
                        </InputLabel> */}
                        <SelectField
                          id='howDidYouLearnAboutTaxCastPicklist'
                          name='howDidYouLearnAboutTaxCastPicklist'
                          label='How did you learn about TaxCast?'
                          variant='outlined'
                        >
                          {/* <Select
                          id='howDidYouLearnAboutTaxCastPicklist'
                          name='howDidYouLearnAboutTaxCastPicklist'
                          value={howDidYouLearnAboutTaxCast}
                          onChange={howDidYouLearnAboutTaxCastPicklistChange}
                        > */}
                          <MenuItem value='veres' key='veres'>
                            Bob Veres&apos; Insider&apos;s Forum (Nashville TN
                            October 2021)
                          </MenuItem>
                          <MenuItem value='xypn' key='xypn'>
                            #XYPNLive (Denver CO November 2021)
                          </MenuItem>
                          <MenuItem value='search' key='search'>
                            Search Engine (Google, Bing, DuckDuckGo, etc.)
                          </MenuItem>
                          <MenuItem value='network' key='network'>
                            My Network (LinkedIn, a colleague, etc.)
                          </MenuItem>
                          <MenuItem value='other' key='other'>
                            Other
                          </MenuItem>
                        </SelectField>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} className={classes.field}>
                      <FormField
                        name='howDidYouLearnAboutTaxCastOther'
                        id='howDidYouLearnAboutTaxCastOther'
                        label='Please specify'
                        format={trimWhiteSpace}
                        variant='outlined'
                      />
                    </Grid>

                    <Grid container item xs={12} justify='flex-end'>
                      <Button
                        disabled={
                          submitting ||
                          invalid ||
                          !values.confirmEulaCheckbox ||
                          !values.confirmPrivacyPolicyCheckbox
                        }
                        type='submit'
                        color='primary'
                        variant='contained'
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          />
        </MuiPickersUtilsProvider>
      </Paper>
    </Grid>
  );
};

export default NewAdvisorForm;

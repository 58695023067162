/* eslint max-lines: [2, {"max": 175, "skipComments": true, "skipBlankLines": true}] */
import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import * as advisorApi from 'api/advisor';
import {
  advisorDesignations,
  designationIsRegistered,
} from 'constants/advisorDesignations';
import { shapeErrorsForToast } from 'lib/utils/errorUtil';
import { ErrorToast } from 'lib/components/ErrorToast';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.maxWidth,
    padding: theme.spacing(3, 3),
  },
  title: {
    fontWeight: 'bold',
    padding: theme.spacing(2),
  },
  designation: {
    padding: theme.spacing(1),
  },
}));

const Designations = ({
  affiliateId,
  advisorId,
  advisorDesignationsList,
  onSave,
}) => {
  const classes = useStyles();
  const [designations, setDesignations] = useState(advisorDesignationsList);
  const [showDesignationDialog, setShowDesignationDialog] = useState(false);

  const onClickCancel = () => {
    setDesignations(advisorDesignationsList);
  };

  const onClickSave = () => {
    advisorApi
      .updateAdvisorDesignationsList(affiliateId, advisorId, designations)
      .then(() => {
        toast.success(`Successfully updated designations.`);
        onSave(designations);
      })
      .catch((error) => {
        toast.error(<ErrorToast errorMessages={shapeErrorsForToast(error)} />, {
          autoClose: false,
        });
      });
  };

  const onChangeCheckbox = (value) => {
    const selectedIndex = designations.indexOf(value);
    const updatedDesignations = [...designations];

    if (selectedIndex === -1) {
      updatedDesignations.push(value);
    } else {
      updatedDesignations.splice(selectedIndex, 1);
    }

    setDesignations(updatedDesignations);

    // SPECIAL CASE: A PFS must have a CPA designation.
    const indexOfPfs = designations.indexOf('PFS');
    const indexOfCpa = designations.indexOf('CPA');

    // Scenario a: Adding PFS
    if (value === 'PFS' && indexOfPfs === -1 && indexOfCpa === -1) {
      setShowDesignationDialog(true);
    }

    // Scenario 2: Removing CPA
    if (value === 'CPA' && indexOfCpa !== -1 && indexOfPfs !== -1) {
      setShowDesignationDialog(true);
    }
  };

  const handleConfirmPfsCpaRelationshipClose = () => {
    setShowDesignationDialog(false);
  };

  return (
    <>
      <Grid container className={classes.root}>
        <Grid container item xs={12} className={classes.designation}>
          <List>
            {Object.keys(advisorDesignations).map((item) => (
              <ListItem key={item}>
                <ListItemText
                  id={item}
                  primary={`${item}${
                    designationIsRegistered[item] ? '\xAE' : ''
                  } - ${advisorDesignations[item]}`}
                />
                <ListItemSecondaryAction>
                  <Checkbox
                    edge='end'
                    onChange={() => onChangeCheckbox(item)}
                    checked={designations.includes(item)}
                    color='primary'
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid
          container
          item
          xs={12}
          justify='flex-end'
          className={classes.designations}
        >
          <Button variant='outlined' color='primary' onClick={onClickCancel}>
            Cancel
          </Button>
          &nbsp;&nbsp;
          <Button variant='contained' color='primary' onClick={onClickSave}>
            Save
          </Button>
        </Grid>
      </Grid>
      <Dialog
        open={showDesignationDialog}
        onClose={handleConfirmPfsCpaRelationshipClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Confirm Designation Selection
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            A PFS designation requires a corresponding CPA designation.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button
            onClick={() => handleConfirmPfsCpaRelationshipClose('cancel')}
            color='primary'
            value='cancel'
          >
            Cancel
          </Button> */}
          <Button
            onClick={() => handleConfirmPfsCpaRelationshipClose('ok')}
            color='primary'
            value='ok'
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

Designations.propTypes = {
  affiliateId: PropTypes.string.isRequired,
  advisorId: PropTypes.string.isRequired,
  advisorDesignationsList: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSave: PropTypes.func.isRequired,
};

Designations.defaultProps = {};

export default Designations;

/* eslint-disable import/prefer-default-export */
/* eslint max-lines: [2, {"max": 500, "skipComments": true, "skipBlankLines": true}] */
export const provisions = {
  currentProvisions: [
    {
      provision: 'Alimony Received',
      section: '1-Ordinary Income',
      taxReturnReference: 'Schedule 1 Item 2a',
      notes: '',
    },
    {
      provision: 'Annuities',
      section: '1-Ordinary Income',
      taxReturnReference: 'Form 1040 Item 5B',
      notes:
        'Enter taxable amount of non-qualified annuity distributions. The insurer should report this information on a Form 1099-R',
    },
    {
      provision: 'Farm Income',
      section: '1-Ordinary Income',
      taxReturnReference: 'Schedule 1 Item 6',
      notes:
        'Drag a Farm Income transaction tile into the desired year of the Canvas in the Ordinary Income section. On the tax return, this amount will be supported by a Schedule F and it can be a gain or loss.',
    },
    {
      provision: 'Interest',
      section: '1-Ordinary Income',
      taxReturnReference: 'Form 1040 Item 2B',
      notes:
        'Enter amount of interest income in the Ordinary Income section using an Interest Income transaction tile. Drag the transaction tile into the desired Ordinary Income year. The bank or other financial institution should report this information on a Form 1099-INT',
    },
    {
      provision: 'IRA & Retirement Distributions',
      section: '1-Ordinary Income',
      taxReturnReference: 'Form 1040 Item 4B',
      notes:
        'Enter taxable amount of retirement distributions. The retirement custodian should report this information on a Form 1099-R',
    },
    {
      provision: 'Ordinary Dividends',
      section: '1-Ordinay Income',
      taxReturnReference: 'Form 1040 Item 2B',
      notes: '',
    },
    {
      provision: 'Pensions',
      section: '1-Ordinary Income',
      taxReturnReference: 'Form 1040 Item 4B',
      notes: '',
    },
    {
      provision: 'S Corp Distributions',
      section: '1-Ordinary Income',
      taxReturnReference: 'Schedule 1 Item 5',
      notes:
        'Drag an S Corp Distribution tile into the Ordinary Income section of the applicable year. The TaxCast dialog box will include prompts to duplicate/spread the transaction and to calculate the allowable QBID.',
    },
    {
      provision: 'Short-term Capital Gains',
      section: '1-Ordinary Income',
      taxReturnReference: 'Form 1040 Line 3b',
      notes: '',
    },
    {
      provision: 'Social Security',
      section: '1-Ordinary Income',
      taxReturnReference: 'Form 1040 Item 6A',
      notes:
        'Drag a transaction tile from the left toolbox (Ordinary Income section) into the desired year on the canvas. Enter the gross annual Social Security benefit prior to any Medicare premium deductions in the dialog box. A user can manually enter Social Security amounts for each year on the canvas or use the “Distribute/Split” option to have TaxCast adjust subsequent benefit amounts by a desired percentage or fixed dollar amount. TaxCast will calculate the taxation of the gross Social Security benefit using the other income information entered onto the canvas.',
    },
    {
      provision: 'Sole Proprietorship Income',
      section: '1-Ordinary Income',
      taxReturnReference: 'Schedule 1 Item 3',
      notes:
        'Drag a Sole Proprietorship Income tile into the Ordinary Income section of the applicable year. The TaxCast dialog box will include prompts to duplicate/spread the transaction and to calculate the allowable QBID.',
    },
    {
      provision: 'Wages',
      section: '1-Ordinary Income',
      taxReturnReference: 'Form 1040 Item 1',
      notes:
        'Drag a Wages transaction tile into the desired year on the Canvas. The TaxCast dialog bix will allow you to designate whether the wages were earned by the taxpayer or the spouse. The dialog box will also allow quick entry for multiple years.',
    },
    {
      provision: 'Long-term Capital Gains',
      section: '2-Capital Gains',
      taxReturnReference: '',
      notes: '',
    },
    {
      provision: 'Qualified Dividends',
      section: '2-Capital Gains',
      taxReturnReference: 'Form 1040 Line 3a',
      notes: '',
    },
    {
      provision: 'Alimony Deduction',
      section: '3-ATL Deductions',
      taxReturnReference: '',
      notes: '',
    },
    {
      provision: 'Health Savings Accounts',
      section: '3-ATL Deductions',
      taxReturnReference: '',
      notes: '',
    },
    {
      provision: 'Retirement Contributions',
      section: '3-ATL Deductions',
      taxReturnReference: '',
      notes: '',
    },
    {
      provision: 'Student Loan Interest Deduction',
      section: '3-ATL Deductions',
      taxReturnReference: '',
      notes: '',
    },
    {
      provision: 'Charitable Cash Contributions',
      section: '4-Itemized Deductions',
      taxReturnReference: 'Schedule A Line 11',
      notes:
        'Drag a Charitable Contribution tile from the toolbox into the desired year on the canvas. In the dialog box, enter the gross value of the contribution. Using the dropdown menu, select “cash” as the type of contribution. TaxCast will automatically calculate the allowable deduction given the taxpayers’s reported income and carryover any unused deduction as a system transaction tile. TaxCast will include this deduction in the total of all itemized deductions and then automatically use the higher of the standard or itemized deductions.',
    },
    {
      provision: 'Charitable Contributions Carryover',
      section: '4-Itemized Deductions',
      taxReturnReference: 'Schedule A Line 13',
      notes:
        'Drag a Charitable Contribution tile from the toolbox into the beginning year on the canvas for any charitable contribution carryover. In the dialog box, enter the gross value of the contribution. Using the dropdown menu, select the type of contribution. TaxCast will automatically calculate the allowable deduction given the taxpayers’s reported income and carryover any unused deduction as a system transaction tile.',
    },
    {
      provision: 'Charitable Non-Cash Deductions',
      section: '4-Itemized Deductions',
      taxReturnReference: 'Schedule A Line 12',
      notes:
        'Drag a Charitable Contribution tile from the toolbox into the desired year on the canvas. In the dialog box, enter the gross value of the contribution. Using the dropdown menu, select the specific type of non-cash contribution. TaxCast will automatically calculate the allowable deduction given the taxpayers’s reported income and carryover any unused deduction as a system transaction tile. TaxCast will include this deduction in the total of all itemized deductions and then automatically use the higher of the standard or itemized deductions.',
    },
    {
      provision: 'Medical Expense Deduction',
      section: '4-Itemized Deductions',
      taxReturnReference: 'Schedule A Line 1',
      notes:
        'Drag a Medical Expense tile from the toolbox into the applicable year on the canvas. A user can enter either an aggregate of all unreimbursed medical expenses (i.e., not paid by insurance) or individual unreimbursed medical expenses. TaxCast will calculate the maximum allowable Medical Expense itemized deduction relative to the taxpayer’s adjusted gross income (AGI). TaxCast will include this deduction in the total of all itemized deductions and then automatically use the higher of the standard or itemized deductions.',
    },
    {
      provision: 'Mortgage Interest Deduction',
      section: '4-Itemized Deductions',
      taxReturnReference: 'Schedule A Line 8',
      notes:
        'Drag a mortgage interest transaction tile into the desired year on the canvas. TaxCast will include the interest in the total of all itemized deductions and then compare that total to the standard deduction. TaxCast will automatically use the higher of the standard or itemized deductions.',
    },
    {
      provision: 'Other Itemized Deductions',
      section: '4-Itemized Deductions',
      taxReturnReference: 'Schedule A Line 16',
      notes:
        "Drag an 'other' transaction tile into the desired year on the canvas. TaxCast will include the reported deduction amount in the total of all itemized deductions and then compare that total to the standard deduction. TaxCast will automatically use the higher of the standard or itemized deductions. Note TaxCast does not perform other validation tests on entered Other Itemized Deduction transactions.",
    },
    {
      provision: 'Qualified Business Income Deduction',
      section: '5-System Calculation',
      taxReturnReference: 'Form 1040 Line 13; Form 8995; Form 8995A;',
      notes:
        'Drag a transaction tile for an S Corp Distribution or Sole Proprietorship onto the desired year of the Canvas. The TaxCast dialog box will include the necessary prompts for the additional information required to calculate both the individual QBID and aggregate QBID if there are multiple sources of QBI income.',
    },
    {
      provision: 'Tax Cut & Jobs Act Sunset',
      section: '5-System Calculation',
      taxReturnReference: '',
      notes:
        'This enhancement will allow the user to select sunset of the TCJA rates or continued inflation of the brackets and thresholds.',
    },
  ],
  futureProvisions: [
    {
      provision: 'Casualty and Theft Losses',
      availability: 'Beta',
      notes:
        'Drag a casualty/theft loss transaction tile onto the canvas for the year of loss. Note each casualty or theft loss should have a separate tile to allow TaxCast to calculate the individual and aggregate limits for casualty and theft losses. TaxCast does not verify that the casualty loss qualifies under the tax code’s disaster declaration provisions.',
    },
    {
      provision: 'American Opportunity Credit',
      availability: 'GA',
      notes:
        "This enhancement will ask questions within a new 'college cost' dialogue box to determine whether higher education expenses qualify for this credit. TaxCast will then calculate income eligibility using other information already entered onto the canvas for that year and use any allowable credit to reduce ordinary tax liability.",
    },
    {
      provision: 'Bracket Inflation',
      availability: 'GA',
      notes:
        'TaxCast currently assumes future tax brackets and thresholds (i.e., standard deduction amounts, IRA contribution amounts, etc.,) are the same as the 2021 amounts. This enhancement will allow the advisor to use either a system assumption or a custom inflation assumption amount for future amounts.',
    },
    {
      provision: 'Capital Loss Carryforward',
      availability: 'GA',
      notes: '',
    },
    {
      provision: 'Capital Loss Carryover',
      availability: 'GA',
      notes: 'Track the expiration of Capital Loss carryovers',
    },
    {
      provision: 'Child Tax Credit',
      availability: 'GA',
      notes:
        'TaxCast will calculate eligible child credit based upon income information entered onto the canvas and family information as entered for the client’s profile. Eligible credit amounts will be reflected as reductions of the calculated federal tax liability.',
    },
    {
      provision: 'Lifetime Learning Credit',
      availability: 'GA',
      notes:
        'This enhancement will ask questions within a new “college cost” dialogue box to determine whether higher education expenses qualify for this credit. TaxCast will then calculate income eligibility using other information already entered onto the canvas for that year and use any allowable credit to reduce ordinary tax liability.',
    },
    {
      provision: 'Qualified Small Business Deduction',
      availability: 'GA',
      notes:
        'Initial functionality will include a toggle within the dialogue box asking users about eligibility. For eligible entities, TaxCast will then apply any applicable income limits. Future releases will contain more detailed questionnaires to guide users unfamiliar with this deduction through the determination of eligibility.',
    },
    {
      provision: 'Tax Exempt Income',
      availability: 'GA',
      notes:
        'Although exempt from federal income taxes, municipal bond income does affect the taxation of Social Security benefits. This enhancement will allow the user to enter tax exempt bond income as a transaction tile that will be used in that calculation.',
    },
    {
      provision: 'Medicare Surtax on Wages and Self-Employment Income',
      availability: 'Q1 2022',
      notes:
        'This enhancement will calculate the Medicare Surtax on Wages and Self-Employment Income using transaction tiles of a given year and include that amount as a “surtax” in the Summary section.',
    },
    {
      provision: 'Net Investment Income Tax',
      availability: 'Q1 2022',
      notes:
        'This enhancement will calculate the NIIT using transaction tiles of a given year and include that amount as a “surtax” in the Summary section.',
    },
    {
      provision: 'Net Operating Loss Carryforward',
      availability: 'Q2 2022',
      notes:
        'TaxCast presently assumes any unused losses within a given tax year “expire” but users can manually set new transaction tiles for the amounts of any net operating loss carryforwards. This enhancement will create system tiles for these unused losses and carry them forward after validation checks for any limits (normally limited to 80% of taxable income).',
    },
    {
      provision: 'Roth IRA',
      availability: 'Q2 2022',
      notes:
        'Tax Cast does not presently reflect Roth IRA contributions because Roth IRAs do not result in a current tax benefit. This enhancement will allow users to toggle between Roth IRA and traditional IRA within the transaction tile dialogue box so that the current cash flow effect of Roth vs traditional contributions can be validated. The enhancement will also include testing to verify Roth IRA contribution eligibility.',
    },
    {
      provision: 'Self-employed Retirement Plans',
      availability: 'Q2 2022',
      notes: '',
    },
    {
      provision: 'US Savings Bond Exclusion for Higher Education',
      availability: 'Q2 2022',
      notes:
        'This enhancement will include a section on the transaction tile dialogue box asking the user whether the US Savings Bonds proceeds were used for qualified higher education expenses. If the user provides a positive answer, TaxCast will validate the exclusion based upon the taxpayer’s income.',
    },
    {
      provision: 'Farm Loss Carryover',
      availability: 'Q3 2022',
      notes:
        'TaxCast presently requires the user to manually enter the amount of any desired Farm Loss carryover as a separate transaction tile. In this enhancement, TaxCast will include a toggle within the transaction tile dialogue box to allow the user to choose whether the loss is carried back or forward. TaxCast will then calculate the allowable loss carryover and create a system tile(s) within the affected years. To edit the Farm loss, the user will adjust the transaction tile in the original year of loss.',
    },
    {
      provision: 'Medicare Income Related Monthly Adjustment Amount',
      availability: 'Q3 2022',
      notes:
        "This enhancement will calculate the Medicare IRMAA rates and include them as a 'surtax' within the Summary section.",
    },
    {
      provision: 'Self-employed Health Insurance',
      availability: 'Q3 2022',
      notes: '',
    },
    {
      provision: 'Alimony Income & Deduction',
      availability: 'Q3 2022',
      notes:
        'TaxCast presently allows users to enter alimony income and deductions without error checking although it does include a cautionary reminder that only divorces from 2018 or prior are considered for income tax purposes. This enhancement will include questions within the transaction tile dialogue box to error check this entry.',
    },
    {
      provision: 'Depreciation Recapture',
      availability: 'Q4 2022',
      notes:
        'TaxCast presently accounts for short and long-term capital gains. This enhancement will add a questionnaire to the transaction tile dialogue box to request recapture basis so that the differential tax can be calculated.',
    },
    {
      provision: 'IRA Deduction Eligibility',
      availability: 'Q4 2022',
      notes:
        'TaxCast presently allows users to input IRA deduction without error checking eligibility. This enhancement will include a questionnaire within the transaction tile dialogue box asking a series of questions to verify IRA deduction eligibility.',
    },
    {
      provision: 'Passive Loss Limits',
      availability: '2023',
      notes:
        'TaxCast does not presently limit passive loss activities if the user enters a transaction tile for such losses. This enhancement will include a series of questions within the transaction tile dialogue box to determine whether a loss should be partially or fully disallowed given the nature of the activity and the taxpayer’s income.',
    },
    {
      provision: 'Retirement Plan Early Distribution Penalty',
      availability: '2023',
      notes:
        "TaxCast presently assumes that all retirement plan withdrawals are free of the premature distribution penalty regardless of the taxpayer’s age. This enhancement will consider the taxpayer’s age and include a dropdown menu that allows users to negate a possible penalty by selecting one of the available exceptions. For withdrawals not eligible for an exception to the penalty, TaxCast will calculate a premature distribution penalty and include it within the 'surtax' section.",
    },
    {
      provision: 'S Corp Shareholder Health Insurance Premiums',
      availability: '2023',
      notes:
        'TaxCast does not presently include either the imputed income related to S Corp shareholder premiums or the deduction. This enhancement will include the necessary tile to allow TaxCast to calculate both items.',
    },
    {
      provision: 'Self Employed Retirement Plan',
      availability: '2023',
      notes:
        'TaxCast presently allows users to input amounts for retirement plan contributions without error checking. This enhancement will include several questions within the transaction tile dialogue box to determine maximum deductible retirement plan contributions for self-employed individuals.',
    },
    {
      provision: 'Alternative Minimum Tax',
      availability: '2023',
      notes: '',
    },
    {
      provision: 'Premature Retirement Plan Distribution Penalty',
      availability: '2023',
      notes:
        "TaxCast presently allows users to enter this penalty as an 'Other' above-the-line deduction but does not calculate penalties on any premature distributions. The enhancement will include questions within the transaction tile dialogue box to determine whether the retirement plan distribution qualifies for an exception. Any penalty will be included as a “surtax” within the Summary section.",
    },
    {
      provision: 'Adoption Credit',
      availability: '2023',
      notes: '',
    },
    {
      provision: 'Foreign Taxes, Credits & Offsets',
      availability: '2023',
      notes:
        'TaxCast does not calculate credits or offsets related to foreign taxes or the effect of foreign transactions on such items as the Net Investment Income Tax',
    },
    {
      provision: 'Gross Income Under Section 911, 931, or 933',
      availability: '2023',
      notes: '',
    },
    {
      provision: 'Qualified Business Income Deduction Qualified Property',
      availability: '2023',
      notes:
        "For certain 'favored' pass-through businesses whose owners exceed the income phase-out thresholds, the Qualified Business Income Deduction (the 'QBID' under Section 199A) considers 'qualified property' owned by the business when calculating the maximum deduction. Qualified Property is generally the cost basis prior to depreciation for property placed into service within the prior ten years. TaxCast does not track the ages, acquisition cost, or accumulated depreciation of capital assets. TaxCast does all users to input gross capital asset amounts for inclusion in the QBID calculation.",
    },
    {
      provision: 'Refundable Tax Credits',
      availability: '2023',
      notes:
        'Various tax credits including portions of the Child Tax Credit or American Opportunity Credit are refundable meaning a taxpayer can receive a refund that exceeds their respective tax liability. TaxCast will calculate a zero liability in these circumstances.',
    },
    {
      provision: "Retirement Savers' Credit",
      availability: '2023',
      notes: '',
    },
    {
      provision: 'Earned Income Tax Credit',
      availability: '2023',
      notes: '',
    },
    {
      provision: 'State Income Taxes',
      availability: '2023',
      notes:
        'The TaxCast calculation engine evaluates federal income tax obligations. Individual state income tax calculations are not included within TaxCast’s functionality.',
    },
    {
      provision: '',
      availability: '',
      notes: '',
    },
  ],
};

/* eslint max-lines: [2, {"max": 250, "skipComments": true, "skipBlankLines": true}] */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import { categoryTypes } from 'constants/categories';
import {
  filingStatusDisplayMap,
  filingStatusTypes,
} from 'constants/filingStatuses';

import { UPDATE_FILING_STATUS_FOR_TAX_YEAR } from 'store/asyncActions/taxCast';
import InactiveTaxGroup from './InactiveTaxGroup';
import TaxYearSummary from './TaxYearSummary';

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.taxCast.yearContainer,
    padding: theme.spacing(1, 2),
    margin: theme.spacing(0.5, 0),
    border: `1px solid ${theme.palette.secondary.main}`,
  },
  summary: {
    [theme.breakpoints.up('sm')]: {
      borderLeft: '1px solid lightgrey',
    },
  },
  reorderIcon: {
    border: '1px',
  },
}));

const InactiveTaxYear = ({ year, filingStatus }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const taxYearAvailable = useSelector(
    ({ taxCast }) => !!taxCast.forecast.years[year]
  );
  const clientProfileId = useSelector(({ taxCast }) => taxCast.client.id);
  const forecastId = useSelector(({ taxCast }) => taxCast.forecast.id);
  const [
    showChangeFilingStatusDialog,
    setShowChangeFilingStatusDialog,
  ] = useState(false);
  const [currentFilingStatus, setCurrentFilingStatus] = useState(filingStatus);
  const [newFilingStatus, setNewFilingStatus] = useState(filingStatus);
  const summary = useSelector(({ taxCast }) => taxCast.calculations[year]);

  const handleShowChangeFilingStatusDialog = () => {
    setShowChangeFilingStatusDialog(true);
  };

  const handleSelection = (event) => {
    setNewFilingStatus(event.target.value);
  };

  const handleConfirmChangeFilingStatus = () => {
    if (currentFilingStatus !== newFilingStatus) {
      dispatch(
        UPDATE_FILING_STATUS_FOR_TAX_YEAR(
          clientProfileId,
          forecastId,
          year,
          newFilingStatus
        )
      );
      setCurrentFilingStatus(newFilingStatus);
    }
    setShowChangeFilingStatusDialog(false);
  };

  const handleCancelChangeFilingStatus = () => {
    // Revert to the original/current filing status.
    setNewFilingStatus(currentFilingStatus);
    setShowChangeFilingStatusDialog(false);
  };

  if (!taxYearAvailable) {
    return null;
  }

  return (
    <Paper className={classes.root}>
      <Grid container spacing={1}>
        <Grid container item xs={12} sm={8} md={9}>
          <Grid item xs={6}>
            <Typography color='secondary'>
              {year} - {filingStatusDisplayMap[currentFilingStatus]}
              <IconButton
                size='small'
                className={classes.reorderIcon}
                onClick={handleShowChangeFilingStatusDialog}
              >
                <MoreHorizIcon fontSize='small' />
              </IconButton>
            </Typography>
          </Grid>
          <Grid item xs={3}>
            {summary && (
              <Typography color='secondary'>
                Marginal: {Math.round(summary.marginalTaxRate)}%
              </Typography>
            )}
          </Grid>
          <Grid item xs={3}>
            {summary && (
              <Typography color='secondary'>
                Effective: {Math.round(summary.effectiveTaxRate)}%
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <InactiveTaxGroup
              type={categoryTypes.ordinaryIncome}
              label='Ordinary Income'
              year={year}
            />
          </Grid>

          <Grid item xs={12}>
            <InactiveTaxGroup
              type={categoryTypes.capitalGains}
              label='Capital Gains'
              year={year}
            />
          </Grid>

          <Grid item xs={12}>
            <InactiveTaxGroup
              type={categoryTypes.deductions}
              label='Deductions'
              year={year}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} sm={4} md={3} className={classes.summary}>
          <TaxYearSummary year={year} />
        </Grid>

        <Dialog
          disableBackdropClick
          maxWidth='xs'
          aria-labelledby='change-filing-status-by-tax-year-dialog-title'
          open={showChangeFilingStatusDialog}
        >
          <DialogTitle id='confirmation-dialog-title'>
            {year} Filing Status
          </DialogTitle>
          <DialogContent dividers>
            <RadioGroup
              aria-label='filingStatus'
              name='filingStatus'
              value={newFilingStatus}
              onChange={handleSelection}
            >
              {Object.keys(filingStatusTypes).map((item) => (
                <FormControlLabel
                  value={item}
                  key={item}
                  control={<Radio color='primary' />}
                  label={filingStatusDisplayMap[item]}
                />
              ))}
            </RadioGroup>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={handleCancelChangeFilingStatus}
              color='primary'
            >
              Cancel
            </Button>
            <Button onClick={handleConfirmChangeFilingStatus} color='primary'>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Paper>
  );
};

InactiveTaxYear.propTypes = {
  year: PropTypes.string.isRequired,
  filingStatus: PropTypes.string.isRequired,
};

export default InactiveTaxYear;

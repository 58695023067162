/* eslint max-lines: [2, {"max": 200, "skipComments": true, "skipBlankLines": true}] */
import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  makeStyles,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import {
  NEW_TRANSACTION,
  SPLIT_DISTRIBUTE_TRANSACTION_DIALOG,
} from 'store/asyncActions/taxCast';

import { forEach, pickBy } from 'lodash';
import { isValidAmount } from 'lib/utils/validation';

import TransactionInfoPanel from './components/TransactionInfoPanel';
import SpreadTransactionForm from './components/SpreadTransactionForm';

const useStyles = makeStyles((theme) => ({
  leftPanel: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    position: 'relative',
  },
  category: {
    paddingTop: theme.spacing(2),
  },
  subcategory: {
    paddingLeft: theme.spacing(1),
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
    },
  },
  button: {
    [theme.breakpoints.up('sm')]: {
      minWidth: theme.spacing(13),
    },
  },
  textArea: {
    marginBottom: theme.spacing(3),
  },
}));

const SplitDistributeTransactionDialog = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const transaction = useSelector(
    ({ taxCast }) => taxCast.splitDistributeTransactionDialog
  );
  const isSubmitting = useSelector(({ taxCast }) => taxCast.isSubmitting);
  const forecast = useSelector(({ taxCast }) => taxCast.forecast);

  const [isValidSpread, setIsValidSpread] = useState(true);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  /* spreadData structure:
   {
    <year>: { selected: <bool>, value: <num>, displaySequence: <num>, error: <bool> },
    2024: { selected: true, value: '', displaySequence: -1, error: false },
    2025: { selected: true, value: 1100, displaySequence: -1, error: true }
   }
  */
  const [spreadData, setSpreadData] = useState({});

  const handleCancel = () => {
    setIsValidSpread(true);
    setIsSaveEnabled(false);
    setSpreadData({});
    dispatch(SPLIT_DISTRIBUTE_TRANSACTION_DIALOG(null));
  };

  const handleSubmit = () => {
    if (isValidSpread) {
      const selectedItems = pickBy(
        spreadData,
        (item) => item.selected && isValidAmount(item.value)
      );

      // Since each new transaction is based on an existing transaction,
      // spread the existing transaction into the new transactions.
      const newTransactions = Object.keys(selectedItems).map((key) => {
        return {
          ...transaction,
          taxYear: key,
          value: selectedItems[key].value,
          displaySequence: forecast.years[key][transaction.category].length,
          isSpread: false,
        };
      });
      forEach(newTransactions, (tx) => {
        dispatch(NEW_TRANSACTION(tx));
      });
      handleCancel();
    } else {
      // TODO: Need to present to the user.
    }
  };

  // **********************
  // *** SPREAD SUBFORM ***
  // **********************
  const handleOnValidateSpread = (isValid) => {
    setIsValidSpread(isValid);
    setIsSaveEnabled(isValid);
  };

  const handleOnIsSpreadUpdate = () => {
    // This is a NOOP for duplicating existing transactions.
  };

  const handleOnSpreadDataUpdate = (updatingSpreadData) => {
    setSpreadData(updatingSpreadData);
  };

  if (!transaction) {
    return null;
  }

  return (
    <Dialog
      open={!!transaction}
      disableBackdropClick
      aria-labelledby='Duplicate Transaction Dialog'
      maxWidth='sm'
    >
      <Grid container>
        <Grid item xs={12} sm={4} className={classes.leftPanel}>
          <TransactionInfoPanel mode='Duplicate' transaction={transaction} />
        </Grid>

        <Grid item xs={12} sm={8}>
          <DialogContent>
            {/* Spread Form */}
            <SpreadTransactionForm
              mode='Duplicate'
              value={+transaction.value}
              taxYear={+transaction.taxYear}
              forecastYearList={forecast.yearList}
              onIsSpreadUpdate={handleOnIsSpreadUpdate}
              onSpreadDataUpdate={handleOnSpreadDataUpdate}
              onValidate={handleOnValidateSpread}
            />
            <DialogActions>
              <Button
                disabled={isSubmitting}
                className={classes.button}
                variant='outlined'
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                disabled={isSubmitting || !isSaveEnabled}
                className={classes.button}
                color='primary'
                variant='outlined'
                type='submit'
                onClick={handleSubmit}
              >
                {isSubmitting ? 'Saving...' : 'Save'}
              </Button>
            </DialogActions>
          </DialogContent>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default SplitDistributeTransactionDialog;

import React, { useEffect, useState } from 'react';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import { charitableContributionTypesDisplayMap } from 'constants/charitableContributionTypes';

const useStyles = makeStyles((theme) => ({
  field: {
    minHeight: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const CharitableContributionTypeForm = ({ contributionType, onDataUpdate }) => {
  const classes = useStyles();
  const [data, setData] = useState({
    charitableContributionTypeValue: contributionType,
  });

  useEffect(() => {
    onDataUpdate(data);
  }, [data, onDataUpdate]);

  const handleCharitableContributionTypeChange = (event) => {
    setData({ charitableContributionTypeValue: event.target.value });
    onDataUpdate({ charitableContributionTypeValue: event.target.value });
  };

  return (
    <Grid item xs={12} className={classes.field}>
      <FormControl fullWidth>
        <InputLabel id='charitableContributionTypeLabel'>
          Charitable Contribution Type
        </InputLabel>
        <Select
          id='charitableContributionType'
          name='charitableContributionType'
          value={data.charitableContributionTypeValue}
          onChange={handleCharitableContributionTypeChange}
        >
          {Object.keys(charitableContributionTypesDisplayMap).map((item) => (
            <MenuItem value={item} key={item}>
              {charitableContributionTypesDisplayMap[item]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
};

CharitableContributionTypeForm.propTypes = {
  contributionType: PropTypes.string,
  onDataUpdate: PropTypes.func.isRequired,
};

CharitableContributionTypeForm.defaultProps = {
  contributionType: 'cash',
};

export default CharitableContributionTypeForm;

import React from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { orderBy } from 'lodash';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.maxWidth,
    padding: theme.spacing(3, 3),
  },
  content: {
    padding: theme.spacing(1),
  },
  tableBanner: {
    fontSize: '1.5em',
    padding: theme.spacing(1, 3),
  },
  table: {
    padding: theme.spacing(1, 3),
  },
}));

const ChangeRequests = ({ changeRequests }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Grid container item xs={12} className={classes.tableBanner}>
        {`${changeRequests.length} change ${
          changeRequests.length !== 1 ? 'requests' : 'request'
        }`}
      </Grid>
      <Grid container item xs={12} className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Property Group</TableCell>
              <TableCell>Current Value</TableCell>
              <TableCell>Requested Values</TableCell>
              <TableCell>Reason</TableCell>
              <TableCell>Submitted</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Notes</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderBy(changeRequests, 'submittedStamp', ['desc']).map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.propertyGroup}</TableCell>
                <TableCell>{item.currentValue}</TableCell>
                <TableCell>{item.requestedValue}</TableCell>
                <TableCell>{item.reasonForChange}</TableCell>
                <TableCell>{`${moment(item.submittedStamp).format(
                  'MM/DD/YYYY hh:mm'
                )}`}</TableCell>
                <TableCell>{item.status}</TableCell>
                <TableCell>{item.notes}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

ChangeRequests.propTypes = {
  changeRequests: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      submittedStamp: PropTypes.string,
      currentValue: PropTypes.string,
      requestedValue: PropTypes.string,
      reasonForChange: PropTypes.string,
      status: PropTypes.string,
      notes: PropTypes.string,
      propertyGroup: PropTypes.string,
    })
  ),
};

ChangeRequests.defaultProps = {
  changeRequests: [],
};

export default ChangeRequests;

import moment from 'moment';

export const composeFormatters = (...formatters) => (value) =>
  formatters.reduce((formatted, formatter) => formatter(formatted), value);

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const dateTimeFormatter = (dateTimeString) =>
  moment(dateTimeString).format('MMMM DD, YYYY h:mm a');

export const phoneNumberFormatter = (phoneNumber) => {
  let result = '';
  if (phoneNumber) {
    const match = phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
    result = `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return result;
};

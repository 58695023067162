import axios from 'axios';

import { baseUrl } from 'constants/api';

export const getAmountDue = (affiliateId, advisorId) =>
  axios.get(
    `${baseUrl}/api/affiliates/${affiliateId}/advisors/${advisorId}/payments/amount-due`
  );

export const applyDiscountCode = (affiliateId, advisorId, discountCodeId) =>
  axios.patch(
    `${baseUrl}/api/affiliates/${affiliateId}/advisors/${advisorId}/discount-codes/${discountCodeId}`
  );

export const createPaymentAuthorization = (
  affiliateId,
  advisorId,
  authorizationInfo
) =>
  axios.post(
    `${baseUrl}/api/affiliates/${affiliateId}/advisors/${advisorId}/payment-authorizations`,
    authorizationInfo
  );

export const createPayment = (affiliateId, advisorId, paymentInfo) =>
  axios.post(
    `${baseUrl}/api/affiliates/${affiliateId}/advisors/${advisorId}/initial-payments`,
    paymentInfo
  );

export const createPaymentRecurring = (affiliateId, advisorId, paymentInfo) =>
  axios.post(
    `${baseUrl}/api/affiliates/${affiliateId}/advisors/${advisorId}/recurring-payments`,
    paymentInfo
  );

// 400 - discount found but is not applicable
// 404 - discount code not found by name
export const getDiscountCodeInfo = (discountCode) =>
  axios.get(`${baseUrl}/api/payments/discount-codes/${discountCode}`);

export const getPublicDiscountCodeInfo = (affiliateId, advisorId) =>
  axios.get(
    `${baseUrl}/api/affiliates/${affiliateId}/advisors/${advisorId}/discount-codes?public`
  );

export const getPayments = (affiliateId, advisorId) =>
  axios.get(
    `${baseUrl}/api/affiliates/${affiliateId}/advisors/${advisorId}/payments/`
  );

import React from 'react';
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { shapeErrorsForToast } from 'lib/utils/errorUtil';
import { ErrorToast } from 'lib/components/ErrorToast';

const initialState = {
  isSubmitting: false,
  error: '',
  advisor: {},
};

/* eslint no-param-reassign: ["error", { "ignorePropertyModificationsFor": ["state"] }] */
const advisorSlice = createSlice({
  name: 'advisor',
  initialState,
  reducers: {
    ERROR(state, action) {
      Object.assign(state, initialState, { error: action.payload });
    },
    SILENT_ERROR(state, action) {
      toast.error(
        <ErrorToast errorMessages={shapeErrorsForToast(action.payload)} />,
        { autoClose: false }
      );
      state.isSubmitting = false;
    },
    SUBMITTING_FORM(state) {
      state.isSubmitting = true;
    },
    ADD_ADVISOR(state, action) {
      const advisor = action.payload;
      state.advisor = advisor;
      state.isSubmitting = false;
    },
  },
});

export const advisorActions = advisorSlice.actions;
export default advisorSlice.reducer;

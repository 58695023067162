import React from 'react';
import { Paper, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.primaryBackground,
    // border is needed here, eventhough it's not visible,
    // otherwise the text inside will wrap on drag due to box sizing.
    border: `1px solid ${theme.palette.primaryBackground}`,
  },
  isDragging: {
    borderRadius: 2,
    border: '1px solid lightgrey',
  },
}));

const ToolBoxItem = ({ item, index }) => {
  const classes = useStyles();
  return (
    <Draggable draggableId={item.id} type={item.type} index={index}>
      {(provided, snapshot) => (
        <Paper
          square
          elevation={0}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={provided.draggableProps.style}
          className={clsx(classes.root, { [classes.isDragging]: snapshot.isDragging })}
        >
          {item.name}
        </Paper>
      )}
    </Draggable>
  );
};

ToolBoxItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
};

export default ToolBoxItem;

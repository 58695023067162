/* eslint max-lines: [2, {"max": 200, "skipComments": true, "skipBlankLines": true}] */
import React, { useState } from 'react';
import {
  Avatar,
  Button,
  ButtonGroup,
  Grid,
  Paper,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { FileCopy as FileCopyIcon, Mail as MailIcon } from '@material-ui/icons';
import moment from 'moment';
import PropTypes from 'prop-types';

import { filingStatusDisplayMap } from 'constants/filingStatuses';
import { phoneNumberFormatter } from 'lib/utils/format';
import { getAge } from 'lib/utils/dateTimeUtils';

import EditDialog from './EditDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.maxWidth,
    // [theme.breakpoints.down('lg')]: {
    //   margin: theme.spacing(1, 3),
    // },
    // [theme.breakpoints.up('lg')]: {
    //   margin: theme.spacing(1, 'auto'),
    // },
  },
  avatar: {
    width: theme.spacing(17),
    height: theme.spacing(17),
    fontSize: '48px',
    backgroundColor: theme.palette.primary.main,
  },
}));

const Profile = ({ profile }) => {
  const classes = useStyles();
  const [showDialogEdit, setShowDialogEdit] = useState(false);
  const [clientProfile, setClientProfile] = useState(profile);

  const onClickEdit = () => {
    setShowDialogEdit(true);
  };

  const onCloseEditDialog = (updatedProfile) => {
    if (updatedProfile) {
      setClientProfile(updatedProfile);
    }
    setShowDialogEdit(false);
  };

  return (
    <>
      <EditDialog
        clientProfile={clientProfile}
        show={showDialogEdit}
        onClose={onCloseEditDialog}
      />
      {clientProfile && (
        <Paper>
          <Grid container className={classes.root} spacing={2}>
            <Grid container item xs={12}>
              <Grid item xs={12} sm={3} md={2}>
                <Avatar
                  alt={`${clientProfile.givenName
                    .substring(0, 1)
                    .toUpperCase()}${clientProfile.surname
                    .substring(0, 1)
                    .toUpperCase()}`}
                  className={classes.avatar}
                >
                  {clientProfile.givenName.substring(0, 1).toUpperCase()}
                  {clientProfile.surname.substring(0, 1).toUpperCase()}
                </Avatar>
              </Grid>
              <Grid container item xs={12} sm={8} md={9}>
                <Grid container item xs={12}>
                  <Grid item xs={11}>
                    <Typography variant='h3' component='h3'>
                      {`${clientProfile.givenName} ${clientProfile.surname}`}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Grid container item xs={12} sm={8} md={4}>
                    <Grid item xs={12}>
                      <Typography variant='h5' component='h5'>
                        {`${clientProfile.preferredAddressLineOne}`}
                      </Typography>
                    </Grid>
                    {clientProfile.preferredAddressLineTwo && (
                      <Grid item xs={12}>
                        <Typography variant='h5' component='h5'>
                          {`${clientProfile.preferredAddressLineTwo}`}
                        </Typography>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Typography variant='h5' component='h5'>
                        {`${clientProfile.preferredAddressCity} ${clientProfile.preferredAddressStateOrProvince} ${clientProfile.preferredAddressPostalCode}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant='h5' component='h5'>
                        {phoneNumberFormatter(
                          clientProfile.preferredPhoneNumber
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} sm={8}>
                    <Grid item xs={12}>
                      <Typography variant='h5' component='h5'>
                        {`Filing Status: ${
                          filingStatusDisplayMap[clientProfile.filingStatus]
                        }`}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant='h5' component='h5'>
                        {`Birth Date: ${moment(clientProfile.birthDate).format(
                          'MM/DD/YYYY'
                        )} (${getAge(clientProfile.birthDate)})`}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant='h5' component='h5'>
                        {clientProfile.preferredEmailAddress}
                        <ButtonGroup size='small' variant='text'>
                          <Tooltip title='Email'>
                            <Button
                              size='small'
                              onClick={() => {
                                window.location.href = `mailto:${clientProfile.preferredEmailAddress}`;
                              }}
                            >
                              <MailIcon fontSize='small' />
                            </Button>
                          </Tooltip>
                          <Tooltip title='Copy'>
                            <Button
                              size='small'
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  clientProfile.preferredEmailAddress
                                );
                              }}
                            >
                              <FileCopyIcon fontSize='small' />
                            </Button>
                          </Tooltip>
                        </ButtonGroup>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Grid container item xs={12} justify='flex-end'>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={onClickEdit}
                  >
                    Edit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
};

Profile.propTypes = {
  profile: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Profile;

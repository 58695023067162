/* eslint max-lines: [2, {"max": 200, "skipComments": true, "skipBlankLines": true}] */
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Grid, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import { MsalProvider } from '@azure/msal-react';

import { ProtectedRoute } from 'lib/router/routes';
import Auth from 'modules/Auth';
import NavDrawer from 'modules/NavDrawer';
import Login from 'modules/Login';
import TaxCast from 'modules/TaxCast';
import ClientSearch from 'modules/ClientSearch';
import NewClientForm from 'modules/Forms/NewClientForm';
import NewAdvisorForm from 'modules/Forms/NewAdvisorForm';
// import EarlyAdopter from 'modules/Payment/EarlyAdopter';
import HelpAndSupport from 'modules/Help';
import HelpContactUs from 'modules/Help/ContactUs';
import Advisor from 'modules/Advisor';
import Logout from 'modules/Logout';
import ReportViewer from 'modules/TaxCast/components/Analytics/ReportViewer';
import LandingPage from 'modules/LandingPage';
import About from 'modules/Help/About';
import Glossary from 'modules/Help/Glossary';
import CurrentProvisions from 'modules/Help/CurrentProvisions';
import FutureProvisions from 'modules/Help/FutureProvisions';
import ItsDeadJim from 'modules/ItsDeadJim';
import ContactUsAboutStatus from 'modules/Auth/components/ContactUsAboutStatus';
import AcceptUsagePolicies from 'modules/Auth/components/AcceptUsagePolicies';
// import Trial from 'modules/Payment/components/Trial';
import Payment from 'modules/Payment';
import ExtendForecast from 'modules/ExtendForecast';
import Campaign from 'modules/Campaign';

const useStyles = makeStyles((theme) => ({
  content: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
    },
  },
  reports: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(3),
    },
  },
}));

const App = ({ pca }) => {
  const classes = useStyles();

  return (
    <>
      <BrowserRouter>
        <MsalProvider instance={pca}>
          <Switch>
            <Route exact path='/lz' component={LandingPage} />
            <Route exact path='/login' component={Login} />
            <Route exact path='/logout' component={Logout} />
            <Route exact path='/itsdeadjim' component={ItsDeadJim} />
            <Route exact path='/email-campaign' component={Campaign} />
            <Route exact path='/linkedin-campaign' component={Campaign} />
            <Route exact path='/campaign' component={Campaign} />
            <Route
              exact
              path='/contact-us-about-status'
              component={ContactUsAboutStatus}
            />
            <Switch>
              <ProtectedRoute exact path='/reports/:path?'>
                <Grid container>
                  <Grid item xs={1} />
                  <Grid item xs={10} className={classes.reports}>
                    <Route exact path='/reports' component={ReportViewer} />
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
              </ProtectedRoute>
              <ProtectedRoute path='/'>
                <Grid container>
                  <Grid item xs={1}>
                    <NavDrawer />
                  </Grid>

                  <Grid item xs={10} className={classes.content}>
                    <Switch>
                      {/* <Route
                        exact
                        path='/payments/trial'
                        component={Trial}
                      /> */}
                      <Route path='/auth' component={Auth} />
                      <Route
                        exact
                        path='/advisors/new'
                        component={NewAdvisorForm}
                      />
                      {/* <Route
                        exact
                        path='/payments/early-adopter'
                        component={EarlyAdopter}
                      /> */}
                      <Route
                        exact
                        path='/agreements'
                        component={AcceptUsagePolicies}
                      />
                      <Route
                        exact
                        path='/payments/initial'
                        component={Payment}
                      />

                      <Route exact path='/' component={LandingPage} />
                      <Route
                        exact
                        path='/clients/new'
                        component={NewClientForm}
                      />
                      <Route
                        exact
                        path='/clients/search'
                        component={ClientSearch}
                      />
                      <Route exact path='/clients/:id' component={TaxCast} />
                      <Route
                        exact
                        path='/clients/:id/extend-forecast'
                        component={ExtendForecast}
                      />
                      <Route exact path='/advisor' component={Advisor} />
                      <Route exact path='/help' component={HelpAndSupport} />
                      <Route
                        exact
                        path='/help/contact-us'
                        component={HelpContactUs}
                      />
                      <Route
                        exact
                        path='/help/tax-planning'
                        component={HelpAndSupport}
                      />
                      <Route exact path='/help/glossary' component={Glossary} />
                      <Route
                        exact
                        path='/help/current-provisions'
                        component={CurrentProvisions}
                      />
                      <Route
                        exact
                        path='/help/future-provisions'
                        component={FutureProvisions}
                      />
                      <Route
                        exact
                        path='/help/account-maintenance'
                        component={HelpAndSupport}
                      />
                      <Route exact path='/help/about' component={About} />
                      <Route>
                        <div>404 not found</div>
                      </Route>
                    </Switch>
                  </Grid>
                </Grid>
              </ProtectedRoute>
            </Switch>
          </Switch>
        </MsalProvider>
      </BrowserRouter>
    </>
  );
};

App.propTypes = {
  pca: PropTypes.objectOf(PropTypes.shape).isRequired,
};

export default App;

/* eslint-disable import/prefer-default-export */
export const categoryTypes = {
  ordinaryIncome: 'ordinaryIncome',
  capitalGains: 'capitalGains',
  deductions: 'deductions',
};

export const categoryDisplayMap = {
  ordinaryIncome: 'Ordinary Income',
  capitalGains: 'Capital Gains',
  deductions: 'Deductions',
};

export const subcategoryDisplayMap = {
  // Ordinary Income
  farmIncome: 'Farm Income',
  interest: 'Interest',
  // iraDistributionRetirementPlanPensions: 'IRA Distribution/Retirement Plan, Pensions',
  iraDistribution: 'IRA Distribution/Retirement Plan, Pensions',
  nonQualifiedDividends: 'Non-Qualified Dividends',
  sCorpDistributions: 'S Corp Distributions',
  shortTermCapitalGains: 'Short Term Capital Gains',
  soleProprietorshipIncome: 'Sole Proprietorship Income',
  taxableSocialSecurity: 'Social Security',
  wages: 'Wages',

  // Capital Gains
  longTermCapitalGains: 'Long-Term Capital Gains',
  qualifiedDividends: 'Qualified Dividends',

  // Deductions
  charitableContribution: 'Charitable Contribution',
  healthSavingsAccountContribution: 'Health Savings Account Contribution',
  medicalExpense: 'Medical Expense',
  mortgageInterest: 'Mortgage Interest',
  personalPropertyTaxes: 'Personal Property Taxes',
  realEstateTaxes: 'Real Estate Taxes',
  retirementPlanContribution: 'Retirement Plan Contribution',
  stateIncomeTaxes: 'State Income Taxes',
  studentLoanInterest: 'Student Loan Interest',
  alimony: 'Alimony',
  otherDeductionAboveTheLine: 'Other',
  otherDeductionBelowTheLine: 'Other',
  higherEducationExpense: 'Higher Education Expense',
};

import React from 'react';
import { Button, Grid, Paper, Typography, makeStyles } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import TaxCastLogo from 'assets/img/Logo512x512.png';
import { useHistory } from 'react-router-dom';
import { TaxCastWebVersion } from 'constants/settings';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(4),
  },
}));

const About = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleClickBack = () => history.goBack();

  return (
    <Grid container justify='center' className={classes.root}>
      <Grid item xs={12}>
        <Button onClick={handleClickBack}>
          <ArrowBackIcon />
          &nbsp;Back
        </Button>
      </Grid>
      <Paper spacing={2} elevation={3}>
        <Grid item xs={12}>
          <Typography variant='h6' component='h2' color='primary'>
            TaxCast version {TaxCastWebVersion}
          </Typography>
        </Grid>
        <Grid item>
          <img src={TaxCastLogo} alt='TaxCast Logo' />
        </Grid>
      </Paper>
    </Grid>
  );
};

export default About;

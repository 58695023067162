import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  field: {
    minHeight: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const NonQualifiedDividendForm = ({ isSection199a, onDataUpdate }) => {
  const classes = useStyles();
  const [data, setData] = useState({
    isSection199AReitDividend: isSection199a,
  });

  useEffect(() => {
    onDataUpdate(data);
  }, [data, onDataUpdate]);

  const handleInputChangeSection199a = (event) => {
    setData({ isSection199AReitDividend: event.target.checked });
    onDataUpdate({ isSection199AReitDividend: event.target.checked });
  };

  return (
    <Grid item xs={12} className={classes.field}>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              name='isSection199a'
              id='isSection199a'
              checked={data.isSection199AReitDividend}
              onChange={handleInputChangeSection199a}
              color='primary'
            />
          }
          label='Section 199A REIT Dividend'
        />
      </Grid>
    </Grid>
  );
};

NonQualifiedDividendForm.propTypes = {
  isSection199a: PropTypes.bool,
  onDataUpdate: PropTypes.func.isRequired,
};

NonQualifiedDividendForm.defaultProps = {
  isSection199a: false,
};

export default NonQualifiedDividendForm;

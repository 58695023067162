import React from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  Help as HelpIcon,
  PowerSettingsNew as LogoutIcon,
  Brightness3 as MoonIcon,
  Settings as SettingsIcon,
  WbSunny as SunIcon,
} from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

import { useIsAuthenticated } from '@azure/msal-react';

import { prefActions } from 'store/slices/pref';

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.primary.main,
  },
}));

const UserUtils = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const isDarkMode = useSelector(
    ({ preference }) => preference.theme === 'dark'
  );
  const isAuthenticated = useIsAuthenticated();

  const toggleTheme = () => {
    dispatch(prefActions.UPDATE_THEME(isDarkMode ? 'light' : 'dark'));
  };

  const navHelp = () => history.push('/help');
  const advisorMaintenance = () => history.push(`/advisor`);

  const handleLoginClick = () => {
    history.push('/login');
  };

  const handleLogoutClick = () => {
    history.push('/logout');
  };

  return (
    <List>
      <ListItem button onClick={navHelp} component='li'>
        <ListItemIcon>
          <HelpIcon color='primary' />
        </ListItemIcon>
        <ListItemText className={classes.text} primary='Help' />
      </ListItem>

      {isAuthenticated && (
        <ListItem button onClick={toggleTheme} component='li'>
          <ListItemIcon>
            {isDarkMode ? (
              <SunIcon color='primary' />
            ) : (
              <MoonIcon color='primary' />
            )}
          </ListItemIcon>
          <ListItemText
            className={classes.text}
            primary={isDarkMode ? 'Standard Mode' : 'Dark Mode'}
          />
        </ListItem>
      )}

      {isAuthenticated && (
        <ListItem button onClick={advisorMaintenance} component='li'>
          <ListItemIcon>
            <SettingsIcon color='primary' />
          </ListItemIcon>
          <ListItemText className={classes.text} primary='Settings' />
        </ListItem>
      )}

      <ListItem
        button
        onClick={isAuthenticated ? handleLogoutClick : handleLoginClick}
        component='li'
      >
        <ListItemIcon>
          <LogoutIcon color='primary' />
        </ListItemIcon>
        <ListItemText
          className={classes.text}
          primary={isAuthenticated ? 'Logout' : 'Login'}
        />
      </ListItem>
    </List>
  );
};

export default UserUtils;

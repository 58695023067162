/* eslint-disable import/prefer-default-export */
export const advisorDesignations = {
  AIF: 'Accredited Investment Fiduciary',
  AIFA: '	Accredited Investment Fiduciary Analyst',
  CFA: '	Chartered Financial Analyst',
  CFP: '	Certified Financial Planner',
  ChFC: '	Chartered Financial Consultant',
  CIC: '	Certified Insurance Counselor',
  CIMA: '	Certified Investment Management Analyst',
  CLU: '	Chartered Life Underwriter',
  CPA: '	Certified Public Accountant',
  EA: '	Enrolled Agent',
  JD: '	Juris Doctor',
  PFS: '	Personal Financial Specialist',
};

export const designationIsRegistered = {
  AIF: true,
  AIFA: true,
  CFA: true,
  CFP: true,
  ChFC: true,
  CIC: false,
  CIMA: true,
  CLU: true,
  CPA: false,
  EA: false,
  JD: false,
  PFS: false,
};

/* eslint max-lines: [2, {"max": 275, "skipComments": true, "skipBlankLines": true}] */
import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { orderBy } from 'lodash';
import moment from 'moment';

import * as advisorApi from 'api/advisor';
import { shapeErrorsForToast } from 'lib/utils/errorUtil';
import { ErrorToast } from 'lib/components/ErrorToast';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.maxWidth,
    padding: theme.spacing(3, 3),
  },
  content: {
    padding: theme.spacing(1),
  },
  tableBanner: {
    fontSize: '1.5em',
    padding: theme.spacing(1, 3),
  },
  table: {
    padding: theme.spacing(1, 3),
  },
}));

const EmailAddresses = ({
  advisorId,
  emailAddress,
  changeRequests,
  onCreateChangeRequest,
}) => {
  const classes = useStyles();
  const [newEmailAddress, setNewEmailAddress] = useState(emailAddress);
  const [reasonForChange, setReasonForChange] = useState('');
  const [showChangeRequestDialog, setShowChangeRequestDialog] = useState(false);
  const [isErrorReasonForChange, setIsErrorReasonForChange] = useState(false);
  const maxReasonLength = 1000;
  const minReasonLength = 12;

  const onClickChange = () => {
    setShowChangeRequestDialog(true);
  };

  const onClickDialogAction = (event) => {
    if (event === 'ok') {
      if (reasonForChange.length < minReasonLength) {
        setIsErrorReasonForChange(true);
      } else if (emailAddress === newEmailAddress) {
        toast.error('Nothing changed.');
      } else {
        setIsErrorReasonForChange(false);
        setShowChangeRequestDialog(false);

        // Reset the dialog.
        setReasonForChange('');

        const changeRequest = {
          propertyGroup: 'emailaddress',
          currentValue: emailAddress,
          requestedValue: newEmailAddress,
          reasonForChange,
        };
        advisorApi
          .createAccountChangeRequest(advisorId, changeRequest)
          .then((response) => {
            onCreateChangeRequest(response.data);
            toast.success(
              `Change request submitted to change account information.`
            );
          })
          .catch((error) => {
            toast.error(
              <ErrorToast errorMessages={shapeErrorsForToast(error)} />,
              {
                autoClose: false,
              }
            );
          });
      }
    } else {
      setReasonForChange('');
      setShowChangeRequestDialog(false);
    }
  };

  const onChangeNewEmailAddress = (event) => {
    setNewEmailAddress(event.target.value);
  };

  const onChangeReasonForChange = (event) => {
    if (event.target.value.length <= maxReasonLength) {
      setReasonForChange(event.target.value);
    }
  };

  return (
    <>
      <Grid container className={classes.root}>
        <Grid container item xs={12} className={classes.content}>
          <TextField
            fullWidth
            variant='outlined'
            label='Email Address'
            disabled
            value={emailAddress}
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          className={classes.content}
          justify='flex-end'
        >
          <Button variant='contained' color='primary' onClick={onClickChange}>
            Change
          </Button>
        </Grid>
      </Grid>
      {changeRequests && (
        <Grid container item xs={12} className={classes.tableBanner}>
          {`${changeRequests.length} change ${
            changeRequests.length !== 1 ? 'requests' : 'request'
          }`}
        </Grid>
      )}
      {changeRequests && changeRequests.length > 0 && (
        <Grid container item xs={12} className={classes.table}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Property Group</TableCell>
                <TableCell>Current Value</TableCell>
                <TableCell>Requested Values</TableCell>
                <TableCell>Reason</TableCell>
                <TableCell>Submitted</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Notes</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderBy(changeRequests, 'submittedStamp', ['desc']).map(
                (item) => (
                  <TableRow key={item.id}>
                    <TableCell>{item.propertyGroup}</TableCell>
                    <TableCell>{item.currentValue}</TableCell>
                    <TableCell>{item.requestedValue}</TableCell>
                    <TableCell>{item.reasonForChange}</TableCell>
                    <TableCell>{`${moment(item.submittedStamp).format(
                      'MM/DD/YYYY hh:mm'
                    )}`}</TableCell>
                    <TableCell>{item.status}</TableCell>
                    <TableCell>{item.notes}</TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </Grid>
      )}
      <Dialog
        open={!!showChangeRequestDialog}
        disableBackdropClick
        aria-labelledby='Change Request Dialog'
      >
        <Grid container className={classes.root}>
          <DialogTitle id='form-dialog-title'>
            Account Change Request - Email Address
          </DialogTitle>
          <DialogContent>
            <Grid container item xs={12} className={classes.content}>
              <TextField
                fullWidth
                variant='outlined'
                label='Email Address'
                disabled
                value='codesailor@gmail.com'
              />
            </Grid>
            <Grid container item xs={12} className={classes.content}>
              <TextField
                fullWidth
                variant='outlined'
                label='New Email Address'
                value={newEmailAddress}
                onChange={onChangeNewEmailAddress}
              />
            </Grid>
            <Grid container item xs={12} className={classes.content}>
              <TextField
                fullWidth
                variant='outlined'
                multiline
                rows={3}
                rowsMax={10}
                label={`Reason for Change (${reasonForChange.length} of ${maxReasonLength} characters)`}
                value={reasonForChange}
                onChange={onChangeReasonForChange}
                helperText='Minimum 12 Characters'
                error={isErrorReasonForChange}
              />
            </Grid>
            <DialogActions>
              <Button
                onClick={() => onClickDialogAction('cancel')}
                color='primary'
                value='cancel'
                variant='outlined'
              >
                Cancel
              </Button>
              <Button
                onClick={() => onClickDialogAction('ok')}
                color='primary'
                value='ok'
                autoFocus
                variant='contained'
              >
                Ok
              </Button>
            </DialogActions>
          </DialogContent>
        </Grid>
      </Dialog>
    </>
  );
};

EmailAddresses.propTypes = {
  advisorId: PropTypes.string.isRequired,
  emailAddress: PropTypes.string.isRequired,
  changeRequests: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      submittedStamp: PropTypes.string,
      currentValue: PropTypes.string,
      requestedValue: PropTypes.string,
      reasonForChange: PropTypes.string,
      status: PropTypes.string,
      notes: PropTypes.string,
      propertyGroup: PropTypes.string,
    })
  ),
  onCreateChangeRequest: PropTypes.func.isRequired,
};

EmailAddresses.defaultProps = {
  changeRequests: [],
};

export default EmailAddresses;

/* eslint max-lines: [2, {"max": 250, "skipComments": true, "skipBlankLines": true}] */
import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Button,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Person as PersonIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import moment from 'moment';
import { toast } from 'react-toastify';

import * as clientApi from 'api/client';
import { getAge } from 'lib/utils/dateTimeUtils';
import { phoneNumberFormatter } from 'lib/utils/format';
import { shapeErrorsForToast } from 'lib/utils/errorUtil';
import { ErrorToast } from 'lib/components/ErrorToast';

import NewDialog from './NewDialog';
import EditDialog from './EditDialog';
import DeleteDialog from '../DeleteDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1000,
    width: '100%',
    flexGrow: 1,
  },
  list: {
    padding: theme.spacing(2),
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  content: {
    padding: theme.spacing(1),
  },
}));

const SpousePartner = ({
  spousePartnerRelation,
  clientProfileId,
  clientProfileAddress,
}) => {
  const classes = useStyles();
  const [showDialogNew, setShowDialogNew] = useState(false);
  const [showDialogEdit, setShowDialogEdit] = useState(false);
  const [showDialogDelete, setShowDialogDelete] = useState(false);
  const [spousePartner, setSpousePartner] = useState(spousePartnerRelation);
  const [isAddressSame, setIsAddressSame] = useState(true);

  useEffect(() => {
    if (spousePartner) {
      let addressSame = true;
      addressSame =
        addressSame &&
        spousePartner.addressLineOne ===
          clientProfileAddress.preferredAddressLineOne;
      addressSame =
        addressSame &&
        spousePartner.addressLineTwo ===
          clientProfileAddress.preferredAddressLineTwo;
      addressSame =
        addressSame &&
        spousePartner.addressCity === clientProfileAddress.preferredAddressCity;
      addressSame =
        addressSame &&
        spousePartner.addressStateOrProvince ===
          clientProfileAddress.preferredAddressStateOrProvince;
      addressSame =
        addressSame &&
        spousePartner.addressPostalCode ===
          clientProfileAddress.preferredAddressPostalCode;
      setIsAddressSame(addressSame);
    }
  }, [spousePartnerRelation, clientProfileAddress, spousePartner]);

  const onClickEdit = () => {
    setShowDialogEdit(true);
  };

  const onClickDelete = () => {
    setShowDialogDelete(true);
  };

  const onCloseDeleteDialog = (event) => {
    if (event === 'ok') {
      clientApi
        .deleteClientRelation(clientProfileId, spousePartner.id)
        .then(() => {
          setSpousePartner();
        })
        .catch((error) => {
          toast.error(
            <ErrorToast errorMessages={shapeErrorsForToast(error)} />,
            {
              autoClose: false,
            }
          );
        });
    }
    setShowDialogDelete(false);
  };

  const onClickAdd = () => {
    setShowDialogNew(true);
  };

  const onCloseEditDialog = (relation) => {
    if (relation) {
      setSpousePartner(relation);
    }
    setShowDialogEdit(false);
  };

  const onCloseNewDialog = (relation) => {
    if (relation) {
      setSpousePartner(relation);
    }
    setShowDialogNew(false);
  };

  return (
    <>
      <NewDialog
        show={showDialogNew}
        clientProfileId={clientProfileId}
        onClose={onCloseNewDialog}
        clientProfileAddress={clientProfileAddress}
      />
      <EditDialog
        spousePartner={spousePartner}
        show={showDialogEdit}
        onClose={onCloseEditDialog}
        clientProfileAddress={clientProfileAddress}
        clientProfileId={clientProfileId}
        isSameAddressAsClient={isAddressSame}
      />
      <DeleteDialog
        title='Remove the spouse/partner relationship?'
        content='Are you certain you want to remove the spouse/partner relationship from the client profile?'
        show={showDialogDelete}
        onClose={onCloseDeleteDialog}
      />
      <Grid container item xs={12} className={classes.content}>
        <Grid item xs={9}>
          <Typography component='h6' variant='h6'>
            Spouse / Partner
          </Typography>
        </Grid>
        <Grid item xs={3}>
          {!!spousePartner && (
            <Button variant='contained' color='primary' onClick={onClickEdit}>
              Edit
            </Button>
          )}
          {!spousePartner && (
            <Button variant='contained' color='primary' onClick={onClickAdd}>
              Add
            </Button>
          )}
        </Grid>
      </Grid>
      {!!spousePartner && (
        <Grid container>
          <Grid container item xs={12} className={classes.content}>
            <Grid item xs={2}>
              <Avatar aria-label='recipe' className={classes.avatar}>
                <PersonIcon />
              </Avatar>
            </Grid>
            <Grid item xs={10}>
              <Grid item xs={12}>
                <b>
                  {spousePartner.givenName} {spousePartner.surname}
                </b>
              </Grid>
              <Grid item xs={12}>
                {moment(spousePartner.birthDate).format('MM/DD/YYYY')} (
                {getAge(spousePartner.birthDate)})
              </Grid>
              <Grid item xs={12}>
                {spousePartner.emailAddress}
              </Grid>
              <Grid item xs={12}>
                {phoneNumberFormatter(spousePartner.phoneNumber)}
              </Grid>
              {!isAddressSame && (
                <>
                  <Grid item xs={12}>
                    {spousePartner.addressLineOne}
                  </Grid>
                  <Grid item xs={12}>
                    {spousePartner.addressLineTwo}
                  </Grid>
                  <Grid item xs={12}>
                    {spousePartner.addressCity}{' '}
                    {spousePartner.addressStateOrProvince}{' '}
                    {spousePartner.addressPostalCode}
                  </Grid>
                </>
              )}
              {isAddressSame && (
                <Grid item xs={12}>
                  <i>Cohabitates with client</i>
                </Grid>
              )}
              <Grid item xs={12}>
                {spousePartner.notes}
              </Grid>
            </Grid>
            <Grid container item xs={11} justify='flex-end'>
              {!!spousePartner && (
                <Button
                  variant='outlined'
                  color='primary'
                  onClick={onClickDelete}
                >
                  Remove
                </Button>
              )}
            </Grid>
            {/* <List className={classes.root}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar className={classes.avatar}>
                    {spousePartner.givenName.substring(0, 1)}
                    {spousePartner.surname.substring(0, 1)}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={`${spousePartner.givenName} ${spousePartner.surname
                    } (${getAge(spousePartner.birthDate)})`}
                  secondary={
                    relationshipDisplayMap[spousePartner.relationship]
                  }
                />
              </ListItem>
            </List> */}
          </Grid>
        </Grid>
      )}
    </>
  );
};

SpousePartner.propTypes = {
  spousePartnerRelation: PropTypes.objectOf(PropTypes.any),
  clientProfileId: PropTypes.string.isRequired,
  clientProfileAddress: PropTypes.objectOf(PropTypes.any).isRequired,
};

SpousePartner.defaultProps = {
  spousePartnerRelation: null,
};

export default SpousePartner;

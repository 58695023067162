/* eslint-disable import/prefer-default-export */
import { forEach, pickBy } from 'lodash';

const modifyByPercent = (value, amount) => {
  return +value * (1 + +amount / 100);
};

const modifyByMultiplier = (value, amount) => {
  // The first year, amount will be zero.
  return +value * (+amount === 0 ? 1 : +amount);
};

const modifyByAmount = (value, amount) => {
  return value + amount;
};

const modifyByNoChange = (value) => {
  return +value;
};

const getModifier = (units) => {
  let result;
  switch (units) {
    case 'percent':
      result = modifyByPercent;
      break;
    case 'times':
      result = modifyByMultiplier;
      break;
    case 'currency':
      result = modifyByAmount;
      break;
    default:
      result = modifyByNoChange;
      break;
  }
  return result;
};

/**
 *
 * @param {number} value The base value for the transactions;
 * @param {number} amount The amount by which @value is modified per year
 * @param {string} units The type of @amount: percent or multiplier
 * @param {number[]} years The years
 */
const distributeTransaction = (
  transactionValue,
  transactionTaxYear,
  amount,
  units,
  years
) => {
  const result = { ...years };
  const modifier = getModifier(units);
  let previousYearValue = Number(transactionValue);
  forEach(Object.keys(years), (year) => {
    if (years[year].selected) {
      let currentYearValue = previousYearValue;
      if (year > transactionTaxYear) {
        currentYearValue = Math.round(modifier(previousYearValue, amount));
      }
      result[year].value = currentYearValue;
      previousYearValue = currentYearValue;
    } else {
      result[year].value = 0;
    }
  });
  return result;
};

const splitTransaction = (transactionValue, years) => {
  const result = { ...years };
  // Evenly distribute the value over the number of years.
  const selectedYears = pickBy(years, (year) => year.selected === true);
  const yearlyValue = Math.round(
    Number(transactionValue) / Object.keys(selectedYears).length
  );
  forEach(Object.keys(years), (year) => {
    result[year].value = years[year].selected ? yearlyValue : 0;
  });
  return result;
};

export const spreadTransaction = (
  transactionValue,
  transactionTaxYear,
  years,
  spreadByAmount,
  spreadByUnits
) => {
  if (spreadByUnits === 'split') {
    return splitTransaction(transactionValue, years);
  }
  return distributeTransaction(
    transactionValue,
    transactionTaxYear,
    +spreadByAmount,
    spreadByUnits,
    years
  );
};

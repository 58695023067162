import React, { useRef, useState } from 'react';
import { IconButton, TextField, makeStyles } from '@material-ui/core';
import { Clear as ClearIcon, Search as SearchIcon } from '@material-ui/icons';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  root: { width: '100%' },
}));

const FSSearchBar = ({ id, placeholder, onChange }) => {
  const classes = useStyles();
  const inputEl = useRef(null);
  const [isEmpty, setEmpty] = useState(true);

  const search = () => {
    setEmpty(!inputEl.current.value);
    onChange(inputEl.current.value);
  };
  const clear = () => {
    inputEl.current.value = '';
    inputEl.current.focus();
    search();
  };

  const bouncedSearch = debounce(search, 300);

  return (
    <>
      <TextField
        className={classes.root}
        id={id}
        placeholder={placeholder}
        InputProps={{
          inputProps: {
            'aria-label': placeholder,
            maxLength: 30,
          },
          endAdornment: isEmpty ? (
            <IconButton aria-label={placeholder} onClick={search} size='small'>
              <SearchIcon />
            </IconButton>
          ) : (
            <IconButton aria-label='Clear Search' onClick={clear} size='small'>
              <ClearIcon />
            </IconButton>
          ),
        }}
        onChange={bouncedSearch}
        inputRef={inputEl}
      />
    </>
  );
};

FSSearchBar.propTypes = {
  placeholder: PropTypes.string,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

FSSearchBar.defaultProps = {
  placeholder: 'Search',
};

export default FSSearchBar;

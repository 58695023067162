import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  theme: 'light',
  choice: 'app',
};

/* eslint no-param-reassign: ["error", { "ignorePropertyModificationsFor": ["state"] }] */
const prefSlice = createSlice({
  name: 'pref',
  initialState,
  reducers: {
    UPDATE_THEME(state, action) {
      state.theme = action.payload || 'light';
    },
    SET_WEB_SITE_CHOICE(state, action) {
      state.choice = action.payload;
    },
  },
});

export const prefActions = prefSlice.actions;
export default prefSlice.reducer;

/* eslint max-lines: [2, {"max": 200, "skipComments": true, "skipBlankLines": true}] */
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { filter, find, forEach } from 'lodash';

const initialState = {
  isLoadingCanvas: true,
  isSubmitting: false,
  newTransactionDialog: null,
  editTransactionDialog: null,
  deleteTransactionDialog: null,
  splitDistributeTransactionDialog: null,
  splitDistributeOperation: null,
  error: '',
  client: {},
  forecast: {},
  isRunningCalculations: false,
  calculations: {},
  isBusy: false,
  isBusyBackground: false,
  filingStatusByTaxYearChangeData: null,
  needsToCatchUp: false,
  canRollover: false,
  isPreFlightComplete: false,
};

/* eslint no-param-reassign: ["error", { "ignorePropertyModificationsFor": ["state"] }] */
const taxCastSlice = createSlice({
  name: 'taxCast',
  initialState,
  reducers: {
    ERROR(state, action) {
      Object.assign(state, initialState, {
        error: action.payload,
        isBusy: false,
      });
    },
    SILENT_ERROR(state) {
      state.isBusy = false;
      state.isRunningCalculations = false;
      state.isSubmitting = false;
    },
    SUBMITTING_FORM(state) {
      state.isSubmitting = true;
    },
    TOGGLE_NEW_TRANSACTION_DIALOG(state, action) {
      state.newTransactionDialog = action.payload;
      state.isSubmitting = false;
    },
    TOGGLE_EDIT_TRANSACTION_DIALOG(state, action) {
      state.editTransactionDialog = action.payload;
      state.isSubmitting = false;
    },
    TOGGLE_DELETE_TRANSACTION_DIALOG(state, action) {
      state.deleteTransactionDialog = action.payload;
      state.isSubmitting = false;
    },
    ADD_TRANSACTION(state, action) {
      const transaction = action.payload;
      const index = transaction.displaySequence;

      state.forecast.years[transaction.taxYear][transaction.category].splice(
        index,
        0,
        transaction
      );
      toast.success(
        `${action.payload.length} Transaction(s) Successfully Created!`
      );
      state.newTransactionDialog = null;
    },
    ADD_TRANSACTION_SPREAD(state, action) {
      forEach(action.payload, (tx) => {
        state.forecast.years[tx.taxYear][tx.category].splice(
          tx.displaySequence,
          0,
          tx
        );
      });
      toast.success(
        `${action.payload.length} Transaction(s) Successfully Created!`
      );
      state.newTransactionDialog = null;
    },
    EDIT_TRANSACTION(state, action) {
      const data = action.payload;
      const transaction = state.forecast.years[data.taxYear][
        data.category
      ].find((trans) => trans.id === data.id);

      Object.assign(transaction, data);
      toast.success('Transaction Successfully Updated!');
      state.editTransactionDialog = null;
    },
    DELETE_TRANSACTION(state, action) {
      const { id, taxYear, category } = action.payload;
      state.forecast.years[taxYear][category] = filter(
        state.forecast.years[taxYear][category],
        (trans) => trans.id !== id
      );
      toast.success('Transaction Successfully Deleted!');
      state.deleteTransactionDialog = null;
    },
    REORDER_TRANSACTIONS(state, action) {
      const { destination, source, category, id } = action.payload;
      const transaction = find(
        state.forecast.years[source.taxYear][category],
        (trans) => trans.id === id
      );

      state.forecast.years[source.taxYear][category].splice(
        source.displaySequence,
        1
      );
      state.forecast.years[destination.taxYear][category].splice(
        destination.displaySequence,
        0,
        transaction
      );
    },
    LOADING(state) {
      state.isLoadingCanvas = true;
    },
    LOAD_CANVAS(state, action) {
      state.isLoadingCanvas = false;
      state.client = action.payload.client;
      state.forecast = action.payload.forecast;
      state.needsToCatchUp = action.payload.needsToCatchUp;
      state.canRollover = action.payload.canRollover;
    },
    UPDATE_PREFLIGHT_STATUS(state, action) {
      state.isPreFlightComplete = action.payload;
    },
    UNLOAD_FORECAST(state) {
      state.forecast = {};
      state.calculations = {};
      state.needsToCatchUp = false;
      state.canRollover = false;
      state.isPreFlightComplete = false;
    },
    RESET(state) {
      Object.assign(state, initialState);
    },
    RUNNING_CALCULATIONS(state) {
      state.isRunningCalculations = true;
    },
    CALCULATIONS_RESULTS(state, action) {
      state.isRunningCalculations = false;
      state.calculations = action.payload;
    },
    UPDATED_CLIENT_PROFILE(state, action) {
      state.client = { ...state.client, ...action.payload };
    },
    SET_BUSY(state) {
      state.isBusy = true;
    },
    CLEAR_BUSY(state) {
      state.isBusy = false;
    },
    UPDATED_FILING_STATUS_FOR_TAX_YEAR(state, action) {
      // Expected: { taxYear, filingStatus }
      const taxYearIndex = state.forecast.yearList.indexOf(
        action.payload.taxYear
      );
      state.forecast.filingStatusByYearList[taxYearIndex] =
        action.payload.filingStatus;
    },
    SPREAD_TRANSACTIONS(state, action) {
      state.newTransactionDialog = {
        ...state.newTransactionDialog,
        ...action.payload,
      };
    },
    TOGGLE_SPLIT_DISTRIBUTE_TRANSACTION_DIALOG(state, action) {
      state.splitDistributeTransactionDialog = action.payload?.transaction;
      state.splitDistributeOperation = action.payload?.operation;
      state.isSubmitting = false;
    },
  },
});

export const taxCastActions = taxCastSlice.actions;
export default taxCastSlice.reducer;

/* eslint max-lines: [2, {"max": 200, "skipComments": true, "skipBlankLines": true}] */
import React from 'react';
import { Button, Grid, Paper, Typography, makeStyles } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';
import TaxCastLogo from 'assets/img/Logo192x192.png';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.maxWidth,
    [theme.breakpoints.down('lg')]: {
      margin: theme.spacing(1, 3),
    },
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(1, 'auto'),
    },
  },
  paper: {
    minWidth: '100%',
  },
  header: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(1),
  },
  divider: {
    borderRight: `1px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(1),
  },
  rightCell: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
  },
  leftCell: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
    borderRight: `1px solid ${theme.palette.primary.main}`,
  },
}));

const ContactUs = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleClickBack = () => history.goBack();

  return (
    <Grid container item xs={12} className={classes.root} spacing={2}>
      <Grid item xs={12}>
        <Button onClick={handleClickBack}>
          <ArrowBackIcon />
          &nbsp;Back
        </Button>
        <Typography
          variant='h4'
          component='h1'
          color='primary'
          className={classes.title}
        >
          Contact Us
        </Typography>
      </Grid>
      <Paper spacing={2} elevation={3} className={classes.paper}>
        <Grid container>
          <Grid container item xs={12}>
            <Grid item xs={12} md={6} className={classes.leftCell}>
              <img src={TaxCastLogo} alt='TaxCast Logo' />
            </Grid>
            <Grid item xs={12} md={5} className={classes.rightCell}>
              <Grid item xs={12}>
                <Typography component='h5' variant='h5' color='primary'>
                  Help is available via phone or email.
                  <br />
                  <br />
                  We respond to email inquiries within 24 business hours.
                  <br />
                  <a href='mailto: support@taxcastplan.com'>
                    support@taxcastplan.com
                  </a>
                  <br />
                  <br />
                  The phone lines are open 9AM to 4PM ET Monday through Friday.
                  You can leave a message and we will respond within 24 hours.
                  <br />
                  888.482.9227 (888-4-TAXCAST)
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default ContactUs;

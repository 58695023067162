import React, { useEffect, useState } from 'react';
import {
  Backdrop,
  CircularProgress,
  Grid,
  Tab,
  makeStyles,
} from '@material-ui/core';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { FSTabPanel, FSTabs } from 'lib/components/Tabs';
import { GET_CLIENT_DATA, UNLOAD_FORECAST } from 'store/asyncActions/taxCast';
import { accountStatusIsActiveOrUnexpiredTrial } from 'lib/utils/account';

import ClientProfile from './components/ClientProfile';
import TaxCastCanvas from './components/Canvas';
import Analytics from './components/Analytics';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.maxWidth,
    [theme.breakpoints.down('lg')]: {
      margin: theme.spacing(1, 3),
    },
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(1, 'auto'),
    },
  },
  tab: {
    textTransform: 'none',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const TaxCast = () => {
  const classes = useStyles();
  const params = useParams();
  const dispatch = useDispatch();
  const queryString = new URLSearchParams(useLocation().search);
  const [currentTab, setCurrentTab] = useState(
    queryString.get('tab') ? parseInt(queryString.get('tab'), 10) : 1
  );
  const isBusy = useSelector(({ taxCast }) => taxCast.isBusy);
  const taxTableYear = useSelector(({ settings }) => settings.taxTableYear);

  // TODO: Replace when authentication is implemented.
  const advisorId = useSelector(({ auth }) => auth.account.id);
  const account = useSelector(({ auth }) => auth.account);

  const handleChangeTab = (_, newTab) => {
    setCurrentTab(newTab);
  };

  useEffect(() => {
    dispatch(GET_CLIENT_DATA(params.id, taxTableYear));
  }, [advisorId, dispatch, params.id, taxTableYear]);

  useEffect(() => () => dispatch(UNLOAD_FORECAST()), [dispatch]);

  useEffect(() => {
    document.title = `TaxCast - Canvas`;
  }, []);

  if (isBusy) {
    return (
      <Backdrop className={classes.backdrop} open>
        <CircularProgress color='inherit' />
      </Backdrop>
    );
  }

  if (!accountStatusIsActiveOrUnexpiredTrial(account)) {
    return <Redirect to='/auth' />;
  }

  return (
    <>
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <FSTabs currentTab={currentTab} onChange={handleChangeTab}>
            <Tab
              className={classes.tab}
              label='Profile'
              id='client-tab-0'
              aria-controls='client-tabpanel-0'
            />
            <Tab
              className={classes.tab}
              label='TaxCast'
              id='client-tab-1'
              aria-controls='client-tabpanel-1'
            />
            <Tab
              className={classes.tab}
              label='Analytics'
              id='client-tab-2'
              aria-controls='client-tabpanel-2'
            />
          </FSTabs>
        </Grid>

        <Grid item xs={12}>
          <FSTabPanel
            value={currentTab}
            index={0}
            id='client-tabpanel-0'
            labelBy='client-tab-0'
          >
            <ClientProfile
              selectedTab={
                queryString.get('subtab') ? +queryString.get('subtab') : 0
              }
            />
          </FSTabPanel>

          <FSTabPanel
            value={currentTab}
            index={1}
            id='client-tabpanel-1'
            labelBy='client-tab-1'
          >
            <TaxCastCanvas />
          </FSTabPanel>

          <FSTabPanel
            value={currentTab}
            index={2}
            id='client-tabpanel-2'
            labelBy='client-tab-2'
          >
            <Analytics />
          </FSTabPanel>
        </Grid>
      </Grid>
    </>
  );
};

export default TaxCast;

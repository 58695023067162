/* eslint max-lines: [2, {"max": 200, "skipComments": true, "skipBlankLines": true}] */
import React, { useState } from 'react';
import {
  Grid,
  IconButton,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { MoreVert as MoreIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Draggable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';

import { categoryTypes } from 'constants/categories';
import { currencyFormatter } from 'lib/utils/format';
import { taxCastActions } from 'store/slices/taxCast';

import TransactionPopOver from './TransactionPopover';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    margin: theme.spacing(0.3),
    padding: theme.spacing(0.5, 1),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  gridContainer: {
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(18),
    },
  },
  blue: {
    background: theme.taxCast.tiles.incomeColor,
  },
  pink: {
    background: theme.taxCast.tiles.deductionsColor,
  },
  orange: {
    background: theme.taxCast.tiles.capitalGainsColor,
  },
  name: {
    fontSize: '0.9em',
    color: theme.taxCast.primaryTextColor,
  },
  amount: {
    fontSize: '0.7em',
    color: 'grey',
  },
  moreIcon: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    color: theme.taxCast.tiles.moreIcon,
  },
}));

const TaxTile = ({ transaction, type, index }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();

  const togglePopover = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const editTransaction = () => {
    dispatch(taxCastActions.TOGGLE_EDIT_TRANSACTION_DIALOG(transaction));
    setAnchorEl(null);
  };

  const deleteTransaction = () => {
    dispatch(taxCastActions.TOGGLE_DELETE_TRANSACTION_DIALOG(transaction));
    setAnchorEl(null);
  };

  const duplicateTransaction = () => {
    dispatch(
      taxCastActions.TOGGLE_SPLIT_DISTRIBUTE_TRANSACTION_DIALOG({
        transaction,
        operation: 'currency',
      })
    );
    setAnchorEl(null);
  };

  return (
    <Draggable draggableId={transaction.id} type={type} index={index}>
      {(provided) => (
        <Paper
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={provided.draggableProps.style}
          className={clsx(classes.root, {
            [classes.blue]: type === categoryTypes.ordinaryIncome,
            [classes.orange]: type === categoryTypes.capitalGains,
            [classes.pink]: type === categoryTypes.deductions,
          })}
        >
          <Grid container className={classes.gridContainer}>
            <Grid item xs={12}>
              <Typography className={classes.name} noWrap>
                {transaction.name}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>
                  <Typography className={classes.amount}>
                    {currencyFormatter.format(transaction.value)}
                  </Typography>
                </Grid>
                {transaction.subcategory === 'charitableContribution' && (
                  <Grid container item xs={5} justify='flex-end'>
                    <Typography className={classes.amount}>
                      {transaction.charitableContributionClassification}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>

            <IconButton
              size='small'
              className={classes.moreIcon}
              onClick={togglePopover}
            >
              <MoreIcon fontSize='small' />
            </IconButton>

            <TransactionPopOver
              id={transaction.id}
              anchorEl={anchorEl}
              togglePopover={togglePopover}
              editTransaction={editTransaction}
              deleteTransaction={deleteTransaction}
              duplicateTransaction={duplicateTransaction}
              taxYear={transaction.taxYear}
            />
          </Grid>
        </Paper>
      )}
    </Draggable>
  );
};

TaxTile.propTypes = {
  transaction: PropTypes.shape({
    displaySequence: PropTypes.number.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    subcategory: PropTypes.string.isRequired,
    charitableContributionClassification: PropTypes.string,
    taxYear: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
};

export default TaxTile;

/* eslint max-lines: [2, {"max": 500, "skipComments": true, "skipBlankLines": true}] */
import React, { useCallback, useEffect, useState } from 'react';
import { Backdrop, Grid, makeStyles } from '@material-ui/core';
import {
  Point,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryLegend,
  VictoryPortal,
  VictoryStack,
  VictoryTheme,
} from 'victory';

import PropTypes from 'prop-types';
import axios from 'axios';
import { filter, findIndex, forEach, range, sortBy } from 'lodash';

import loading from 'assets/img/BouncingBarsBlack.png';
import { baseUrl } from 'constants/api';
import { currencyFormatter } from 'lib/utils/format';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: '#9C9EA1',
  },
}));

const UnusedOrdinaryBracketCapacity = ({
  clientProfileId,
  forecastId,
  forecast,
  calculations,
  advisorGivenName,
  advisorSurname,
  disclosureA,
  disclosureB,
  disclosureC,
  clientGivenName,
  clientSurname,
}) => {
  const classes = useStyles();
  const [brackets, setBrackets] = useState({});
  const [effectiveRateYValues, setEffectiveRateYValues] = useState({});
  const [isBusyBracketsLoading, setIsBusyBracketsLoading] = useState(true);
  const [isBusyComputingOffsets, setIsBusyComputingOffsets] = useState(true);
  const [
    taxWithinBracketDeltaYValues,
    setTaxWithinBracketDeltaYValues,
  ] = useState({});
  const [fullBracketCountValues, setFullBracketCountValues] = useState({});
  const chartHeight = 300;
  const forecastYearListSubset = forecast.yearList.slice(
    forecast.yearList.indexOf(new Date().getFullYear().toString())
  );

  const loadTaxBrackets = useCallback(() => {
    axios
      .get(
        `${baseUrl}/api/client-profiles/${clientProfileId}/forecasts/${forecastId}/ordinary-tax-brackets`
      )
      .then((response) => {
        // ASSUMPTION: forecast.yearList is in increasing order.
        let newBrackets = {};
        const currentYear = new Date().getFullYear();
        for (let i = 0; i < forecast.yearList.length; i += 1) {
          if (forecast.yearList[i] >= currentYear) {
            const bracketsForTaxYear = filter(response.data, (taxBracket) => {
              return taxBracket.taxYear.toString() === forecast.yearList[i];
            });
            const sortedBracket = sortBy(bracketsForTaxYear, [
              (taxBracket) => {
                return taxBracket.value;
              },
            ]);
            const bracketEntry = {};
            bracketEntry[forecast.yearList[i]] = sortedBracket;
            newBrackets = { ...newBrackets, ...bracketEntry };
            setBrackets(newBrackets);
          }
        }
      })
      .catch(() => {
        // TODO: Handle errors
      });
    setIsBusyBracketsLoading(false);
  }, [clientProfileId, forecast.yearList, forecastId]);

  useEffect(() => {
    // axios
    //   .get(
    //     `${baseUrl}/api/client-profiles/${clientProfileId}/forecasts/${forecastId}/ordinary-tax-brackets`
    //   )
    //   .then((response) => {
    //     // ASSUMPTION: forecast.yearList is in increasing order.
    //     let newBrackets = {};
    //     for (let i = 0; i < forecast.yearList.length; i += 1) {
    //       const bracketsForTaxYear = filter(response.data, (
    //         taxBracket
    //       ) => {
    //         return taxBracket.taxYear.toString() === forecast.yearList[i];
    //       });
    //       const sortedBracket = sortBy(bracketsForTaxYear, [
    //         (taxBracket) => {
    //           return taxBracket.value;
    //         },
    //       ]);
    //       const bracketEntry = {};
    //       bracketEntry[forecast.yearList[i]] = sortedBracket;
    //       newBrackets = { ...newBrackets, ...bracketEntry };
    //       setBrackets(newBrackets);
    //     }
    //   })
    //   .catch(() => {
    //     // TODO: Handle errors
    //   });
    // console.log('ue');
    loadTaxBrackets();
    setIsBusyBracketsLoading(false);
  }, [clientProfileId, forecast.yearList, forecastId, loadTaxBrackets]);

  const computeBarHeightInBracket = useCallback(
    (taxYear, totalAmount) => {
      let result = 0;
      if (taxYear && totalAmount) {
        // a) Obtain the bracket for the ordinary taxable income.
        const bracketIndex = findIndex(brackets[taxYear], (taxBracket) => {
          return (
            taxBracket.maximum > totalAmount && taxBracket.minimum < totalAmount
          );
        });

        if (bracketIndex !== -1) {
          const bracket = brackets[taxYear][bracketIndex];

          // b) Calculate the amount of tax within the bracket.
          const taxAmountWithinBracket = totalAmount - bracket.minimum;

          // c) Determine fraction of amount within the bracket.
          const taxFractionWithinBracket =
            taxAmountWithinBracket / (bracket.maximum - bracket.minimum);
          const tickFractionWithinBracket = taxFractionWithinBracket;
          const tickPosition = tickFractionWithinBracket;

          result = tickPosition;
        } else {
          result = 0; // This is a scenario in which the client pays no taxes.
        }
      }
      return result;
    },
    [brackets]
  );

  const getFullBracketCount = useCallback(
    (taxYear, totalAmount) => {
      let result = 0;
      if (taxYear && totalAmount) {
        // a) Obtain the bracket for the ordinary taxable income.
        const bracketIndex = findIndex(brackets[taxYear], (taxBracket) => {
          return (
            taxBracket.maximum > totalAmount && taxBracket.minimum < totalAmount
          );
        });

        if (bracketIndex !== -1) {
          result = bracketIndex;
        } else {
          result = 0; // This is a scenario in which the client pays no taxes.
        }
      }
      return result;
    },
    [brackets]
  );

  const computeEffectiveRateTick = useCallback(
    (taxYear, effectiveRate, filingStatus) => {
      let result = chartHeight;
      if (taxYear && effectiveRate && filingStatus) {
        // a) Obtain the bracket for the ordinary taxable income.
        let lowerBracketValue = -1;
        let upperBracketValue = -1;
        let bracketsBelow = 0;
        const bracket = brackets[taxYear];
        if (bracket) {
          const filingStatusBracket = bracket.filter(
            (tb) => tb.filingStatus === filingStatus
          );
          let withinBracketIndex = 0;
          let bracketFound = false;
          for (let index = 0; index < filingStatusBracket.length; index += 1) {
            if (
              !bracketFound &&
              effectiveRate <= filingStatusBracket[index].value
            ) {
              withinBracketIndex = index;
              bracketFound = true;
            }
            if (withinBracketIndex === 0) {
              // Lowest Bracket
              upperBracketValue = filingStatusBracket[0].value;
              lowerBracketValue = 0;
              bracketsBelow = 0;
            } else {
              // All other brackets, including the highest bracket also.
              upperBracketValue = filingStatusBracket[withinBracketIndex].value;
              lowerBracketValue =
                filingStatusBracket[withinBracketIndex - 1].value;
              bracketsBelow = withinBracketIndex;
            }
          }

          // console.log('result', effectiveRate, lowerBracketValue, upperBracketValue, bracketsBelow, withinBracketIndex);

          // b) Calculate the amount of tax within the bracket.
          const percentWithinBracket = effectiveRate - lowerBracketValue;

          // c) Determine fraction of amount within the bracket.
          const percentFractionWithinBracket =
            percentWithinBracket / (upperBracketValue - lowerBracketValue);
          const tickFractionWithinBracket =
            bracketsBelow + percentFractionWithinBracket;
          // console.log('interim values', percentWithinBracket, percentFractionWithinBracket, tickFractionWithinBracket);

          const tickPosition = tickFractionWithinBracket;

          // 8 ticks === {chartHeight} pixels
          // {chartHeight}/8 * tickPosition
          const pixelPosition = chartHeight - (chartHeight / 8) * tickPosition;

          // console.log('effectiveRate', effectiveRate, pixelPosition, tickPosition, taxYear);
          result = pixelPosition;
        }
      }
      return result;
    },
    [brackets]
  );

  // const computeTotalTaxLabelDeltaY = useCallback(
  //   (taxYear, totalAmount) => {
  //     let result = 0;
  //     if (taxYear && totalAmount) {
  //       const barHeightInPixels = chartHeight; // Represents y-axis value 0
  //       const maxBracketAmount = 8; // Represents $550,000
  //       const maxChartDollarAmount = 550000; // Represents 8 ticks

  //       // a) Obtain the bracket for the ordinary taxable income.
  //       const bracketIndex = findIndex(brackets[taxYear], (
  //         taxBracket
  //       ) => {
  //         return (
  //           taxBracket.maximum > totalAmount && taxBracket.minimum < totalAmount
  //         );
  //       });

  //       if (bracketIndex !== -1) {
  //         let tickCount = -1;
  //         const bracket = brackets[taxYear][bracketIndex];

  //         // b) Determine the number of ticks across the bracket.
  //         //    If bracket from a) is NOT lowest bracket AND NOT highest bracket
  //         //       Then tickCount <- currentBracketValue - previousBracketValue (e.g. 32 - 24)
  //         //    If bracket from a) is lowest bracket
  //         //       Then tickCount <- bracketValue (e.g. 10)
  //         //    If bracket fgrom a) is highest bracket
  //         //       Then tickCount <- {maxBracketAmount} - bracketValue (e.g. 50 - 37)
  //         // Highest
  //         if (bracketIndex === brackets[taxYear].length - 1) {
  //           tickCount = maxBracketAmount - bracket.value;
  //         }
  //         // Lowest
  //         else if (bracketIndex === 0) {
  //           tickCount = bracket.value;
  //         } else {
  //           tickCount =
  //             bracket.value - brackets[taxYear][bracketIndex - 1].value;
  //         }

  //         // c) Convert ordinary taxable income amount to ticks:
  //         //    If bracket from a) is NOT highest bracket
  //         //       Then (dollars per tick) <- (bracketMax - bracketMin) / {tickCount}
  //         //    Else
  //         //       Then (dollarsPerTick) <- ({maxChartDollarAmount} - bracketMin) / {tickCount}
  //         let dollarsPerTick = 0;
  //         if (bracketIndex !== brackets[taxYear].length - 1) {
  //           dollarsPerTick = (bracket.maximum - bracket.minimum) / tickCount;
  //         } else {
  //           dollarsPerTick =
  //             (maxChartDollarAmount - bracket.minimum) / tickCount;
  //         }

  //         // d) Convert tick value to pixels:
  //         const dollarsPerPixel =
  //           dollarsPerTick / (maxBracketAmount / barHeightInPixels);

  //         // e) Determine the pixel offset.
  //         // Incorporate the bracket minimum tick value.
  //         let tickOffset = 0;
  //         if (bracketIndex !== 0) {
  //           tickOffset =
  //             brackets[taxYear][bracketIndex - 1].value *
  //             (barHeightInPixels / maxBracketAmount);
  //         }
  //         const pixelOffset =
  //           barHeightInPixels - (tickOffset + totalAmount / dollarsPerPixel);

  //         result = pixelOffset;
  //       } else {
  //         result = barHeightInPixels; // This is a scenario in which the client pays no taxes.
  //       }
  //     }
  //     return result - 5; // Adjust the result by 5 pixels for presentation purposes.
  //   },
  //   [brackets]
  // );

  useEffect(() => {
    const deltaYTickList = {};
    const effectiveRateList = {};
    const fullBracketCountList = {};
    const currentYear = new Date().getFullYear();
    forEach(Object.keys(calculations), (taxYear) => {
      if (taxYear >= currentYear) {
        deltaYTickList[taxYear] = computeBarHeightInBracket(
          taxYear,
          calculations[taxYear].ordinaryTaxableIncome
        );
        fullBracketCountList[taxYear] = getFullBracketCount(
          taxYear,
          calculations[taxYear].ordinaryTaxableIncome
        );
        effectiveRateList[taxYear] = computeEffectiveRateTick(
          taxYear,
          calculations[taxYear].effectiveTaxRate,
          calculations[taxYear].filingStatus
        );
      }
    });
    setEffectiveRateYValues(effectiveRateList);
    setIsBusyComputingOffsets(false);
    setTaxWithinBracketDeltaYValues(deltaYTickList);
    setFullBracketCountValues(fullBracketCountList);
  }, [
    clientProfileId,
    forecast.yearList,
    forecastId,
    calculations,
    computeBarHeightInBracket,
    computeEffectiveRateTick,
    getFullBracketCount,
  ]);

  return (
    <Grid container className={classes.root}>
      <Backdrop
        className={classes.backdrop}
        open={isBusyBracketsLoading || isBusyComputingOffsets}
      >
        <img
          src={loading}
          alt='Loading...'
          className='loading-img'
          height='256px'
          width='256px'
        />
      </Backdrop>
      <Grid item xs={12}>
        <h1>
          This report is not quite ready but we are giving you a small taste of
          what we are working to build.
        </h1>
        <h2>
          Exclusively Prepared for {clientGivenName} {clientSurname} by{' '}
          {advisorGivenName} {advisorSurname}
        </h2>
        {!isBusyBracketsLoading && !isBusyComputingOffsets && (
          <div>
            <VictoryChart
              domain={{ x: [0, 10], y: [0, 7] }}
              domainPadding={0}
              theme={VictoryTheme.material}
            >
              <VictoryLegend
                title='Unused Ordinary Bracket Capacity'
                centerTitle
                orientation='horizontal'
                data={[]}
                x={75}
              />
              <VictoryAxis
                tickValues={range(0, 11)}
                tickFormat={['', ...forecastYearListSubset, '']}
                style={{
                  tickLabels: { fontSize: 6 },
                }}
              />
              <VictoryAxis
                dependentAxis
                tickFormat={[
                  '',
                  '10%',
                  '12%',
                  '22%',
                  '24%',
                  '32%',
                  '35%',
                  '37%',
                ]}
                tickValues={[0, 1, 2, 3, 4, 5, 6, 7]}
                style={{
                  tickLabels: { fontSize: 4 },
                }}
                tickLabelComponent={<VictoryLabel dy={12.5} />}
              />
              {forecastYearListSubset.map((taxYear, index) => (
                <VictoryStack key={`bar${taxYear}`}>
                  <VictoryBar
                    data={[
                      { x: index + 1, y: fullBracketCountValues[taxYear] },
                    ]}
                    style={{
                      data: { fill: '#FFB8B8' },
                      labels: { fontSize: 4 },
                    }}
                  />
                  <VictoryBar
                    data={[
                      {
                        x: index + 1,
                        y: taxWithinBracketDeltaYValues[taxYear],
                      },
                    ]}
                    style={{
                      data: { fill: '#FEE6B3' },
                      labels: { fontSize: 4 },
                    }}
                    labels={() =>
                      `${currencyFormatter.format(
                        calculations[taxYear].ordinaryTaxableIncome
                      )}`
                    }
                    labelComponent={
                      <VictoryLabel
                        dy={taxWithinBracketDeltaYValues[taxYear]}
                        dx={12}
                      />
                    }
                  />
                  <VictoryBar
                    data={[
                      {
                        x: index + 1,
                        y:
                          7 -
                          (fullBracketCountValues[taxYear] +
                            taxWithinBracketDeltaYValues[taxYear]),
                      },
                    ]}
                    style={{
                      data: { fill: '#dcedc8' },
                      labels: { fontSize: 4 },
                    }}
                  />
                  <VictoryPortal>
                    <Point
                      symbol='diamond'
                      size={2}
                      x={75 + index * 25}
                      y={effectiveRateYValues[taxYear]}
                      style={{ fill: '#B8E4FF' }}
                    />
                  </VictoryPortal>
                  <VictoryLabel
                    x={80 + index * 25}
                    y={effectiveRateYValues[taxYear]}
                    text={`${Math.round(
                      calculations[taxYear].effectiveTaxRate
                    )}%`}
                    style={[{ fill: '#B8E4FF', fontSize: 4 }]}
                  />
                </VictoryStack>
              ))}
            </VictoryChart>
            <p>
              <b>TaxCast Disclosures</b>
            </p>
            <p>
              The TaxCast&trade; visualization allows taxpayers to consider the
              potential effects of transaction timing across multiple year
              horizons in the hope of optimizing for reduced tax liability. This
              optimization process requires a number of assumptions any one of
              which may or may not be realized. As a result, actual and expected
              results will frequently differ, and those differences may be
              material and significant.
            </p>
            <p>
              TaxCast&trade; makes efforts to use what its authors believe to be
              accepted interpretations of relevant tax provisions in the
              visualizations. However, tax law is subject to amendment in part
              or whole at any time and these amendments can apply both
              prospectively and retroactively. In addition, there can be no
              guarantee the Internal Revenue Service, US Tax Court, or other
              body with jurisdictional authority will continue to follow
              previously issued guidance or concur with what TaxCast&trade;
              understands to be consensus among practitioners. As such, the
              Internal Revenue Code and supporting regulations, rulings, court
              decisions, and other Internal Revenue Service or US Treasury
              Department guidance represent the final authority on the federal
              tax consequences of any given transaction. Any U.S. tax advice
              contained in TaxCast&trade; screen visualizations or printed
              reports was not intended or written to be used, and cannot be
              used, for the purpose of avoiding penalties that may be imposed
              under the Internal Revenue Code or applicable federal, state, or
              local law.
            </p>
            <p>
              TaxCast&trade;’s SmartAudit&trade; performs a number of validation
              checks on selected individual transactions. However,
              TaxCast&trade; makes no guarantee as to the legality or illegality
              of any specific transaction or group of transactions regardless of
              whether TaxCast&trade; allows or suppresses those transactions
              within the visualization and calculations. TaxCast&trade; is not
              intended to replace and should not be viewed as a substitute for
              tax, legal, investment, or financial planning advice rendered by
              competent professionals.
            </p>
            <p>
              <b>
                {advisorGivenName} {advisorSurname} Disclosures
              </b>
            </p>
            <p>{disclosureA}</p>
            <p>{disclosureB}</p>
            <p>{disclosureC}</p>
          </div>
        )}
      </Grid>
    </Grid>
  );
};

UnusedOrdinaryBracketCapacity.propTypes = {
  clientProfileId: PropTypes.string.isRequired,
  forecastId: PropTypes.string.isRequired,
  forecast: PropTypes.objectOf(PropTypes.any).isRequired,
  calculations: PropTypes.objectOf(PropTypes.any).isRequired,
  advisorGivenName: PropTypes.string.isRequired,
  advisorSurname: PropTypes.string.isRequired,
  disclosureA: PropTypes.string,
  disclosureB: PropTypes.string,
  disclosureC: PropTypes.string,
  clientGivenName: PropTypes.string.isRequired,
  clientSurname: PropTypes.string.isRequired,
};

UnusedOrdinaryBracketCapacity.defaultProps = {
  disclosureA: undefined,
  disclosureB: undefined,
  disclosureC: undefined,
};

export default UnusedOrdinaryBracketCapacity;

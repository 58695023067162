import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { DatePicker } from '@material-ui/pickers';
import noop from 'lodash/noop';
import moment from 'moment';

const DatePickerField = ({
  name,
  id,
  label,
  variant,
  required,
  disableToolbar,
  disableFuture,
  disablePast,
  format,
  clearable,
  validate,
}) => {
  return (
    <Field
      id={id}
      name={name}
      validate={validate}
      parse={(value) => moment(value).startOf('day').toISOString()}
      render={({ input, meta }) => {
        const inputConfig = {
          ...input,
          error: !!(meta.touched && meta.error),
          helperText: !!(meta.touched && meta.error) && meta.error,
          value: input.value || null,
        };

        return (
          <DatePicker
            fullWidth
            label={label}
            id={id}
            format={format}
            disableToolbar={disableToolbar}
            disableFuture={disableFuture}
            disablePast={disablePast}
            inputVariant={variant}
            clearable={clearable}
            InputLabelProps={{
              htmlFor: id,
              required,
            }}
            {...inputConfig}
          />
        );
      }}
    />
  );
};

DatePickerField.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  variant: PropTypes.string,
  disableToolbar: PropTypes.bool,
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool,
  clearable: PropTypes.bool,
  required: PropTypes.bool,
  format: PropTypes.string,
  validate: PropTypes.func,
};

DatePickerField.defaultProps = {
  variant: 'outlined',
  disableToolbar: false,
  disableFuture: false,
  disablePast: false,
  clearable: false,
  required: false,
  format: 'MMM Do YYYY',
  validate: noop,
};

export default DatePickerField;

/* eslint-disable import/prefer-default-export */

export const extractTrialDuration = (account) => {
  if (account.accountStatus === 'trial') {
    let result = 14;
    if (
      account.accountStatusReason.toLowerCase().startsWith('extended trial')
    ) {
      const regExp = /\(([^)]+)\)/;
      const matches = regExp.exec(account.accountStatusReason);
      result = +matches[1];
    }
    return result;
  }
  return -1; // Only applies to trial accounts.
};

export const isTrialExpired = (trialStartDate, trialDuration) => {
  const today = new Date();
  const trialExpirationDate = new Date(trialStartDate);
  trialExpirationDate.setDate(trialExpirationDate.getDate() + trialDuration);
  return today > trialExpirationDate;
};

// A convenience method for displaying the number of days remaining in the trial.
export const countTrialDaysRemaining = (trialStartDate, trialDuration) => {
  const trialExpirationDate = new Date(trialStartDate);
  trialExpirationDate.setDate(trialExpirationDate.getDate() + trialDuration);
  const today = new Date();
  return Math.round((trialExpirationDate - today) / (1000 * 60 * 60 * 24));
};

export const accountStatusIsActiveOrUnexpiredTrial = (account) => {
  return (
    account.accountStatus === 'active' ||
    (account.accountStatus === 'trial' &&
      !isTrialExpired(
        account.accountCreateStamp,
        extractTrialDuration(account)
      ))
  );
};

import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { filingStatusDisplayMap } from 'constants/filingStatuses';
import { getAge } from 'lib/utils/dateTimeUtils';

const useStyles = makeStyles((theme) => ({
  header: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    fontSize: '1.2em',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9em',
      width: '90%',
    },
  },
  label: {
    fontSize: '1em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9em',
    },
  },
  value: {
    fontSize: '1em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9em',
    },
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(0, 0, 0.5, 1.5),
    },
  },
}));

const KeyValueGrid = ({ label, value, classes }) => (
  <>
    <Grid item xs={5} lg={12}>
      <Typography className={classes.label} color='primary'>
        {label}:
      </Typography>
    </Grid>

    <Grid item xs={7} lg={12}>
      <Typography className={classes.value}>{value}</Typography>
    </Grid>
  </>
);

KeyValueGrid.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  classes: PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
    .isRequired,
};

const ClientShortProfile = () => {
  const classes = useStyles();
  const client = useSelector(({ taxCast }) => taxCast.client);
  // TODO: Find out why the 'value' prop on Line 75 (77) throws an error but the KVG before it doesn't.
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography color='primary' className={classes.header}>
          Client Profile
        </Typography>
      </Grid>

      <KeyValueGrid
        label='Name'
        value={`${client.givenName} ${client.surname} (${getAge(
          client.birthDate
        )})`}
        classes={classes}
      />
      <KeyValueGrid
        label='Filing Status'
        value={
          client.filingStatus ? filingStatusDisplayMap[client.filingStatus] : ''
        }
        classes={classes}
      />
    </Grid>
  );
};

export default ClientShortProfile;

import React from 'react';
import { useSelector } from 'react-redux';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import PropTypes from 'prop-types';

import getTheme from './theme';

const ThemeProvider = ({ children }) => {
  const theme = useSelector(({ preference }) => preference.theme);

  return (
    <MuiThemeProvider theme={getTheme(theme)}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ThemeProvider;

import React from 'react';
import PropTypes from 'prop-types';

export const ErrorToast = ({ toastTitle, errorMessages }) => {
  return (
    <>
      <b>{toastTitle}</b>
      <ul>
        {errorMessages.map((msg) => (
          <li key={msg}>{msg}</li>
        ))}
      </ul>
    </>
  );
};

ErrorToast.propTypes = {
  toastTitle: PropTypes.string,
  errorMessages: PropTypes.arrayOf(PropTypes.string).isRequired,
};

ErrorToast.defaultProps = {
  toastTitle: 'The following error(s) occurred:',
};

export default ErrorToast;

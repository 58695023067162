// import { toast } from 'react-toastify';
import { createSlice } from '@reduxjs/toolkit';
import { pick } from 'lodash';
import axios from 'axios';

// In this context, the following distinctions are made:
// user - the Azure AD B2C identity information
// account - the Canvilytics (TaxCast) account information
const initialState = {
  user: {},
  accessToken: '',
  account: {},
};

/* eslint no-param-reassign: ["error", { "ignorePropertyModificationsFor": ["state"] }] */
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    LOAD_ACCOUNT(state, action) {
      state.account = action.payload;
      axios.defaults.headers.common['x-taxcast-user-id'] = action.payload.id;
    },
    UPDATE_ACCOUNT_STATUS(state, action) {
      // Although .accountStatus SHOULD BE used throughout,
      // set .accountStatusValue to match for consistency.
      state.account.accountStatusValue = action.payload.newStatus;
      state.account.accountStatus = action.payload.newStatus;
      state.account.accountStatusReason = action.payload.newStatusReason;
    },
    AADB2C_LOGIN_SUCCESS(state, action) {
      const emailAddress = action.payload.emails[0];
      state.user = pick(action.payload, [
        'family_name',
        'given_name',
        'name',
        'oid',
      ]);
      state.user = {
        ...state.user,
        preferredEmailAddress: emailAddress,
        isNew: action.payload.newUser ?? true,
      };
    },
    AADB2C_LOGOUT_SUCCESS(state) {
      window.sessionStorage.setItem('taxcast.auth.accessToken', null);
      Object.assign(state, initialState);
    },
  },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;

/* eslint max-lines: [2, {"max": 250, "skipComments": true, "skipBlankLines": true}] */
import React, { useEffect, useState } from 'react';
import {
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useHistory } from 'react-router-dom';

import { countBy, sortBy, sortedUniqBy } from 'lodash';
import axios from 'axios';
import { toast } from 'react-toastify';

import SearchBar from 'lib/components/SearchBar';
import * as glossaryApi from 'api/glossary';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.maxWidth,
    [theme.breakpoints.down('lg')]: {
      margin: theme.spacing(1, 1),
    },
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(1, 'auto'),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  row: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
}));

const Glossary = () => {
  const classes = useStyles();
  const history = useHistory();
  const [glossaryEntries, setGlossaryEntries] = useState([]);
  const [selectedEntry, setSelectedEntry] = useState();
  const [filteredEntries, setFilteredEntries] = useState([]);
  const [quickFilterList, setQuickFilterList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const buildQuickFilterList = (entries) => {
    const firstValueList = entries
      .map((entry) => {
        return Number(entry.term.substring(0, 1))
          ? '#'
          : entry.term.substring(0, 1).toUpperCase();
      })
      .sort();
    let sortedUniqueList = sortedUniqBy(firstValueList);
    if (sortedUniqueList.length > 28) {
      // 28 === "All", "#", plus all 26 letters of the Phoenician alphabet.
      const occurrenceCountObject = countBy(firstValueList);
      const sortedOccurrenceCountList = Object.keys(occurrenceCountObject).sort(
        (x, y) => occurrenceCountObject[y] - occurrenceCountObject[x]
      );
      sortedOccurrenceCountList.splice(9);
      sortedUniqueList = [...sortedOccurrenceCountList.sort()];
    }
    sortedUniqueList.splice(0, 0, 'All');
    setQuickFilterList(sortedUniqueList);
  };

  useEffect(() => {
    let unmounted = false;
    const tokenSource = axios.CancelToken.source();
    // Load the glossary from the server.
    glossaryApi
      .getGlossaryEntryList()
      .then((response) => {
        if (!unmounted) {
          // Sort the glossary by term.
          const entries = sortBy(response.data, (item) => {
            return item.term.toLowerCase();
          });
          setGlossaryEntries(entries);
          setFilteredEntries(entries);
          buildQuickFilterList(entries);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        if (!unmounted) {
          toast.error(`An error occurred: ${error}`, { autoClose: false });
          setIsLoading(false);
        }
      });

    return () => {
      unmounted = true;
      tokenSource.cancel('unmounted');
    };
  }, []);

  // A helper function to allow interactive filtering by typing in the 'search' field.
  // NOTE: This algorithm performs a 'startsWith' comparison.
  const filterGlossaryEntries = (filterValue) => {
    let filteredList = [...glossaryEntries];
    if (filterValue && filterValue.length > 0) {
      if (filterValue === '#') {
        filteredList = glossaryEntries.filter((entry) => {
          return Number(entry.term.substring(0, 1));
        });
      } else {
        filteredList = glossaryEntries.filter((entry) => {
          return entry.term.toLowerCase().startsWith(filterValue.toLowerCase());
        });
      }
    }
    setFilteredEntries([
      ...sortBy(filteredList, (item) => item.term.toLowerCase()),
    ]);
  };

  const handleQuickFilterButtonClick = (quickFilter) => {
    filterGlossaryEntries(quickFilter === 'All' ? '' : quickFilter);
  };

  const handleListItemClick = (id) => {
    const foundEntry = glossaryEntries.find((entry) => {
      return entry.id === id;
    });
    if (foundEntry && selectedEntry) {
      if (foundEntry.id === selectedEntry.id) {
        // Close
        setSelectedEntry(null);
      } else {
        // Open
        setSelectedEntry(foundEntry);
      }
    } else {
      setSelectedEntry(foundEntry);
    }
  };

  const handleClickBack = () => history.goBack();

  return (
    <>
      {isLoading && (
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color='inherit' />
        </Backdrop>
      )}
      {!isLoading && (
        <Grid container justify='center' className={classes.root}>
          <Grid item xs={12}>
            <Button onClick={handleClickBack}>
              <ArrowBackIcon />
              &nbsp;Back
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Paper padding={3}>
              <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12}>
                  <SearchBar
                    id='glossarySearchBar'
                    placeholder={`filter ${glossaryEntries.length} entries`}
                    onChange={filterGlossaryEntries}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ButtonGroup variant='text' size='small'>
                    {quickFilterList.map((item) => (
                      <Button
                        key={`quickFilterButton-${item}`}
                        id={`quickFilterButton-${item}`}
                        value={item}
                        onClick={() => handleQuickFilterButtonClick(item)}
                      >
                        {item}
                      </Button>
                    ))}
                  </ButtonGroup>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid container className={classes.root}>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table aria-label='collapsible table'>
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell>Term</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredEntries.map((item) => (
                      <React.Fragment key={item.id}>
                        <TableRow className={classes.root}>
                          <TableCell>
                            <IconButton
                              aria-label='expand row'
                              size='small'
                              onClick={() => handleListItemClick(item.id)}
                            >
                              {selectedEntry && selectedEntry.id === item.id ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </IconButton>
                          </TableCell>
                          <TableCell component='th' scope='row' width='100%'>
                            {item.term}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                            colSpan={6}
                          >
                            <Collapse
                              in={selectedEntry && selectedEntry.id === item.id}
                              timeout='auto'
                              unmountOnExit
                            >
                              <Box margin={1}>
                                <Table size='small' aria-label='purchases'>
                                  <TableBody>
                                    <TableRow className={classes.row}>
                                      <TableCell component='th' scope='row'>
                                        {item.description}
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Glossary;

import React, { useEffect, useState } from 'react';
import {
  Grid,
  InputAdornment,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import { validAmount } from 'lib/utils/form';
import { isNullEmptyOrBlank, isValidAmount } from 'lib/utils/validation';
import { currencyFormatter } from 'lib/utils/format';

const useStyles = makeStyles((theme) => ({
  field: {
    minHeight: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const LongTermCapitalGainsForm = ({
  salePrice,
  basisAmount,
  onDataUpdate,
  onValidate,
}) => {
  const classes = useStyles();
  const [computedValue, setComputedValue] = useState('');
  const [isErrorBasisAmount, setIsErrorBasisAmount] = useState(false);
  const [isErrorSalePrice, setIsErrorSalePrice] = useState(false);
  const [data, setData] = useState({
    salePrice,
    basisAmount,
  });

  useEffect(() => {
    onDataUpdate(data);
    setComputedValue(data.salePrice - data.basisAmount);
  }, [data, onDataUpdate, computedValue]);

  const handleInputChangeLongTermCapitalGains = (event) => {
    if (event.target.id === 'salePrice') {
      setIsErrorSalePrice(
        isNullEmptyOrBlank(event.target.value) ||
          !isValidAmount(event.target.value)
      );
    }
    if (event.target.id === 'basisAmount') {
      setIsErrorBasisAmount(
        isNullEmptyOrBlank(event.target.value) ||
          !isValidAmount(event.target.value)
      );
    }
    const newData = { ...data };
    newData[event.target.name] = +event.target.value;
    setData(newData);
    onDataUpdate(newData);
    const isValidForm =
      !isNullEmptyOrBlank(newData.salePrice) &&
      isValidAmount(newData.salePrice) &&
      !isNullEmptyOrBlank(newData.basisAmount) &&
      isValidAmount(newData.basisAmount);
    onValidate(isValidForm);
    setComputedValue(
      isValidForm ? newData.salePrice - newData.basisAmount : ''
    );
  };

  return (
    <>
      <Grid item xs={12} className={classes.field}>
        <TextField
          name='salePrice'
          id='salePrice'
          label='Sale Price'
          fullWidth
          value={data.salePrice}
          onChange={handleInputChangeLongTermCapitalGains}
          error={isErrorSalePrice}
          helperText={validAmount(salePrice)}
          required
          InputProps={{
            startAdornment: <InputAdornment position='start'>$</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12} className={classes.field}>
        <TextField
          name='basisAmount'
          id='basisAmount'
          label='Basis Amount'
          fullWidth
          value={data.basisAmount}
          onChange={handleInputChangeLongTermCapitalGains}
          error={isErrorBasisAmount}
          helperText={validAmount(basisAmount)}
          InputProps={{
            startAdornment: <InputAdornment position='start'>$</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12} className={classes.capitalGainsIcons}>
        <Typography>
          Computed Transaction Value: {currencyFormatter.format(computedValue)}
        </Typography>
      </Grid>
    </>
  );
};

LongTermCapitalGainsForm.propTypes = {
  salePrice: PropTypes.number.isRequired,
  basisAmount: PropTypes.number.isRequired,
  onDataUpdate: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
};

LongTermCapitalGainsForm.defaultProps = {};

export default LongTermCapitalGainsForm;

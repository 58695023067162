/* eslint max-lines: [2, {"max": 200, "skipComments": true, "skipBlankLines": true}] */
import React from 'react';
import {
  Backdrop,
  CircularProgress,
  Grid,
  makeStyles,
} from '@material-ui/core';
import { DragDropContext } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual, split } from 'lodash';

import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from 'lib/utils/authConfig';

import {
  MOVE_TRANSACTION,
  NEW_TRANSACTION_DIALOG,
} from 'store/asyncActions/taxCast';

import BusySignal from 'lib/components/BusySignal';
import ItsDeadJim from 'modules/ItsDeadJim';
import TaxCastToolBox from './TaxCastToolBox';
import TaxCastCanvas from './TaxCastCanvas';
import TrendRibbon from './TrendRibbon';
import NewTransactionDialog from './dialogs/NewTransaction';
import EditTransactionDialog from './dialogs/EditTransaction';
import DeleteTransactionDialog from './dialogs/DeleteTransaction';
import SplitDistributeTransactionDialog from './dialogs/SplitDistributeTransaction';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(0.5),
  },
  columnOrder: {
    order: 2,
  },
  trendRibbon: {
    order: 1,
    [theme.breakpoints.up('lg')]: {
      order: 3,
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const ClientTaxCast = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const forecastId = useSelector(({ taxCast }) => taxCast.forecast.id);
  const clientProfileId = useSelector(({ taxCast }) => taxCast.client.id);
  const isBusy = useSelector(({ taxCast }) => taxCast.isBusy);

  // TODO: Remove when authentication is implemented.
  const advisorId = useSelector(({ taxCast }) => taxCast.forecast.advisorId);

  const createTransaction = (event) => {
    dispatch(NEW_TRANSACTION_DIALOG(event, forecastId, clientProfileId));
  };

  const moveTransaction = (event) => {
    const source = {
      taxYear: split(event.source.droppableId, '-')[0],
      displaySequence: event.source.index,
    };
    const destination = {
      taxYear: split(event.destination.droppableId, '-')[0],
      displaySequence: event.destination.index,
    };

    const category = split(event.destination.droppableId, '-')[1];

    if (!isEqual(source, destination)) {
      dispatch(
        MOVE_TRANSACTION({
          destination,
          source,
          category,
          id: event.draggableId,
          forecastId,
          clientProfileId,
          advisorId,
        })
      );
    }
  };

  const handleDragEvent = (event) => {
    if (event.destination) {
      // Only allow dropping on current and future years,
      // including both creating and moving transactions.
      const currentYear = new Date().getFullYear();
      if (split(event.destination.droppableId, '-')[0] >= currentYear) {
        if (event.draggableId.match(/toolbox-/)) {
          createTransaction(event);
        } else if (event.source) {
          if (split(event.source.droppableId, '-')[0] >= currentYear) {
            moveTransaction(event);
          }
        }
      }
    }
  };

  return (
    <>
      {/* <Backdrop className={classes.backdrop} open={isBusy}>
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <NewTransactionDialog />
      <SplitDistributeTransactionDialog />
      <EditTransactionDialog />
      <DeleteTransactionDialog />
      <Backdrop className={classes.backdrop} open={isBusy}>
        <CircularProgress color='primary' />
      </Backdrop>
      <Grid container className={classes.root} spacing={1}>
        <DragDropContext onDragEnd={handleDragEvent}>
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Popup}
            authenticationRequest={loginRequest}
            errorComponent={ItsDeadJim}
            loadingComponent={BusySignal}
          >
            <Grid item xs={12} sm={3} lg={2} className={classes.columnOrder}>
              <TaxCastToolBox />
            </Grid>

            <Grid item xs={12} sm={9} lg={8} className={classes.columnOrder}>
              <TaxCastCanvas />
            </Grid>
          </MsalAuthenticationTemplate>
        </DragDropContext>

        <Grid item xs={12} sm={12} lg={2} className={classes.trendRibbon}>
          <TrendRibbon />
        </Grid>
      </Grid>
    </>
  );
};

export default ClientTaxCast;

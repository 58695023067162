import React, { useState } from 'react';
import { Button, Grid, TextField, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import * as advisorApi from 'api/advisor';
import { shapeErrorsForToast } from 'lib/utils/errorUtil';
import { ErrorToast } from 'lib/components/ErrorToast';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.maxWidth,
    padding: theme.spacing(3, 3),
  },
  disclosure: {
    padding: theme.spacing(1),
  },
}));

const Disclosures = ({
  affiliateId,
  advisorId,
  advisorDisclosures,
  onSave,
}) => {
  const classes = useStyles();
  const [disclosures, setDisclosures] = useState({ ...advisorDisclosures });
  const maxDisclosureLength = 4000;

  const onClickCancel = () => {
    setDisclosures(advisorDisclosures);
  };

  const onClickSave = () => {
    advisorApi
      .updateAdvisorDisclosures(affiliateId, advisorId, disclosures)
      .then(() => {
        toast.success(`Successfully updated disclosures.`);
        onSave(disclosures);
      })
      .catch((error) => {
        toast.error(<ErrorToast errorMessages={shapeErrorsForToast(error)} />, {
          autoClose: false,
        });
      });
  };

  const onChangeDisclosure = (event) => {
    const disclosureId = event.target.id.replace('disclosure', '');
    if (event.target.value.length <= maxDisclosureLength) {
      const newDisclosures = { ...disclosures };
      newDisclosures[disclosureId] = event.target.value;
      setDisclosures({ ...newDisclosures });
    }
  };

  return (
    <Grid container className={classes.root}>
      {Object.keys(disclosures).map((item) => (
        <Grid
          container
          item
          xs={12}
          className={classes.disclosure}
          key={`disclosure${item}`}
        >
          <TextField
            id={`disclosure${item}`}
            fullWidth
            multiline
            rows={6}
            rowsMax={6}
            variant='outlined'
            label={`Disclosure ${item.replace('disclosure', '')} (${
              disclosures[item].length
            } of ${maxDisclosureLength} characters)`}
            onChange={onChangeDisclosure}
            value={disclosures[item]}
          />
        </Grid>
      ))}
      <Grid
        container
        item
        xs={12}
        justify='flex-end'
        className={classes.disclosure}
      >
        <Button
          variant='outlined'
          color='primary'
          onClick={onClickCancel}
          style={{ marginRight: 5 }}
        >
          Cancel
        </Button>
        <Button variant='contained' color='primary' onClick={onClickSave}>
          Save
        </Button>
      </Grid>
    </Grid>
  );
};

Disclosures.propTypes = {
  affiliateId: PropTypes.string.isRequired,
  advisorId: PropTypes.string.isRequired,
  advisorDisclosures: PropTypes.shape({
    dislcosureA: PropTypes.string,
    disclosureB: PropTypes.string,
    disclosureC: PropTypes.string,
  }).isRequired,
  onSave: PropTypes.func.isRequired,
};

Disclosures.defaultProps = {};

export default Disclosures;

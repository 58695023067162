/* eslint max-lines: [2, {"max": 625, "skipComments": true, "skipBlankLines": true}] */
import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Switch,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons';

import axios from 'axios';
import { Redirect, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { baseUrl } from 'constants/api';
import { phoneNumberFormatter } from 'lib/utils/format';
import { shapeErrorsForToast } from 'lib/utils/errorUtil';
import { ErrorToast } from 'lib/components/ErrorToast';
import { accountStatusIsActiveOrUnexpiredTrial } from 'lib/utils/account';

import TemplatesList from './components/TemplatesList';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.maxWidth,
    [theme.breakpoints.down('lg')]: {
      margin: theme.spacing(1, 3),
    },
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(1, 'auto'),
    },
  },
  criteria: {
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(1, 'auto'),
    },
    [theme.breakpoints.down('lg')]: {
      margin: theme.spacing(1, 'auto'),
    },
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(1, 'auto'),
    },
    maxWidth: 800,
  },
  results: {
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(1, 'auto'),
    },
    [theme.breakpoints.down('lg')]: {
      margin: theme.spacing(1, 3),
    },
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(1, 'auto'),
    },
    maxWidth: 800,
  },
  resultsList: {
    width: '100%',
  },
  form: {
    padding: theme.spacing(0),
  },
  field: {
    [theme.breakpoints.up('sm')]: {
      minHeight: theme.spacing(12),
    },
  },
}));

const ClientSearch = () => {
  const history = useHistory();
  const classes = useStyles();
  const [useAdvancedSearch, setUseAdvancedSearch] = useState(false); // Default to simple search.
  const [searchResults, setSearchResults] = useState([]);
  const [simpleCriteria, setSimpleCriteria] = useState('');
  const [advancedCriteria, setAdvancedCriteria] = useState({
    useAdvancedSearch: true,
    givenName: '',
    surname: '',
    emailAddress: '',
    phoneNumber: '',
    addressLineOne: '',
    addressCity: '',
    addressStateOrProvince: '',
    addressPostalCode: '',
    birthDate: '',
  });
  const [revealedId, setRevealedId] = useState();
  const mask = '*************';
  const [canSearch, setCanSearch] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchComplete, setSearchComplete] = useState(false);
  const [isErrorSimpleSearch, setIsErrorSimpleSearch] = useState(false);
  const minCriterionLength = 3;
  const [revealAll, setReveaAll] = useState(false);
  const account = useSelector(({ auth }) => auth.account);

  useEffect(() => {
    document.title = 'TaxCast - Search';
  }, []);

  const handleToggleSearchType = (event) => {
    setUseAdvancedSearch(event.target.checked);
  };

  useEffect(() => {
    if (useAdvancedSearch) {
      let value = false;
      value = value || advancedCriteria.givenName.trim().length > 0;
      value = value || advancedCriteria.surname.trim().length > 0;
      value = value || advancedCriteria.emailAddress.trim().length > 0;
      value = value || advancedCriteria.phoneNumber.trim().length > 0;
      value = value || advancedCriteria.addressLineOne.trim().length > 0;
      value = value || advancedCriteria.addressCity.trim().length > 0;
      value =
        value || advancedCriteria.addressStateOrProvince.trim().length > 0;
      value = value || advancedCriteria.addressPostalCode.trim().length > 0;
      value = value || advancedCriteria.birthDate.trim().length > 0;
      setCanSearch(value);
    } else {
      setCanSearch(simpleCriteria.trim().length > 0);
    }
  }, [useAdvancedSearch, simpleCriteria, advancedCriteria, canSearch]);

  const handleInputChange = (event) => {
    switch (event.target.id) {
      case 'simpleCriteria':
        setSimpleCriteria(event.target.value);
        break;
      default:
        setAdvancedCriteria({
          ...advancedCriteria,
          [event.target.name]: event.target.value,
        });
        break;
    }
  };

  const handleClickSearch = () => {
    if (!useAdvancedSearch && simpleCriteria.length < minCriterionLength) {
      setIsErrorSimpleSearch(true);
    } else {
      setIsSearching(true);
      const searchCriteria = useAdvancedSearch
        ? advancedCriteria
        : {
            criterion: simpleCriteria,
          };

      axios
        .post(`${baseUrl}/api/client-profiles/search`, searchCriteria)
        .then((response) => {
          setSearchComplete(true);
          setSearchResults(
            response.data.filter((sr) => sr.isTemplate === false)
          );
        })
        .catch((error) => {
          setSearchComplete(false);
          toast.error(
            <ErrorToast errorMessages={shapeErrorsForToast(error)} />,
            {
              autoClose: false,
            }
          );
        })
        .finally(() => {
          setRevealedId('');
          setIsSearching(false);
        });
    }
  };

  const handleOnKeyDown = (event) => {
    if (event.keyCode === 13 && simpleCriteria.length >= minCriterionLength) {
      // <enter> key
      handleClickSearch();
    }
  };

  const handleToggleReveal = (id) => {
    if (revealedId === id) {
      setRevealedId(); // Basically, hide the currently revealed item.
    } else {
      setRevealedId(id);
    }
  };

  const handleClickClear = () => {
    setSearchResults([]);
    setSimpleCriteria('');
    setAdvancedCriteria({
      useAdvancedSearch: true,
      givenName: '',
      surname: '',
      emailAddress: '',
      phoneNumber: '',
      addressLineOne: '',
      addressCity: '',
      addressStateOrProvince: '',
      addressPostalCode: '',
      birthDate: '',
    });
    setReveaAll(false);
    setRevealedId('');
  };

  const handleClickNewClient = () => history.push('/clients/new');

  const handleToggleRevealAll = () => {
    const nextState = !revealAll;
    setReveaAll(nextState);
    // If `revealAll` is toggled off (that is, set to Hide All)
    // need to reset the revealedId as well to truly hide all items.
    // nextState === false => will hide
    if (!nextState) {
      setRevealedId('');
    }
  };

  if (!accountStatusIsActiveOrUnexpiredTrial(account)) {
    return <Redirect to='/auth' />;
  }

  return (
    <Grid container item xs={12} className={classes.root} spacing={2}>
      <Grid container item xs={12} sm={8}>
        <Grid container className={classes.criteria}>
          <Paper spacing={1} elevation={3}>
            <Grid container className={classes.form}>
              <Grid container item xs={12} spacing={2} padding={2}>
                <Grid item xs={12} sm={8}>
                  <Typography component='h4' variant='h4' color='primary'>
                    Search
                  </Typography>
                </Grid>
                <Grid container item xs={12} sm={4} justify='flex-end'>
                  <FormControlLabel
                    label='Advanced Search'
                    control={
                      <Switch
                        checked={useAdvancedSearch}
                        onChange={handleToggleSearchType}
                        name='switchSearchType'
                        color='primary'
                      />
                    }
                  />
                </Grid>
              </Grid>
              {!useAdvancedSearch && (
                <Grid
                  container
                  justify='center'
                  spacing={2}
                  className={classes.criteria}
                >
                  <Grid item xs={12}>
                    <TextField
                      name='simpleCriteria'
                      id='simpleCriteria'
                      label='Criteria'
                      autoFocus
                      fullWidth
                      variant='outlined'
                      onChange={handleInputChange}
                      value={simpleCriteria}
                      error={isErrorSimpleSearch}
                      helperText='Minimum 3 characters'
                      onKeyDown={handleOnKeyDown}
                    />
                  </Grid>
                  <Grid container item xs={12} justify='flex-end' spacing={3}>
                    <Button
                      className={classes.button}
                      variant='contained'
                      color='primary'
                      onClick={handleClickNewClient}
                    >
                      New Client
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      className={classes.button}
                      variant='outlined'
                      color='primary'
                      onClick={handleClickClear}
                    >
                      Clear
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      className={classes.button}
                      variant='contained'
                      color='primary'
                      onClick={handleClickSearch}
                      disabled={!canSearch || isSearching}
                    >
                      Search
                    </Button>
                  </Grid>
                </Grid>
              )}
              {useAdvancedSearch && (
                <Grid
                  container
                  justify='center'
                  spacing={2}
                  className={classes.criteria}
                >
                  <Grid item xs={12} sm={4} className={classes.field}>
                    <TextField
                      name='givenName'
                      id='givenName'
                      label='First Name'
                      variant='outlined'
                      onChange={handleInputChange}
                      fullWidth
                      value={advancedCriteria.givenName}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} className={classes.field}>
                    <TextField
                      name='surname'
                      id='surname'
                      label='Last Name'
                      variant='outlined'
                      onChange={handleInputChange}
                      fullWidth
                      value={advancedCriteria.surname}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} className={classes.field}>
                    <TextField
                      name='birthDate'
                      id='birthDate'
                      label='Birth Date'
                      variant='outlined'
                      onChange={handleInputChange}
                      fullWidth
                      value={advancedCriteria.birthDate}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} className={classes.field}>
                    <TextField
                      name='phoneNumber'
                      id='phoneNumber'
                      label='Phone'
                      variant='outlined'
                      onChange={handleInputChange}
                      fullWidth
                      value={advancedCriteria.phoneNumber}
                    />
                  </Grid>

                  <Grid item xs={12} sm={8} className={classes.field}>
                    <TextField
                      name='emailAddress'
                      id='emailAddress'
                      label='Email'
                      variant='outlined'
                      onChange={handleInputChange}
                      fullWidth
                      value={advancedCriteria.emailAddress}
                    />
                  </Grid>

                  <Grid container item xs={12} className={classes.field}>
                    <TextField
                      name='addressLineOne'
                      id='addressLineOne'
                      label='Address Line 1'
                      variant='outlined'
                      onChange={handleInputChange}
                      fullWidth
                      value={advancedCriteria.addressLineOne}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} className={classes.field}>
                    <TextField
                      name='addressCity'
                      id='addressCity'
                      label='City'
                      variant='outlined'
                      onChange={handleInputChange}
                      fullWidth
                      value={advancedCriteria.addressCity}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} className={classes.field}>
                    <TextField
                      name='addressStateOrProvince'
                      id='addressStateOrProvince'
                      label='State'
                      variant='outlined'
                      onChange={handleInputChange}
                      fullWidth
                      value={advancedCriteria.addressStateOrProvince}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} className={classes.field}>
                    <TextField
                      name='addressPostalCode'
                      id='addressPostalCode'
                      label='Postal Code'
                      variant='outlined'
                      onChange={handleInputChange}
                      fullWidth
                      value={advancedCriteria.addressPostalCode}
                    />
                  </Grid>

                  <Grid container item xs={12} justify='flex-end'>
                    <Button
                      className={classes.button}
                      variant='contained'
                      color='primary'
                      onClick={handleClickNewClient}
                    >
                      New Client
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      className={classes.button}
                      variant='outlined'
                      color='primary'
                      onClick={handleClickClear}
                    >
                      Clear
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      type='submit'
                      color='primary'
                      variant='contained'
                      onClick={handleClickSearch}
                      disabled={!canSearch}
                    >
                      Search
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {searchComplete && (
              <Grid container className={classes.form}>
                <Grid container item xs={12} spacing={2}>
                  <Grid container item xs={12}>
                    <Typography component='h4' variant='h4' color='primary'>
                      {`Found ${searchResults.length} ${
                        searchResults.length !== 1 ? 'items' : 'item'
                      }`}
                    </Typography>
                    &nbsp;&nbsp;&nbsp;
                    <Button
                      color='primary'
                      variant='outlined'
                      onClick={handleToggleRevealAll}
                      disabled={searchResults.length === 0}
                    >
                      {revealAll ? `Hide` : `Show`} All
                    </Button>
                  </Grid>
                </Grid>
                {searchResults.length > 10 && (
                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12}>
                      <Typography
                        component='span'
                        variant='body2'
                        color='textPrimary'
                      >
                        Your search returned {searchResults.length} items.
                        Consider refining your results and trying again.
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                <Grid container item xs={12} spacing={2}>
                  <Grid item xs={12}>
                    <List>
                      {searchResults.map((result) => (
                        <ListItem
                          key={result.id}
                          button
                          onClick={() => history.push(`/clients/${result.id}`)}
                          component='li'
                        >
                          <ListItemAvatar>
                            <Avatar
                              alt={`${result.givenName
                                .substring(0, 1)
                                .toUpperCase()}${result.surname
                                .substring(0, 1)
                                .toUpperCase()}`}
                            >
                              {result.givenName.substring(0, 1).toUpperCase()}
                              {result.surname.substring(0, 1).toUpperCase()}
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              revealedId === result.id || revealAll
                                ? `${result.givenName} ${result.surname}`
                                : `${mask} ${mask}`
                            }
                            secondary={
                              <>
                                <Typography
                                  component='span'
                                  variant='body2'
                                  className={classes.inline}
                                  color='textPrimary'
                                >
                                  {revealedId === result.id || revealAll
                                    ? `${result.preferredEmailAddress}`
                                    : `${mask}`}
                                </Typography>
                                <br />
                                <Typography
                                  component='span'
                                  variant='body2'
                                  className={classes.inline}
                                  color='textPrimary'
                                >
                                  {revealedId === result.id || revealAll
                                    ? `${phoneNumberFormatter(
                                        result.preferredPhoneNumber
                                      )}`
                                    : `${mask}`}
                                </Typography>
                                <br />
                                <Typography
                                  component='span'
                                  variant='body2'
                                  className={classes.inline}
                                  color='textPrimary'
                                >
                                  {revealedId === result.id || revealAll
                                    ? result.preferredAddressLineOne
                                    : `${mask}`}
                                </Typography>
                                <br />
                                <Typography
                                  component='span'
                                  variant='body2'
                                  className={classes.inline}
                                  color='textPrimary'
                                >
                                  {revealedId === result.id || revealAll
                                    ? `${result.preferredAddressCity} ${result.preferredAddressStateOrProvince} ${result.preferredAddressPostalCode}`
                                    : `${mask} ${mask} ${mask}`}
                                </Typography>
                              </>
                            }
                          />
                          <ListItemSecondaryAction>
                            {!revealAll && (
                              <IconButton
                                edge='end'
                                aria-label='toggle show and hide'
                                onClick={() => handleToggleReveal(result.id)}
                              >
                                {result.id === revealedId && (
                                  <VisibilityOffIcon />
                                )}
                                {result.id !== revealedId && <VisibilityIcon />}
                              </IconButton>
                            )}
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Paper>
        </Grid>
      </Grid>
      <Grid container item xs={12} sm={4}>
        <Grid container className={classes.criteria}>
          <Paper spacing={1} elevation={3}>
            <TemplatesList />
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ClientSearch;

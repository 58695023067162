/* eslint max-lines: [2, {"max": 350, "skipComments": true, "skipBlankLines": true}] */
import React, { useEffect, useState } from 'react';
import {
  Backdrop,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { pick } from 'lodash';
import { toast } from 'react-toastify';

import * as clientApi from 'api/client';
import { shapeErrorsForToast } from 'lib/utils/errorUtil';
import { ErrorToast } from 'lib/components/ErrorToast';

import OtherDependents from './OtherDependents';
import Profile from './Profile';
import SpousePartner from './SpousePartner';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.maxWidth,
    // [theme.breakpoints.down('lg')]: {
    //   margin: theme.spacing(1, 3),
    // },
    // [theme.breakpoints.up('lg')]: {
    //   margin: theme.spacing(1, 'auto'),
    // },
  },
  leftColumn: {
    [theme.breakpoints.up('md')]: {
      paddingRight: 16,
    },
  },
  middleColumn: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 16,
    },
  },
  rightColumn: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: 16,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 16,
    },
  },
  header: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(1),
  },
}));

const ClientProfile = () => {
  const classes = useStyles();
  const params = useParams();
  const clientProfileId = params.id;
  const [isLoading, setIsLoading] = useState(true);
  const [family, setFamily] = useState();
  const [isInEditModeNotes, setIsInEditModeNotes] = useState(false);
  const [editedNotes, setEditedNotes] = useState('');

  // TODO: Replace when authentication is implemented.
  const advisorId = useSelector(({ auth }) => auth.account.id);

  useEffect(() => {
    clientApi
      .getClientProfileWithDependents(clientProfileId, advisorId)
      .then((response) => {
        setFamily({
          profile: response.data.profile,
          spousePartner: response.data.spousePartner,
          dependents: response.data.dependents,
        });
        setEditedNotes(response.data.profile.notes);
        setIsLoading(false);
      });
  }, [advisorId, clientProfileId]);

  const onChangeNotes = (event) => {
    setEditedNotes(event.target.value);
  };

  const handleClickCancelNotes = () => {
    setEditedNotes(family.profile.notes);
    setIsInEditModeNotes(false);
  };

  const handleClickSaveNotes = () => {
    const updatedFamily = { ...family };
    updatedFamily.profile.notes = editedNotes;
    clientApi
      .updateClientProfile(updatedFamily.profile)
      .then(() => {
        setFamily({ ...updatedFamily });
      })
      .catch((error) => {
        toast.error(<ErrorToast errorMessages={shapeErrorsForToast(error)} />, {
          autoClose: false,
        });
      })
      .finally(() => {
        setIsInEditModeNotes(false);
      });
  };

  if (!family) {
    return null;
  }

  return (
    <>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <Grid container item xs={12} className={classes.root}>
        &nbsp;
      </Grid>
      <Grid container item xs={12} className={classes.root}>
        <Grid item xs={12}>
          <Profile profile={family.profile} />
        </Grid>
      </Grid>
      <Grid container item xs={12} className={classes.root}>
        &nbsp;
      </Grid>
      <Grid container item xs={12} className={classes.root}>
        <Grid item xs={12} sm={12} md={4} className={classes.leftColumn}>
          <Paper spacing={2} elevation={3}>
            <Grid container>
              <Grid container item xs={12} className={classes.header}>
                <Grid item xs={9}>
                  <Typography component='h6' variant='h6' color='primary'>
                    LIFE EVENTS
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Button variant='contained' color='primary' disabled>
                    Add
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid container className={classes.content}>
              Pardon the dust! We are working on this feature.
            </Grid>
          </Paper>
          <Grid item xs={12}>
            &nbsp;
          </Grid>
          <Paper spacing={2} elevation={3}>
            <Grid container>
              <Grid container item xs={12} className={classes.header}>
                <Grid item xs={9}>
                  <Typography component='h6' variant='h6' color='primary'>
                    ACTION ITEMS
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Button variant='contained' color='primary' disabled>
                    Add
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid container className={classes.content}>
              Do you have a hardhat? This feature is being built.
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} className={classes.middleColumn}>
          <Paper spacing={2} elevation={3}>
            <Grid container>
              <Grid item xs={12} className={classes.header}>
                <Typography component='h6' variant='h6' color='primary'>
                  FAMILY
                </Typography>
              </Grid>
            </Grid>
            {family.profile && (
              <SpousePartner
                spousePartnerRelation={family.spousePartner}
                clientProfileId={clientProfileId}
                clientProfileAddress={pick(family.profile, [
                  'preferredAddressLineOne',
                  'preferredAddressLineTwo',
                  'preferredAddressCity',
                  'preferredAddressStateOrProvince',
                  'preferredAddressPostalCode',
                ])}
              />
            )}
            <Grid container item xs={12} />
            <Divider
              variant='middle'
              style={{ marginTop: 10, marginBottom: 10 }}
            />
            <OtherDependents
              dependentsList={family.dependents}
              clientProfileId={clientProfileId}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={4} className={classes.rightColumn}>
          <Paper spacing={2} elevation={3}>
            <Grid container>
              <Grid container item xs={12} className={classes.header}>
                <Grid item xs={9}>
                  <Typography component='h6' variant='h6' color='primary'>
                    NOTES
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => setIsInEditModeNotes(true)}
                  >
                    Edit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12} className={classes.content}>
              {!isInEditModeNotes && <>{family.profile.notes}</>}
              {isInEditModeNotes && (
                <Grid container item xs={12}>
                  <Grid item xs={12}>
                    <TextField
                      label='Notes'
                      fullWidth
                      variant='outlined'
                      rowsMax={25}
                      rows={10}
                      multiline
                      maxLength={2000}
                      value={editedNotes}
                      onChange={onChangeNotes}
                    />
                  </Grid>
                  <Grid container item xs={12} justify='flex-end'>
                    <Button
                      color='primary'
                      variant='outlined'
                      onClick={handleClickCancelNotes}
                      style={{ marginRight: 5 }}
                    >
                      Cancel
                    </Button>
                    <Button
                      color='primary'
                      variant='contained'
                      onClick={handleClickSaveNotes}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Paper>
          <Grid item xs={12}>
            &nbsp;
          </Grid>
          <Paper spacing={2} elevation={3}>
            <Grid container>
              <Grid container item xs={12} className={classes.header}>
                <Grid item xs={9}>
                  <Typography component='h6' variant='h6' color='primary'>
                    FINANCIAL INFO
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Button variant='contained' color='primary' disabled>
                    Add
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid container className={classes.content}>
              Do not cross! Construction zone.
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

ClientProfile.propTypes = {};

ClientProfile.defaultProps = {};

export default ClientProfile;

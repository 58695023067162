import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import { DELETE_TRANSACTION } from 'store/asyncActions/taxCast';
import { taxCastActions } from 'store/slices/taxCast';

const useStyles = makeStyles((theme) => ({
  name: {
    marginLeft: theme.spacing(1),
    fontWeight: 'bold',
  },
  deleteButton: {
    borderColor: theme.palette.danger,
    color: theme.palette.danger,
  },
  button: {
    [theme.breakpoints.up('sm')]: {
      minWidth: theme.spacing(13),
    },
  },
}));

const DeleteTransactionDialog = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const transaction = useSelector(
    ({ taxCast }) => taxCast.deleteTransactionDialog
  );
  const isSubmitting = useSelector(({ taxCast }) => taxCast.isSubmitting);

  const closeDialog = () => {
    dispatch(taxCastActions.TOGGLE_DELETE_TRANSACTION_DIALOG(null));
  };

  const deleteTransaction = () => {
    dispatch(DELETE_TRANSACTION(transaction));
  };

  if (!transaction) {
    return null;
  }

  return (
    <Dialog
      open={!!transaction}
      disableBackdropClick
      aria-labelledby='Delete Transaction Dialog'
    >
      <DialogTitle id='delete_transaction_title'>
        Delete
        <Typography
          component='span'
          color='primary'
          variant='h6'
          className={classes.name}
        >
          {transaction.name}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <DialogContentText id='delete_transaction_description'>
          Warning: This is an irreversible process. Please confirm this is what
          you want.
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={deleteTransaction}
          disabled={isSubmitting}
          className={clsx(classes.button, {
            [classes.deleteButton]: !isSubmitting,
          })}
          variant='outlined'
        >
          {isSubmitting ? 'Deleting' : 'Delete'}
        </Button>
        <Button
          onClick={closeDialog}
          disabled={isSubmitting}
          className={classes.button}
          variant='outlined'
          type='submit'
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteTransactionDialog;
